import { Alert, Keyboard, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import { Text, View } from 'src/components/Themed';
import { Color } from 'src/constant/Color';
import { StackActions, useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import Onboarding from 'src/components/Onboarding';
import { useDispatch } from 'react-redux';
import { setUser, setUserProfile, signIn } from 'src/store/reducer/userReducer';
import { AuthService } from 'src/service/AuthService';
import Icon from 'react-native-vector-icons/FontAwesome';
import StorageUtil from 'src/util/StorageUtil';
import { GenUtil } from 'src/util/GenUtil';
import { signInToFirebase } from 'firebaseConfig';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
import { ProfileStatus } from 'src/common/models/profile.model';
import { ProfileUpdateService } from 'src/service/ProfileUpdateService';
import { PrivacySettingsBody } from 'src/common/dto/profile.dto';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';

export default function SignIn({ navigation }) {
  /*
  use this credentials to login {
    "emailOrMobile": "anas.ansal10@gmail.com",
    "password":"123456"
    }
   */
  const [emailOrMobile, setEmailOrMobile] = useState();
  const [password, setPassword] = useState();
  const [emailValid, setEmailValid] = useState({});
  const [passwordValid, setPasswordValid] = useState({});
  const [profileStatus, setProfileStatus] = useState('live');
  const [response, setResponse] = useState();
  const dispatch = useDispatch();

  const validateEmailOrMobile = (email: string) => {
    return (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ) || String(email).match(/^\+[1-9]{1}[0-9]{3,14}$/)
    );
  };

  const validatePassword = (password: string) => {
    return password?.trim().length > 2;
  };

  const handleChangeEmailOrMobile = (email: string) => {
    setEmailValid(validateEmailOrMobile(email));
    setEmailOrMobile(email);
  };

  const makeProfileLive = async () => {
    try {
      const privacySettingsBody: PrivacySettingsBody = {
        status: ProfileStatus.Live,
      };
      const response = await ProfileUpdateService.updatePrivacySettings(privacySettingsBody);
      if (!response.success) return;

      dispatch(
        showError({ message: 'Your profile is live now', alertType: AlertTypeEnum.Success }),
      );
    } catch (error) {
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
    }
  };

  const handleChangePassword = (password: string) => {
    setPasswordValid(validatePassword(password));
    setPassword(password);
  };

  const loginUser = (response) => {
    try {
      dispatch(setUserProfile(response.data.profile));
      dispatch(setUser(response.data.user));
      const firebaseToken: string = response.data.firebaseToken;
      signInToFirebase(firebaseToken);

      StorageUtil.setAuthTokenInLocalStorage(response.data.token);
      StorageUtil.setFirebaseAuthTokenInLocalStorage(firebaseToken);
    } catch (error) {
      console.log('Error in login user', error);
    }
  };

  const handleKeyPress = async () => {
    console.log({ emailOrMobile, password });
    Keyboard.dismiss();
    try {
      const response = await AuthService.loginUser({
        emailOrMobile,
        password,
      });

      if (response.success) {
        setResponse(response);
        const status = response?.data?.profile?.status;
        setProfileStatus(status);
        if (status === 'live') {
          loginUser(response);
          navigation.navigate('user');
        }
      }
      return;
    } catch (error) {
      console.error(error);
      dispatch(
        showError({ alertType: AlertTypeEnum.Error, message: GenUtil.getMessageFromError(error) }),
      );
    }
  };

  const handleSignUp = () => {
    navigation.goBack();
    console.log('signedup!');
  };

  const handleConfirmPressed = async () => {
    loginUser(response);
    await makeProfileLive();
  };

  const handleOnCancel = () => {
    navigation.dispatch(StackActions.replace('welcome'));
  };

  return (
    <>
      {profileStatus !== 'live' ? (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
          }}
        >
          <ConfirmationModal
            cancelButtonText="Cancel"
            primaryButtonText="Confirm"
            message={`It looks like your profile is ${profileStatus}, make it visible to everyone`}
            title="Make profile visible"
            onCancel={handleOnCancel}
            onPrimaryButtonPressed={handleConfirmPressed}
            primaryButtonStyle={{ backgroundColor: Color.purple }}
          />
        </View>
      ) : (
        <Onboarding
          title="Sign In"
          buttonText="Sign In"
          Component={
            <React.Fragment>
              <Text style={styles.info}>Please enter below details to continue</Text>

              <Text style={styles.labelText}>Email or Mobile</Text>

              <TextInput
                style={
                  emailValid
                    ? styles.textInput
                    : StyleSheet.compose(styles.textInput, styles.textInputErrorOverride)
                }
                autoCapitalize={'none'}
                keyboardType={'email-address'}
                value={emailOrMobile}
                onChangeText={(value) => handleChangeEmailOrMobile(value)}
                placeholder={'davis@gmail.com'}
                placeholderTextColor={Color.gray}
              />
              <View
                style={
                  emailValid
                    ? styles.errorView
                    : StyleSheet.compose(styles.errorView, styles.errorViewAppearOverride)
                }
              >
                <Icon name="warning" color={Color.red} />
                <Text style={styles.errorInfo}>Enter a valid email or mobile number</Text>
              </View>

              <Text style={styles.labelText}>Password</Text>

              <TextInput
                style={
                  passwordValid
                    ? styles.textInput
                    : StyleSheet.compose(styles.textInput, styles.textInputErrorOverride)
                }
                value={password}
                onChangeText={(value) => handleChangePassword(value)}
                placeholder={'*****'}
                secureTextEntry
                autoCorrect={false}
                placeholderTextColor={Color.gray}
              />
              <View
                style={
                  passwordValid
                    ? styles.errorView
                    : StyleSheet.compose(styles.errorView, styles.errorViewAppearOverride)
                }
              >
                <Icon name="warning" color={Color.red} />
                <Text style={styles.errorInfo}>Password must be atleast 3 characters</Text>
              </View>
              <View style={styles.singUpMsgStyle}>
                <Text style={styles.noAcc}>Don't have an account? </Text>
                <TouchableOpacity onPress={handleSignUp}>
                  <Text style={styles.signUp}>Sign up</Text>
                </TouchableOpacity>
              </View>
            </React.Fragment>
          }
          handleKeyPress={handleKeyPress}
          buttonDisabled={
            emailOrMobile?.trim().length < 3 ||
            password?.trim().length < 3 ||
            !emailValid ||
            !passwordValid
          }
          isMinimumPaddingTop
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  textInput: {
    fontSize: 18,
    fontWeight: '700',
    color: Color.purple,
    backgroundColor: Color.backgroundGray,
    width: '100%',
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: Color.white,
  },
  textInputErrorOverride: {
    borderWidth: 1,
    borderColor: Color.red,
  },
  info: {
    color: Color.gray,
    marginTop: 10,
    left: 5,
    top: -15,
    fontSize: 15,
  },
  signUp: {
    color: Color.purple,
    // top: 6,
    fontSize: 15,
    fontWeight: 'bold',
  },
  noAcc: {
    zIndex: 1,
    color: Color.gray,
    fontSize: 15,
  },
  labelText: {
    color: Color.gray,
    marginVertical: 10,
    left: 5,
  },
  errorView: {
    flexDirection: 'row',
    marginTop: 5,
    left: 5,
    opacity: 0,
    alignItems: 'center',
  },
  errorViewAppearOverride: {
    opacity: 1,
  },
  errorInfo: {
    color: Color.red,
    marginLeft: 5,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  singUpMsgStyle: {
    zIndex: 1,
    marginTop: GenUtil.isWEB() ? 295 : 375,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    top: -15,
  },
});
