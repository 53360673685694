import React from 'react';
import { TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native';
import { Text } from 'src/components/Themed';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';

const SaveButton = ({
  buttonDisabled = false,
  isValidating = false,
  buttonText = 'Continue',
  handleKeyPress = () => {},
}) => {
  return (
    <TouchableOpacity
      disabled={buttonDisabled}
      style={
        !buttonDisabled
          ? styles.button
          : StyleSheet.compose(styles.button, styles.buttonDisabledOverride)
      }
      onPress={handleKeyPress}
    >
      {isValidating ? (
        <ActivityIndicator size={15} color={Color.purple} />
      ) : (
        <Text style={styles.buttonText}>{buttonText}</Text>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: Color.white,
    borderWidth: 1,
    borderColor: Color.purple,
    width: 90,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    borderRadius: 5,
    marginVertical: 10,
  },
  buttonDisabledOverride: {
    backgroundColor: Color.gray,
  },
  buttonText: {
    color: Color.purple,
    fontSize: 12,
    fontFamily: EFonts.SORA_MEDIUM,
  },
});
export default SaveButton;
