import { useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { Text, View } from 'src/components/Themed';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import EFonts from 'src/constant/EFonts';
import { ReligionData } from 'src/common/data/religion.data';
import PillShapeChip from 'src/components/common/chips/PillShapeChip';

interface religionOptions {
  key: string;
  value: string;
}

const { width } = GenUtil.getDimension();

const OnboardingReligionSelectionComp = ({
  prev = () => {},
  next = () => {},
  religion = '',
  setReligion = (_) => {},
}) => {
  const [religionOptions, setReligionOptions] = useState<religionOptions[]>();
  const [religionSelected, setReligionSelected] = useState(religion ? [religion] : []);

  useEffect(() => {
    const temp = [];
    ReligionData.map((res) => {
      temp.push({ key: res.name, value: res.name });
    });
    setReligionOptions(temp);
  }, []);

  useEffect(() => {
    setReligion('');
    if (religionSelected.length > 0) setReligion(religionSelected?.[0]);
  }, [religionSelected]);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Your Religion</Text>
      <PillShapeChip
        options={religionOptions}
        selected={religionSelected}
        setSelected={setReligionSelected}
        callChipClicked
        chipClicked={() => {
          setTimeout(() => next(), 500);
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontFamily: EFonts.SORA_LIGHT,
    fontSize: 28,
    lineHeight: 38,
    color: Color.lightBlack,
    marginTop: 10,
  },
  container: {
    paddingTop: 20,
    flex: GenUtil.isWEB() ? null : 1,
    paddingHorizontal: width * 0.05,
    backgroundColor: 'transparent',
  },
});

export default OnboardingReligionSelectionComp;
