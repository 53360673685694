import { View, Text, StyleSheet } from 'react-native';
import React from 'react';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import PhotoCollage from './PhotoCollage';
import FilledButton from '../common/buttons/FilledButton';
import { InterestActionType, InterestsStatus } from 'src/common/models/interest.model';
import { InterestService } from 'src/service/InterestService';
import { ProfileService } from 'src/service/ProfileService';
import { Icon } from 'react-native-elements';

const InterestRejectedCard = ({
  isSender,
  senderName,
  interestUuid,
  profileIds,
  gender,
  otherUserGender,
  otherUserProfilePhoto,
  profilePhoto,
  showButtons,
  setInterestAccepted,
}: {
  isSender: boolean;
  senderName: string;
  interestUuid: string;
  profileIds: string[];
  gender: string;
  otherUserGender: string;
  otherUserProfilePhoto: string;
  profilePhoto: string;
  showButtons?: boolean;
  setInterestAccepted?: (t) => void;
}) => {
  const message = isSender
    ? `You have rejected ${senderName}'s interest`
    : `${senderName} has rejected your interest.`;

  const getInterest = async () => {
    try {
      const response = await ProfileService.listProfilesDetails(profileIds);
      const profiles = response.data.data;
      for (const { interest } of profiles) {
        if (interest) {
          return interest;
        }
      }
      console.log(profiles);
      return null;
    } catch (error) {
      console.log('Error in getInterest', error);
    }
  };

  const handleAcceptPressed = async () => {
    try {
      const interest = await getInterest();
      if (interest === null) {
        alert("This interest dosn't exsist");
        return;
      }

      console.log('interes in accept', interest);

      if (interest.status === InterestsStatus.Accepted) {
        alert('This interest is already accepted!!!');
        return;
      }
      const actionBody = {
        interestUuid: interestUuid,
        customMessage: 'Interest Accepted',
        type: InterestActionType.Accept,
      };
      const response = await InterestService.actionOnInterest(actionBody);
      setInterestAccepted(true);
      console.log('response of interest accept', response);
    } catch (error) {
      console.log('Error in handleAcceptPressed', error);
    }
  };
  return (
    <View style={isSender ? styles.containerStyleForSender : styles.containerStyleForReceiver}>
      <View style={isSender ? styles.headerStyleForSender : styles.headerStyleForReceiver}>
        <View style={{ height: 68, width: 136 }}>
          <PhotoCollage
            icon={<Icon name="cancel" color={'red'} size={20} />}
            profilePhoto={profilePhoto}
            gender={gender}
            otherUserGender={otherUserGender}
            otherUserProfilePhoto={otherUserProfilePhoto}
          />
        </View>
        <View
          style={{
            marginTop: 6,
            marginBottom: 8,
          }}
        >
          <Text
            style={{
              fontFamily: EFonts.SORA_SEMIBOLD,
              fontSize: 15,
              lineHeight: 20,
              color: Color.lightBlack,
            }}
          >
            Interest Rejected
          </Text>
        </View>
      </View>

      <View style={{ marginTop: 8, paddingHorizontal: isSender ? 0 : 8 }}>
        <Text
          style={{
            fontFamily: EFonts.SORA_REGULAR,
            fontSize: 12,
            lineHeight: 20,
            color: Color.lightBlack,
          }}
        >
          {message}
        </Text>
      </View>
      {isSender && showButtons ? (
        <View
          style={{
            marginVertical: 8,
            flexDirection: 'row',
            justifyContent: 'center',
            paddingHorizontal: 20,
          }}
        >
          <FilledButton
            title="Accept again"
            style={{
              backgroundColor: Color.purple,
              paddingVertical: 5,
              paddingHorizontal: 5,
              borderWidth: 1,
            }}
            textStyle={{
              fontFamily: EFonts.SORA_REGULAR,
              fontSize: 12,
              lineHeight: 15,
              color: Color.white,
              marginVertical: 0,
              marginHorizontal: 0,
            }}
            onPress={handleAcceptPressed}
          />
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  containerStyleForSender: {
    width: 230,
    backgroundColor: '#F6F6F6',
    borderRadius: 12,
    borderBottomRightRadius: 0,
    paddingHorizontal: 8,
    paddingBottom: 8,
  },
  containerStyleForReceiver: {
    width: 230,
    backgroundColor: '#F6F6F6',
    borderRadius: 12,
    borderBottomLeftRadius: 0,
    paddingBottom: 8,
  },
  headerStyleForSender: {
    borderBottomWidth: 1,
    borderColor: Color.lightBorder,
    paddingVertical: 8,
    alignItems: 'center',
  },
  headerStyleForReceiver: {
    borderRadius: 12,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderColor: Color.lightBorder,
    alignItems: 'center',
  },
});

export default InterestRejectedCard;
