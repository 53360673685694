import React, { useEffect, useState } from 'react';
import { StyleSheet, FlatList } from 'react-native';
import { View, Text } from 'src/components/Themed';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import { Color } from 'src/constant/Color';
import ModalAdornment from 'src/components/editProfileComponents/atoms/ModalAdornment';
import { ReligionData } from 'src/common/data/religion.data';
import SaveButton from 'src/components/editProfileComponents/SaveButton';
import { ProfileUpdateService } from 'src/service/ProfileUpdateService';
import { setUserProfile } from 'src/store/reducer/userReducer';
import { useDispatch } from 'react-redux';
import { ReligiousBelief } from 'src/common/dto/profile.dto';

export default function MyReligionBeliefs({ profileData }: { profileData: Partial<ProfileResp> }) {
  const religionFilter = ReligionData?.find((item) => item?.name === profileData?.religion);

  const genderFilter = religionFilter?.religiousBeliefs?.filter(
    (value) => !value?.gender || value?.gender?.split('.')?.[1] === profileData?.gender,
  );

  const [religiousBeliefsQuestions, setReligiousBeliefsQuestions] = useState(
    genderFilter?.reduce((acc, obj) => {
      acc[obj?.question] = '';
      return acc;
    }, {}),
  );

  const [isValidating, setIsValidating] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const religiousBeliefsDataArr = profileData?.religionExtra?.religiousBeliefs;
    const religiousBeliefsDataObj = religiousBeliefsDataArr?.reduce((acc, obj) => {
      if (obj?.label) {
        acc[obj?.label] = obj?.value;
        return acc;
      }
    }, {});

    if (religiousBeliefsDataObj !== undefined) {
      let tempObj = religiousBeliefsQuestions;
      Object.keys(tempObj)?.map((value) => {
        if (religiousBeliefsDataObj?.[value]) {
          tempObj = { ...tempObj, [value]: religiousBeliefsDataObj?.[value] as string };
        }
      });
      setReligiousBeliefsQuestions(tempObj);
    }
  }, []);

  const handleSave = async () => {
    try {
      setIsValidating(true);
      const religiousBeliefsSelected: ReligiousBelief[] = Object.keys(religiousBeliefsQuestions)
        ?.filter((data) => religiousBeliefsQuestions?.[data] !== '')
        ?.map((value) => ({
          label: value,
          value: religiousBeliefsQuestions?.[value] as string,
        }));
      if (religiousBeliefsSelected?.length > 0) {
        const finalData = {
          religiousBeliefs: religiousBeliefsSelected,
        };
        const updateReligiousBeliefsResponse = await ProfileUpdateService.updateReligiousBeliefs(
          finalData,
        );
        if (updateReligiousBeliefsResponse?.httpCode === 200) {
          //update redux
          dispatch(setUserProfile(updateReligiousBeliefsResponse?.data));
        }
      }
      setIsValidating(false);
    } catch (error) {
      console.log(error);
      setIsValidating(false);
    }
  };

  interface interfaceRowData {
    id: number;
    label: string;
    itemArray: string[];
    religiousBeliefsQuestions: { [key: string]: string };
    setReligiousBeliefsQuestions: (_) => void;
  }

  const filteredData: interfaceRowData[] = genderFilter?.map((value, key) => ({
    id: key,
    itemArray: value?.correctOptions,
    label: value?.question,
    religiousBeliefsQuestions: religiousBeliefsQuestions,
    setReligiousBeliefsQuestions: setReligiousBeliefsQuestions,
  }));

  const renderItem = ({ item }: { item: interfaceRowData }) => (
    <View style={styles.info}>
      <Text style={styles.infoLabel}>{item?.label}</Text>

      <ModalAdornment
        modalItems={item?.itemArray}
        isReligiousBelief={true}
        label={item?.label}
        placeHolder="Select"
        religiousBeliefs={item?.religiousBeliefsQuestions}
        setReligiousBeliefs={item?.setReligiousBeliefsQuestions}
      />
    </View>
  );

  return (
    <View style={styles.container}>
      <FlatList
        data={filteredData}
        renderItem={renderItem}
        keyExtractor={(item) => item?.id?.toString()}
        numColumns={1}
        scrollEnabled={false}
      />

      <View style={styles.saveButtonWrapper}>
        <SaveButton buttonText="Save" handleKeyPress={handleSave} isValidating={isValidating} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.white,
    paddingHorizontal: 6,
  },
  infoLabel: {
    flex: 1,
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
    color: Color.lightGrey,
    marginLeft: 15,
  },
  info: {
    width: '100%',
    marginVertical: 3,
  },
  saveButtonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
