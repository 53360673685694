import { View, Text, StyleSheet, ImageBackground, Image, ImageSourcePropType } from 'react-native';
import React from 'react';

import { Color } from 'src/constant/Color';
import { QualificationDataMap } from 'src/common/data/education.data';
import { GenUtil } from 'src/util/GenUtil';
import TruncatedText from 'src/components/common/TruncatedText';
import EFonts from 'src/constant/EFonts';
import { ProfileResp } from 'src/common/response/profile.resp';
import { EducationRecord, EmploymentRecord } from 'src/common/models/profile.model';

const RenderSingleItem = ({ title, type }) => {
  const containerStyle =
    type === 'heading' ? { minHeight: 19, minWidth: 34, marginBottom: 8 } : { marginBottom: 5 };
  const textStyle = type === 'heading' ? styles.headingStyle : styles.textStyle;
  if (title)
    return (
      <View style={containerStyle}>
        <Text style={textStyle}>{title}</Text>
      </View>
    );
};

const RenderListItem = ({
  item,
  isLastItem,
  type,
  index,
}: {
  item: EducationRecord | EmploymentRecord;
  isLastItem: boolean;
  type: string;
  index: number;
}) => {
  const graduationIcon = require('src/assets/icons/graduation3x.png') as ImageSourcePropType;
  const companyIcon1 = require('src/assets/icons/companyIcon1-3x.png') as ImageSourcePropType;
  const companyIcon2 = require('src/assets/icons/companyIcon2-3x.png') as ImageSourcePropType;

  let icon = graduationIcon;
  if (type === 'careerDetails') {
    icon = index % 2 === 0 ? companyIcon1 : companyIcon2;
  }

  return (
    <View style={styles.detailsRow}>
      <View style={styles.imageLogo}>
        <Image style={styles.imageLogo} source={icon} />
      </View>
      <View style={styles.detailsContainer}>
        <View style={styles.yearAndDeatils}>
          <View style={styles.yearParentContainer}>
            {Object.keys(item).map((key) => {
              if (key.includes('year') || key === 'qualification') return;
              let title = item?.[key] as number | string;
              const type = key === 'college' || key === 'companyName' ? 'heading' : 'sub-heading';
              if (key === 'qualificationId') {
                const qualification = QualificationDataMap[item?.[key] as number]?.name;
                title = qualification;
              }
              return <RenderSingleItem title={title} type={type} key={key} />;
            })}
          </View>
          {item?.yearStarted !== 0 && (
            <View style={styles.yearParentContainer}>
              <View>
                <Text style={styles.yearText}>{`${item?.yearStarted} - ${
                  item?.yearCompleted ?? 'Present'
                }`}</Text>
              </View>
            </View>
          )}
        </View>
        {!isLastItem && <View style={styles.divider}></View>}
      </View>
    </View>
  );
};

const UserProfileEducationAndCareerComp = ({
  profileData,
  type,
}: {
  profileData: Partial<ProfileResp>;
  type: string;
}) => {
  const isCareerPage = type === 'careerDetails';
  const description = isCareerPage ? profileData.aboutCareer : profileData.aboutEducation;
  const title = isCareerPage ? 'Career details' : 'Educations';
  const educationBg =
    require('src/assets/images/background/background1.png') as ImageSourcePropType;
  const careersBg = require('src/assets/images/background/background2.png') as ImageSourcePropType;
  const backgroundImage = isCareerPage ? careersBg : educationBg;
  const heading = isCareerPage ? 'Profession' : 'Higest qualification';
  const qualificationOrCareer = isCareerPage
    ? profileData.profession
    : profileData.highestQualification;
  const data = isCareerPage ? profileData.employments : profileData.educations;

  if (description || qualificationOrCareer || data?.length > 0)
    return (
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{title}</Text>
        </View>
        {description && (
          <View style={styles.aboutContainer}>
            <TruncatedText text={description} numberOfLines={3} />
          </View>
        )}
        {qualificationOrCareer && (
          <View style={[styles.headingContainer, { marginTop: description ? 25 : 16 }]}>
            <ImageBackground
              style={{ flex: 1 }}
              imageStyle={{ borderRadius: 20 }}
              source={backgroundImage}
            >
              <View style={styles.imageChild}>
                <View>
                  <Text style={styles.titleHeader}>{heading}</Text>
                </View>
                <View style={styles.titleValueContainer}>
                  <Text style={styles.titleValue}>{qualificationOrCareer}</Text>
                </View>
                {isCareerPage && (profileData.inrIncome > 0 || profileData.usdIncome > 0) && (
                  <View style={styles.titleValueContainer}>
                    <Text style={styles.incomeText}>
                      {profileData.currentCountry === 'India'
                        ? String(profileData.inrIncome) + ' Lacs'
                        : String(profileData.usdIncome) + ' K'}
                    </Text>
                  </View>
                )}
              </View>
            </ImageBackground>
          </View>
        )}

        {isCareerPage && profileData.employments?.length > 0 && (
          <View style={styles.employementListHeadingCont}>
            <Text style={styles.employementListHeading}>Employment Journey</Text>
          </View>
        )}
        {data?.length > 0 && (
          <View style={styles.employementListContainer}>
            {data?.map((item: EducationRecord | EmploymentRecord, index: number) => {
              const isLastItem = index === data?.length - 1;
              const itemReorder = Object.fromEntries(
                Object.entries(item).sort((a, b) => (a[0] < b[0] ? -1 : 1)),
              ) as EducationRecord | EmploymentRecord;
              return (
                <RenderListItem
                  item={itemReorder}
                  isLastItem={isLastItem}
                  type={type}
                  index={index}
                  key={index}
                />
              );
            })}
          </View>
        )}
      </View>
    );
};

const styles = StyleSheet.create({
  container: {
    flex: GenUtil.isWEB() ? null : 1,
    backgroundColor: Color.white,
    paddingHorizontal: 12,
    paddingVertical: 16,
    marginHorizontal: 16,
    marginVertical: 5,
    borderRadius: 20,
    elevation: 6,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
  },
  titleContainer: {
    minHeight: 21,
    minWidth: 97,
  },
  title: {
    fontFamily: EFonts.SORA_MEDIUM,
    fontSize: 17,
    lineHeight: 18,
    color: Color.lightBlack,
  },
  aboutContainer: {
    marginTop: 16,
  },
  details: {
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 20,
    color: '#2C2C2C',
  },
  headingContainer: {
    marginTop: 25,
  },
  headingStyle: {
    fontSize: 15,
    lineHeight: 19,
    fontFamily: EFonts.SORA_MEDIUM,
  },
  textStyle: {
    fontSize: 12,
    lineHeight: 15,
    fontFamily: EFonts.SORA_REGULAR,
  },
  imageChild: {
    flex: 1,
    paddingTop: 12,
    paddingBottom: 12,
    alignItems: 'center',
    borderRadius: 20,
  },
  titleHeader: {
    fontSize: 12,
    lineHeight: 20,
    fontFamily: EFonts.SORA_REGULAR,
  },
  titleValueContainer: {
    marginTop: 12,
  },
  incomeText: {
    fontSize: 15,
    lineHeight: 20,
    fontFamily: EFonts.SORA_MEDIUM,
  },
  employementListHeading: {
    fontSize: 15,
    lineHeight: 19,
    fontFamily: EFonts.SORA_MEDIUM,
  },
  titleValue: {
    fontSize: 22,
    lineHeight: 22,
    fontFamily: EFonts.SORA_MEDIUM,
    marginHorizontal: 25,
  },
  employementListContainer: {
    marginTop: 19,
    flex: 1,
  },
  employementListHeadingCont: {
    marginTop: 25,
  },
  divider: {
    borderWidth: 1,
    borderColor: '#D6D6D6',
    marginTop: 5,
  },
  yearText: {
    fontSize: 12,
    lineHeight: 15,
    fontFamily: EFonts.SORA_REGULAR,
    textAlign: 'right',
  },
  detailsRow: {
    flexDirection: 'row',
  },
  imageLogo: {
    height: 30,
    width: 30,
  },
  yearParentContainer: {
    flex: 1,
  },
  detailsContainer: {
    flex: 1,
    marginLeft: 16,
  },
  yearAndDeatils: {
    marginTop: 5,
    flexDirection: 'row',
  },
});

export default UserProfileEducationAndCareerComp;
