import { View, Text, Image } from 'react-native';
import React from 'react';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import OutlinedButton from 'src/components/common/buttons/OutlinedButton';
import FilledButton from '../common/buttons/FilledButton';
import PhotoCollage from './PhotoCollage';
import { InterestService } from 'src/service/InterestService';
import { InterestActionType, InterestsStatus } from 'src/common/models/interest.model';
import { ProfileService } from 'src/service/ProfileService';

const InterestReceviedCard = ({
  name,
  interestUuid,
  profileIds,
  gender,
  otherUserGender,
  otherUserProfilePhoto,
  profilePhoto,
  showButtons,
  setInterestAccepted,
  setInterestRejected,
}: {
  name: string;
  interestUuid: string;
  profileIds: string[];
  gender: string;
  otherUserGender: string;
  otherUserProfilePhoto: string;
  profilePhoto: string;
  showButtons: boolean;
  setInterestAccepted: (t) => void;
  setInterestRejected: (t) => void;
}) => {
  const message = `You received an interest from ${name}.`;

  const getInterest = async () => {
    try {
      const response = await ProfileService.listProfilesDetails(profileIds);
      const profiles = response.data.data;
      for (const { interest } of profiles) {
        if (interest) {
          return interest;
        }
      }
      console.log(profiles);
      return null;
    } catch (error) {
      console.log('Error in getInterest', error);
    }
  };
  const handleRejectPressed = async () => {
    const interest = await getInterest();
    if (interest === null) {
      alert("This interest dosn't exsist");
      return;
    }

    if (interest.status === InterestsStatus.Declined) {
      alert('This interest is already declined!!!');
      return;
    }
    const actionBody = {
      interestUuid: interestUuid,
      customMessage: 'Interest Declined',
      type: InterestActionType.Decline,
    };
    const response = await InterestService.actionOnInterest(actionBody);
    setInterestRejected(true);
    console.log('response of cancel interest', response);
  };

  const handleAcceptPressed = async () => {
    try {
      const interest = await getInterest();
      if (interest === null) {
        alert("This interest dosn't exsist");
        return;
      }

      console.log('interes in accept', interest);

      if (interest.status === InterestsStatus.Accepted) {
        alert('This interest is already accepted!!!');
        return;
      }
      const actionBody = {
        interestUuid: interestUuid,
        customMessage: 'Interest Accepted',
        type: InterestActionType.Accept,
      };
      const response = await InterestService.actionOnInterest(actionBody);
      setInterestAccepted(true);
      console.log('response of interest accept', response);
    } catch (error) {
      console.log('Error in handleAcceptPressed', error);
    }
  };
  return (
    <View
      style={{
        width: 230,
        backgroundColor: '#F6F6F6',
        borderRadius: 12,
        borderBottomLeftRadius: 0,
        paddingHorizontal: 8,
      }}
    >
      <View
        style={{
          height: 68,
          borderBottomWidth: 1,
          borderColor: '#E1E1E1',
          paddingVertical: 8,
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            height: '100%',
            width: 72,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <PhotoCollage
            size={36}
            smallCircleSize={22}
            icon={
              <Image
                style={{ height: 12, width: 12, left: 1, bottom: 1 }}
                source={require('src/assets/icons/receiveIcon.png')}
              />
            }
            profilePhoto={profilePhoto}
            gender={gender}
            otherUserGender={otherUserGender}
            otherUserProfilePhoto={otherUserProfilePhoto}
          />
        </View>
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text
            style={{
              fontFamily: EFonts.SORA_SEMIBOLD,
              fontSize: 15,
              lineHeight: 20,
              color: Color.lightBlack,
            }}
          >
            Interest Received
          </Text>
        </View>
      </View>
      <View style={{ marginTop: 8 }}>
        <Text
          style={{
            fontFamily: EFonts.SORA_REGULAR,
            fontSize: 12,
            lineHeight: 20,
            color: Color.lightBlack,
          }}
        >
          {message}
        </Text>
      </View>
      {showButtons ? (
        <View
          style={{
            marginVertical: 8,
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
          }}
        >
          <OutlinedButton
            title="Reject"
            style={{ borderColor: Color.lightBlack, paddingVertical: 5, paddingHorizontal: 5 }}
            textStyle={{
              fontFamily: EFonts.SORA_REGULAR,
              fontSize: 12,
              lineHeight: 15,
              color: Color.lightBlack,
              marginVertical: 0,
              marginHorizontal: 0,
            }}
            onPress={handleRejectPressed}
          />
          <FilledButton
            title="Accept"
            style={{
              backgroundColor: Color.purple,
              paddingVertical: 5,
              paddingHorizontal: 5,
              borderWidth: 1,
            }}
            textStyle={{
              fontFamily: EFonts.SORA_REGULAR,
              fontSize: 12,
              lineHeight: 15,
              color: Color.white,
              marginVertical: 0,
              marginHorizontal: 0,
            }}
            onPress={handleAcceptPressed}
          />
        </View>
      ) : null}
    </View>
  );
};

export default InterestReceviedCard;
