import { View, Text, StyleSheet, ScrollView } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalAdornment from 'src/components/editProfileComponents/atoms/ModalAdornment';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';
import { ProfileUpdateService } from 'src/service/ProfileUpdateService';
import { ContactPrivacy, InterestPrivacy, PrivacyLevel } from 'src/common/models/profile.model';
import { ProfileResp } from 'src/common/response/profile.resp';
import { RootState } from 'src/store/store';
import { setUserProfile } from 'src/store/reducer/userReducer';
import { PrivacySettingsBody } from 'src/common/dto/profile.dto';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
import { GenUtil } from 'src/util/GenUtil';
import SafeContainer from 'src/components/SafeContainer';
import { useNavigation } from '@react-navigation/native';
import { Icon } from 'react-native-elements';

type PrivacyLabel = {
  namePrivacy?: string;
  photoPrivacy?: string;
  astroPrivacy?: string;
  interestPrivacy?: string;
  contactPrivacy?: string;
};

const PrivacySelectionComp = ({
  label,
  items,
  setPrivacySettings,
  defaultValue,
}: {
  label: Partial<PrivacyLabel>;
  items: string[];
  setPrivacySettings: (t) => void;
  defaultValue: string;
}) => {
  const [selectedItem, setSelectedItem] = useState('');
  useEffect(() => {
    if (!selectedItem) setSelectedItem(defaultValue);
    setPrivacySettings((prevBody: Partial<PrivacySettingsBody>) => ({
      ...prevBody,
      [Object.keys(label)[0]]: selectedItem,
    }));
  }, [selectedItem]);

  const labelText = Object.values(label)[0].split(' ')[0];
  let privacyLevel = selectedItem;
  if (selectedItem === 'Visible to All' || selectedItem === 'All') {
    privacyLevel = 'Everyone';
  } else if (selectedItem === 'Visible to None' || selectedItem === 'None') {
    privacyLevel = 'No one';
  } else {
    const regex = /^Visible to (.+)/;
    const match = privacyLevel.match(regex);

    if (match) {
      const variable = match[1];
      privacyLevel = `Only ${variable}`;
    }
  }

  const message =
    labelText === 'Interest'
      ? `${privacyLevel} can send you ${labelText}.`
      : `${privacyLevel} can see your ${labelText}.`;

  return (
    <View style={{ paddingHorizontal: 16, marginBottom: 26 }}>
      <View>
        <Text style={styles.labelStyle}>{Object.values(label)}</Text>
        <ModalAdornment
          modalItems={items}
          value={selectedItem}
          setValue={setSelectedItem}
          maxValueLength={60}
        />
        <Text style={styles.bottomText}>{message}</Text>
      </View>
    </View>
  );
};

const EditPrivacyPolicyScreen = () => {
  const user: Partial<ProfileResp> = useSelector((state: RootState) => state.user.userProfile);
  const dispatch = useDispatch();

  const privacyLabels: PrivacyLabel[] = [
    { namePrivacy: 'Name Privacy' },
    { photoPrivacy: 'Album Privacy' },
    { interestPrivacy: 'Interest Privacy' },
    { contactPrivacy: 'Phone Number' },
  ];
  const [privacySettings, setPrivacySettings] = useState({});
  const navigation = useNavigation();

  const handleUpdatePrivacyPressed = async () => {
    try {
      const updatePrivacyResponse = await ProfileUpdateService.updatePrivacySettings(
        privacySettings,
      );
      if (!updatePrivacyResponse.success) return;
      dispatch(
        showError({ message: 'Settings updated successfully', alertType: AlertTypeEnum.Success }),
      );
      dispatch(setUserProfile(updatePrivacyResponse?.data));
    } catch (error) {
      console.log('Error in updating privacy', error);
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
    }
  };
  return (
    <SafeContainer
      showPageHeader={true}
      heading="Privacy Settings"
      style={{ flex: 1, backgroundColor: Color.white }}
      leftIcon={<Icon name="arrow-back" />}
      onPressLeftIcon={() => navigation.navigate('settings')}
    >
      <ScrollView style={{ paddingTop: 20 }}>
        {privacyLabels.map((label, index) => {
          let item: PrivacyLevel[] | InterestPrivacy[] | ContactPrivacy[] =
            Object.values(PrivacyLevel);
          if (index === 2) item = Object.values(InterestPrivacy);
          if (index === 3) item = Object.values(ContactPrivacy);
          return (
            <PrivacySelectionComp
              label={label}
              key={index}
              items={item}
              setPrivacySettings={setPrivacySettings}
              defaultValue={user[Object.keys(label)[0]]}
            />
          );
        })}
        <View style={{ flex: 1, alignSelf: 'center', marginTop: 20 }}>
          <PrimaryButton buttonText="Update Privacy" handleKeyPress={handleUpdatePrivacyPressed} />
        </View>
      </ScrollView>
    </SafeContainer>
  );
};

const styles = StyleSheet.create({
  labelStyle: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 15,
    color: Color.lightGrey,
    marginBottom: 1,
    marginLeft: 10,
  },
  bottomText: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 15,
    color: Color.lightBlack,
    marginLeft: 10,
    marginTop: 3,
  },
});

export default EditPrivacyPolicyScreen;
