import { Image, ImageStyle, StyleSheet, View } from 'react-native';
import { Color } from 'src/constant/Color';

type AvatarProps = {
  src: string;
  style?: ImageStyle;
  blurImage?: boolean;
};

export const Avatar = ({ src, blurImage, style }: AvatarProps) => {
  return (
    <View>
      <Image source={{ uri: src }} style={[styles.img, style]} blurRadius={blurImage ? 4 : 0} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
  img: {
    width: 52,
    height: 52,
    borderRadius: 50,
    resizeMode: 'cover',
    borderWidth: 2,
    borderColor: Color.white,
  },
});
