import Constant from 'src/constant/Constant';
import { ELanguage } from 'src/model/language.model';
import SyncStorage from 'sync-storage';

export default class StorageUtil {
  static setKey(key: string, value: object | string | number | boolean) {
    SyncStorage.set(key, value);
  }

  static getKey(key: string): string {
    return SyncStorage.get(key);
  }

  static setObject(key: string, value: object) {
    const str = JSON.stringify(value);
    StorageUtil.setKey(key, str);
  }

  static getObject(key: string): object {
    const str = SyncStorage.get(key);
    // TODO: str could come as 'undefined' 'null' as well. Not getting used but fix it when you are using it.
    return !str ? {} : JSON.parse(str);
  }

  static getNumber(key: string): number {
    const value = StorageUtil.getKey(key);
    return parseInt(value) || 0;
  }

  static setNumber(key: string, val: number) {
    return SyncStorage.set(key, val);
  }

  static getBoolean(key: string, defaultValue: boolean): boolean {
    const value = StorageUtil.getKey(key);
    return value === 'true' ? true : value === 'false' ? false : defaultValue;
  }

  static setBoolean(key: string, val: boolean) {
    return StorageUtil.setKey(key, val);
  }

  static removeKey(key: string) {
    SyncStorage.remove(key);
  }

  static getAuthTokenFromLocalStorage(): string {
    return StorageUtil.getKey(Constant.AUTH_TOKEN);
  }

  static setAuthTokenInLocalStorage(token: string) {
    StorageUtil.setKey(Constant.AUTH_TOKEN, token);
  }

  static getFirebaseAuthTokenFromLocalStorage(): string {
    return StorageUtil.getKey(Constant.FIREBASE_TOKEN);
  }

  static setFirebaseAuthTokenInLocalStorage(token: string) {
    StorageUtil.setKey(Constant.FIREBASE_TOKEN, token);
  }

  static getLanguageFromLocalStorage(): string {
    return StorageUtil.getKey(Constant.LANGUAGE) || ELanguage.English.toLowerCase();
  }

  static getLocaleFromLocalStorage(): string {
    return StorageUtil.getLanguageFromLocalStorage() == ELanguage.Hindi.toLowerCase() ? 'hi' : 'en';
  }

  static isLoggedIn(): boolean {
    return !!StorageUtil.getAuthTokenFromLocalStorage();
  }

  static isFirstTimeLaunch() {
    return StorageUtil.getBoolean(Constant.FIRST_TIME_LAUNCH, true);
  }

  static updateFirstTimeLaunch() {
    return StorageUtil.setBoolean(Constant.FIRST_TIME_LAUNCH, false);
  }

  static saveLoggedInUserIdToLocalStorage(id: any) {}

  static clearStorage() {
    SyncStorage.getAllFromLocalStorage()
      .then((data) => {
        console.log('data:', data);
        const keys = Object.keys(data);
        keys.map((key) => StorageUtil.remove(key));
      })
      .catch((err) => {
        console.warn(err);
      });
  }
}
