import { StyleSheet, Text, View } from 'react-native';
import { Color } from 'src/constant/Color';
import React from 'react';
import Button from 'src/components/common/buttons/Button';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { useNavigation } from '@react-navigation/native';

const ProfileProgressCard = () => {
  const { userProfile } = useSelector((state: RootState) => state.user);
  const navigate = useNavigation();
  const handleButton = () => {
    navigate.navigate('editprofile');
  };

  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        Your profile is {userProfile?.profileCompletionScore}% completed
      </Text>
      <Text style={[styles.text, styles.textSmall]}>
        Complete your profile to get chance to more matches.
      </Text>
      <Button
        onPress={handleButton}
        variant={'primary'}
        size={'small'}
        styles={{ borderRadius: 8 }}
      >
        Complete Now
      </Button>
    </View>
  );
};

export default ProfileProgressCard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.darkPurple,
    textAlign: 'center',
    marginTop: 20,
    minHeight: 110,
    maxHeight: 120,
    width: '90%',
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 15,
  },
  text: {
    color: Color.white,
    fontSize: 12,
    fontWeight: '600',
    marginBottom: 5,
  },
  textSmall: {
    fontSize: 12,
    fontWeight: '400',
    marginBottom: 10,
  },
});
