import React, { useState } from 'react';
import { StyleSheet, FlatList, TouchableOpacity, Image, ScrollView } from 'react-native';
import { View, Text } from 'src/components/Themed';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import { Color } from 'src/constant/Color';
import InputAdornment from 'src/components/common/input/InputAdornment';
import ModalAdornment from 'src/components/editProfileComponents/atoms/ModalAdornment';
import { AgeData, HeightData } from 'src/constant/searchProfileOptionsData';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import moment from 'moment';
import {
  ChildStatus,
  HealthStatus,
  LivingWith,
  MaritalStatus,
  ProfileHandler,
} from 'src/common/models/profile.model';
import { HashtagDataMap, HashtagData } from 'src/common/data/hashtag.data';
import ChoicesSelector from 'src/components/editProfileComponents/ChoicesSelector';
import { LanguageData } from 'src/common/data/language.data';
import { majorCities } from 'src/screens/OnboardingScreen';
import { useAutoCompleteLocation } from 'src/hooks/useAutoCompleteLocation';
import SaveButton from 'src/components/editProfileComponents/SaveButton';
import { ProfileService } from 'src/service/ProfileService';
import { useDispatch } from 'react-redux';
import { setUserProfile } from 'src/store/reducer/userReducer';
import { LocationType } from 'src/common/models/location.model';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';

const { width } = GenUtil.getDimension();

export const DateView = ({ icon = null, value = '', placeHolder = 'Select' }) => {
  return (
    <View style={styles.wrapperPicker}>
      {icon && <TouchableOpacity style={styles.icon}>{icon}</TouchableOpacity>}
      <Text style={styles.inputPicker}>
        {value === '' ? placeHolder : value?.length > 12 ? value.slice(0, 8) + '...' : value}{' '}
      </Text>
    </View>
  );
};

export const TimeView = ({ icon = null, value = '', placeHolder = 'Select' }) => {
  return (
    <View style={styles.wrapperPicker}>
      {icon && <TouchableOpacity style={styles.icon}>{icon}</TouchableOpacity>}
      <Text style={styles.inputPicker}>
        {value === '' ? placeHolder : value?.length > 12 ? value.slice(0, 8) + '...' : value}{' '}
      </Text>
    </View>
  );
};

export default function MyPersonalDetails({ profileData }: { profileData: Partial<ProfileResp> }) {
  const [isValidating, setIsValidating] = useState(false);
  const dispatch = useDispatch();
  const [aboutMeText, setAboutMeText] = useState(profileData.aboutMe ? profileData.aboutMe : '');
  const [fullName, setFullName] = useState(profileData.fullName ?? '');
  const [ageValue, setAgeValue] = useState(profileData.age ?? '');
  const [profileHandlerValue, setProfileHandlerValue] = useState(profileData.profileHandler ?? '');
  const [maritalStatusValue, setMaritalStatusValue] = useState(profileData.maritalStatus ?? '');
  const [childStatusValue, setChildStatusValue] = useState(profileData.marriedChildStatus ?? '');
  const [heightValue, setHeightValue] = useState(
    profileData.height ? `${profileData.height} cm` : '',
  );
  const [healthStatusValue, setHealthStatusValue] = useState(
    profileData.healthStatuses ? profileData.healthStatuses[0] : '',
  );
  const [livingWithValue, setLivingWithValue] = useState(profileData.livingWith ?? '');
  const [currLocation, setCurrLocation] = useState(profileData.currentLocation ?? '');
  const [motherTongue, setMotherTongue] = useState(profileData.motherTongue ?? '');
  const [formattedDate, setFormattedDate] = useState(profileData.dob ?? '');
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const currentDate = moment().startOf('day').toDate();
  const [dobValue, setDobValue] = useState(currentDate);
  const [selectedHashtags, setSelectedHashtags] = useState(
    profileData.hashtags
      ? profileData.hashtags.map((name) => HashtagData.find((h) => h.name === name))
      : [],
  );
  const [locationData, searchedLocation, setSearchedLocation] = useAutoCompleteLocation(
    500,
    majorCities,
  );

  const showDatePicker = () => setIsDatePickerVisible(true);
  const hideDatePicker = () => setIsDatePickerVisible(false);
  const handleConfirm = (dob: Date) => {
    const d = moment(dob).format('YYYY-MM-DD');
    setFormattedDate(d);
    setDobValue(dob);
    hideDatePicker();
  };

  const INFO_ROWS = [
    {
      id: 0,
      icon: require('src/assets/images/editprofileicons/FrameFullname.png'),
      label: 'Full Name',
      value: fullName,
      setValue: setFullName,
    },
    {
      id: 1,
      icon: require('src/assets/images/editprofileicons/FrameDOB.png'),
      label: 'DOB',
      value: formattedDate,
      setValue: setFormattedDate,
    },
    {
      id: 2,
      icon: require('src/assets/images/editprofileicons/FrameAge.png'),
      itemArray: AgeData.map((item) => item.toString() + ' years old'),
      label: 'Age',
      value: ageValue,
      setValue: setAgeValue,
    },
    {
      id: 3,
      icon: require('src/assets/images/editprofileicons/FrameProfilehandler.png'),
      label: 'Profile Handler',
      itemArray: Object.values(ProfileHandler).map((value) => value as string),
      value: profileHandlerValue,
      setValue: setProfileHandlerValue,
    },
    {
      id: 4,
      itemArray: Object.values(MaritalStatus).map((value) => value as string),
      icon: require('src/assets/images/editprofileicons/FrameMaritalStatus.png'),
      label: 'Marital Status',

      value: maritalStatusValue,
      setValue: setMaritalStatusValue,
    },
    {
      id: 5,
      icon: require('src/assets/images/editprofileicons/FrameHeight.png'),
      itemArray: HeightData.map((item) => item.toString() + ' cm'),
      label: 'Height (in cm)',
      value: heightValue,
      setValue: setHeightValue,
    },
    {
      id: 6,
      icon: require('src/assets/images/editprofileicons/FrameHealthStatus.png'),
      label: 'Health Status',
      itemArray: Object.values(HealthStatus).map((value) => value as string),
      value: healthStatusValue,
      setValue: setHealthStatusValue,
    },
    {
      id: 7,
      icon: require('src/assets/images/editprofileicons/FrameLivingWith.png'),
      label: 'Living With',
      itemArray: Object.values(LivingWith).map((value) => value as string),
      value: livingWithValue,
      setValue: setLivingWithValue,
    },
    {
      id: 8,
      icon: require('src/assets/images/editprofileicons/FrameCurrentLocation.png'),
      itemArray: (searchedLocation === '' ? majorCities : locationData).map((item) =>
        GenUtil.getLocationName(
          item.cityName,
          item.stateName,
          item.countryName,
          LocationType.City,
          true,
        ),
      ),
      label: 'Current Location',
      value: currLocation,
      setValue: setCurrLocation,
    },
    {
      id: 9,

      icon: require('src/assets/images/editprofileicons/FrameMothertongue.png'),
      label: 'Mother tongue',
      itemArray: LanguageData.map((item) => item.name),
      value: motherTongue,
      setValue: setMotherTongue,
    },
    {
      id: 10,
      itemArray: Object.values(ChildStatus).map((value) => value as string),
      icon: require('src/assets/images/editprofileicons/Framesiblingsdata.png'),
      label: 'Children',
      value: childStatusValue,
      setValue: setChildStatusValue,
    },
  ];

  const myPersonalDetails = INFO_ROWS.filter((item) => item);

  const handleSave = async () => {
    try {
      setIsValidating(true);
      const currentLocation = currLocation.split(',');
      const data = {
        fullName,
        gender: profileData.gender,
        height: parseInt(heightValue),
        currentLocation: {
          countryName: currentLocation?.[2].trim(),
          stateName: currentLocation?.[1].trim(),
          cityName: currentLocation?.[0].trim(),
        },
        maritalStatus: maritalStatusValue,
        religion: profileData.religion,
        communityId: profileData.communityId,
        subCommunityId: profileData.subCommunityId,
        motherTongueId: LanguageData.find((l) => l.name === motherTongue).id,
        dob: formattedDate,
        profileHandler: profileHandlerValue,
        hashtags: selectedHashtags.map((h) => h.name),
        livingWith: livingWithValue,
        healthStatuses: [healthStatusValue],
        aboutMe: aboutMeText,
        childStatus: childStatusValue,
      };
      const updatePersonalDetailsResponse = await ProfileService.updatePersonalDetails(data);
      if (updatePersonalDetailsResponse?.httpCode === 200) {
        //update redux
        dispatch(setUserProfile(updatePersonalDetailsResponse?.data));
        dispatch(showError({ alertType: AlertTypeEnum.Success, message: 'Saved' }));
      }
      setIsValidating(false);
    } catch (error) {
      setIsValidating(false);
      dispatch(
        showError({ alertType: AlertTypeEnum.Error, message: GenUtil.getMessageFromError(error) }),
      );
      console.log(error);
    }
  };

  const renderItem = ({ item, index }) => {
    if (index === 10 && maritalStatusValue === MaritalStatus.NeverMarried) return <></>;
    return (
      <View style={styles.info}>
        <Text
          style={[
            styles.infoLabel,
            { marginTop: item?.label === 'Full Name' || item?.label === 'DOB' ? null : 6 },
          ]}
        >
          {item?.label}
        </Text>

        {index === 0 || index === 2 ? (
          <InputAdornment
            icon={<Image source={item?.icon} style={{ width: 18, height: 18 }} />}
            inputStyle={{ paddingVertical: 5, marginVertical: 5 }}
            textStyle={{ width: '100%' }}
            value={item?.value}
            setValue={item?.setValue}
            placeholder="Type Here"
            editable={false}
          />
        ) : item?.label === 'DOB' ? (
          <TouchableOpacity onPress={showDatePicker}>
            <DateView
              icon={<Image source={item?.icon} style={{ width: 18, height: 18 }} />}
              value={item?.value}
            />
          </TouchableOpacity>
        ) : (
          <ModalAdornment
            icon={<Image source={item?.icon} style={{ width: 18, height: 18 }} />}
            modalItems={item?.itemArray}
            value={item?.value}
            setValue={item?.setValue}
            placeHolder="Select"
            searchedLocation={searchedLocation}
            setSearchedLocation={setSearchedLocation}
            isLocation={item?.label === 'Current Location'}
          />
        )}
        <DateTimePickerModal
          isVisible={isDatePickerVisible}
          mode="date"
          onConfirm={handleConfirm}
          onCancel={hideDatePicker}
          maximumDate={currentDate}
          date={dobValue}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <FlatList
        data={myPersonalDetails}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        numColumns={2}
        scrollEnabled={false}
        columnWrapperStyle={{
          justifyContent: 'space-between',
        }}
      />
      <View style={{ width: width * 0.9 }}>
        <Text style={[styles.infoLabel, { marginTop: 6 }]}>About Me</Text>
        <View>
          <ModalAdornment
            value={aboutMeText}
            inputTextFunction={setAboutMeText}
            isInputText={true}
            placeHolder="Write about yourself..."
          />
        </View>
      </View>
      <ChoicesSelector
        dropdownItems={HashtagData}
        preprocessItems={(item: { name: string }) => item.name}
        title="Hashtags"
        placeHolder="Search hashtags here..."
        selectedValues={selectedHashtags}
        setSelectedValues={setSelectedHashtags}
      />
      <View style={styles.saveButtonWrapper}>
        <SaveButton buttonText="Save" handleKeyPress={handleSave} isValidating={isValidating} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.white,
    paddingHorizontal: 6,
  },
  aboutMeStyle: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    borderWidth: 1,
    borderColor: '#D6D6D6',
    width: '100%',
    lineHeight: 24,
    padding: 9,
    borderRadius: 8,
    marginTop: 4,
  },
  infoLabel: {
    flex: 1,
    fontFamily: EFonts.SORA_REGULAR,
    fontStyle: 'normal',
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
    color: Color.lightGrey,
    alignItems: 'center',
    marginLeft: 15,
  },
  info: {
    width: width * 0.42,
  },
  wrapperPicker: {
    minHeight: 38,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: Color.white,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginVertical: 5,
    marginRight: 5,
    borderWidth: 1,
    borderColor: Color.lightBorder,
  },
  inputPicker: {
    backgroundColor: Color.white,
    flex: 1,
    fontSize: 12,
    lineHeight: 15,
    fontFamily: EFonts.SORA_REGULAR,
    color: Color.black,
    paddingRight: 5,
    marginLeft: 10.83,
    outlineStyle: 'none',
    width: width * 0.1,
  },
  icon: {
    paddingVertical: 3,
  },
  saveButtonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
