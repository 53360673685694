import React from 'react';
import { View, Text, StyleSheet, Image, FlatList, ImageSourcePropType } from 'react-native';

import { ManglikStatus } from 'src/common/models/profile.model';
import { ProfileResp } from 'src/common/response/profile.resp';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';

const UserProfileAstroDetailsComp = ({ profileData }: { profileData: Partial<ProfileResp> }) => {
  interface InterfaceAstroDataArray {
    id: number;
    icon: ImageSourcePropType;
    title: string;
    value: string;
  }

  const astroDetailsArray: InterfaceAstroDataArray[] = [
    {
      id: 1,
      icon: require('src/assets/images/profileIcons/astrology.png'),
      title: 'Rashi',
      value: profileData.rashi,
    },
    {
      id: 2,
      icon: require('src/assets/images/profileIcons/dot-inside-a-circle.png'),
      title: 'Manglik',
      value: profileData.manglik
        ? profileData.manglik === ManglikStatus.Manglik
          ? 'YES'
          : profileData.manglik === ManglikStatus.NonManglik
          ? 'NO'
          : ManglikStatus.Angshik
        : '',
    },
    {
      id: 3,
      icon: require('src/assets/images/profileIcons/parchment.png'),
      title: 'Nakshatra',
      value: profileData.nakshatra,
    },
    {
      id: 4,
      icon: require('src/assets/images/profileIcons/cake.png'),
      title: 'Birthdate',
      value: profileData.astroDetails?.birthDate,
    },
    {
      id: 5,
      icon: require('src/assets/images/profileIcons/clock.png'),
      title: 'Birth Time',
      value: profileData.astroDetails?.birthTime,
    },
    {
      id: 6,
      icon: require('src/assets/images/profileIcons/google-maps.png'),
      title: 'Birth Place',
      value: profileData.astroDetails?.birthPlace,
    },
  ];

  const renderItem = ({ item }: { item: InterfaceAstroDataArray }) => (
    <View style={styles.astroItem}>
      <Image source={item.icon} style={styles.image} />
      <View style={styles.textParent}>
        <Text style={styles.astroHeading}>{item.title}</Text>
        <Text style={styles.astroValue}>{item.value}</Text>
      </View>
    </View>
  );

  const astroFilteredData: InterfaceAstroDataArray[] = astroDetailsArray.filter(
    (item) => item.value,
  );

  if (astroFilteredData.length)
    return (
      <View style={styles.container}>
        <Text style={styles.heading}>Astro Details</Text>
        <View>
          <FlatList
            data={astroFilteredData}
            renderItem={renderItem}
            keyExtractor={(item) => item.id.toString()}
            numColumns={2}
            columnWrapperStyle={{ marginTop: 18 }}
          />
        </View>
      </View>
    );
};
const styles = StyleSheet.create({
  container: {
    flex: GenUtil.isWEB() ? null : 1,
    marginHorizontal: 16,
    borderRadius: 20,
    paddingHorizontal: 12,
    paddingTop: 16,
    paddingBottom: 17,
    backgroundColor: Color.white,
    elevation: 6,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
    marginVertical: 5,
  },
  parentRow: {
    flex: 1,
    flexDirection: 'row',
  },
  astroItem: {
    flex: 1,
    flexDirection: 'row',
  },
  image: {
    height: 34,
    width: 34,
    alignSelf: 'flex-start',
  },
  heading: {
    fontSize: 17,
    lineHeight: 21,
    fontFamily: EFonts.SORA_MEDIUM,
    color: Color.lightBlack,
  },
  textParent: {
    justifyContent: 'space-between',
    marginLeft: 10,
    flex: 1,
    alignSelf: 'flex-start',
  },
  astroHeading: {
    fontSize: GenUtil.isWEB() ? 13 : 10.5,
    lineHeight: 15,
    color: Color.lightGrey,
    fontFamily: EFonts.SORA_REGULAR,
  },
  astroValue: {
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 15,
    color: Color.lightBlack,
    fontFamily: EFonts.SORA_SEMIBOLD,
    marginTop: GenUtil.isWEB() ? 5 : 0,
  },
});
export default UserProfileAstroDetailsComp;
