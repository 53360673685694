import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import EFonts from 'src/constant/EFonts';
import { Icon } from 'react-native-elements';
import { GenUtil } from 'src/util/GenUtil';
import React from 'react';
import HeaderBackButton from 'src/components/HeaderBackButton';

type PageHeaderProps = {
  heading: string;
  rightIcon?: React.ReactElement;
  leftIcon?: React.ReactNode;
  renderHeading?: React.ReactNode;
  onPressLeftIcon?: () => void;
};

export default function PageHeader({
  heading,
  rightIcon = null,
  renderHeading,
  leftIcon = null,
  onPressLeftIcon = () => {},
}: PageHeaderProps) {
  function onFilterPress() {}

  return (
    <View style={[styles.container, { paddingVertical: renderHeading ? 0 : 17 }]}>
      <View style={styles.iconAndText}>
        <HeaderBackButton
          icon={leftIcon ?? <Icon name="arrow-back" />}
          onPressIcon={leftIcon ? onPressLeftIcon : null}
        />
        {renderHeading ? renderHeading : <Text style={styles.text}>{heading}</Text>}
      </View>
      <TouchableOpacity onPress={onFilterPress}>{rightIcon}</TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 17,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomLeftRadius: GenUtil.isWEB() ? 0 : 30,
    borderBottomRightRadius: GenUtil.isWEB() ? 0 : 30,
    backgroundColor: '#FFFFFF',
    elevation: 6,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
    paddingTop: GenUtil.isIOS() ? 50 : 35,
    zIndex: 1000,
  },
  text: {
    marginLeft: 19,
    fontSize: 17,
    fontFamily: EFonts.SORA_MEDIUM,
    color: '#2E2835',
  },
  iconAndText: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
});
