import React, { useState, useRef } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  FlatList,
  ImageSourcePropType,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';

import { ProfileResp } from 'src/common/response/profile.resp';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import { Color } from 'src/constant/Color';
import { PrivacyLevel, Verification } from 'src/common/models/profile.model';
import FullScreenImage from 'src/components/common/FullScreenImageViewer';
import { CircularProgressBase } from 'react-native-circular-progress-indicator';
import { SwiperFlatList } from 'react-native-swiper-flatlist';
import { ProfileMediaResp } from 'src/common/response/profile-media.resp';
import { Icon } from 'react-native-elements';
import * as Clipboard from 'expo-clipboard';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
import MoreOptionsAction from 'src/components/common/profileCard/actions/MoreOptionsAction';
import HeaderBackButton from 'src/components/HeaderBackButton';

const { width } = GenUtil.getDimension();

const ProfileScores = ({ trustScore = 0, mutualScore = 0, preferenceScore = 0 }) => {
  return (
    <View style={styles.scores}>
      {trustScore !== 0 && (
        <TouchableOpacity style={styles.scoreWrapper}>
          <CircularProgressBase
            value={trustScore}
            radius={35}
            activeStrokeColor="#2AB108"
            inActiveStrokeColor={Color.backgroundGray}
            activeStrokeWidth={GenUtil.isWEB() ? 9 : 12}
            inActiveStrokeWidth={GenUtil.isWEB() ? 9 : 12}
          />
          <View style={styles.scoreDetails}>
            <Text style={styles.scoreText}>{`${trustScore}%`}</Text>
          </View>
          <View style={GenUtil.isWEB() ? styles.scoreDetailsWeb : styles.scoreDetailsText}>
            <Text style={styles.scoreLabel}>Trust Score</Text>
          </View>
        </TouchableOpacity>
      )}
      {mutualScore !== 0 && (
        <TouchableOpacity style={styles.scoreWrapper}>
          <CircularProgressBase
            value={mutualScore}
            radius={35}
            activeStrokeColor="#FF4588"
            inActiveStrokeColor={Color.backgroundGray}
            activeStrokeWidth={GenUtil.isWEB() ? 9 : 12}
            inActiveStrokeWidth={GenUtil.isWEB() ? 9 : 12}
          />
          <View style={styles.scoreDetails}>
            <Text style={styles.scoreText}>{`${Math.ceil(mutualScore)}%`}</Text>
          </View>
          <View style={GenUtil.isWEB() ? styles.scoreDetailsWeb : styles.scoreDetailsText}>
            <Text style={styles.scoreLabel}>Mutual Score</Text>
          </View>
        </TouchableOpacity>
      )}
      {preferenceScore !== 0 && (
        <TouchableOpacity style={styles.scoreWrapper}>
          <CircularProgressBase
            value={preferenceScore}
            radius={35}
            activeStrokeColor="#4945FF"
            inActiveStrokeColor={Color.backgroundGray}
            activeStrokeWidth={GenUtil.isWEB() ? 9 : 12}
            inActiveStrokeWidth={GenUtil.isWEB() ? 9 : 12}
          />
          <View style={styles.scoreDetails}>
            <Text style={styles.scoreText}>{`${preferenceScore}%`}</Text>
          </View>
          <View style={GenUtil.isWEB() ? styles.scoreDetailsWeb : styles.scoreDetailsText}>
            <Text style={styles.scoreLabel}>Preference Score</Text>
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
};

const UserProfileBasicDetailsComp = ({
  profileData,
  setFullScreenImage,
}: {
  profileData: Partial<ProfileResp>;
  setFullScreenImage: (arg0: boolean) => void;
}) => {
  interface InterfaceBasicDataArray {
    id: number;
    icon: ImageSourcePropType;
    data: string;
  }

  interface InterfaceVerificationArray {
    id: number;
    icon: ImageSourcePropType;
    label: string;
  }

  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [fullScreenVisible, setFullScreenVisible] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const separator = profileData.age && profileData.height ? ', ' : '';
  const userBasicsDataArray: InterfaceBasicDataArray[] = [
    {
      id: 1,
      icon: require('src/assets/images/profileIcons/user.png'),
      data:
        profileData.age || profileData.height
          ? `${profileData.age ? String(profileData.age) + ' Years Old' : ''}` +
            separator +
            `${profileData.height ? String(profileData.height) + ' cm Height' : ''}`
          : '',
    },
    {
      id: 2,
      icon: require('src/assets/images/profileIcons/ring.png'),
      data: profileData.maritalStatus,
    },
    {
      id: 3,
      icon: require('src/assets/images/profileIcons/job.png'),
      data: profileData.profession,
    },
    {
      id: 4,
      icon: require('src/assets/images/profileIcons/income.png'),
      data:
        profileData.currentCountry === 'India' && profileData.inrIncome
          ? '₹' + String(profileData.inrIncome) + ' Lacs p.a.'
          : profileData.currentCountry !== 'India' && profileData.usdIncome
          ? '$' + String(profileData.usdIncome) + 'K p.a.'
          : '',
    },
    {
      id: 5,
      icon: require('src/assets/images/profileIcons/location.png'),
      data: profileData.currentLocation,
    },
    {
      id: 6,
      icon: require('src/assets/images/profileIcons/distance.png'),
      data: '',
    },
  ];

  const copyProfileID = async () => {
    await Clipboard.setStringAsync(profileData.profileId);
    dispatch(showError({ alertType: AlertTypeEnum.Default, message: 'Profile ID Copied' }));
  };

  // const VerificationArray: InterfaceVerificationArray[] = [
  //   {
  //     id: 1,
  //     icon: require('src/assets/images/profileIcons/user_badge.png'),
  //     label: 'selfieVerified',
  //   },
  //   {
  //     id: 2,
  //     icon: require('src/assets/images/profileIcons/PAN_badge.png'),
  //     label: 'panVerified',
  //   },
  //   {
  //     id: 3,
  //     icon: require('src/assets/images/profileIcons/job_badge.png'),
  //     label: 'professionVerified',
  //   },
  //   {
  //     id: 4,
  //     icon: require('src/assets/images/profileIcons/income_badge.png'),
  //     label: 'incomeVerified',
  //   },
  // ];

  const renderItem = ({ item, index }: { item: InterfaceBasicDataArray; index: number }) => (
    <View style={[styles.userDetailsParent, { flex: index % 2 === 0 ? 3 : 2 }]}>
      <Image source={item.icon} style={styles.userDetailsIcon} />
      <Text style={styles.userDetailsText}>{item.data}</Text>
    </View>
  );

  const userBasicsFilteredData: InterfaceBasicDataArray[] = userBasicsDataArray.filter(
    (item) => item.data,
  );

  return (
    <View style={styles.container}>
      <FullScreenImage
        hideFullScreen={() => {
          setFullScreenVisible(false);
          setFullScreenImage(false);
          setImageUrl('');
        }}
        visible={fullScreenVisible}
        images={[{ url: imageUrl }]}
      />
      <View>
        <HeaderBackButton
          icon={
            <Image
              source={require('src/assets/images/profileIcons/back.png')}
              style={styles.backButtonIcon}
            />
          }
          iconStyle={styles.backButton}
        />
        {profileData.medias?.length > 0 ? (
          <View style={{ flex: 1 }}>
            <SwiperFlatList
              index={0}
              showPagination
              data={profileData.medias}
              paginationStyleItem={{
                height: 2,
                width: width / profileData.medias?.length - 4,
                marginHorizontal: 1,
              }}
              paginationStyle={{ marginBottom: 510 }}
              renderItem={({ item }: { item: Partial<ProfileMediaResp> }) => (
                <View style={styles.imageContainer}>
                  <Image
                    source={{
                      uri: item?.originalUrl,
                    }}
                    style={styles.image}
                  />
                </View>
              )}
            />
          </View>
        ) : (
          <View style={styles.placeholderImageContainer}>
            <Image
              source={require('src/assets/images/profileIcons/placeholder_profile.png')}
              style={styles.placeHolderImage}
            />
          </View>
        )}

        <MoreOptionsAction profile={profileData as ProfileResp} styles={styles.moreOption} />

        {profileData.isOnline && (
          <View style={styles.activeNowContainer}>
            <View style={styles.activeNowIndicator}></View>
            <Text style={styles.activeNowText}>Active Now</Text>
          </View>
        )}
      </View>
      <View style={styles.detailsFlex}>
        <View style={styles.nameFlex}>
          <View style={styles.nameContainer}>
            {profileData.fullName && <Text style={styles.fullName}>{profileData.fullName}</Text>}
            {profileData.verifications?.length > 0 && (
              <Image
                source={require('src/assets/images/profileIcons/verify.png')}
                style={styles.verifyIcon}
              />
            )}
          </View>
          {profileData.profileId && (
            <View style={styles.profileIdContainer}>
              <TouchableOpacity style={{ flexDirection: 'row' }} onPress={copyProfileID}>
                <Text style={styles.idText}>Profile ID - {profileData.profileId}</Text>
                <Icon name="copy" type="font-awesome" size={14} color={Color.gray} />
              </TouchableOpacity>
            </View>
          )}

          {/* <View style={styles.verificationBadgesParent}>
            {VerificationArray.map((value, key) => (
              <View style={styles.verificationBadges} key={key}>
                {profileData.verifications?.includes(
                  value.id === 1
                    ? Verification.SelfieVerified
                    : value.id === 2
                    ? Verification.IDVerified
                    : value.id === 3
                    ? Verification.EmploymentVerified
                    : Verification.IncomeVerified,
                ) ? (
                  <Image
                    source={require('src/assets/images/profileIcons/Verified_badge.png')}
                    style={styles.badgestyle}
                  />
                ) : (
                  <Image
                    source={require('src/assets/images/profileIcons/Unverified_badge.png')}
                    style={styles.badgestyle}
                  />
                )}
                <Image
                  source={value.icon}
                  style={[
                    styles.badgeIconStyle,
                    {
                      height: value.label === 'panVerified' ? 7 : 10,
                      width: value.label === 'incomeVerified' ? 8 : 10,
                    },
                  ]}
                />
              </View>
            ))}
                </View> */}
        </View>
        {userBasicsFilteredData.length > 0 && (
          <View style={GenUtil.isWEB() && { marginBottom: 5 }}>
            <FlatList
              data={userBasicsFilteredData}
              renderItem={({ item, index }) => renderItem({ item, index })}
              keyExtractor={(index) => index.toString()}
              numColumns={2}
              columnWrapperStyle={{ marginTop: 16 }}
            />
          </View>
        )}
      </View>
      {(profileData.trustScore > 0 ||
        profileData.mutualMatchScore > 0 ||
        profileData.partnerPreferenceScore > 0) && (
        <>
          <View style={styles.dividerWrapper}>
            <Image source={require('src/assets/images/divider.png')} style={styles.divider} />
          </View>
          <View>
            <ProfileScores
              trustScore={profileData.trustScore}
              mutualScore={profileData.mutualMatchScore}
              preferenceScore={profileData.partnerPreferenceScore}
            />
          </View>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: GenUtil.isWEB() ? null : 1,
    borderRadius: 20,
    backgroundColor: Color.white,
    elevation: 6,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
    marginBottom: 5,
    paddingBottom: GenUtil.isWEB() ? 5 : 15,
  },
  backButton: {
    position: 'absolute',
    zIndex: 1,
    marginTop: 56,
    marginLeft: 20,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,.3)',
    width: 24,
    height: 24,
    borderRadius: 75,
  },
  backButtonIcon: {
    width: 20,
    height: 20,
    zIndex: 1,
    position: 'absolute',
  },
  moreOption: {
    position: 'absolute',
    zIndex: 1,
    marginTop: 56,
    right: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,.3)',
    width: 24,
    height: 24,
    borderRadius: 75,
  },
  activeNowText: {
    fontSize: 12,
    color: Color.white,
    fontFamily: EFonts.SORA_REGULAR,
    lineHeight: 18,
  },
  activeNowIndicator: {
    height: 8,
    width: 8,
    backgroundColor: '#06E21C',
    borderRadius: 4,
    marginRight: 10,
  },
  activeNowContainer: {
    paddingVertical: 8,
    backgroundColor: Color.black,
    opacity: 0.5,
    position: 'absolute',
    bottom: 0,
    right: 0,
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 14,
    borderRadius: 38,
    marginRight: 16,
    marginBottom: 10,
  },
  userDetailsParent: {
    flex: 1,
    flexDirection: 'row',
  },
  image: {
    height: 612,
    width: '100%',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  blurredImage: {
    width: 38,
    height: 38,
    position: 'absolute',
  },
  verificationBadges: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  nameTextContainer: {
    maxWidth: '80%',
  },
  verifyIcon: {
    height: 22,
    width: 22,
    marginLeft: 10,
  },
  blurredImageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholderImageContainer: {
    height: 503,
    width: '100%',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    backgroundColor: '#F0F0F0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeHolderImage: {
    height: 122,
    width: 138,
  },
  badgestyle: {
    width: 34,
    height: 23,
  },
  detailsFlex: {
    flex: 1,
    marginHorizontal: 16,
    marginTop: 16,
  },
  userDetailsText: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 20,
    marginLeft: 10,
    flex: 1,
    marginTop: GenUtil.isWEB() ? 0 : -4,
  },
  userDetailsIcon: {
    height: 18,
    width: 18,
  },
  nameFlex: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 2,
  },
  nameContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  profileIdContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  idText: {
    fontFamily: 'dmsans-bold',
    fontSize: 13,
    color: Color.gray,
    marginRight: 5,
  },
  badgeIconStyle: {
    width: 10,
    height: 10,
    position: 'absolute',
  },
  fullName: {
    color: Color.lightBlack,
    fontSize: 18,
    fontFamily: EFonts.SORA_SEMIBOLD,
    lineHeight: 23,
    marginTop: GenUtil.isWEB() ? 0 : -3,
    maxWidth: '80%',
  },
  verificationBadgesParent: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'flex-end',
  },
  divider: {
    height: 1,
    width: width * 0.9,
  },
  dividerWrapper: {
    top: GenUtil.isWEB() ? 5 : 10,
    alignItems: 'center',
  },
  scoreWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  scores: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    minHeight: GenUtil.isWEB() ? 130 : 150,
    paddingHorizontal: 15,
  },
  scoreDetails: {
    position: 'absolute',
    alignItems: 'center',
  },
  scoreDetailsText: {
    position: 'absolute',
    alignItems: 'center',
    top: 35,
    minWidth: 100,
  },
  scoreDetailsWeb: {
    alignItems: 'center',
    top: 10,
  },
  scoreText: {
    fontFamily: 'sora-semibold',
    fontSize: GenUtil.isWEB() ? 18 : 19,
    color: Color.black,
    marginTop: GenUtil.isWEB() ? -15 : 0,
  },
  scoreLabel: {
    top: 90,
    fontFamily: 'sora-regular',
    fontSize: 11,
  },
  dot: {
    backgroundColor: 'rgba(0,0,0,.2)',
    height: 2,
    borderRadius: 4,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 3,
    marginBottom: 3,
  },
  activeDot: {
    backgroundColor: '#FFFFFF',
    height: 2,
    borderRadius: 4,
    marginLeft: 3,
    marginRight: 3,
    marginTop: 3,
    marginBottom: 3,
  },
  imageContainer: {
    width,
    justifyContent: 'center',
  },
});

export default UserProfileBasicDetailsComp;
