import React, { useState } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, ScrollView } from 'react-native';

import { ProfileResp } from 'src/common/response/profile.resp';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import FullScreenImage, { InterfaceImageURls } from 'src/components/common/FullScreenImageViewer';

const UserProfilePhotosComp = ({
  profileData,
  setFullScreenImage,
}: {
  profileData: Partial<ProfileResp>;
  setFullScreenImage: (arg0: boolean) => void;
}) => {
  const [fullScreenVisible, setFullScreenVisible] = useState<boolean>(false);
  const [imageUrlArray, setImageUrlArray] = useState([] as InterfaceImageURls[]);
  const [indexOfCurrentImage, setIndexOfCurrentImage] = useState<number>(0);

  const createArrayOfImages = () => {
    setImageUrlArray(
      profileData.medias?.map(
        (image) =>
          image?.originalUrl && {
            url: image?.originalUrl,
          },
      ),
    );
  };

  return (
    <View style={styles.container}>
      <FullScreenImage
        hideFullScreen={() => {
          setFullScreenVisible(false);
          setFullScreenImage(false);
          setImageUrlArray([]);
        }}
        visible={fullScreenVisible}
        images={imageUrlArray}
        index={indexOfCurrentImage}
      />
      {profileData.fullName && profileData.medias.length > 0 && (
        <Text style={styles.heading}>More Photos of {profileData.fullName.split(' ')[0]}</Text>
      )}
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.scrollStyle}
      >
        {profileData.medias.map(
          (value, key) =>
            value?.originalUrl && (
              <TouchableOpacity
                key={key}
                onPress={() => {
                  createArrayOfImages();
                  setIndexOfCurrentImage(key);
                  setFullScreenVisible(true);
                  setFullScreenImage(true);
                }}
                style={styles.imageButton}
              >
                <Image source={{ uri: value.originalUrl }} style={styles.image} />
              </TouchableOpacity>
            ),
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Color.white,
    paddingLeft: 17,
    marginVertical: 15,
  },
  heading: {
    fontSize: 17,
    lineHeight: 21,
    color: Color.lightBlack,
    fontFamily: EFonts.SORA_MEDIUM,
  },
  image: {
    width: 104.81,
    height: 130,
    borderRadius: 16,
  },
  imageButton: {
    marginRight: 19.19,
  },
  scrollStyle: {
    marginTop: 18,
  },
});

export default UserProfilePhotosComp;
