import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';

import { WebRootStackParamList } from 'types';

const Weblinking: LinkingOptions<WebRootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      user: {
        path: 'user',
        screens: {
          data: {
            screens: {
              home: 'home',
              partnerpreferences: 'partnerpreferences',
              blockedprofiles: 'blockedprofiles',
              phonebook: 'phonebook',
              rateus: 'rateus',
              editprofile: {
                screens: {
                  editprofile: 'editprofile',
                  editphotoscreen: 'editphotoscreen',
                },
              },
              settings: {
                screens: {
                  settings: 'settings',
                  editprivacypolicyscreen: 'editprivacypolicyscreen',
                  notifications: 'notifications',
                },
              },
              activity: {
                screens: {
                  ActivityScreen: 'activity',
                },
              },
              dynamicprofileslist: {
                screens: {
                  dynamicprofileslist: 'dynamicprofileslist',
                },
              },
            },
          },
          otherpersonprofile: 'otherpersonprofile',
          search: {
            screens: {
              search: 'search',
              searchedprofilesscreen: 'searchedprofiles',
              searchfiltersscreen: 'searchfilters',
            },
          },
          messenger: {
            path: 'chat',
            screens: {
              InboxScreen: 'inboxscreen',
              MessageScreen: 'messagescreen',
            },
          },
          membership: 'membership',
        },
      },
      intro: 'intro',
      welcome: 'welcome',
      login: 'login',
      onboardingdetails: 'onboardingdetails',

      Modal: 'modal',
      notfound: '*', // Keep it unchanged
    },
  },
  async getInitialURL() {
    const url = await Linking.getInitialURL();

    if (url != null) {
      return url;
    }

    const response = await Notifications.getLastNotificationResponseAsync();
    return response?.notification.request.content.data.url;
  },
  subscribe(listener) {
    const onReceiveURL = ({ url }: { url: string }) => listener(url);

    const eventListenerSubscription = Linking.addEventListener('url', onReceiveURL);

    const subscription = Notifications.addNotificationResponseReceivedListener((response) => {
      const url = response.notification.request.content.data.url;

      listener(url);
    });

    return () => {
      eventListenerSubscription.remove();
      subscription.remove();
    };
  },
};

export default Weblinking;
