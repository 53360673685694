import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import { RootTabParamList } from 'types';

const HeaderTitle = ({ routeName }: { routeName: keyof RootTabParamList }) => {
  return (
    <View style={styles.titleContainer}>
      <Text style={styles.title}>{routeName === 'Home' ? 'MatriMilan' : routeName}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  titleContainer: {
    left: GenUtil.isIOS() ? 0 : 10,
  },
  title: {
    fontFamily: EFonts.SORA_REGULAR,
    fontWeight: '600',
    fontSize: 20,
  },
});
export default HeaderTitle;
