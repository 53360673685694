/* eslint-disable @typescript-eslint/no-var-requires */
import { FontAwesome } from '@expo/vector-icons';
import * as Font from 'expo-font';
import { useEffect, useState } from 'react';
import SyncStorage from 'sync-storage';
import EFonts from 'src/constant/EFonts';

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  async function loadResourcesAndDataAsync() {
    try {
      // Load fonts
      await Font.loadAsync({
        ...FontAwesome.font,
        'space-mono': require('src/assets/fonts/SpaceMono-Regular.ttf') as Font.FontSource,
        [EFonts.ATYP_REGULAR]:
          require('src/assets/fonts/AtypDisplay-Regular.ttf') as Font.FontSource,
        [EFonts.ATYP_MEDIUM]: require('src/assets/fonts/AtypDisplay-Medium.ttf') as Font.FontSource,
        [EFonts.ATYP_BOLD]: require('src/assets/fonts/AtypDisplay-Bold.ttf') as Font.FontSource,
        [EFonts.DMSANS_REGULAR]: require('src/assets/fonts/DMSans-Regular.ttf') as Font.FontSource,
        [EFonts.DMSANS_MEDIUM]: require('src/assets/fonts/DMSans-Medium.ttf') as Font.FontSource,
        [EFonts.DMSANS_BOLD]: require('src/assets/fonts/DMSans-Bold.ttf') as Font.FontSource,
        [EFonts.SORA_LIGHT]: require('src/assets/fonts/Sora-Light.ttf') as Font.FontSource,
        [EFonts.SORA_BOLD]: require('src/assets/fonts/Sora-Bold.ttf') as Font.FontSource,
        [EFonts.SORA_MEDIUM]: require('src/assets/fonts/Sora-Medium.ttf') as Font.FontSource,
        [EFonts.SORA_REGULAR]: require('src/assets/fonts/Sora-Regular.ttf') as Font.FontSource,
        [EFonts.SORA_SEMIBOLD]: require('src/assets/fonts/Sora-SemiBold.ttf') as Font.FontSource,
      });

      await SyncStorage.init();
    } catch (err) {
      console.log(err);
    }
  }

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    const loadAsyncResources = async () => {
      await loadResourcesAndDataAsync();
      setLoadingComplete(true);
    };

    loadAsyncResources();
  });

  return isLoadingComplete;
}
