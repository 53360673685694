import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { ParamListBase, useNavigation } from '@react-navigation/native';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import BurgerMenuSvg from 'src/assets/icons/burgerMenu.svg';

export const HeaderLeft = () => {
  const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const openDrawer = () => {
    // NOTE: here this would work as the drawer navigator is a parent of tab navigator which has header.
    navigation.openDrawer();
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={openDrawer} style={styles.touchableStyle}>
        <BurgerMenuSvg />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: 20,
  },
  touchableStyle: {
    flex: 1,
    flexDirection: 'row',
    width: 15,
    alignItems: 'center',
  },
});
