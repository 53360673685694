import { ModalTypes } from 'src/store/reducer/uiReducer';
import { UserProfileOptionsModal } from 'src/components/modals/UserProfileOptionsModal';
import UserContactBottomSheet from './UserContactBottomSheet';
import { ProfileActionBottomSheet } from './ProfileActionsBottomSheet';

export const modalsList: Record<string, () => JSX.Element> = {
  [ModalTypes.UserProfileOptionsModal]: UserProfileOptionsModal,
  [ModalTypes.UserContactBottomSheet]: UserContactBottomSheet,
  [ModalTypes.ProfileActionBottomSheet]: ProfileActionBottomSheet,
};
