import { View, Text, StyleSheet } from 'react-native';
import React, { CSSProperties } from 'react';
import OutlinedButton from '../common/buttons/OutlinedButton';
import PrimaryButton from '../common/buttons/PrimaryButton';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';

const { height, width } = GenUtil.getDimension();

const ConfirmationModal = ({
  title,
  message,
  cancelButtonText,
  primaryButtonText,
  onCancel,
  onPrimaryButtonPressed,
  cancelButtonStyle,
  primaryButtonStyle,
}: {
  title?: string;
  message?: string;
  cancelButtonText?: string;
  primaryButtonText?: string;
  onCancel?: () => void;
  onPrimaryButtonPressed?: () => void;
  cancelButtonStyle?: CSSProperties;
  primaryButtonStyle?: CSSProperties;
}) => {
  return (
    <View style={GenUtil.isWEB() ? styles.modalContainerWeb : styles.modalContainerMob}>
      <View style={{ padding: 10, borderRadius: 6 }}>
        <Text style={{ fontSize: 22, fontFamily: EFonts.SORA_SEMIBOLD, textAlign: 'center' }}>
          {title}
        </Text>
      </View>
      <View style={{ padding: 10 }}>
        <Text style={{ textAlign: 'center', fontSize: 15, color: Color.lightGrey }}>{message}</Text>
      </View>
      <View
        style={{
          height: 40,
          flexDirection: 'row',
          justifyContent: 'space-around',
          marginTop: 20,
        }}
      >
        <OutlinedButton
          style={[
            {
              width: '40%',
              borderRadius: 12,
              paddingVertical: 0,
            },
            cancelButtonStyle,
          ]}
          textStyle={{ fontSize: 15, color: Color.purple }}
          title={cancelButtonText}
          onPress={onCancel}
        />
        <PrimaryButton
          buttonText={primaryButtonText}
          buttonStyle={[
            {
              paddingVertical: 10,
              width: '40%',
              borderRadius: 12,
              backgroundColor: 'red',
            },
            primaryButtonStyle,
          ]}
          textStyle={{ fontSize: 15 }}
          handleKeyPress={onPrimaryButtonPressed}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  modalContainerWeb: {
    backgroundColor: '#fff',
    borderRadius: 6,
    width: width * 0.9,
    margin: 'auto',
    padding: 20,
  },
  modalContainerMob: {
    backgroundColor: '#fff',
    borderRadius: 6,
    width: width * 0.9,
    padding: 20,
  },
});

export default ConfirmationModal;
