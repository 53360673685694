import awaitTo from 'async-await-error-handling';
import axios from 'axios';
import { IGroup, IUser } from '../model/user.model';
import { firestore, isUserLoggedIn } from 'firebaseConfig';
import { ExcludedProfileBody } from 'src/common/dto/profile.dto';
import { BaseResp, HttpResponse } from 'src/common/response/base.resp';
import { AxiosResponse } from 'axios/index';
import Endpoints from 'src/service/Endpoints';
import { UpdateUserBody } from 'src/common/dto/user.dto';
import { ProfileResp } from 'src/common/response/profile.resp';

export const getError = (err: any) => {
  try {
    if (err?.response) {
      if (err?.response?.data?.message) {
        return err.response.data.message;
      }
      if (err?.response?.status === 500) {
        return 'Internal Server Error';
      }
      if (err?.response?.status === 404) {
        return 'Not found';
      }
      if (err?.response?.status === 403) {
        return 'Permission Denied';
      }
      if (err?.response?.status === 401) {
        return 'Unauthorized Access';
      }
      // return oc(err).response.data.errorMessage(
      //     "something went wrong"
      // );
    }
    return 'Something went wrong';
  } catch (ex) {
    return 'Something went wrong';
  }
};

export default class UserService {
  static async sendHeartBeat(): Promise<HttpResponse<BaseResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.HEART_BEAT}`,
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async updateUser(
    uuid: string,
    updateUserBody: UpdateUserBody,
  ): Promise<HttpResponse<BaseResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.UPDATE_USER}/${uuid}`,
      { ...updateUserBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }
}
