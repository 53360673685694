import React, { Component } from 'react';
import { DataProvider, LayoutProvider, RecyclerListView as RLView } from 'recyclerlistview';
import { View } from 'react-native';
import HorizontalProfileCard from 'src/components/common/profileCard/HorizontalProfileCard';
import LargeProfileCard from 'src/components/common/profileCard/LargeProfileCard';
import ProfileCardXL from 'src/components/common/profileCard/ProfileCardXL';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';

const { width, height } = GenUtil.getDimension();

export enum ProfileComponentEnum {
  HORIZONTAL_PROFILE_CARD = 'HORIZONTAL_PROFILE_CARD',
  LARGE_PROFILE_CARD = 'LARGE_PROFILE_CARD',
  PROFILE_CARD_XL = 'PROFILE_CARD_XL',
}

type Props = {
  data: ProfileResp[];
  componentType: ProfileComponentEnum;
};

type State = {
  dataProvider: DataProvider;
};

class ProfilesRecyclerList extends Component<Props, State> {
  private readonly _layoutProvider: LayoutProvider;

  constructor(props: Props) {
    super(props);
    this.state = {
      dataProvider: new DataProvider((r1, r2) => r1 !== r2).cloneWithRows(this.props.data),
    };

    this._layoutProvider = new LayoutProvider(
      (_) => {
        return this.props.componentType;
      },
      (type, dim) => {
        switch (type) {
          case ProfileComponentEnum.PROFILE_CARD_XL:
            dim.height = GenUtil.isWEB() ? height / 2.15 : height / 1.45;
            dim.width = width;
            break;

          case ProfileComponentEnum.HORIZONTAL_PROFILE_CARD:
            dim.height = 230;
            dim.width = width;
            break;

          case ProfileComponentEnum.LARGE_PROFILE_CARD:
            dim.height = GenUtil.isWEB() ? height / 2.45 : height / 1.95;
            dim.width = width;
        }
      },
    );

    this._renderRow = this._renderRow.bind(this);
  }

  _renderRow(type, data: ProfileResp) {
    switch (type) {
      case ProfileComponentEnum.HORIZONTAL_PROFILE_CARD:
        return <HorizontalProfileCard item={data} />;

      case ProfileComponentEnum.LARGE_PROFILE_CARD:
        return (
          <LargeProfileCard
            item={data}
            containerStyles={{
              alignSelf: 'center',
              width: '90%',
              marginRight: 0,
            }}
          />
        );

      case ProfileComponentEnum.PROFILE_CARD_XL:
        return <ProfileCardXL profile={data} />;

      default:
        return <></>;
    }
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        <RLView
          rowRenderer={this._renderRow}
          dataProvider={this.state.dataProvider}
          layoutProvider={this._layoutProvider}
          contentContainerStyle={{ flexGrow: 1 }}
        />
      </View>
    );
  }
}

export default ProfilesRecyclerList;
