import Endpoints from 'src/service/Endpoints';

export default class Constant {
  static APP_NAME = 'MatriGram';

  static APP_TAG_LINE = 'Find your forever';

  static DYNAMIC_LINK_DOMAIN = 'https://changaapp.page.link';

  static IMAGE_BUCKET_URL = 'https://cdn-bz.changa.in';

  static LOCAL_VIDEO_PATH_FOR_THUMB =
    'file://storage/emulated/0/Changa/.downloads/last_upload_file.mp4';

  static GOOGLE_PACKAGE_NAME = 'com.bitcs.desitalent';

  static INSTAGRAM_PACKAGE = 'com.instagram.android';

  static APPLE_STORE_ID = '';

  static ASPECT_RATIO = 960 / 540;

  static AUTH_TOKEN = 'jwttoken';

  static ENCODING = 'Accept-Encoding';

  static GZIP = 'gzip';

  static LOADER = 'LOADER';

  static DEFAULT = 'DEFAULT';

  static USER_ID = 'USER_ID';

  static INSTA_FOLLOW = 'insta-follow';

  static FIRST_TIME_LAUNCH = 'first_time_launch';

  static LANGUAGE = 'language';

  static VERSION_CODE = 'VERSION_CODE';

  static CODEPUSH_VERSION = 'CODEPUSH_VERSION';

  static API_KEY = 'API_KEY';

  static FCM_TOKEN = 'fcm-token';

  static RESEND_OTP_TIMER = 30;

  static COMMENT_POPULATE_TIMER = 8000;

  static SHARE_ICON_CHANGE_TIMER = 5000;

  static LAST_FETCH_TIME = 'last_fetch_time';

  static INITIAL_TIME_LEFT = 24 * 60 * 60 - 1;

  static DIALOG_BORDER_RADIUS = 20;

  static RECENT_SEARCH = 'recentSearch';

  static HIGH_IMPORTANCE = 'HIGH_IMPORTANCE';
  static DEFAULT_IMPORTANCE = 'DEFAULT_IMPORTANCE';
  static LOW_IMPORTANCE = 'LOW_IMPORTANCE';

  static PROFILE_ID = 'profile_id';
  static HASHTAG_NAME = 'hashtag_name';
  static VIDEO_ID = 'video_id';
  static LOCAL_VIDEO_PATH = 'local_video_path';
  static PHOTOALBUM_ID = 'photoalbum_id';
  static WEB_LINK = 'web_link';
  static NEWS_BODY = 'news_body';
  static LOTTIE_ASSETS_AVAILABLE = 'lottie_assets_available';
  static LOTTIE_ASSETS_LIST = 'lottie_assets_list';
  static VIDEO_PATH = 'video_path';
  static SOUND_ID = 'sound_id';
  static TITLE = 'title';
  static POST_URI = 'post_uri';
  static BODY = 'body';
  static DOWNLOAD_FILE = 'download_file';
  static DEFAULT_SOUND_NAME = 'Original Sound';
  static SUPPORT_EMAIL = 'info@changa.in';

  static CHANGA_DIRECTORY = Constant.ROOT_DIRECTORY + '/' + Constant.APP_NAME;

  static HIDDEN_FOLDER = Constant.CHANGA_DIRECTORY + '/.downloads';

  static DOWNLOAD_DIRECTORY = Constant.CHANGA_DIRECTORY + '/downloads';

  static LOTTIE_ASSETS_DIRECTORY = Constant.HIDDEN_FOLDER + '/lottie';

  static VIDEO_DOWNLOAD_DIRECTORY = Constant.DOWNLOAD_DIRECTORY + '/videos';
  static PHOTOS_DOWNLOAD_DIRECTORY = Constant.DOWNLOAD_DIRECTORY + '/photos';
  static MUSIC_DOWNLOAD_DIRECTORY = Constant.DOWNLOAD_DIRECTORY + '/music';

  static UPLOAD_FILE_PATH = Constant.HIDDEN_FOLDER + '/last_upload_file.mp4';

  static WATERMARK_FILE_PATH = Constant.HIDDEN_FOLDER + '/watermarktemp.mp4';

  static ROUND_PROFILE_PIC = `${Constant.HIDDEN_FOLDER}/circle_watermarkpp.png`;
  static TEMP1 = `${Constant.HIDDEN_FOLDER}/temp1.mp4`;
  static userWatermarkProfilePic = `${Constant.HIDDEN_FOLDER}/userpp.png`;

  static FONT_FILE_PATH = Constant.HIDDEN_FOLDER + '/font.ttf';
  static USER_AGENT =
    'Mozilla/5.0 (Linux; Android 8.0.0; SM-G960F Build/R16NW) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/62.0.3202.84 Mobile Safari/537.36';

  // TODO: change this
  static APPEND_WATERMARK_CLIP_PATH = `${Constant.HIDDEN_FOLDER}/watermark.mp4`;

  static SHARE_PROFILE_TEXT =
    'ki profile dekhe Changa app par - India ka apna TikTok. Click here to download now';

  static SHARE_VIDEO_TEXT = `Download Changa app - India ka apna TikTok. Click here to download now 👉 ${Endpoints.APP_LINK}`;

  static MaxVideoLengthInSeconds = 30;

  static FROMMAINACTIVITYTOVISIT = 1001;

  static HASHTAG_CHANGA_ROCKS = '#changarocks';

  static MIN_VERSION_FOR_LOCATION_ACCESS = 1.92;

  static MIN_VERSION_FOR_PBA = '2.1.93';

  static SHOW_AD_AFTER_N_VIDEOS = 8;

  static GG_AD_ID_INTERSTITIAL = 'float-5794';
  static GG_AD_ID_REWARDED = '';
  static GG_AD_ID_NATIVE = 'float-5781';
  static GG_AD_ID_BANNER = '';

  static GG_APP_ID = '28489367';

  static RAZORPAY_KEY_ID_TEST = 'rzp_test_H41ieI0Mu4mttx';
  static RAZORPAY_KEY_ID_PROD = 'rzp_live_AsYtIqVnCsQPp2';

  static AD_ID = 'ca-app-pub-5093172565546285/8291985032';

  static NO_CACHE_HEADERS = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  };

  static USER_CURRENT_STATE = 'UserCurrentState';

  static GENERIC_COMMENTS = 'GENERIC_COMMENTS';

  static LIVESTREAM_CHANNEL_NAME = 'LIVESTREAM_CHANNEL_NAME';

  static LIVESTREAM_TOKEN = 'LIVESTREAM_TOKEN';

  static URL = 'url';

  static CALLDORADO = 'Calldorado';

  static MIN_DURATION_FOR_MOVIEPLAYER = 60; //seconds

  static VIDEO_URL = 'videoUrl';

  static THUMB_URL = 'thumUrl';

  static SOUND_URL = 'soundUrl';

  static CELEBZONE_URL = 'https://celeb.zone';

  static VIDEOSLANGUAGES = 'videosLanguages';

  static HASHTAGS_BANNER = 'hashtagbanner';

  static CHANNEL_NAME = 'channelName';

  static REDEEM_COINS_LIMIT = 5000;

  static REDEEM_PERCENTAGE = 70;

  static FIREBASE_TOKEN = 'firebase_token';
}
