import { ScrollView, StyleSheet, View } from 'react-native';
import WelcomeCard from 'src/components/WelcomeCard';
import ProfileProgressCard from 'src/components/ProfileProgressCard';
import React, { useMemo } from 'react';
import RecievedInterestsSection from 'src/components/RecievedInterestsSection';
import UserNotificationsSection from 'src/components/UserNotificationsSection';
import UnlockPremiumCard from 'src/components/UnlockPremiumCard';
import ProfileVisitorsCard from 'src/components/ProfileVisitorsCard';
import ProfileViewsCard from 'src/components/ProfileViewsCard';
import ShortlistedProfilesSection from 'src/components/ShortlistedProfilesSection';
import HandPickerProfilesSection from 'src/components/HandPickerProfilesSection';
import AppStatsCard from 'src/components/AppStatsCard';
import UnlockProfileView from 'src/components/UnlockProfileViewsCard';
import InterestsSection from 'src/components/InterestsSection';
import RecommendedProfilesSection from 'src/components/RecommendedProfilesCard';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import UserUtil from 'src/util/UserUtil';
import { Color } from 'src/constant/Color';
import { MembershipPermissions, isFeatureEnabled } from 'src/common/data/membership.data';

const HomeScreen = () => {
  const { userProfile } = useSelector((state: RootState) => state.user);
  const isPremiumMember = useMemo(() => UserUtil.isPremiumMember(userProfile), [userProfile]);

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={styles.screen}
      contentContainerStyle={styles.container}
    >
      <WelcomeCard />
      <RecievedInterestsSection />
      <ProfileProgressCard />
      <UserNotificationsSection />
      <InterestsSection />
      {!isPremiumMember ? <UnlockPremiumCard /> : <></>}
      {userProfile && <RecommendedProfilesSection />}
      <View style={styles.visitorsContainer}>
        <ProfileVisitorsCard
          hideProfiles={!isFeatureEnabled(userProfile, MembershipPermissions.CanSeeProfileVisitors)}
        />
        <ProfileViewsCard
          hideProfiles={!isFeatureEnabled(userProfile, MembershipPermissions.CanSeeProfileVisited)}
        />
      </View>
      <ShortlistedProfilesSection />
      {!isPremiumMember ? <UnlockProfileView /> : <></>}
      <HandPickerProfilesSection />
      <AppStatsCard />
    </ScrollView>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  screen: {
    flex: 1,
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Color.white,
  },
  visitorsContainer: {
    marginTop: 20,
    width: '90%',
    flexDirection: 'row',
  },
});
