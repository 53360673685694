import axios, { AxiosResponse } from 'axios';
import Endpoints, { getError } from 'src/service/Endpoints';
import { BaseResp, HttpResponse } from 'src/common/response/base.resp';
import { SearchLocationResp, StateAndCitiesResp } from 'src/common/response/location.resp';

export class CommonService {
  static async getCities(isoCode: string) {
    const response = await axios.get<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.GET_CITIES_OF_COUNTRY}/${isoCode}`,
    );
    return HttpResponse.fromRes<StateAndCitiesResp>(response.data);
  }

  static async getLocationAutocomplete(query: string) {
    const response = await axios.get<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.SEARCH_AUTOCOMPLETE_LOCATION}?q=${query}&type=Location`,
    );
    return HttpResponse.fromRes<SearchLocationResp[]>(response.data);
  }
}
