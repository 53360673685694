/* eslint-disable @typescript-eslint/require-await */
import React, { useState } from 'react';
import { StyleSheet, FlatList, Image } from 'react-native';
import { View, Text } from 'src/components/Themed';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import { Color } from 'src/constant/Color';
import ModalAdornment from 'src/components/editProfileComponents/atoms/ModalAdornment';
import ChoicesSelector from 'src/components/editProfileComponents/ChoicesSelector';
import { AssetsType, DietType, HabitType, Pets } from 'src/common/models/profile.model';
import { HobbiesData, HobbiesDataMap, HobbyCategory } from 'src/common/data/hobbies.data';
import { useDispatch } from 'react-redux';
import SaveButton from 'src/components/editProfileComponents/SaveButton';
import { ProfileUpdateService } from 'src/service/ProfileUpdateService';
import { setUserProfile } from 'src/store/reducer/userReducer';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';

const { width } = GenUtil.getDimension();

export default function MyLifeStyleAndHobbies({
  profileData,
}: {
  profileData: Partial<ProfileResp>;
}) {
  const [isValidating, setIsValidating] = useState(false);
  const dispatch = useDispatch();
  const [dietVal, setDietVal] = useState(profileData.diet ?? '');
  const [smokingVal, setSmokingVal] = useState(profileData.smoking ?? '');
  const [drinkingVal, setDrinkingVal] = useState(profileData.drinking ?? '');
  const [selectedPets, setSelectedPets] = useState(profileData.pets ?? []);
  const [selectedAssets, setSelectedAssets] = useState(profileData.assets ?? []);
  const [selectedHobbiesAndInterests, setSelectedHobbiesAndInterests] = useState(
    profileData.favHobbyAndInterestIds?.map((id) => HobbiesDataMap[id]) ?? [],
  );
  const [selectedMusic, setSelectedMusic] = useState(
    profileData.favMusicIds?.map((id) => HobbiesDataMap[id]) ?? [],
  );
  const [selectedBooks, setSelectedBooks] = useState(
    profileData.favBookGenreIds?.map((id) => HobbiesDataMap[id]) ?? [],
  );
  const [selectedActivityandSports, setSelectedActivityandSports] = useState(
    profileData.favActivityAndSportIds?.map((id) => HobbiesDataMap[id]) ?? [],
  );
  const [selectedCuisines, setSelectedCuisines] = useState(
    profileData.favCuisineIds?.map((id) => HobbiesDataMap[id]) ?? [],
  );
  const [selectedMovies, setSelectedMovies] = useState(
    profileData.favMovieGenreIds?.map((id) => HobbiesDataMap[id]) ?? [],
  );
  const INFO_ROWS_LIFESTYLE = [
    {
      id: 0,
      icon: require('src/assets/images/profileIcons/parchment.png'),
      label: ' Diet',
      value: dietVal,
      itemArray: Object.values(DietType).map((value) => value as string),
      setValue: setDietVal,
    },
    {
      id: 1,
      icon: require('src/assets/images/profileIcons/cake.png'),
      label: 'Smoking',
      value: smokingVal,
      itemArray: Object.values(HabitType).map((value) => value as string),
      setValue: setSmokingVal,
    },
    {
      id: 2,
      icon: require('src/assets/images/profileIcons/parchment.png'),
      itemArray: Object.values(HabitType).map((value) => value as string),
      label: 'Drinking',
      value: drinkingVal,
      setValue: setDrinkingVal,
    },
  ];

  const INFO_ROWS_HOBBIES = [
    {
      itemArray: Object.values(Pets).map((value) => value as string),
      title: 'Pets',
      selected: selectedPets,
      setSelected: setSelectedPets,
    },
    {
      itemArray: Object.values(AssetsType).map((value) => value as string),
      title: 'Assets',
      selected: selectedAssets,
      setSelected: setSelectedAssets,
    },
    {
      itemArray: HobbiesData.filter((item) => item.category === HobbyCategory.HobbyAndInterest),
      preprocessItems: (item: { name: string }) => item.name,
      title: 'Hobbies and Interests',
      selected: selectedHobbiesAndInterests,
      setSelected: setSelectedHobbiesAndInterests,
    },
    {
      itemArray: HobbiesData.filter((item) => item.category === HobbyCategory.MusicTypes),
      preprocessItems: (item: { name: string }) => item.name,
      title: 'Music',
      selected: selectedMusic,
      setSelected: setSelectedMusic,
    },
    {
      itemArray: HobbiesData.filter((item) => item.category === HobbyCategory.Reads),
      preprocessItems: (item: { name: string }) => item.name,
      title: 'Books',
      selected: selectedBooks,
      setSelected: setSelectedBooks,
    },
    {
      itemArray: HobbiesData.filter((item) => item.category === HobbyCategory.SportsAndActivities),
      preprocessItems: (item: { name: string }) => item.name,
      title: 'Activity and Sports',
      selected: selectedActivityandSports,
      setSelected: setSelectedActivityandSports,
    },
    {
      itemArray: HobbiesData.filter((item) => item.category === HobbyCategory.Cuisines),
      preprocessItems: (item: { name: string }) => item.name,
      title: 'Cuisines',
      selected: selectedCuisines,
      setSelected: setSelectedCuisines,
    },
    {
      itemArray: HobbiesData.filter((item) => item.category === HobbyCategory.MovieGenres),
      preprocessItems: (item: { name: string }) => item.name,
      title: 'Movies',
      selected: selectedMovies,
      setSelected: setSelectedMovies,
    },
  ];

  const handleSave = async () => {
    try {
      setIsValidating(true);
      const dataA = {
        diet: dietVal,
        smoking: smokingVal,
        drinking: drinkingVal,
        assets: selectedAssets,
        pets: selectedPets,
      };
      const dataB = {
        favHobbyAndInterestIds: selectedHobbiesAndInterests.map((h) => h.id),
        favMusicIds: selectedMusic.map((m) => m.id),
        favBookGenreIds: selectedBooks.map((b) => b.id),
        favActivityAndSportIds: selectedActivityandSports.map((a) => a.id),
        favCuisineIds: selectedCuisines.map((c) => c.id),
        favMovieGenreIds: selectedMovies.map((g) => g.id),
      };
      const updateLifestyleDetailsResponse = await ProfileUpdateService.updateLifeStyle(dataA);
      const updateHobboesDetailsResponse = await ProfileUpdateService.updateHobbies(dataB);
      console.log(updateLifestyleDetailsResponse);
      if (
        updateLifestyleDetailsResponse?.httpCode === 200 &&
        updateHobboesDetailsResponse?.httpCode === 200
      ) {
        //update redux
        dispatch(setUserProfile(updateLifestyleDetailsResponse?.data));
        dispatch(setUserProfile(updateHobboesDetailsResponse?.data));
        dispatch(showError({ alertType: AlertTypeEnum.Success, message: 'Saved' }));
      }
      setIsValidating(false);
    } catch (error) {
      console.log(error);
      setIsValidating(false);
      dispatch(
        showError({ alertType: AlertTypeEnum.Error, message: GenUtil.getMessageFromError(error) }),
      );
    }
  };

  const myLifetyleDetails = INFO_ROWS_LIFESTYLE.filter((item) => item);
  const myHobbyDetails = INFO_ROWS_HOBBIES.filter((item) => item);
  const petFilter = async () => {
    if (selectedPets.includes('all')) {
      setSelectedPets(['all']);
    }
  };

  const renderItem = ({ item, index }) => (
    <View style={styles.info}>
      <Text
        style={[
          styles.infoLabel,
          { marginTop: item?.label === 'Diet' || item?.label === 'Smoking' ? null : 6 },
        ]}
      >
        {item?.label}
      </Text>

      <ModalAdornment
        icon={<Image source={item?.icon} style={{ width: 18, height: 18 }} />}
        modalItems={item?.itemArray}
        value={item?.value}
        setValue={item?.setValue}
      />
    </View>
  );

  return (
    <View style={styles.container}>
      <FlatList
        data={myLifetyleDetails}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        numColumns={2}
        columnWrapperStyle={{ justifyContent: 'space-between' }}
      />
      {myHobbyDetails.map((item, index) => {
        return (
          <ChoicesSelector
            key={index}
            preprocessItems={item.preprocessItems ?? null}
            dropdownItems={item?.itemArray}
            title={item?.title}
            placeHolder="Search here..."
            selectedValues={item?.selected}
            setSelectedValues={item?.setSelected}
            onPressDone={petFilter}
          />
        );
      })}
      <View style={styles.saveButtonWrapper}>
        <SaveButton buttonText="Save" handleKeyPress={handleSave} isValidating={isValidating} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.white,
    paddingHorizontal: 6,
  },
  infoLabel: {
    flex: 1,
    fontFamily: EFonts.SORA_REGULAR,
    fontStyle: 'normal',
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
    color: Color.lightGrey,
    alignItems: 'center',
    marginLeft: 15,
  },
  info: {
    width: width * 0.42,
  },
  saveButtonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});
