import React, { useEffect, useRef, useState } from 'react';
import { Alert, TouchableOpacity } from 'react-native';
import { KeyboardAvoidingView, Text, View } from 'src/components/Themed';
import * as Clipboard from 'expo-clipboard';
// import { firestore } from 'firebaseConfig';
import {
  Actions,
  Composer,
  GiftedChat,
  IMessage,
  InputToolbar,
  Time,
} from 'react-native-gifted-chat';
import { Badge, Icon, Image, Input } from 'react-native-elements';
import ImagePicker, { ImageOrVideo } from 'react-native-image-crop-picker';
import Bubble, { BubbleProps } from 'react-native-gifted-chat/lib/Bubble';
import { Dialog } from 'react-native-simple-dialogs';
import { Color } from '../constant/Color';
import { GenUtil } from '../util/GenUtil';
import DataUtil from '../util/DataUtil';
import { strings } from '../util/LocalizationUtil';
import { ImageWithPlaceholder } from './ImageWithPlaceholder';
import { FullLoader } from './loader/FullLoader';
import { StyleSheet } from 'react-native';
import FullScreenImage from './common/FullScreenImageViewer';
import EFonts from 'src/constant/EFonts';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { ProfileService } from 'src/service/ProfileService';
import { ChatMessage, ChatMessageType } from 'src/common/models/chat.model';
import { v4 } from 'uuid';
import * as ImageManipulator from 'expo-image-manipulator';
import ProfileMediaService from 'src/service/ProfileMediaService';
import { MediaCategory } from 'src/common/models/media.model';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import firebase from 'firebase/compat';
import { PreSignedUrlBody } from 'src/common/models/media.model';
import { HttpResponse } from 'src/common/response/base.resp';
import { PreSignedUrlResp } from 'src/service/ProfileMediaService';
import { ProfileResp } from 'src/common/response/profile.resp';
import { firestore } from '../../firebaseConfig';
import { Button, Divider, Menu } from 'react-native-paper';
import { ExcludedProfileBody } from 'src/common/dto/profile.dto';
import { ExcludedStatus } from 'src/common/models/profile.model';
import { ChatResp } from 'src/common/response/chat.resp';
import moment from 'moment';
import InterestReceviedCard from './chat-components/InterestReceviedCard';
import InterestSentCard from './chat-components/InterestSentCard';
import InterestAcceptedCard from './chat-components/InterestAcceptedCard';
import InterestRejectedCard from './chat-components/InterestRejectedCard';
import { TextInput } from 'react-native';
import * as WebImagePicker from 'expo-image-picker';
import {
  ImagePickerCanceledResult,
  ImagePickerSuccessResult,
} from 'expo-image-picker/build/ImagePicker.types';
import WebImgPickerComp from './WebImagePicker';
import SafeContainer from './SafeContainer';
import MoreOptionsAction from './common/profileCard/actions/MoreOptionsAction';

const { width, height } = GenUtil.getDimension();
const COMPRESSED_IMG_WIDTH = 500;
const COMPRESSED_IMG_QUALITY = 1;

const emptyMessage: IMessage[] = [
  // example of system message
  {
    _id: 0,
    text: strings.empty_message,
    system: true,
    createdAt: new Date().getTime(),
    user: {
      _id: 0,
    },
  },
];

type MessageScreenProps = {
  route: {
    params: {
      group: ProfileResp | undefined; // Define the expected shape of the route.params.group prop
    };
  };
  navigation: NavigationProp<ParamListBase>; // Define the expected type for the navigation prop
};

type DocumentReference = firebase.firestore.DocumentReference;
type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type QuerySnapshot = firebase.firestore.QuerySnapshot;
type CustomIMessage = {
  messageType: ChatMessageType;
};

const initialIds: string[] = ['default'];
let setListenerRef: any;

const MessageScreen = ({ route, navigation }: MessageScreenProps) => {
  const group: ProfileResp = route.params?.group;
  let quickReplyMessage: IMessage;

  const [isBlocked, setIsBlocked] = useState(false);
  const loggedInUser = useSelector((state: RootState) => state.user.user);
  const loggedInDetails = useSelector((state: RootState) => state.user);

  const messageRef = useRef(emptyMessage);

  // const [textInput, setTextInput] = useState(messageInput);
  const [messages, setMessages] = useState(messageRef.current);
  const [otherUser, setOtherUser] = useState<Partial<ProfileResp>>({});
  const [loader, setLoader] = useState(false);
  const [isOtherUserOnline, setOtherUserOnlineStatus] = useState(true);
  const [pickedImagePath, setPickedImagePath] = useState('');
  const [loadingEarlier, setIsLoadingEarlier] = useState(false);
  const [noMoreMessages, setNoMoreMessages] = useState(false);
  const [fullImage, setFullImage] = useState<string>(null);
  const [lastVisible, setLastVisible] = useState<DocumentSnapshot>();
  const [currentGroup, setCurrentGroup] = useState<ChatResp>();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [interestAccepted, setInterestAccepted] = useState(false);
  const [interestRejected, setInterestRejected] = useState(false);
  const [isBlockedDialogVisible, setIsBlockedDialogVisible] = useState(false);
  const [isReportDialogVisible, setIsReportDialogVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState<WebImagePicker.ImagePickerAsset>();

  let inputText = '';

  const getOtherUser = async (otherUserProfileUuid = false) => {
    const profileUuid: string = group.chat.profileUuid1;
    const loggedInUserProfileUuid: string = loggedInUser.profileUuid;
    const otherPersonProfileUuid: string =
      profileUuid === loggedInUserProfileUuid ? group.chat.profileUuid2 : profileUuid;
    if (otherUserProfileUuid) return otherPersonProfileUuid;

    const { data: otherPerson } = await ProfileService.listProfilesDetails([
      otherPersonProfileUuid,
    ]);
    return otherPerson.data[0];
  };

  const markSeen = (groupRef: DocumentReference) => {
    groupRef
      .get()
      .then((docSnap) => {
        const data = docSnap.data() as ChatResp;
        const usersUnreadMessages = data.usersUnreadMessages;
        if (usersUnreadMessages && usersUnreadMessages[loggedInUser.uuid] == 0) return;

        usersUnreadMessages[loggedInUser.uuid] = 0;
        groupRef
          .update({ usersUnreadMessages })
          .then(() => console.log('successfully updated unread messages'))
          .catch((error) => console.log('failed to update unread', error));
      })
      .catch((error) => console.log('failed to call mark seen', error));
  };

  const isUserBlocked = () => {
    return group.chat.excludedStatus === 'Block';
  };

  useEffect(() => {
    try {
      (async () => {
        const otherPerson = await getOtherUser();
        if (typeof otherPerson !== 'string') setOtherUser(otherPerson);
      })().catch((error) => console.log('error in getting other user', error));

      const isBlocked = isUserBlocked();

      setIsBlocked(isBlocked);

      setLoader(false);
      loadInitialMessages();

      const groupRef = firestore.collection('chat-groups').doc(group.chat.uuid);
      markSeen(groupRef);

      const listener = groupRef.onSnapshot((querySnap) => {
        const { id } = querySnap;
        const group = querySnap.data() as ChatResp;
        if (group.lastChatMessage.receiverProfileUuid === loggedInUser.profileUuid) {
          markSeen(groupRef);
        }

        const message = formattedMessage(id, group.lastChatMessage);
        if (message) {
          setMessages((prevMsgs) => {
            const isLastAndCurrentMsgSame = prevMsgs[0].createdAt === message.createdAt;
            if (isLastAndCurrentMsgSame) return prevMsgs;
            return GiftedChat.append(prevMsgs, [message]);
          });

          setCurrentGroup(group);
        }
      });

      return () => {
        listener();
      };
    } catch (error) {
      console.log('error in useEffect = ', error);
    }
  }, []);

  useEffect(() => {
    if (!otherUser.fullName) return;
    navigation.setOptions({
      title: `${otherUser.fullName}'s chat - Matrimilan`
    });
  }, [otherUser]);

  const loadInitialMessages = () => {
    const messageDocRef = firestore
      .collection('chat-groups')
      .doc(group.chat.uuid)
      .collection('messages')
      .orderBy('timestamp', 'desc')
      .limit(10);
    const docs: IMessage[] = [];
    messageDocRef
      .get()
      .then((querySnapShot) => {
        const lastVisible = querySnapShot.docs[querySnapShot.docs.length - 1];
        setLastVisible(lastVisible);
        querySnapShot.forEach((docSnap) => {
          const { id } = docSnap;
          const data = docSnap.data() as ChatMessage;
          const message = formattedMessage(id, data);
          if (message) docs.push(message);
        });
        setMessages(docs);
        messageRef.current = docs;
      })
      .catch((error) => console.log('error in querysnap', error));
  };

  const loadEarlierMessages = () => {
    try {
      setIsLoadingEarlier(true);
      firestore
        .collection('chat-groups')
        .doc(group.chat.uuid)
        .collection('messages')
        .orderBy('timestamp', 'desc')
        .startAfter(lastVisible)
        .limit(10)
        .get()
        .then((querySnapshot: QuerySnapshot) => {
          if (querySnapshot.size > 0) {
            const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            setLastVisible(lastVisible);
            const newMessages: IMessage[] = [];
            querySnapshot.forEach((documentSnapshot) => {
              const data = documentSnapshot.data() as ChatMessage;
              const { id } = documentSnapshot;
              const newData = formattedMessage(id, data);
              if (newData) newMessages.push(newData);
            });
            setMessages([...messages, ...newMessages]);
          } else {
            console.log('inside else of load earlier');
            setNoMoreMessages(true);
          }
          setIsLoadingEarlier(false);
        })
        .catch((error) => console.log('error in loading messages', error));
    } catch (error) {
      console.log('error in load earlier', error);
    }
  };

  const updateCurrentGroup = (newMessage: ChatMessage) => {
    const receiverUserUuids = group.users.map((user) => user.uuid);

    const profileLastSeenTimestamps = {
      ...currentGroup?.profileLastSeenTimestamps,
      [newMessage.senderProfileUuid]: newMessage.timestamp,
    };

    const usersUnreadMessages = {
      ...currentGroup?.usersUnreadMessages,
      [newMessage.senderUserUuid]: 0,
    };

    const updatedGroup = {
      ...currentGroup,
      lastChatMessage: newMessage,
      lastSenderProfileUuid: loggedInUser.profileUuid,
      profileLastSeenTimestamps,
      updatedAt: newMessage.timestamp,
      usersUnreadMessages,
    };

    for (const userUuid of receiverUserUuids) {
      updatedGroup.usersUnreadMessages[userUuid] =
        (updatedGroup.usersUnreadMessages[userUuid] ?? 0) + 1;
    }
    const docRef = firestore.collection('chat-groups').doc(group.chat.uuid);
    docRef
      .update(updatedGroup)
      .then(() => console.log('group update on fb'))
      .catch((error) => console.log('failed to update the group', error));
  };

  const saveMessage = async (messages: IMessage[]) => {
    try {
      const timestamp = new Date().toISOString();

      const newMessage: ChatMessage = {
        uuid: v4(),
        senderUserUuid: loggedInUser.uuid,
        senderProfileUuid: loggedInUser.profileUuid,
        receiverProfileUuid: (await getOtherUser(true)) as string,
        messageType: ChatMessageType.Text,
        content: messages[0].text,
        timestamp,
      };

      if (messages[0].image) {
        const { image } = messages[0];
        newMessage.content = null;
        newMessage.messageType = ChatMessageType.Image;
        newMessage.contentUrl = image;
      }

      updateCurrentGroup(newMessage);

      firestore
        .collection('chat-groups')
        .doc(group.chat.uuid)
        .collection('messages')
        .add(newMessage)
        .then(() => console.log('saved msg on firebase'))
        .catch((error) => console.log('failed to save msg on firebase', error));
    } catch (error) {
      console.log('error in saving message', error);
    }
  };

  const formattedMessage = (id: string, mMessage: ChatMessage): IMessage => {
    // if (!mMessage.content && !mMessage.contentUrl) return;
    if (
      [
        ChatMessageType.InterestIgnored,
        ChatMessageType.InterestArchived,
        ChatMessageType.UnBlocked,
        ChatMessageType.Blocked,
      ].includes(mMessage.messageType)
    )
      return;
    const timestamp: string = mMessage.timestamp;
    const text: string = mMessage.content;
    const senderProfileUuid: string = mMessage.senderProfileUuid;
    const formattedMessage: IMessage & CustomIMessage = {
      _id: id,
      createdAt: timestamp || Date.now(),
      text: text || null,
      user: {
        _id: senderProfileUuid,
      },
      messageType: mMessage.messageType,
    };
    if (mMessage.contentUrl) formattedMessage.image = mMessage.contentUrl;
    return formattedMessage;
  };

  const pickImageWeb = async () => {
    // Ask the user for the permission to access the media library
    const permissionResult = await WebImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      alert("You've refused to allow this appp to access your photos!");
      return;
    }
    const result: ImagePickerSuccessResult | ImagePickerCanceledResult =
      await WebImagePicker.launchImageLibraryAsync({
        mediaTypes: WebImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        base64: false,
      });

    if (!result.canceled) {
      setSelectedImage(result.assets[0]);
    }
  };

  const showImagePicker = async () => {
    if (GenUtil.isWEB()) {
      await pickImageWeb();
      return;
    }
    ImagePicker.openPicker({
      cropping: true,
      mediaType: 'photo',
      freeStyleCropEnabled: true,
      forceJpg: true,
    })
      .then(async (image: ImageOrVideo) => {
        setPickedImagePath(image.path);
        await onImageSelected(image);
      })
      .catch((error) => {
        console.log('Error in image selection', error);
      });
  };

  const openCamera = async () => {
    if (GenUtil.isWEB()) {
      await pickImageWeb();
      return;
    }
    ImagePicker.openCamera({
      cropping: true,
      mediaType: 'photo',
      freeStyleCropEnabled: true,
      forceJpg: true,
      useFrontCamera: true,
    })
      .then(async (image: ImageOrVideo) => {
        setPickedImagePath(image.path);
        await onImageSelected(image);
      })
      .catch((error) => {
        console.log('Error in image selection', error);
      });
  };

  const onImageSelected = async (image: ImageOrVideo) => {
    try {
      const imgUrl = image.path ?? image.sourceURL;
      const resizedImageUri: string = await resizePhoto(imgUrl);
      const urlBody: PreSignedUrlBody = {
        category: MediaCategory.ChatUpload,
        uploads: [
          {
            ext: 'jpg',
          },
        ],
      };
      const response: HttpResponse<PreSignedUrlResp> =
        await ProfileMediaService.getPreSignedUrlForImage(urlBody);
      if (!response.success) return;

      const uploadURL = (response.data as PreSignedUrlResp[])?.[0]?.preSignedUploadUrl;

      const uploadResponse = await uploadFile(uploadURL, resizedImageUri);
      if (uploadResponse.httpCode !== 200) return null;

      const imageUrl = (response.data as PreSignedUrlResp[])?.[0]?.publicUrl;
      console.log('upload url', uploadURL, 'image url', imageUrl);

      const message = {
        _id: DataUtil.getUuid(),
        createdAt: Date.now(),
        text: null,
        user: {
          _id: loggedInUser.profileUuid,
        },
        image: imageUrl,
      };
      await saveMessage([message]);
    } catch (error) {
      console.log('error in onImageSelect', error);
    }
  };

  const uploadFile = async (uploadUrl: string, resizedImageUri: string) => {
    try {
      const formData = new FormData();
      const fileName = resizedImageUri.split('/').pop();
      const imageUri = resizedImageUri;

      if (GenUtil.isWEB()) {
        const file = await fetch(resizedImageUri);
        const imageBlob = await file.blob();

        formData.append('file', imageBlob);
      } else {
        formData.append('file', {
          uri: imageUri,
          name: fileName,
          type: 'image/jpeg',
        });
      }

      formData.append('preSignedUrl', uploadUrl);
      const response = await ProfileMediaService.uploadMedia(formData);
      return response;
    } catch (error) {
      console.log('Failed to upload file', error);
    }
  };

  const resizePhoto = async (
    imageUri: string,
    compressedImgQuality = COMPRESSED_IMG_QUALITY,
  ): Promise<string> => {
    const resizedPhoto = await ImageManipulator.manipulateAsync(
      imageUri,
      [{ resize: { width: COMPRESSED_IMG_WIDTH } }], // resize to width of 300 and preserve aspect ratio
      { compress: compressedImgQuality, format: ImageManipulator.SaveFormat.JPEG },
    );

    // console.log('resized photo=', resizedPhoto);
    return resizedPhoto.uri;
  };

  const handleBlockUserPressed = async () => {
    try {
      const blockedUserProfileUuid = await getOtherUser(true);
      if (typeof blockedUserProfileUuid === 'string') {
        const excludedProfileBody: ExcludedProfileBody = {
          profileUuid: blockedUserProfileUuid,
          type: ExcludedStatus.Block,
          customMessage: 'Blocking user',
        };
        const response = await ProfileService.restrictProfile(excludedProfileBody);
        if (response.success) setIsBlocked(true);
        setIsBlockedDialogVisible(true);
        setIsDialogVisible(false);
        setTimeout(() => {
          setIsBlockedDialogVisible(false);
          navigation.goBack();
        }, 3000);
      }
    } catch (error) {
      console.log('error while blocking user', error);
    }
  };

  const handleUnblockUserPressed = async () => {
    try {
      const blockedUserProfileUuid = await getOtherUser(true);
      if (typeof blockedUserProfileUuid === 'string') {
        const excludedProfileBody: ExcludedProfileBody = {
          profileUuid: blockedUserProfileUuid,
          type: ExcludedStatus.Block,
          customMessage: 'Unblocking user',
        };
        const response = await ProfileService.undoRestrictProfile(excludedProfileBody);
        if (response.success) setIsBlocked(false);

        console.log('Unblock request response = ', response);
      }

      setIsDialogVisible(false);
    } catch (error) {
      console.log('error while blocking user', error);
    }
  };

  const handleReportUserPressed = async (inputText: string) => {
    try {
      const blockedUserProfileUuid = await getOtherUser(true);
      if (typeof blockedUserProfileUuid === 'string') {
        const excludedProfileBody: ExcludedProfileBody = {
          profileUuid: blockedUserProfileUuid,
          type: ExcludedStatus.Report,
          customMessage: inputText || 'Reporting user',
        };

        const response = await ProfileService.restrictProfile(excludedProfileBody);
      }
      setIsReportDialogVisible(false);
      setIsDialogVisible(false);
      navigation.goBack();
    } catch (error) {
      console.log('error while reporting user', error);
    }
  };

  const renderActions = (props: any) => (
    <Actions
      {...props}
      icon={() => <Icon name="camera" type="ionicon" />}
      options={{
        'Pick from Gallery': async () => {
          await showImagePicker();
        },
        'Open Camera': async () => {
          await openCamera();
        },
        Cancel: () => {
          console.log('Cancel');
        },
      }}
      optionTintColor="#222B45"
    />
  );

  const renderComposer = (props: any) => (
    <Composer {...props} textInputStyle={styles.textInputStyle} composerHeight={38} />
  );

  const renderBubble = (props: Readonly<BubbleProps<IMessage & CustomIMessage>>) => {
    const { currentMessage } = props;

    let extraWidth = 0;
    if (currentMessage.text?.length < 24) {
      extraWidth = 50;
    }

    if (currentMessage.messageType !== 'text' && currentMessage.messageType !== 'image') {
      if (currentMessage.messageType === ChatMessageType.InterestSent) {
        if (currentMessage.user._id === loggedInUser.profileUuid) {
          return (
            <View style={{ marginBottom: 10 }}>
              <InterestSentCard
                name={otherUser?.fullName}
                gender={loggedInDetails?.userProfile?.gender}
                otherUserGender={otherUser?.gender}
                otherUserProfilePhoto={otherUser?.profilePhoto?.originalUrl}
                profilePhoto={loggedInDetails?.userProfile?.profilePhoto?.originalUrl}
                interestUuid={group?.interest?.uuid}
              />
            </View>
          );
        } else {
          return (
            <View style={{ marginBottom: 10 }}>
              <InterestReceviedCard
                name={otherUser?.fullName}
                interestUuid={group?.interest?.uuid}
                profileIds={[loggedInUser.profileUuid, otherUser.uuid]}
                gender={loggedInDetails?.userProfile?.gender}
                otherUserGender={otherUser?.gender}
                otherUserProfilePhoto={otherUser?.profilePhoto?.originalUrl}
                profilePhoto={loggedInDetails?.userProfile?.profilePhoto?.originalUrl}
                showButtons={!interestAccepted && !interestRejected}
                setInterestAccepted={setInterestAccepted}
                setInterestRejected={setInterestRejected}
              />
            </View>
          );
        }
      } else if (currentMessage.messageType === ChatMessageType.InterestAccepted) {
        if (group?.interest?.fromProfileUuid === loggedInUser.profileUuid) {
          return (
            <View style={{ marginBottom: 10 }}>
              <InterestAcceptedCard
                isSender={false}
                senderName={otherUser?.fullName}
                gender={loggedInDetails?.userProfile?.gender}
                otherUserGender={otherUser?.gender}
                otherUserProfilePhoto={otherUser?.profilePhoto?.originalUrl}
                profilePhoto={loggedInDetails?.userProfile?.profilePhoto?.originalUrl}
              />
            </View>
          );
        } else {
          return (
            <View style={{ marginBottom: 10 }}>
              <InterestAcceptedCard
                isSender={true}
                senderName={otherUser?.fullName}
                gender={loggedInDetails?.userProfile?.gender}
                otherUserGender={otherUser?.gender}
                otherUserProfilePhoto={otherUser?.profilePhoto?.originalUrl}
                profilePhoto={loggedInDetails?.userProfile?.profilePhoto?.originalUrl}
              />
            </View>
          );
        }
      } else if (currentMessage.messageType === ChatMessageType.InterestDeclined) {
        if (group?.interest?.fromProfileUuid === loggedInUser.profileUuid) {
          return (
            <View style={{ marginBottom: 10 }}>
              <InterestRejectedCard
                isSender={false}
                senderName={otherUser?.fullName}
                interestUuid={group?.interest?.uuid}
                profileIds={[loggedInUser.profileUuid, otherUser.uuid]}
                gender={loggedInDetails?.userProfile?.gender}
                otherUserGender={otherUser?.gender}
                otherUserProfilePhoto={otherUser?.profilePhoto?.originalUrl}
                profilePhoto={loggedInDetails?.userProfile?.profilePhoto?.originalUrl}
              />
            </View>
          );
        } else {
          return (
            <View style={{ marginBottom: 10 }}>
              <InterestRejectedCard
                isSender={true}
                senderName={otherUser?.fullName}
                interestUuid={group?.interest?.uuid}
                profileIds={[loggedInUser.profileUuid, otherUser.uuid]}
                gender={loggedInDetails?.userProfile?.gender}
                otherUserGender={otherUser?.gender}
                otherUserProfilePhoto={otherUser?.profilePhoto?.originalUrl}
                profilePhoto={loggedInDetails?.userProfile?.profilePhoto?.originalUrl}
                showButtons={!interestAccepted}
                setInterestAccepted={setInterestAccepted}
              />
            </View>
          );
        }
      }
    }

    if (currentMessage.text === null && !currentMessage.image) return null;
    return (
      <Bubble
        {...props}
        textStyle={{
          right: {
            fontFamily: EFonts.SORA_REGULAR,
            lineHeight: 20,
            fontSize: 14,
            textAlign: 'right',
            color: Color.white,
            ...(GenUtil.isWEB() && { marginLeft: 5, marginRight: 5 }),
          },
          left: {
            fontFamily: EFonts.SORA_REGULAR,
            lineHeight: 20,
            fontSize: 14,
            ...(GenUtil.isWEB() && { marginLeft: 5, marginRight: 5 }),
          },
        }}
        wrapperStyle={{
          right: {
            marginRight: 5,
            padding: 5,
            backgroundColor: '#811EFF',
            borderRadius: 12,
            borderTopRightRadius: 12,
            borderBottomRightRadius: 0,
            paddingRight: 5,
          },
          left: {
            marginLeft: 5,
            padding: 5,
            backgroundColor: '#F7F7F7',
            borderRadius: 12,
            borderTopLeftRadius: 12,
            borderBottomLeftRadius: 0,
            paddingRight: 5,
          },
        }}
      />
    );
  };

  const renderSend = (sendProps) => {
    const { text, messageIdGenerator, user, onSend } = sendProps;

    return (
      <TouchableOpacity
        style={{
          height: 38,
          width: 38,
          borderRadius: 19,
          backgroundColor: Color.purple,
          alignItems: 'center',
          justifyContent: 'center',
          bottom: 4,
          marginRight: 12,
        }}
        onPress={() => {
          if (text && onSend) {
            onSend({ text: text.trim(), user: user, _id: messageIdGenerator() }, true);
          }
        }}
      >
        <Image
          style={{ height: 17, width: 17 }}
          source={require('src/assets/icons/send_icon_white.png')}
        />
      </TouchableOpacity>
    );
  };

  const renderInputToolbar = (props: any) => {
    return (
      <InputToolbar
        {...props}
        containerStyle={{
          height: 70,
          borderTopWidth: 1,
          borderTopColor: 'white',
          position: 'absolute',
          bottom: GenUtil.isAndroid() || GenUtil.isWEB() ? 0 : -35,
          justifyContent: 'center',
          backgroundColor: Color.white,
          shadowColor: '#000000',
          shadowOpacity: 0.1,
          shadowRadius: 5,
        }}
      />
    );
  };

  const renderMessageImage = (props: { currentMessage: IMessage }) => {
    return (
      <Image
        {...props}
        onPress={() => setFullImage(props?.currentMessage?.image)}
        source={{ uri: props?.currentMessage?.image }}
        style={styles.imageMessage}
      />
    );
  };

  const KAView = GenUtil.isIOS() ? KeyboardAvoidingView : View;

  const handleOtherProfilePressed = () => {
    const uuid = otherUser?.uuid;
    navigation.navigate('otherpersonprofile', { uuid });
  };

  const renderTime = (props) => {
    return (
      <Time
        {...props}
        timeTextStyle={{
          left: { color: '#595959', fontFamily: EFonts.SORA_REGULAR, fontSize: 10 },
          right: {
            color: 'rgba(255, 255, 255, 0.8)',
            fontFamily: EFonts.SORA_REGULAR,
            fontSize: 10,
          },
        }}
        timeFormat="hh:mma"
      />
    );
  };

  const renderAvatar = (props: { currentMessage: IMessage }) => {
    const { currentMessage } = props;
    let imageProps;
    if (currentMessage?.user?._id === otherUser.uuid) {
      imageProps =
        otherUser.gender === 'male'
          ? {
              source: require('src/assets/images/placeholder-male.png'),
            }
          : {
              source: require('src/assets/images/placeholder-female.png'),
            };
    } else {
      imageProps =
        otherUser.gender !== 'male'
          ? {
              source: require('src/assets/images/placeholder-male.png'),
            }
          : {
              source: require('src/assets/images/placeholder-female.png'),
            };
    }
    return <Image style={{ height: 20, width: 20, borderRadius: 10 }} {...imageProps} />;
  };

  const RenderHeading = () => {
    return (
      <View style={styles.header}>
        <TouchableOpacity onPress={handleOtherProfilePressed}>
          <View style={styles.userContainer}>
            <View style={{ minWidth: 45 }}>
              <ImageWithPlaceholder
                style={styles.otherUserAvatar}
                url={otherUser?.profilePhoto?.originalUrl}
                gender={otherUser?.gender}
              />
            </View>
            <View>
              {otherUser?.isOnline ? (
                <Badge status="success" containerStyle={styles.badge} />
              ) : null}
            </View>
            <View
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <View>
                <Text style={styles.otherUserName}>{otherUser?.fullName || ''}</Text>
              </View>
              <View>
                {otherUser.isOnline ? (
                  <Text style={styles.onlineTextStyle}>Online</Text>
                ) : (
                  <Text style={styles.onlineTextStyle}>
                    {GenUtil.formatTimeAccDuration(otherUser?.lastActiveAt)}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </TouchableOpacity>
        <MoreOptionsAction profile={otherUser} iconColor="black" />
      </View>
    );
  };

  return (
    <SafeContainer
      showPageHeader={true}
      style={{ flex: 1, paddingTop: GenUtil.isWEB() ? 0 : 35 }}
      renderHeading={<RenderHeading />}
      leftIcon ={<Icon name="arrow-back" />}
      onPressLeftIcon = {() => navigation.navigate('InboxScreen')}
    >
      <KAView behavior={'padding'} style={{ flex: 1 }}>
        <FullScreenImage
          hideFullScreen={() => setFullImage(null)}
          visible={fullImage != null}
          images={[{ url: fullImage }]}
        />
        {loader ? (
          <View style={styles.loaderContainer}>
            <FullLoader />
          </View>
        ) : (
          <View
            style={{
              flex: 1,
            }}
          >
            <GiftedChat
              showUserAvatar
              messages={messages}
              onSend={(newMessage) => {
                saveMessage(newMessage)
                  .then(() => {})
                  .catch(() => {});
              }}
              user={{ _id: loggedInUser.profileUuid }}
              alwaysShowSend
              infiniteScroll
              loadEarlier={!noMoreMessages}
              isLoadingEarlier={loadingEarlier}
              renderInputToolbar={isBlocked ? () => null : renderInputToolbar}
              onLoadEarlier={loadEarlierMessages}
              renderActions={isBlocked ? null : renderActions}
              renderComposer={renderComposer}
              renderBubble={renderBubble}
              renderMessageImage={renderMessageImage}
              renderAvatar={renderAvatar}
              showAvatarForEveryMessage={true}
              renderSend={renderSend}
              renderTime={renderTime}
              messagesContainerStyle={styles.messagesContainerStyle}
              placeholder="Type your message here..."
            />
          </View>
        )}
        {selectedImage?.uri ? (
          <WebImgPickerComp
            selectedImage={selectedImage?.uri}
            setSelectedImage={setSelectedImage}
            onImageSelected={onImageSelected}
          />
        ) : null}
      </KAView>
    </SafeContainer>
  );
};

const styles = StyleSheet.create({
  userContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  messagesContainerStyle: {
    height: GenUtil.isIOS() ? '98%' : GenUtil.isAndroid() ? '96%' : '67vh',
    top: GenUtil.isWEB() ? 0 : -60,
    paddingTop: GenUtil.isAndroid() ? 28 : 15,
    // borderWidth: 2,
    marginTop: 15,
  },
  imageMessage: {
    width: 200,
    height: 200,
    resizeMode: 'cover',
    borderRadius: 20,
  },
  goBackRow: {
    marginTop: 10,
    alignItems: 'center',
    flexDirection: 'row',
  },
  dialogStyle: {
    alignSelf: 'center',
    width: 250,
    borderRadius: 5,
    backgroundColor: Color.white,
  },
  loaderContainer: {
    flex: 1,
    height,
    width,
    alignSelf: 'center',
  },
  otherUserName: {
    paddingLeft: 10,
    color: Color.lightBlack,
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 15,
    lineHeight: 20,
  },
  otherUserAvatar: {
    width: 40,
    height: 40,
    borderRadius: 50,
  },
  headerContainer: {
    paddingHorizontal: 10,
  },
  header: {
    backgroundColor: Color.white,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    paddingHorizontal: 5,
    flex: 1,
  },
  textInputStyle: {
    color: '#222',
    fontFamily: EFonts.DMSANS_REGULAR,
    borderWidth: 1,
    borderRadius: 58,
    backgroundColor: Color.white,
    borderColor: Color.lightBorder,
    paddingTop: GenUtil.isAndroid() ? 0 : 12,
    paddingLeft: 12,
    marginHorizontal: 12,
  },
  badge: {
    position: 'absolute',
    left: -15,
    bottom: -20,
  },
  onlineTextStyle: {
    fontSize: 10,
    lineHeight: 13,
    fontFamily: EFonts.SORA_REGULAR,
    color: '#595959',
    marginLeft: 12,
  },
  blockDialogStyle: {
    height: 100,
    width: 200,
    borderWidth: 1,
    right: GenUtil.isWEB() ? '-47%' : '-40%',
    top: GenUtil.isWEB() ? '-35%' : GenUtil.isIOS() ? '-36%' : '-37%',
    backgroundColor: Color.white,
    borderRadius: 5,
  },
  blockUserMsgContainerStyle: {
    paddingVertical: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
  userBlockedTextStyle: {
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontSize: 18,
    color: Color.lightBlack,
  },
  redirectingMsgContainerStyle: {
    marginTop: 10,
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  reportUserTextStyle: {
    backgroundColor: Color.backgroundGray,
    fontSize: 16,
    fontFamily: EFonts.DMSANS_BOLD,
    color: Color.gray,
    paddingLeft: 10,
    paddingRight: 5,
    left: 3,
    height: 40,
    borderRadius: 80,
  },
});

export default MessageScreen;
