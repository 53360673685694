/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useState } from 'react';
import { GenUtil } from 'src/util/GenUtil';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import { ProgressBar } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import FullScreenImage from 'src/components/common/FullScreenImageViewer';
import { ProfileResp } from 'src/common/response/profile.resp';
import HeadingPoints from 'src/components/editProfileComponents/molecules/HeadingPoints';
import HeaderBackButton from 'src/components/HeaderBackButton';

const { width, height } = GenUtil.getDimension();

export default function ProfileHeader({
  imageVisible = false,
  setImageVisible = (_: boolean) => {},
  userProfile,
}: {
  imageVisible: boolean;
  setImageVisible: (_: boolean) => void;
  userProfile: Partial<ProfileResp>;
}) {
  const [imageUrl, setImageUrl] = useState('');
  const navigation = useNavigation();

  return (
    <View>
      <FullScreenImage
        hideFullScreen={() => {
          setImageVisible(false);
          setImageUrl('');
        }}
        visible={imageVisible}
        images={[{ url: imageUrl }]}
      />
      <View>
        <View style={styles.backgroundCover}>
          <Image
            source={require('src/assets/images/background/BgDesign1.png')}
            style={styles.bgDesign1}
          />
          <Image
            source={require('src/assets/images/background/BgDesign2.png')}
            style={styles.bgDesign2}
          />
          <Image
            source={require('src/assets/images/background/BgDesign3.png')}
            style={styles.bgDesign3}
          />
          <View style={styles.topHeadingButton}>
            <View style={styles.topImageText}>
              <HeaderBackButton
                icon={
                  <Image
                    source={require('src/assets/images/LeftArrow.png')}
                    style={styles.topHeadingImg}
                  />
                }
              />
              <Text style={styles.topTitle}>My Profile</Text>
            </View>
            <View style={styles.eyeContainer}>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate('otherpersonprofile', { uuid: userProfile?.uuid })
                }
              >
                <Image
                  source={require('src/assets/images/Eyes.png')}
                  style={styles.topHeadingImg}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View>
            <View style={styles.nameDetailParent}>
              {userProfile?.fullName && (
                <View style={styles.nameContainer}>
                  <Text style={styles.name}>{userProfile.fullName} </Text>
                  {userProfile.verifications?.length > 0 && (
                    <View style={{ flex: 1 }}>
                      <Image
                        source={require('src/assets/images/Verified.png')}
                        style={styles.verifyImg}
                      />
                    </View>
                  )}
                </View>
              )}
              {userProfile?.currentLocation && (
                <View style={styles.locationParent}>
                  <HeadingPoints
                    source={require('src/assets/images/Location.png')}
                    title={userProfile.currentLocation}
                    iconDimension={{ height: 14, width: 14 }}
                    textAdditionalStyle={styles.location}
                  />
                </View>
              )}
            </View>
          </View>
        </View>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('editphotoscreen');
            // if (userProfile?.profilePhoto?.originalUrl) {
            //   setImageUrl(userProfile?.profilePhoto?.originalUrl);
            //   setImageVisible(true);
            // }
          }}
          style={styles.imageButton}
        >
          <Image source={GenUtil.getProfilePic(userProfile)} style={styles.profileImage} />
        </TouchableOpacity>
      </View>
      {userProfile?.trustScore > 0 && (
        <View style={styles.progressbarParent}>
          <View style={styles.progressbarTextContainer}>
            <Text style={styles.trustScoreHeading}>Your Trust Score is:</Text>
            <Text style={styles.trustScore}>{userProfile.trustScore}</Text>
          </View>
          <View style={styles.progressbarContainer}>
            <ProgressBar
              progress={userProfile.trustScore / 100}
              color={'#2AB108'}
              style={styles.progressbar}
            />
          </View>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  progressbarParent: {
    width: width * 0.53,
    alignSelf: 'flex-end',
    marginRight: 16,
    marginTop: 16,
  },
  progressbarTextContainer: {
    flexDirection: 'row',
    flexShrink: 1,
    justifyContent: 'space-between',
  },
  trustScoreHeading: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 15,
  },
  trustScore: {
    fontFamily: EFonts.SORA_MEDIUM,
    fontSize: 12,
    lineHeight: 15,
    color: '#2AB108',
  },
  progressbarContainer: {
    marginTop: 4,
  },
  progressbar: {
    backgroundColor: '#cff2c7',
    borderRadius: 32,
  },
  backgroundCover: {
    backgroundColor: Color.purple,
    width: '100%',
    minHeight: 180,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
  },
  topHeadingButton: {
    width: '100%',
    flexDirection: 'row',
    marginTop: 45,
    paddingHorizontal: 16,
  },
  topImageText: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  topTitle: {
    flex: 1,
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 17,
    lineHeight: 21,
    color: Color.white,
    marginLeft: 15,
  },
  eyeContainer: {
    flex: 1,
    alignItems: 'flex-end',
  },
  location: {
    marginLeft: 5,
    marginTop: !GenUtil.isWEB() ? -5 : -2,
    color: Color.white,
  },
  locationParent: {
    flexDirection: 'row',
    marginVertical: 7,
  },
  imageButton: {
    padding: 10,
    backgroundColor: Color.white,
    borderRadius: 80,
    width: 130,
    height: 130,
    marginLeft: 26,
    marginTop: -80,
  },
  profileImage: {
    width: 120,
    height: 120,
    borderRadius: 60,
    marginLeft: -5,
    marginTop: -5,
  },
  nameDetailParent: {
    marginTop: 50,
    marginLeft: 170,
    width: width - 170 - 30,
    paddingRight: 10,
  },
  name: {
    fontFamily: EFonts.SORA_MEDIUM,
    fontSize: 18,
    lineHeight: 23,
    color: Color.white,
  },
  bgDesign1: {
    width: '100%',
    height: 104,
    position: 'absolute',
    top: 50,
  },
  bgDesign2: {
    width: 112,
    height: 180,
    position: 'absolute',
    left: 20,
  },
  bgDesign3: {
    width: 263,
    height: 180,
    position: 'absolute',
    right: 20,
  },
  nameContainer: {
    flexDirection: 'row',
  },
  locationImg: {
    width: 14,
    height: 14,
  },
  topHeadingImg: {
    width: 24,
    height: 24,
  },
  verifyImg: {
    width: 22,
    height: 22,
    marginLeft: 7,
  },
});
