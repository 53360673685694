import { View } from 'src/components/Themed';
import React, { useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import ChoicesSelector from 'src/components/editProfileComponents/ChoicesSelector';
import { MaritalStatus } from 'src/common/models/profile.model';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';

const MarriageStatus = ({
  partnerMaritalStatuses,
  updatePartnerPref,
  setSearchParams,
}: {
  partnerMaritalStatuses: MaritalStatus[];
  updatePartnerPref: (_) => Promise<void>;
  setSearchParams?: (prevParams) => void;
}) => {
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(partnerMaritalStatuses.length ? partnerMaritalStatuses : [DOESNT_MATTER_OPTION.name]);
  const maritalStatus = [DOESNT_MATTER_OPTION.name, ...Object.values(MaritalStatus).map((value) => value as string)];

  useEffect(() => {
    const validPreferences = selectedMaritalStatus[0] === DOESNT_MATTER_OPTION.name ? [] : selectedMaritalStatus;

    if (setSearchParams) {
      setSearchParams((prevParams: Partial<ProfileSearchBody>) => ({
        ...prevParams,
        ...{ maritalStatuses: validPreferences },
      }));
      return;
    }
    void updatePartnerPref({ maritalStatuses: validPreferences });
  }, [selectedMaritalStatus]);

  return (
    <View style={{ bottom: 20 }}>
      <ChoicesSelector
        dropdownItems={maritalStatus}
        placeHolder="Search MaritalStatus here..."
        selectedValues={selectedMaritalStatus}
        setSelectedValues={setSelectedMaritalStatus}
      />
    </View>
  );
};

export default MarriageStatus;
