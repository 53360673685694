type RazorPaySuccess = {
  razorpay_order_id: string;
  razorpay_payment_id: string;
  razorpay_signature: string;
};

interface RazorPay {
  open: () => void;
  on: (eventName: string, callback: (res: Error) => void) => void;
}

type Options = {
  modal: {
    ondismiss: () => void;
  };
  handler: (response: any) => void;
};

export default class ReactNativeRazorpay {
  static open(
    options: Options,
    successCallback: (res: RazorPaySuccess) => void,
    errorCallback: (res: Error | string) => void,
  ) {
    return new Promise((resolve, reject) => {
      const rzpOptions: Options = {
        ...options,
        modal: {
          ondismiss: () => {
            const rejectFn = errorCallback || reject;
            rejectFn('Checkout Form Closed');
          },
        },
        handler: (response: any) => {
          const resolveFn = successCallback || resolve;
          resolveFn(response);
        },
      };

      const rzp = new window.Razorpay(rzpOptions) as RazorPay;

      rzp.on('payment.failed', (response: Error) => {
        const rejectFn = errorCallback || reject;
        rejectFn(response);
      });

      rzp.open();
    });
  }
}
