export type HashtagId = number;
export const HashtagData: Array<{ id: HashtagId; name: string; order: number }> = [
  {
    id: 1,
    name: 'Activist',
    order: 1,
  },
  {
    id: 2,
    name: 'AdventureLover',
    order: 2,
  },
  {
    id: 3,
    name: 'Ambitious',
    order: 3,
  },
  {
    id: 4,
    name: 'Ambivert',
    order: 4,
  },
  {
    id: 5,
    name: 'Amicable',
    order: 5,
  },
  {
    id: 6,
    name: 'AnimalLover',
    order: 6,
  },
  {
    id: 7,
    name: 'BeachLover',
    order: 7,
  },
  {
    id: 8,
    name: 'Believer',
    order: 8,
  },
  {
    id: 9,
    name: 'Bollywood',
    order: 9,
  },
  {
    id: 10,
    name: 'Calm',
    order: 10,
  },
  {
    id: 11,
    name: 'CarBikeLover',
    order: 11,
  },
  {
    id: 12,
    name: 'CareerOriented',
    order: 12,
  },
  {
    id: 13,
    name: 'CareFree',
    order: 13,
  },
  {
    id: 14,
    name: 'CaringNature',
    order: 14,
  },
  {
    id: 15,
    name: 'CatPerson',
    order: 15,
  },
  {
    id: 16,
    name: 'ChatterBox',
    order: 16,
  },
  {
    id: 17,
    name: 'Chill',
    order: 17,
  },
  {
    id: 18,
    name: 'Chocoholic',
    order: 18,
  },
  {
    id: 19,
    name: 'CoffeeAddict',
    order: 19,
  },
  {
    id: 20,
    name: 'Confident',
    order: 20,
  },
  {
    id: 21,
    name: 'Conservative',
    order: 21,
  },
  {
    id: 22,
    name: 'Creative',
    order: 22,
  },
  {
    id: 23,
    name: 'Curious',
    order: 23,
  },
  {
    id: 24,
    name: 'Democrat',
    order: 24,
  },
  {
    id: 25,
    name: 'Dependable',
    order: 25,
  },
  {
    id: 26,
    name: 'DogPerson',
    order: 26,
  },
  {
    id: 27,
    name: 'DownToEarth',
    order: 27,
  },
  {
    id: 28,
    name: 'DreamChaser',
    order: 28,
  },
  {
    id: 29,
    name: 'EasyGoing',
    order: 29,
  },
  {
    id: 30,
    name: 'Emotional',
    order: 30,
  },
  {
    id: 31,
    name: 'Energetic',
    order: 31,
  },
  {
    id: 32,
    name: 'Enthusiastic',
    order: 32,
  },
  {
    id: 33,
    name: 'Environmentalist',
    order: 33,
  },
  {
    id: 34,
    name: 'Escapist',
    order: 34,
  },
  {
    id: 35,
    name: 'Explorer',
    order: 35,
  },
  {
    id: 36,
    name: 'Extrovert',
    order: 36,
  },
  {
    id: 37,
    name: 'Fashionista',
    order: 37,
  },
  {
    id: 38,
    name: 'Feminist',
    order: 38,
  },
  {
    id: 39,
    name: 'Filmy',
    order: 39,
  },
  {
    id: 40,
    name: 'FitnessEnthusiast',
    order: 40,
  },
  {
    id: 41,
    name: 'Focused',
    order: 41,
  },
  {
    id: 42,
    name: 'Foodie',
    order: 42,
  },
  {
    id: 43,
    name: 'Frank',
    order: 43,
  },
  {
    id: 44,
    name: 'FreeSpirited',
    order: 44,
  },
  {
    id: 45,
    name: 'Funaholic',
    order: 45,
  },
  {
    id: 46,
    name: 'GadgetLover',
    order: 46,
  },
  {
    id: 47,
    name: 'GymEnthusiast',
    order: 47,
  },
  {
    id: 48,
    name: 'Happening',
    order: 48,
  },
  {
    id: 49,
    name: 'HappyAlways',
    order: 49,
  },
  {
    id: 50,
    name: 'Hardworking',
    order: 50,
  },
  {
    id: 51,
    name: 'HealthConscious',
    order: 51,
  },
  {
    id: 52,
    name: 'Helping',
    order: 52,
  },
  {
    id: 53,
    name: 'Homely',
    order: 53,
  },
  {
    id: 54,
    name: 'HoroscopeLover',
    order: 54,
  },
  {
    id: 55,
    name: 'Humble',
    order: 55,
  },
  {
    id: 56,
    name: 'Humorous',
    order: 56,
  },
  {
    id: 57,
    name: 'Imaginative',
    order: 57,
  },
  {
    id: 58,
    name: 'Independent',
    order: 58,
  },
  {
    id: 59,
    name: 'Intellectual',
    order: 59,
  },
  {
    id: 60,
    name: 'Introvert',
    order: 60,
  },
  {
    id: 61,
    name: 'Jovial',
    order: 61,
  },
  {
    id: 62,
    name: 'Joyful',
    order: 62,
  },
  {
    id: 63,
    name: 'KindHearted',
    order: 63,
  },
  {
    id: 64,
    name: 'Learner',
    order: 64,
  },
  {
    id: 65,
    name: 'Liberal',
    order: 65,
  },
  {
    id: 66,
    name: 'Logical',
    order: 66,
  },
  {
    id: 67,
    name: 'Mature',
    order: 67,
  },
  {
    id: 68,
    name: 'MorningPerson',
    order: 68,
  },
  {
    id: 69,
    name: 'NatureSeeker',
    order: 69,
  },
  {
    id: 70,
    name: 'Nerd',
    order: 70,
  },
  {
    id: 71,
    name: 'NightOwl',
    order: 71,
  },
  {
    id: 72,
    name: 'Noctural',
    order: 72,
  },
  {
    id: 73,
    name: 'OldSchool',
    order: 73,
  },
  {
    id: 74,
    name: 'OpenMinded',
    order: 74,
  },
  {
    id: 75,
    name: 'Opinionated',
    order: 75,
  },
  {
    id: 76,
    name: 'Optimist',
    order: 76,
  },
  {
    id: 77,
    name: 'OutGoing',
    order: 77,
  },
  {
    id: 78,
    name: 'PassiveActivist',
    order: 78,
  },
  {
    id: 79,
    name: 'PeaceLover',
    order: 79,
  },
  {
    id: 80,
    name: 'PeopleObserver',
    order: 80,
  },
  {
    id: 81,
    name: 'Perfectionist',
    order: 81,
  },
  {
    id: 82,
    name: 'Philosophical',
    order: 82,
  },
  {
    id: 83,
    name: 'Positive',
    order: 83,
  },
  {
    id: 84,
    name: 'Practical',
    order: 84,
  },
  {
    id: 85,
    name: 'Professional',
    order: 85,
  },
  {
    id: 86,
    name: 'Realistic',
    order: 86,
  },
  {
    id: 87,
    name: 'Relaxed',
    order: 87,
  },
  {
    id: 88,
    name: 'Reliable',
    order: 88,
  },
  {
    id: 89,
    name: 'Religious',
    order: 89,
  },
  {
    id: 90,
    name: 'Reserved',
    order: 90,
  },
  {
    id: 91,
    name: 'RiskTaker',
    order: 91,
  },
  {
    id: 92,
    name: 'Romantic',
    order: 92,
  },
  {
    id: 93,
    name: 'Sarcastic',
    order: 93,
  },
  {
    id: 94,
    name: 'ScienceNerd',
    order: 94,
  },
  {
    id: 95,
    name: 'Serious',
    order: 95,
  },
  {
    id: 96,
    name: 'Shopaholic',
    order: 96,
  },
  {
    id: 97,
    name: 'Simple',
    order: 97,
  },
  {
    id: 98,
    name: 'Sincere',
    order: 98,
  },
  {
    id: 99,
    name: 'Skeptic',
    order: 99,
  },
  {
    id: 100,
    name: 'SoftHearted',
    order: 100,
  },
  {
    id: 101,
    name: 'Spiritual',
    order: 101,
  },
  {
    id: 102,
    name: 'Spontaneous',
    order: 102,
  },
  {
    id: 103,
    name: 'StoryTeller',
    order: 103,
  },
  {
    id: 104,
    name: 'StraightForward',
    order: 104,
  },
  {
    id: 105,
    name: 'Stylish',
    order: 105,
  },
  {
    id: 106,
    name: 'Sweet',
    order: 106,
  },
  {
    id: 107,
    name: 'Talkative',
    order: 107,
  },
  {
    id: 108,
    name: 'TeaLover',
    order: 108,
  },
  {
    id: 109,
    name: 'TechFreak',
    order: 109,
  },
  {
    id: 110,
    name: 'TechSavvy',
    order: 110,
  },
  {
    id: 111,
    name: 'Teetotaler',
    order: 111,
  },
  {
    id: 112,
    name: 'Thinker',
    order: 112,
  },
  {
    id: 113,
    name: 'ThrillSeeker',
    order: 113,
  },
  {
    id: 114,
    name: 'Traditional',
    order: 114,
  },
  {
    id: 115,
    name: 'Trustworthy',
    order: 115,
  },
  {
    id: 116,
    name: 'Understanding',
    order: 116,
  },
  {
    id: 117,
    name: 'Unique',
    order: 117,
  },
  {
    id: 118,
    name: 'Wanderlust',
    order: 118,
  },
  {
    id: 119,
    name: 'Witty',
    order: 119,
  },
];

export const HashtagDataMap: { [key in number]: { name: string; id: HashtagId; order: number } } = HashtagData.reduce((map, data) => {
  map[data.id] = data;
  return map;
}, {});
