import React, { useEffect, useState } from 'react';
import { Modal, DatePicker, TimePicker } from 'antd';
import { GenUtil } from 'src/util/GenUtil';
import { Color } from 'src/constant/Color';
import { View } from 'src/components/Themed';
const { width } = GenUtil.getDimension();

const DateTimePickerModal = ({
  isVisible = false,
  mode = 'date',
  onConfirm = () => {},
  onCancel = () => {},
  maximumDate = new Date(Date.now()),
  date = null,
}) => {
  const DateTime = mode === 'date' ? DatePicker : mode === 'time' ? TimePicker : View;
  const [tempDate, setTempDate] = useState(maximumDate);
  const [dateError, setDateError] = useState(false);
  const handleOK = () => {
    if (mode === 'time') {
      onConfirm(tempDate);
      return;
    }
    if (!tempDate || tempDate.getTime() > maximumDate.getTime()) {
      return;
    }
    onConfirm(tempDate);
  };

  useEffect(() => {
    if (mode === 'time') return;
    if (!tempDate || tempDate.getTime() > maximumDate.getTime()) {
      setDateError(true);
    } else {
      setDateError(false);
    }
  }, [tempDate]);

  return (
    <Modal
      centered
      mask={false}
      width={width * 0.9}
      open={isVisible}
      onOk={handleOK}
      onCancel={onCancel}
      closeIcon={<></>}
    >
      <DateTime
        style={{
          width: width * 0.775,
          marginBottom: 10,
          borderWidth: 1,
          borderColor: dateError ? Color.red : Color.purple,
        }}
        onChange={(value) => setTempDate(value?.$d)}
      />
      <div style={{ height: 15 }}>
        {dateError && <span style={{ color: Color.red }}>Choose a valid date</span>}
      </div>
    </Modal>
  );
};

export default DateTimePickerModal;
