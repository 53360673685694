import { FlatList, Image, SafeAreaView, Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import ProfileListAndChatHeader from 'src/components/common/ProfileListAndChatHeader';
import { Color } from 'src/constant/Color';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ProfileService } from 'src/service/ProfileService';
import EFonts from 'src/constant/EFonts';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';
import { ProfileResp } from 'src/common/response/profile.resp';
import LargeProfileCard from 'src/components/common/profileCard/LargeProfileCard';

const titles = ['Similar Profiles', 'Near you', 'Recently joined'];
const propertiesToConvertInArray = [
  'maritalStatuses',
  'professionIds',
  'qualificationIds',
  'religions',
  'statesAndCities',
];

const ListSearchedProfiles = ({ route }: { route: { params: Partial<ProfileSearchBody> } }) => {
  const isFocused = useIsFocused();
  const searchParams = convertStringToArray(route?.params);
  const [currentSearchParams, setCurrentSearchParams] =
    useState<Partial<ProfileSearchBody>>(searchParams);
  const [bestMatchingProfiles, setBestMatchingProfiles] = useState<ProfileResp[]>([]);
  const [currentVerticalPage, setCurrentVerticalPage] = useState(0);

  function convertStringToArray(searchParams: Partial<ProfileSearchBody>) {
    for (const property of propertiesToConvertInArray) {
      if (property in searchParams && searchParams[property] !== '') {
        if (!Array.isArray(searchParams[property])) {
          searchParams[property] = searchParams[property].split(',');
        }
      } else if (property in searchParams) {
        searchParams[property] = [];
      }
    }
    return searchParams;
  }

  const getBestMatchingProfiles = async (page: number) => {
    const response = await ProfileService.getProfiles(currentSearchParams, page);
    return response.data;
  };

  useEffect(() => {
    getBestMatchingProfiles(0)
      .then((resp) => {
        setBestMatchingProfiles(resp);
        setCurrentVerticalPage(1);
      })
      .catch(() => {});
  }, [currentSearchParams]);

  useEffect(() => {
    if (isFocused) {
      setCurrentSearchParams(searchParams);
    }
  }, [isFocused]);

  const [selectedTitle, setSelectedTitle] = useState(titles[0]);
  const navigation = useNavigation();

  const handleTitlePress = (title) => {
    setSelectedTitle(title);
  };

  const onVerticalEndReach = () => {
    if (bestMatchingProfiles.length < 10) return;
    const page = currentVerticalPage;
    getBestMatchingProfiles(page)
      .then((resp) => {
        setBestMatchingProfiles([...bestMatchingProfiles, ...resp]);
        setCurrentVerticalPage(page + 1);
      })
      .catch((error) => console.log('Error in fetching more vertical profiles', error));
  };

  const handleRightIconPressed = () => {
    navigation.navigate('searchfiltersscreen', { currentSearchParams, setCurrentSearchParams });
  };

  return (
    <SafeAreaView style={{ backgroundColor: Color.white, flex: 1 }}>
      <ProfileListAndChatHeader
        heading={`${bestMatchingProfiles.length} Results Found`}
        rightIcon={<Image source={require('src/assets/icons/filterIcon.png')} />}
        onRightIconPressed={handleRightIconPressed}
      />
      <FlatList
        data={[1]}
        keyExtractor={(item) => item.toString()}
        renderItem={() => (
          <View style={{ flex: 1 }}>
            <View style={{ marginTop: 30, marginBottom: 20, paddingLeft: 15 }}>
              <Text
                style={{
                  fontFamily: EFonts.SORA_SEMIBOLD,
                  fontSize: 15,
                  lineHeight: 19,
                  color: Color.lightBlack,
                }}
              >
                Best Matches For You
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              {bestMatchingProfiles.length ? (
                bestMatchingProfiles.map((item, index) => {
                  return (
                    <View style={{ marginBottom: 16 }} key={index}>
                      <LargeProfileCard
                        item={item}
                        containerStyles={{ marginRight: 0 }}
                        isSearchedCard={true}
                      />
                    </View>
                  );
                })
              ) : (
                <View>
                  <Text style={{ textAlign: 'center' }}>No relevant profile found</Text>
                </View>
              )}
            </View>
          </View>
        )}
        showsVerticalScrollIndicator={false}
        onEndReached={onVerticalEndReach}
      />
    </SafeAreaView>
  );
};

export default ListSearchedProfiles;
