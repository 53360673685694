import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, Image } from 'react-native';

import { GenUtil } from 'src/util/GenUtil';
import PillShapeChip from 'src/components/common/chips/PillShapeChip';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import { UserRelation } from 'src/common/models/user.model';
import { Gender } from 'src/common/models/profile.model';

const { height, width } = GenUtil.getDimension();

interface interfaceProfileUserRelation {
  profileRelationToUser: string;
  setProfileRelationToUser: (_) => void;
  gender: Gender;
  setGender: (_) => void;
  next: () => void;
  prev: () => void;
  lookingFor: string;
  setLookingFor: (_) => void;
  userRelationToProfile: string;
  setUserRelationToProfile: (_) => void;
  lookingForGender: Gender;
  setLookingForGender: (_) => void;
}

const OnboardingRelationSelectionComp = ({
  profileRelationToUser = '',
  setProfileRelationToUser = (_) => {},
  gender = '' as Gender,
  setGender = (_) => {},
  next = () => {},
  prev = () => {},
  lookingFor = '',
  setLookingFor = () => {},
  userRelationToProfile = '',
  setUserRelationToProfile = () => {},
  lookingForGender = '' as Gender,
  setLookingForGender = () => {},
}: interfaceProfileUserRelation) => {
  const profileRelationToUserArray = [
    { key: 'Self', value: 'Self' },
    { key: 'Brother', value: 'My Brother' },
    { key: 'Sister', value: 'My Sister' },
    { key: 'Daughter', value: 'My Daughter' },
    { key: 'Son', value: 'My Son' },
    { key: 'Niece', value: 'My Niece' },
    { key: 'Nephew', value: 'My Nephew' },
    { key: 'Relative', value: 'My Relative' },
    { key: 'Friend', value: 'Friend' },
    { key: 'Client', value: 'Bureau Client' },
  ];

  const brideAndGroom = [
    { key: 'Bride', value: 'Bride' },
    { key: 'Groom', value: 'Groom' },
  ];

  const [maleRel, setMaleRel] = useState('');
  const [femaleRel, setFemaleRel] = useState('');
  const [relation, setRelation] = useState([] as string[]);

  useEffect(() => {
    if (profileRelationToUser) {
      setRelation([profileRelationToUser]);
      relationOptionRender(profileRelationToUser);
    }
  }, []);

  const relationOptionRender = (selectedVal) => {
    if (
      selectedVal === 'Self' ||
      selectedVal === 'Friend' ||
      selectedVal === 'Client' ||
      selectedVal === 'Relative'
    ) {
      setMaleRel(brideAndGroom[1].value);
      setFemaleRel(brideAndGroom[0].value);
      setUserRelationToProfile(selectedVal);
    } else if (selectedVal === 'Son' || selectedVal === 'Daughter') {
      setMaleRel(UserRelation.Father);
      setFemaleRel(UserRelation.Mother);
    } else if (selectedVal === 'Brother' || selectedVal === 'Sister') {
      setMaleRel(UserRelation.Brother);
      setFemaleRel(UserRelation.Sister);
    } else if (selectedVal === 'Niece' || selectedVal === 'Nephew') {
      setMaleRel(UserRelation.Uncle);
      setFemaleRel(UserRelation.Aunt);
    }
  };

  const relationSelected = (selectedVal) => {
    if (selectedVal) {
      setProfileRelationToUser(selectedVal);
      setUserRelationToProfile('');
      setLookingFor('');
      if (selectedVal === 'Brother' || selectedVal === 'Son' || selectedVal === 'Nephew') {
        setGender(Gender.Male);
        setLookingForGender(Gender.Female);
        setLookingFor('Bride');
      } else if (
        selectedVal === 'Sister' ||
        selectedVal === 'Daughter' ||
        selectedVal === 'Niece'
      ) {
        setGender(Gender.Female);
        setLookingForGender(Gender.Male);
        setLookingFor('Groom');
      } else {
        setGender('');
        setLookingForGender('');
      }

      relationOptionRender(selectedVal);
    }
  };

  const setRelationOrLookingFor = (relationValue: string) => {
    if (relationValue === 'Bride') {
      setLookingFor('Bride');
      setGender(Gender.Male);
      setLookingForGender(Gender.Female);
    } else if (relationValue === 'Groom') {
      setLookingFor('Groom');
      setGender(Gender.Female);
      setLookingForGender(Gender.Male);
    } else {
      Object.values(UserRelation).map((value) => {
        if (relationValue === value) {
          setLookingFor(gender === Gender.Male ? 'Bride' : 'Groom');
          setUserRelationToProfile(value);
        }
      });
    }
    setTimeout(() => {
      next();
    }, 500);
  };

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.item}>
          <Text style={styles.questionText}>This Profile Is For?</Text>
          <PillShapeChip
            options={profileRelationToUserArray}
            selected={relation}
            setSelected={setRelation}
            unselectedIcon={require('src/assets/icons/person_light.png')}
            selectedIcon={require('src/assets/icons/person_dark.png')}
            iconDimension={{ width: 15, height: 25 }}
            chipClicked={relationSelected}
            callChipClicked={true}
          />
        </View>
        {relation.length > 0 && (
          <View>
            <Text style={styles.questionText}>
              {relation[0] === 'Self' ||
              relation[0] === 'Friend' ||
              relation[0] === 'Client' ||
              relation[0] === 'Relative'
                ? 'Looking For?'
                : `You are ${gender === Gender.Male ? 'his' : 'her'}`}
            </Text>
            <View style={styles.imageContainer}>
              <View style={styles.imageButtonContainer}>
                <TouchableOpacity
                  onPress={() => {
                    setRelationOrLookingFor(maleRel);
                  }}
                  style={
                    (lookingFor === maleRel || userRelationToProfile === maleRel) &&
                    styles.selectedBox
                  }
                >
                  <Image
                    source={require('src/assets/icons/male_avatar.png')}
                    style={styles.imageStyle}
                  />
                </TouchableOpacity>
                <Text style={styles.imageText}>{maleRel}</Text>
              </View>
              <View style={styles.imageButtonContainer}>
                <TouchableOpacity
                  onPress={() => {
                    setRelationOrLookingFor(femaleRel);
                  }}
                  style={
                    (lookingFor === femaleRel || userRelationToProfile === femaleRel) &&
                    styles.selectedBox
                  }
                >
                  <Image
                    source={require('src/assets/icons/female_avatar.png')}
                    style={styles.imageStyle}
                  />
                </TouchableOpacity>
                <Text style={styles.imageText}>{femaleRel}</Text>
              </View>
            </View>
          </View>
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: width * 0.05,
    paddingTop: 20,
    backgroundColor: 'transparent',
  },
  questionText: {
    fontSize: 28,
    fontFamily: 'sora-light',
    marginTop: 10,
  },
  item: {
    marginBottom: 25,
  },
  buttonWrapper: {
    position: 'absolute',
    top: height * 0.9,
    left: width * 0.1,
  },
  selectedBox: {
    borderColor: '#8041C6',
    borderWidth: 2,
    borderRadius: 70,
  },
  imageContainer: {
    flexDirection: 'row',
    marginVertical: 20,
    marginLeft: 5,
  },
  imageText: {
    color: Color.lightBlack,
    fontSize: 15,
    lineHeight: 19,
    fontFamily: EFonts.SORA_REGULAR,
    marginTop: 10,
  },
  imageStyle: {
    height: 120,
    width: 120,
  },
  imageButtonContainer: {
    alignItems: 'center',
    marginRight: 40,
  },
});

export default OnboardingRelationSelectionComp;
