import { ProfileResp } from 'src/common/response/profile.resp';
import {
  ImageBackground,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import { LinearGradient } from 'expo-linear-gradient';
import InterestAction from 'src/components/common/profileCard/actions/InterestAction';
import MessageAction from 'src/components/common/profileCard/actions/MessageAction';
import UserUtil from 'src/util/UserUtil';
import { useNavigation } from '@react-navigation/native';
import { Verification } from 'src/common/models/profile.model';
import VerifySvg from 'src/assets/icons/verify.svg';
import UserTickSvg from 'src/assets/icons/user-tick.svg';
import MapPinSvg from 'src/assets/icons/map-pin.svg';
import AddToFavAction from './actions/AddToFavAction';

const { height, width } = GenUtil.getDimension();

const CARD_HEIGHT = GenUtil.isWEB() ? height / 2.5 : height / 2;
const CARD_WIDTH = width * 0.8;

const LargeProfileCard = ({
  item,
  containerStyles,
  isSearchedCard = false,
}: {
  item: ProfileResp;
  containerStyles?: ViewStyle;
  isSearchedCard?: boolean;
}) => {
  const navigation = useNavigation();

  const navigateToProfile = () => {
    navigation.navigate('otherpersonprofile', { uuid: item.uuid });
  };

  return (
    <TouchableWithoutFeedback onPress={navigateToProfile}>
      <View style={[styles.container, containerStyles]} key={item.profileId}>
        <ImageBackground source={{ uri: item.profilePhoto?.originalUrl }} style={styles.image}>
          <LinearGradient
            colors={['#00000000', '#000000']}
            style={{ height: GenUtil.isWEB() ? height / 2.2 : 500, width: '100%' }}
          ></LinearGradient>
        </ImageBackground>
        <View style={styles.info}>
          <View style={styles.infoContainer}>
            <Text style={styles.infoText}>{item.fullName}</Text>
            {item?.verifications?.find(
              (verification) => verification === Verification.IDVerified,
            ) ? (
              <VerifySvg style={{ marginLeft: 8 }} />
            ) : (
              <></>
            )}
          </View>
          <View style={styles.infoColumn}>
            <View style={styles.infoContainer}>
              <UserTickSvg color={Color.white} />
              <Text style={styles.infoTextSmall}>{item.age} Y,</Text>
              <Text style={styles.infoTextSmall}>{UserUtil.getUserHeight(item.height)}</Text>
            </View>
            <Text style={styles.infoTextSmall}>{item.maritalStatus}</Text>
          </View>
          <View style={styles.infoColumn}>
            <View style={styles.infoContainer}>
              <MapPinSvg color={Color.white} />
              <Text style={styles.infoTextSmall}>{item.currentLocation}</Text>
            </View>
            <View style={styles.infoContainer}>
              {item.isOnline ? (
                <View style={[styles.dot, styles.greenDot]}></View>
              ) : (
                <View style={[styles.dot, styles.redDot]}></View>
              )}
              <Text style={styles.infoTextSmall}>
                {item.isOnline ? 'Active Now' : 'Not Active'}
              </Text>
            </View>
          </View>
          {isSearchedCard ? (
            <View style={[styles.actionsContainer, { marginRight: 30 }]}>
              <InterestAction profile={item} />
              <AddToFavAction />
            </View>
          ) : (
            <View style={styles.actionsContainer}>
              <MessageAction messageCount={item.chat?.unreadMessages} />
              <InterestAction profile={item} />
            </View>
          )}
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default LargeProfileCard;

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    minHeight: CARD_HEIGHT,
    maxHeight: CARD_HEIGHT,
    width: CARD_WIDTH,
    alignSelf: 'center',
    borderRadius: 17,
    marginRight: 15,
  },
  image: {
    width: '100%',
    height: CARD_HEIGHT,
    resizeMode: 'cover',
  },
  info: {
    width: '100%',
    position: 'absolute',
    bottom: 15,
    left: 15,
  },
  infoText: {
    color: '#fff',
    fontSize: 12,
    fonWeight: '600',
    fontFamily: 'dmsans-bold',
  },
  infoTextSmall: {
    fontSize: 11,
    color: Color.white,
    marginLeft: 10,
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoColumn: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
    justifyContent: 'space-between',
  },
  greenDot: {
    backgroundColor: Color.green,
  },
  dot: {
    width: 5,
    height: 5,
    borderRadius: 3,
  },
  redDot: {
    backgroundColor: Color.red,
  },
  actionsContainer: {
    flexDirection: 'row',
    marginTop: 15,
  },
});
