export enum ChatMessageType {
  Text = 'text',
  Image = 'image',
  Video = 'video',
  InterestSent = 'Interest Sent',
  InterestDeclined = 'Interest Declined',
  ReminderSent = 'Reminder Sent',
  InterestAccepted = 'Interest Accepted',
  InterestArchived = 'Interest Archived',
  Blocked = 'Blocked',
  UnBlocked = 'UnBlocked',
  InterestDeleted = 'Interest Deleted',
}

export interface ChatMessage {
  uuid: string;
  timestamp: string;
  messageType: ChatMessageType;
  content: string;
  contentUrl?: string;
  repliedToMessageUuid?: string;
  receiverProfileUuid: string;
  senderProfileUuid: string;
  senderUserUuid: string;
  interestSenderProfileUuid?: string;
  interestReceiverProfileUuid?: string;
}

export interface ChatBody {
  groupUuid: string;
  messageType: ChatMessageType.Text | ChatMessageType.Image | ChatMessageType.Video;
  content: string;
  contentUrl?: string;
  repliedToMessageUuid?: string;
  receiverProfileUuid: string;
}
export enum ChatCategory {
  Accepted = 'Accepted',
  Declined = 'Declined',
  Pending = 'Pending',
  Blocked = 'Blocked',
  Filtered = 'Filtered',
  Archived = 'Archived',
  Deleted = 'Deleted',
}
