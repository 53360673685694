import React, { useState } from 'react';
import { View, Text } from 'src/components/Themed';
import Checkbox from 'expo-checkbox';
import { StyleSheet } from 'react-native';
import EFonts from 'src/constant/EFonts';

const CheckboxAdornment = ({ label, updatePrivateSettings, privacyLevel, setPrivacyLevel, prefPrivacy }) => {
  const [isChecked, setIsChecked] = useState(privacyLevel === 3);

  const handleCheckboxChange = async () => {
    const newPrivacyLevel = isChecked ? 1 : 3;
    setIsChecked(!isChecked);

    const op = Object.keys(prefPrivacy)[0];
    const newPrefPrivacy = { [op]: newPrivacyLevel };
    await updatePrivateSettings(newPrefPrivacy);

    setPrivacyLevel(newPrivacyLevel);
  };

  return (
    <View style={styles.container}>
      <Checkbox color={'#FFA242'} value={isChecked} onValueChange={handleCheckboxChange} style={{ width: 15, height: 15 }} c />
      <Text style={styles.text}>{label}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flexDirection: 'row', alignItems: 'center', marginLeft: 10, marginTop: 14, marginBottom: 9 },
  text: { color: '#FFA242', fontSize: 12, marginLeft: 12.5, fontFamily: EFonts.DMSANS_MEDIUM },
});

export default CheckboxAdornment;
