import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import NotificationBellSvg from 'src/assets/icons/notificationBell.svg';
import { useNavigation } from '@react-navigation/native';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';

const HeaderRight = ({ isNotification = true }: { isNotification: boolean }) => {
  const navigation = useNavigation();

  return (
    <View style={styles.headerRight}>
      <TouchableOpacity
        onPress={() => navigation.navigate('activity')}
        style={styles.touchableStyle}
      >
        <View>
          <NotificationBellSvg />
        </View>
        {isNotification ? <View style={styles.notification} /> : <></>}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  headerRight: {
    flex: 1,
    marginRight: 24,
  },
  touchableStyle: {
    flex: 1,
    flexDirection: 'row',
    width: 15,
    alignItems: 'center',
  },
  notification: {
    position: 'absolute',
    width: 12,
    height: 12,
    borderRadius: 50,
    backgroundColor: Color.purple,
    right: -6,
    top: GenUtil.isWEB() ? 17 : 9,
    borderColor: Color.white,
    borderWidth: 1,
  },
});
export default HeaderRight;
