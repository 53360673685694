import axios, { AxiosResponse } from 'axios';
import Endpoints from 'src/service/Endpoints';
import { BaseResp, HttpResponse } from 'src/common/response/base.resp';
import {
  FetchInterestParams,
  InterestActionBody,
  InterestReminderBody,
  SendInterestBody,
} from 'src/common/dto/interest.dto';
import { GenUtil } from 'src/util/GenUtil';
import { InterestStats } from 'src/common/models/interest.model';

// TODO: Remove this Interface once the 'common' folder is updated
export interface UserStatsResponse extends Array<InterestStats>, BaseResp {}

export class InterestService {
  static async sendInterest(sendInterestBody: SendInterestBody): Promise<HttpResponse<BaseResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.SEND_INTEREST}`,
      { ...sendInterestBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  // TODO: add PaginationParams & FetchInterestParams
  static async fetchInterests(
    fetchInterestParams: FetchInterestParams,
  ): Promise<HttpResponse<BaseResp>> {
    const interestQueryParams = GenUtil.convertObjectToQueryString(fetchInterestParams);
    const response = await axios.get<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.FETCH_INTERESTS}?${interestQueryParams}`,
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async actionOnInterest(
    interestActionBody: InterestActionBody,
  ): Promise<HttpResponse<BaseResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.ACTION_ON_INTEREST}`,
      { ...interestActionBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async deleteInterest(uuid: string): Promise<HttpResponse<BaseResp>> {
    const response = await axios.delete<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.DELETE_INTEREST}/${uuid}`,
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async sendInterestReminder(
    interestReminderBody: InterestReminderBody,
  ): Promise<HttpResponse<BaseResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.SEND_INTEREST_REMINDER}`,
      { ...interestReminderBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async getStats(): Promise<HttpResponse<UserStatsResponse>> {
    const response = await axios.get<any, AxiosResponse<HttpResponse<UserStatsResponse>>>(
      `${Endpoints.USER_STATS}`,
    );
    return HttpResponse.fromRes<UserStatsResponse>(response.data);
  }
}
