export class BaseResp {
  uuid?: string;
}

export class HttpResponse<T extends BaseResp> {
  httpCode: number;
  message: string;
  data: Partial<T>;
  success: boolean;
  errorCode?: number;
  signature?: string;

  private constructor(success: boolean, data: Partial<T>, message?: string, httpCode?: number, errorCode = 0, signature?: string) {
    this.success = success;
    this.data = data;
    this.message = message;
    this.httpCode = httpCode;
    this.errorCode = errorCode;
    this.signature = signature;
  }

  static fromRes<T extends BaseResp>(res: HttpResponse<T>) {
    return new HttpResponse<T>(res.success, res.data, res.message, res.httpCode, res.errorCode, res.signature);
  }
}
