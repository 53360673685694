import { View, SafeAreaView } from 'react-native';
import React, { useState } from 'react';
import ProfileListAndChatHeader from 'src/components/common/ProfileListAndChatHeader';
import CustomCollapsible from 'src/components/editProfileComponents/CustomCollapsible';
import { useSelector } from 'react-redux';
import { Color } from 'src/constant/Color';
import { ProfileResp } from 'src/common/response/profile.resp';
import { RootState } from 'src/store/store';
import { ExapndAll } from './EditProfile';
import { useNavigation } from '@react-navigation/native';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';

const FilterOptions = [
  'Age Range',
  'Income Range',
  'Height Range',
  'Marital Status',
  'Religion',
  'Countries',
  'State',
  'City',
  'Qualification',
  'Profession',
  'Gender',
  'Mother Tounge',
  'Diets',
  'Mangliks',
  'Smokings',
  'Drinks',
  'Profile Handlers',
  'Health Statuses',
];

interface Params {
  currentSearchParams: Partial<ProfileSearchBody>;
  setCurrentSearchParams: (filterParams) => void;
}

const SearchFiltersScreen = ({ route }: { route: { params: Params } }) => {
  const { currentSearchParams, setCurrentSearchParams } = route.params;
  const profileData: Partial<ProfileResp> = useSelector(
    (state: RootState) => state.user.userProfile,
  );
  const [partnerPreferenceActiveSections, setPartnerPreferenceActiveSections] = useState([]);
  const [expandAll, setExpandAll] = useState(false);
  const [filterParams, setFilterParams] = useState<Partial<ProfileSearchBody>>(currentSearchParams);
  const navigation = useNavigation();
  const handleApplyPressed = () => {
    setCurrentSearchParams(filterParams);
    navigation.goBack();
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Color.white }}>
      <ProfileListAndChatHeader heading={'Filters'} />
      <View style={{ flex: 1, marginTop: 23 }}>
        <ExapndAll expandAll={expandAll} setExpandAll={setExpandAll} />
        <CustomCollapsible
          initialData={profileData}
          sections={FilterOptions}
          activeSections={partnerPreferenceActiveSections}
          onChange={setPartnerPreferenceActiveSections}
          strictlyFollow={false}
          showPills={false}
          searchParams={filterParams}
          setSearchParams={setFilterParams}
          showSearchButton={true}
          handleSearchPressed={handleApplyPressed}
          buttonText={'Apply'}
          expandAll={expandAll}
        />
      </View>
    </SafeAreaView>
  );
};

export default SearchFiltersScreen;
