import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import { AuthService } from 'src/service/AuthService';
import StorageUtil from 'src/util/StorageUtil';

const firebaseConfig = {
  apiKey: 'AIzaSyDr1CZaffNIyrDuFuuQk-p1IKcGoz0BQeI',
  authDomain: 'matri-testing.firebaseapp.com',
  projectId: 'matri-testing',
  storageBucket: 'matri-testing.appspot.com',
  messagingSenderId: '119255019687',
  appId: '1:119255019687:web:3eefcde6c3aaf4c2866b82',
  measurementId: 'G-D8S6GNV2MS',
};

const app = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const firestore = firebase.firestore();
const storage = firebase.storage();
const timeStamp = firebase.firestore.FieldValue.serverTimestamp();

const signInToFirebase = async (customToken) => {
  let token = customToken;
  const maxRetries = 3;
  let retryCount = 0;
  while (retryCount < maxRetries) {
    try {
      const response = await auth.signInWithCustomToken(token);
      return response.user;
    } catch (error) {
      if (retryCount === maxRetries - 1) {
        throw new Error(
          '[Firebase SignIn with Custom token] Request failed after multiple attempts.',
        );
      }
      retryCount++;
      try {
        const response = await AuthService.getFirebaseToken();
        if (response.success) {
          token = response.data.token;
          StorageUtil.setFirebaseAuthTokenInLocalStorage(token);
        }
      } catch (error) {
        console.log('Failed to fetch new token: ' + error.message);
        throw new Error(
          '[Firebase SignIn with Custom token] Request failed after multiple attempts.',
        );
      }
    }
  }
};

const firebaseServerTimestamp = () => {
  const timeStamp = firebase.firestore.FieldValue.serverTimestamp();
  return timeStamp;
};

const isFbUserLoggedIn = () => {
  return !!auth.currentUser;
};

const signOutFromFirebase = () => {
  auth
    .signOut()
    .then(() => {
      console.log('log out from fb');
    })
    .catch((error) => {
      console.log('error in logging out', error);
    });
};

export {
  firestore,
  timeStamp,
  storage,
  signInToFirebase,
  isFbUserLoggedIn,
  signOutFromFirebase,
  firebaseServerTimestamp,
};
