import React, { useEffect, useState } from 'react';
import { View, Text } from 'src/components/Themed';
import { TextInput, Linking, StyleSheet, Keyboard } from 'react-native';
import { AirbnbRating } from 'react-native-ratings';
import Modal from 'react-native-modal';
import { GenUtil } from 'src/util/GenUtil';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';
import TertiaryButton from 'src/components/common/buttons/TertiaryButton';
import { useIsFocused } from '@react-navigation/native';

const { height, width } = GenUtil.getDimension();

const CustomBackdrop = ({ width, height }: { width: string | number; height: string | number }) => (
  <View
    style={{ width: width, height: height, margin: 'auto', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
  />
);

const RateUsScreen = ({ navigation }) => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const isFocused = useIsFocused();
  const showFeedbackInput = GenUtil.isWEB() || rating <= 3;

  //todo : modal (open/close) functionality needed to be integrated with redux once its added
  const [rateusModal, setRateusModal] = useState(true);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const screenName = GenUtil.isDesktopWeb() ? 'home' : 'data';

  useEffect(() => {
    if (isFocused) setRateusModal(true);
  }, [isFocused]);

  const handleRating = (value: number) => {
    setRating(value);
  };

  const handleFeedback = (text: string) => {
    setFeedback(text);
  };

  const handleKeyPress = async () => {
    // todo : API call needed here to be made once its added
    try {
      setLoading(true);
      // handleRateusCloseModal();
      setRateusModal(false);

      if (rating >= 4) {
        if (GenUtil.isIOS()) {
          await Linking.openURL('itms-apps://itunes.apple.com/app/APP_ID');
        } else if (GenUtil.isAndroid()) {
          await Linking.openURL('market://details?id=PACKAGE_NAME');
        }
        navigation.navigate(screenName);
      } else {
        console.log('inside else');
        setMessageModalVisible(true);
      }
      setLoading(false);
    } catch (error) {
      navigation.navigate(screenName);
    }
  };

  const handleMessageCloseModal = () => {
    setMessageModalVisible(false);
    navigation.navigate(screenName);
  };

  const handleRateusCloseModal = () => {
    setRateusModal(false);
    navigation.navigate(screenName);
  };

  const handleMoveToHomePage = () => {
    navigation.navigate(screenName);
    handleMessageCloseModal();
    handleRateusCloseModal();
  };

  return (
    <View style={styles.container}>
      <Modal
        customBackdrop={<CustomBackdrop width={width} height={height} />}
        isVisible={rateusModal}
        onBackdropPress={handleRateusCloseModal}
        animationIn="fadeInUp"
        animationOut="fadeOutUp"
        animationInTiming={300}
        animationOutTiming={300}
        backdropTransitionInTiming={300}
        backdropTransitionOutTiming={300}
        key={rateusModal.toString()}
      >
        <View style={styles.modal}>
          <Text style={styles.headingText}>Rate Us</Text>
          <AirbnbRating
            count={5}
            defaultRating={rating}
            size={40}
            showRating={false}
            selectedColor={'orange'}
            onFinishRating={handleRating}
            style={{ paddingVertical: 10 }}
            starContainerStyle={{ marginHorizontal: 30 }}
            starStyle={{ margin: 5 }}
            ratingContainerStyle={{ marginVertical: 20 }}
          />
          {showFeedbackInput && (
            <TextInput
              placeholder="Enter your feedback here"
              placeholderTextColor={Color.black}
              onChangeText={handleFeedback}
              value={feedback}
              multiline
              onSubmitEditing={() => Keyboard.dismiss()}
              style={styles.feedBackText}
            />
          )}
          <View style={styles.buttonContainer}>
            <PrimaryButton
              buttonDisabled={
                rating === 0 || (rating > 0 && showFeedbackInput && feedback.trim().length === 0)
              }
              isValidating={false}
              buttonText={'Submit'}
              handleKeyPress={handleKeyPress}
            />
            <View style={{ marginTop: 20 }}>
              <TertiaryButton
                buttonDisabled={false}
                isValidating={false}
                buttonText={'Close'}
                handleKeyPress={handleRateusCloseModal}
              />
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        key={messageModalVisible.toString()}
        customBackdrop={<CustomBackdrop width={width} height={height} />}
        isVisible={messageModalVisible}
        onBackdropPress={handleMessageCloseModal}
        animationIn="fadeInUp"
        animationOut="fadeOutUp"
        animationInTiming={300}
        animationOutTiming={300}
        backdropTransitionInTiming={300}
        backdropTransitionOutTiming={300}
      >
        <View style={styles.modal}>
          <Text style={styles.modalText}>We will try to improve! Thanks for your feedback.</Text>

          <PrimaryButton
            buttonDisabled={false}
            isValidating={false}
            buttonText={'Home Page'}
            handleKeyPress={handleMoveToHomePage}
          />
          <View style={{ marginTop: 20 }}>
            <TertiaryButton
              buttonDisabled={false}
              isValidating={false}
              buttonText={'Close'}
              handleKeyPress={handleMessageCloseModal}
            />
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  modal: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    width: width * 0.9,
    margin: 'auto',
    borderWidth: 1,
    borderColor: Color.purple,
  },
  modalText: {
    fontFamily: EFonts.DMSANS_REGULAR,
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20,
  },
  modalButton: {
    padding: 13,
    borderRadius: 80,
    marginTop: 10,
    minWidth: 150,
  },
  modalCloseButton: {
    backgroundColor: Color.lightGrey,
  },
  headingText: {
    fontSize: 32,
    fontFamily: EFonts.DMSANS_MEDIUM,
  },
  buttonContainer: {
    marginTop: 40,
  },
  modal: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    width: width * 0.9,
    margin: 'auto',
    borderWidth: 2,
    borderColor: 'orange',
  },
  feedBackText: {
    height: 100,
    width: '99%',
    borderColor: Color.lightGrey,
    borderBottomColor: Color.purple,
    borderBottomWidth: 2,
    borderWidth: 1,
    marginTop: 20,
    padding: 10,
    fontFamily: EFonts.DMSANS_REGULAR,
    borderRadius: 6,
    color: Color.purple,
    outlineStyle: 'none',
  },
  modalText: {
    fontFamily: EFonts.DMSANS_REGULAR,
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 30,
  },
  modalButton: {
    padding: 13,
    borderRadius: 80,
    marginTop: 10,
    minWidth: 150,
  },
  modalCloseButton: {
    backgroundColor: Color.lightGrey,
  },
  modalHomeButton: {
    backgroundColor: Color.purple,
  },
  modalButtonText: {
    fontFamily: EFonts.DMSANS_REGULAR,
    color: Color.white,
    textAlign: 'center',
  },
});

export default RateUsScreen;
