/* eslint-disable @typescript-eslint/no-floating-promises */
import { View } from 'react-native';
import React, { useState, useEffect } from 'react';
import RangeSlider from 'src/components/common/Slider';
import CheckboxAdornment from '../atoms/CheckboxAdornment';
import { Gender, PreferenceLevel } from 'src/common/models/profile.model';
import { debounce } from 'lodash';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';

const HeightRangeComp = ({
  userGender,
  userHeight,
  partnerMinHeight,
  partnerMaxHeight,
  privacyPrefLevel,
  updatePartnerPref,
  updatePrivateSettings,
  strictlyFollow,
  setSearchParams,
}: {
  userGender: string;
  userHeight: number;
  partnerMinHeight: number;
  partnerMaxHeight: number;
  privacyPrefLevel: PreferenceLevel;
  updatePartnerPref: (_) => Promise<void>;
  updatePrivateSettings: (_) => Promise<void>;
  strictlyFollow?: boolean;
  setSearchParams?: (prevParams) => void;
}) => {
  const sliderRange = {
    min: userGender ? userHeight - (userGender === Gender.Male ? 17 : 3) : 122,
    max: userGender ? userHeight + (userGender === Gender.Male ? 3 : 17) : 214,
  };

  const [heightRange, setHeightRange] = useState<number[]>([
    partnerMinHeight ?? sliderRange.min,
    partnerMaxHeight ?? sliderRange.max,
  ]);
  const [privacyLevel, setPrivacyLevel] = useState(privacyPrefLevel ?? 1);

  const applyDebounce = debounce(async (heightPref: Partial<ProfileSearchBody>) => {
    if (setSearchParams) {
      setSearchParams((prevParams: Partial<ProfileSearchBody>) => ({
        ...prevParams,
        ...heightPref,
      }));
      return;
    }
    await updatePartnerPref(heightPref);
  }, 1000);

  useEffect(() => {
    const heightPref = {
      minHeight: heightRange[0],
      maxHeight: heightRange[1],
    };

    if (!(heightRange[0] === partnerMinHeight && heightRange[1] === partnerMaxHeight))
      applyDebounce(heightPref);
    return () => {
      applyDebounce.cancel();
    };
  }, [heightRange]);

  return (
    <View>
      <RangeSlider
        defaultMinVal={partnerMinHeight ?? sliderRange.min}
        defaultMaxVal={partnerMaxHeight ?? sliderRange.max}
        min={122}
        max={214}
        onValueChange={setHeightRange}
        minValueText="4'0''"
        maxValueText="7'0''"
        isHeightComponent={true}
      />
      <View>
        {strictlyFollow && (
          <CheckboxAdornment
            label="Strictly Follow"
            updatePrivateSettings={updatePrivateSettings}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
            prefPrivacy={{ heightRangePref: privacyLevel }}
          />
        )}
      </View>
    </View>
  );
};

export default HeightRangeComp;
