import LottieView from 'lottie-react-native';
import React from 'react';
import { Text, View } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { Color } from 'src/constant/Color';
export const FullLoader = ({ message }: { message?: string | undefined }) => {
  return (
    <View
      style={[
        styles.container,
        message
          ? { backgroundColor: Color.halfBlack, elevation: 3 }
          : { backgroundColor: Color.transparent, elevation: 0 },
      ]}
    >
      <LottieView source={require('../../assets/loading-pink-dot.json')} autoPlay loop />
      <Text style={styles.text}>{message}</Text>
    </View>
  );
};

const styles = EStyleSheet.create({
  container: {
    alignSelf: 'center',
    minWidth: '150rem',
    borderRadius: 15,
    flexDirection: 'row',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: 10,
  },
  text: { color: Color.white, marginTop: 40, fontSize: 16, alignSelf: 'center' },
});
