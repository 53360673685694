import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import MultiSlider, { LabelProps } from '@ptomasroos/react-native-multi-slider';

import { GenUtil } from 'src/util/GenUtil';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';

interface UseRefPRops {
  measure: (
    callback: (
      x: number,
      y: number,
      width: number,
      height: number,
      pageX: number,
      pageY: number,
    ) => void,
  ) => void;
}

interface RangeSliderProps {
  defaultMinVal: number;
  defaultMaxVal: number;
  max: number;
  min: number;
  sliderLength?: number;
  onValueChange: (values: number[]) => void;
  minValueText?: string;
  maxValueText?: string;
  step?: number;
  isHeightComponent?: boolean;
}

const CustomLabel = ({
  current,
  sliderLength,
  isHeightComponent,
}: {
  current: LabelProps;
  sliderLength: number;
  isHeightComponent: boolean;
}) => {
  const leftLabelPosition = current.oneMarkerLeftPosition;
  let leftLabelValue = current.oneMarkerValue;
  const rightLabelPosition = current.twoMarkerLeftPosition;
  let rightLableValue = current.twoMarkerValue;

  if (
    isHeightComponent &&
    typeof leftLabelValue === 'number' &&
    typeof rightLableValue === 'number'
  ) {
    leftLabelValue = GenUtil.convertHeighttoHeightString(leftLabelValue);
    rightLableValue = GenUtil.convertHeighttoHeightString(rightLableValue);
  }

  return (
    <View style={styles.labelContainer}>
      <View
        style={{
          left: leftLabelPosition - 10,
        }}
      >
        <Text style={styles.customLabelText}>{leftLabelValue}</Text>
      </View>

      <View
        style={{
          right: sliderLength - rightLabelPosition - 10,
        }}
      >
        <Text style={styles.customLabelText}>{rightLableValue}</Text>
      </View>
    </View>
  );
};

const RangeSlider = ({
  defaultMinVal = 10,
  defaultMaxVal = 30,
  max = 40,
  min = 1,
  sliderLength = GenUtil.getDimension().width - 60,
  onValueChange = () => {},
  minValueText = '',
  maxValueText = '',
  step = 1,
  isHeightComponent = false,
}: RangeSliderProps) => {
  return (
    <View style={{ alignSelf: 'center' }}>
      <View>
        <MultiSlider
          values={[defaultMinVal, defaultMaxVal]}
          min={min}
          max={max}
          sliderLength={sliderLength}
          onValuesChange={(values) => {
            onValueChange(values);
          }}
          trackStyle={{ backgroundColor: 'rgba(128, 65, 198, 0.2)', height: 6 }}
          selectedStyle={{ backgroundColor: Color.purple, height: 6 }}
          markerStyle={{
            borderWidth: 6,
            borderColor: Color.purple,
            height: 30,
            width: 30,
            borderRadius: 15,
            top: 2,
            shadowColor: 'transparent',
            backgroundColor: Color.white,
          }}
          enableLabel
          step={step}
          customLabel={(e) => (
            <CustomLabel
              current={e}
              sliderLength={sliderLength}
              isHeightComponent={isHeightComponent}
            />
          )}
        />
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: sliderLength,
          marginTop: 0,
        }}
      >
        <Text style={styles.textStyle}>{minValueText && minValueText}</Text>
        <Text style={styles.textStyle}>{maxValueText && maxValueText}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  customLabelText: {
    fontFamily: EFonts.SORA_BOLD,
    fontSize: 12,
    lineHeight: 15,
    color: Color.lightBlack,
  },
  textStyle: {
    fontFamily: EFonts.SORA_MEDIUM,
    fontSize: 12,
    lineHeight: 15,
    color: Color.lightBlack,
  },
  labelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default RangeSlider;
