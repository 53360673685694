import Modal from 'react-native-modal';
import { StyleSheet, Text, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native';
import { Color } from 'src/constant/Color';
import { Icon, Input } from 'react-native-elements';
import Button from 'src/components/common/buttons/Button';
import React, { useEffect, useState } from 'react';
import { ProfileResp } from 'src/common/response/profile.resp';
import { InterestService } from 'src/service/InterestService';
import CrownSvg from 'src/assets/icons/crown.svg';
import SendSvg from 'src/assets/icons/send.svg';
import StorageUtil from 'src/util/StorageUtil';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { MembershipPermissions, isFeatureEnabled } from 'src/common/data/membership.data';
import ConfirmationModal from './ConfirmationModal';
import { useNavigation } from '@react-navigation/native';
import { GenUtil } from 'src/util/GenUtil';
import { Gender } from 'src/common/models/profile.model';

const PAST_MESSAGES = 'PAST_MESSAGES';
const DEFAULT_MESSAGE = 'Hi, I liked your profile. Please accept if you wish to proceed.';
const GENDER_PRONOUNS = {
  [Gender.Male]: 'His',
  [Gender.Female]: 'Her',
};

type Props = {
  profile: Partial<ProfileResp>;
  onFinish: () => void;
  closeModal: () => void;
};

export default function SendInterest({ profile, onFinish, closeModal }: Props) {
  const [customMessage, setCustomMessage] = useState<string>(DEFAULT_MESSAGE);
  const [pastMessages, setPastMessages] = useState<string[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const loggedInUserProfile = useSelector((state: RootState) => state?.user?.userProfile);
  const navigation = useNavigation();
  const canSendCustomMessage = isFeatureEnabled(
    loggedInUserProfile,
    MembershipPermissions.CanSendCustomInterestMessage,
  );

  useEffect(() => {
    const mssgs = StorageUtil.getObject(PAST_MESSAGES);
    setPastMessages(Object.values(mssgs));
  }, []);

  const saveMessage = () => {
    if (customMessage.length > 0 && pastMessages?.[0] !== customMessage) {
      const mssgs = [customMessage];
      if (pastMessages.length > 0) mssgs.push(pastMessages.shift());
      StorageUtil.setObject(PAST_MESSAGES, mssgs);
      setPastMessages(mssgs);
    }
  };

  const handleSendInterest = async () => {
    try {
      const response = await InterestService.sendInterest({
        customMessage: customMessage,
        profileUuid: profile.uuid,
      });
      if (canSendCustomMessage) saveMessage();
      if (response.success) {
        onFinish();
      } else {
        console.log(response.message, response);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleInputClick = () => {
    if (canSendCustomMessage) return;
    setShowConfirmationModal(true);
  };

  const navigateToMembership = () => {
    const membershipScreen = GenUtil.isDesktopWeb() ? 'membership' : 'Membership';
    setShowConfirmationModal(false);
    closeModal();
    navigation.navigate(membershipScreen);
  };

  return (
    <Modal
      isVisible={true}
      swipeThreshold={50}
      animationInTiming={250}
      onModalHide={closeModal}
      onBackdropPress={closeModal}
      avoidKeyboard={true}
    >
      {showConfirmationModal ? (
        <ConfirmationModal
          title="Upgrade to Premium"
          message="With Premium membership you can send custom message while sending interest!"
          cancelButtonText="Cancel"
          primaryButtonText="Buy Premium"
          onCancel={() => setShowConfirmationModal(false)}
          onPrimaryButtonPressed={navigateToMembership}
        />
      ) : (
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.heading}>Send Interest</Text>
            <TouchableOpacity onPress={closeModal}>
              <Icon name={'x'} type={'feather'} size={19} color={Color.lightGrey} />
            </TouchableOpacity>
          </View>
          <View style={styles.textContainer}>
            {canSendCustomMessage && (
              <Text style={styles.text}>Send an interest with custom message</Text>
            )}
            <TouchableWithoutFeedback onPress={handleInputClick}>
              <Input
                leftIcon={
                  !canSendCustomMessage ? (
                    <Icon name="lock" type="font-awesome" size={14} color={Color.gray} />
                  ) : (
                    <></>
                  )
                }
                multiline={true}
                numberOfLines={3}
                inputContainerStyle={styles.inputContainerStyles}
                inputStyle={{
                  height: 80,
                  fontSize: 12,
                  outlineStyle: 'none',
                }}
                value={customMessage}
                onChangeText={setCustomMessage}
                placeholder={'Type your message here'}
                disabled={!canSendCustomMessage}
              />
            </TouchableWithoutFeedback>
          </View>

          {canSendCustomMessage && pastMessages.length > 0 && (
            <View style={styles.pastMsgContainer}>
              <Text style={styles.subHeading}>Past Messages</Text>
              {pastMessages.map((message) => (
                <PastMessage
                  key={message}
                  message={message}
                  onPress={() => setCustomMessage(message)}
                />
              ))}
            </View>
          )}

          <View style={{ flexDirection: 'row', marginTop: 20, alignItems: 'center' }}>
            <View style={{ width: '100%' }}>
              <Button
                onPress={handleSendInterest}
                size={'small'}
                styles={{ paddingHorizontal: 5 }}
                beforeIcon={<SendSvg style={{ marginRight: 8 }} />}
              >
                Send Interest
              </Button>
            </View>
            {/* TODO: add this back later in V2 */}
            {/* <View style={{ width: '55%', marginLeft: 5 }}>
              <Button
                onPress={() => {}}
                variant={'primary'}
                styles={{ backgroundColor: Color.black }}
                textStyles={{ color: '#FFE600' }}
                beforeIcon={<CrownSvg style={{ marginRight: 8 }} width={16} height={16} />}
              >
                Send Premium Interest
              </Button>
            </View> */}
          </View>
          {profile.isFilteredProfile && (
            <View style={{ marginTop: 5 }}>
              <Text style={styles.subHeading}>
                NOTE: {GENDER_PRONOUNS[profile.gender]} partner preference doesn't match your
                profile. This request will land in{' '}
                {GENDER_PRONOUNS[profile.gender].toLocaleLowerCase()} filtered inbox.
              </Text>
            </View>
          )}
        </View>
      )}
    </Modal>
  );
}

const PastMessage = ({ message, onPress }: { message: string; onPress: () => void }) => {
  return (
    <TouchableOpacity style={styles.pastMsg} onPress={onPress}>
      <Text style={[styles.text, styles.msgText]}>{message}</Text>
      <Icon name={'arrow-up-right'} type={'feather'} size={16} color={Color.purple} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Color.white,
    borderRadius: 25,
    borderWidth: 1,
    borderColor: '#000',
    borderStyle: 'solid',
    maxHeight: 380,
    marginHorizontal: 'auto',
    minWidth: '90%',
    padding: 20,
    paddingHorizontal: 15,
  },
  inputContainerStyles: {
    borderWidth: 1,
    borderRadius: 12,
    backgroundColor: Color.white,
    paddingVertical: 5,
    paddingHorizontal: 12,
    marginLeft: -10,
    marginTop: 10,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  heading: {
    fontSize: 17,
    fontWeight: '600',
  },
  textContainer: {
    marginTop: 20,
  },
  text: {
    color: Color.lightGrey,
    fontSize: 12,
  },
  pastMsgContainer: {},
  subHeading: {
    fontWeight: '600',
    fontSize: 13,
  },
  pastMsg: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  msgText: {
    fontSize: 13,
    width: '85%',
  },
});
