export enum HobbyCategory {
  HobbyAndInterest = 'Hobby And Interests',
  MusicTypes = 'Music Types',
  Reads = 'Reads',
  DressStyles = 'Dress Styles',
  MovieGenres = 'Movie Genres',
  Cuisines = 'Cuisines',
  SportsAndActivities = 'Sports And Activities',
}

export const HobbiesData: Array<{ id: number; name: string; category: HobbyCategory }> = [
  { id: 1, name: 'Traveling and exploring new places', category: HobbyCategory.HobbyAndInterest },
  { id: 2, name: 'Reading and keeping up with current events', category: HobbyCategory.HobbyAndInterest },
  { id: 3, name: 'Watching movies and TV shows', category: HobbyCategory.HobbyAndInterest },
  { id: 4, name: 'Listening to music', category: HobbyCategory.HobbyAndInterest },
  { id: 5, name: 'Playing sports, such as cricket or badminton', category: HobbyCategory.HobbyAndInterest },
  { id: 6, name: 'Cooking and trying new recipes', category: HobbyCategory.HobbyAndInterest },
  { id: 7, name: 'Gardening and spending time in nature', category: HobbyCategory.HobbyAndInterest },
  { id: 8, name: 'Photography', category: HobbyCategory.HobbyAndInterest },
  { id: 9, name: 'Yoga and meditation', category: HobbyCategory.HobbyAndInterest },
  { id: 10, name: 'Dancing', category: HobbyCategory.HobbyAndInterest },
  { id: 11, name: 'Painting and drawing', category: HobbyCategory.HobbyAndInterest },
  { id: 12, name: 'Playing musical instruments', category: HobbyCategory.HobbyAndInterest },
  { id: 13, name: 'Hiking and trekking', category: HobbyCategory.HobbyAndInterest },
  { id: 14, name: 'Fitness and working out', category: HobbyCategory.HobbyAndInterest },
  { id: 15, name: 'Shopping', category: HobbyCategory.HobbyAndInterest },
  { id: 16, name: 'Volunteering and social work', category: HobbyCategory.HobbyAndInterest },
  { id: 17, name: 'Learning new languages', category: HobbyCategory.HobbyAndInterest },
  { id: 18, name: 'Organizing events and parties', category: HobbyCategory.HobbyAndInterest },
  { id: 19, name: 'Playing board games and video games', category: HobbyCategory.HobbyAndInterest },
  { id: 20, name: 'Keeping pets', category: HobbyCategory.HobbyAndInterest },
  { id: 21, name: 'Collecting stamps, coins, or other items', category: HobbyCategory.HobbyAndInterest },
  { id: 22, name: 'Genealogy and researching family history', category: HobbyCategory.HobbyAndInterest },
  { id: 23, name: 'Fishing and boating', category: HobbyCategory.HobbyAndInterest },
  { id: 24, name: 'Camping and outdoor activities', category: HobbyCategory.HobbyAndInterest },
  { id: 25, name: 'Playing cards and chess', category: HobbyCategory.HobbyAndInterest },
  { id: 26, name: 'Writing and blogging', category: HobbyCategory.HobbyAndInterest },
  { id: 27, name: 'Embroidery and knitting', category: HobbyCategory.HobbyAndInterest },
  { id: 28, name: 'Scrapbooking and crafts', category: HobbyCategory.HobbyAndInterest },
  { id: 29, name: 'Gardening and flower arranging', category: HobbyCategory.HobbyAndInterest },
  { id: 30, name: 'Astronomy and stargazing', category: HobbyCategory.HobbyAndInterest },
  { id: 31, name: 'Magic and illusion', category: HobbyCategory.HobbyAndInterest },
  { id: 32, name: 'Acting and drama', category: HobbyCategory.HobbyAndInterest },
  { id: 33, name: 'Fashion and personal grooming', category: HobbyCategory.HobbyAndInterest },
  { id: 34, name: 'Horseback riding', category: HobbyCategory.HobbyAndInterest },
  { id: 35, name: 'Dancing and Zumba', category: HobbyCategory.HobbyAndInterest },
  { id: 36, name: 'Martial Arts', category: HobbyCategory.HobbyAndInterest },
  { id: 37, name: 'Golfing', category: HobbyCategory.HobbyAndInterest },
  { id: 38, name: 'Fitness and running', category: HobbyCategory.HobbyAndInterest },
  { id: 39, name: 'Skating and roller skating', category: HobbyCategory.HobbyAndInterest },
  { id: 40, name: 'Swimming', category: HobbyCategory.HobbyAndInterest },
  { id: 41, name: 'Scuba diving', category: HobbyCategory.HobbyAndInterest },
  { id: 42, name: 'Fishing and hunting', category: HobbyCategory.HobbyAndInterest },
  { id: 43, name: 'Rock Climbing', category: HobbyCategory.HobbyAndInterest },
  { id: 44, name: 'Surfing and windsurfing', category: HobbyCategory.HobbyAndInterest },
  { id: 45, name: 'Snowboarding and skiing', category: HobbyCategory.HobbyAndInterest },
  { id: 46, name: 'Parasailing and bungee jumping', category: HobbyCategory.HobbyAndInterest },
  { id: 47, name: 'Paintball and laser tag', category: HobbyCategory.HobbyAndInterest },
  { id: 48, name: 'Quilting and needlepoint', category: HobbyCategory.HobbyAndInterest },
  { id: 49, name: 'Beekeeping', category: HobbyCategory.HobbyAndInterest },
  { id: 50, name: 'Genealogy and researching family history', category: HobbyCategory.HobbyAndInterest },
  { id: 51, name: 'Collecting Stamps', category: HobbyCategory.HobbyAndInterest },
  { id: 52, name: 'Collecting Coins', category: HobbyCategory.HobbyAndInterest },
  { id: 53, name: 'Collecting antiques', category: HobbyCategory.HobbyAndInterest },
  { id: 54, name: 'Art / Handicraft', category: HobbyCategory.HobbyAndInterest },
  { id: 55, name: 'Painting', category: HobbyCategory.HobbyAndInterest },
  { id: 56, name: 'Cooking', category: HobbyCategory.HobbyAndInterest },
  { id: 57, name: 'Photography', category: HobbyCategory.HobbyAndInterest },
  { id: 58, name: 'Film-making', category: HobbyCategory.HobbyAndInterest },
  { id: 59, name: 'Model building', category: HobbyCategory.HobbyAndInterest },
  { id: 60, name: 'Gardening / Landscaping', category: HobbyCategory.HobbyAndInterest },
  { id: 61, name: 'Fishing', category: HobbyCategory.HobbyAndInterest },
  { id: 62, name: 'Bird watching', category: HobbyCategory.HobbyAndInterest },
  { id: 63, name: 'Taking care of pets', category: HobbyCategory.HobbyAndInterest },
  { id: 64, name: 'Playing musical instruments', category: HobbyCategory.HobbyAndInterest },
  { id: 65, name: 'Singing', category: HobbyCategory.HobbyAndInterest },
  { id: 66, name: 'Dancing', category: HobbyCategory.HobbyAndInterest },
  { id: 67, name: 'Acting', category: HobbyCategory.HobbyAndInterest },
  { id: 68, name: 'Ham radio', category: HobbyCategory.HobbyAndInterest },
  { id: 69, name: 'Astrology / Palmistry / Numerology', category: HobbyCategory.HobbyAndInterest },
  { id: 70, name: 'Graphology', category: HobbyCategory.HobbyAndInterest },
  { id: 71, name: 'Solving Crosswords', category: HobbyCategory.HobbyAndInterest },
  { id: 72, name: 'Writing', category: HobbyCategory.HobbyAndInterest },
  { id: 73, name: 'Reading / Book clubs', category: HobbyCategory.HobbyAndInterest },
  { id: 74, name: 'Learning new languages', category: HobbyCategory.HobbyAndInterest },
  { id: 75, name: 'Listening to music', category: HobbyCategory.HobbyAndInterest },
  { id: 76, name: 'Movies', category: HobbyCategory.HobbyAndInterest },
  { id: 77, name: 'Theatre', category: HobbyCategory.HobbyAndInterest },
  { id: 78, name: 'Watching television', category: HobbyCategory.HobbyAndInterest },
  { id: 79, name: 'Travel / Sightseeing', category: HobbyCategory.HobbyAndInterest },
  { id: 80, name: 'Sports - Outdoor', category: HobbyCategory.HobbyAndInterest },
  { id: 81, name: 'Sports - Indoor', category: HobbyCategory.HobbyAndInterest },
  { id: 82, name: 'Trekking / Adventure sports', category: HobbyCategory.HobbyAndInterest },
  { id: 83, name: 'Video / Computer games', category: HobbyCategory.HobbyAndInterest },
  { id: 84, name: 'Health & Fitness', category: HobbyCategory.HobbyAndInterest },
  { id: 85, name: 'Yoga / Meditation', category: HobbyCategory.HobbyAndInterest },
  { id: 86, name: 'Alternative healing', category: HobbyCategory.HobbyAndInterest },
  { id: 87, name: 'Volunteering / Social Service', category: HobbyCategory.HobbyAndInterest },
  { id: 88, name: 'Politics', category: HobbyCategory.HobbyAndInterest },
  { id: 89, name: 'Net surfing', category: HobbyCategory.HobbyAndInterest },
  { id: 90, name: 'Blogging', category: HobbyCategory.HobbyAndInterest },
  { id: 91, name: 'Classical - Hindustani', category: HobbyCategory.MusicTypes },
  { id: 92, name: 'Classical - Carnatic', category: HobbyCategory.MusicTypes },
  { id: 93, name: 'Classical - Western', category: HobbyCategory.MusicTypes },
  { id: 94, name: 'Instrumental - Indian', category: HobbyCategory.MusicTypes },
  { id: 95, name: 'Instrumental - Western', category: HobbyCategory.MusicTypes },
  { id: 96, name: 'Old film songs', category: HobbyCategory.MusicTypes },
  { id: 97, name: 'Latest film songs', category: HobbyCategory.MusicTypes },
  { id: 98, name: 'Ghazals', category: HobbyCategory.MusicTypes },
  { id: 99, name: 'Qawalis', category: HobbyCategory.MusicTypes },
  { id: 100, name: 'Bhajans / Devotional', category: HobbyCategory.MusicTypes },
  { id: 101, name: 'Sufi music', category: HobbyCategory.MusicTypes },
  { id: 102, name: 'Indipop', category: HobbyCategory.MusicTypes },
  { id: 103, name: 'Pop', category: HobbyCategory.MusicTypes },
  { id: 104, name: 'Disco', category: HobbyCategory.MusicTypes },
  { id: 105, name: 'House Music', category: HobbyCategory.MusicTypes },
  { id: 106, name: 'Techno', category: HobbyCategory.MusicTypes },
  { id: 107, name: 'Hip-Hop', category: HobbyCategory.MusicTypes },
  { id: 108, name: 'Rap', category: HobbyCategory.MusicTypes },
  { id: 109, name: 'Jazz', category: HobbyCategory.MusicTypes },
  { id: 110, name: 'Blues', category: HobbyCategory.MusicTypes },
  { id: 111, name: 'Reggae', category: HobbyCategory.MusicTypes },
  { id: 112, name: 'Heavy Metal', category: HobbyCategory.MusicTypes },
  { id: 113, name: 'Acid Rock', category: HobbyCategory.MusicTypes },
  { id: 114, name: 'Classic literature', category: HobbyCategory.Reads },
  { id: 115, name: 'Biographies', category: HobbyCategory.Reads },
  { id: 116, name: 'History', category: HobbyCategory.Reads },
  { id: 117, name: 'Poetry', category: HobbyCategory.Reads },
  { id: 118, name: 'Romance', category: HobbyCategory.Reads },
  { id: 119, name: 'Thriller / Suspense', category: HobbyCategory.Reads },
  { id: 120, name: 'Humour', category: HobbyCategory.Reads },
  { id: 121, name: 'Science Fiction', category: HobbyCategory.Reads },
  { id: 122, name: 'Fantasy', category: HobbyCategory.Reads },
  { id: 123, name: 'Business / Occupational', category: HobbyCategory.Reads },
  { id: 124, name: 'Philosophy / Spiritual', category: HobbyCategory.Reads },
  { id: 125, name: 'Self-help', category: HobbyCategory.Reads },
  { id: 126, name: 'Short stories', category: HobbyCategory.Reads },
  { id: 127, name: 'Comics', category: HobbyCategory.Reads },
  { id: 128, name: 'Magazines & Newspapers', category: HobbyCategory.Reads },
  { id: 129, name: 'Classic Indian - typically Indian formal wear', category: HobbyCategory.DressStyles },
  { id: 130, name: 'Trendy - in line with the latest fashion', category: HobbyCategory.DressStyles },
  { id: 131, name: 'Classic Western - typically western formal wear', category: HobbyCategory.DressStyles },
  { id: 132, name: 'Designer - only leading brands will do', category: HobbyCategory.DressStyles },
  { id: 133, name: 'Casual - usually in jeans and T-shirts', category: HobbyCategory.DressStyles },
  { id: 134, name: 'Classics', category: HobbyCategory.MovieGenres },
  { id: 135, name: 'Romantic', category: HobbyCategory.MovieGenres },
  { id: 136, name: 'Comedy', category: HobbyCategory.MovieGenres },
  { id: 137, name: 'Action / Suspense', category: HobbyCategory.MovieGenres },
  { id: 138, name: 'Sci-Fi & Fantasy', category: HobbyCategory.MovieGenres },
  { id: 139, name: 'Horror', category: HobbyCategory.MovieGenres },
  { id: 140, name: 'Non-commercial / Art', category: HobbyCategory.MovieGenres },
  { id: 141, name: 'World cinema', category: HobbyCategory.MovieGenres },
  { id: 142, name: 'Documentaries', category: HobbyCategory.MovieGenres },
  { id: 143, name: 'Short films', category: HobbyCategory.MovieGenres },
  { id: 144, name: 'Epics', category: HobbyCategory.MovieGenres },
  { id: 145, name: 'Drama', category: HobbyCategory.MovieGenres },
  { id: 146, name: 'South Indian', category: HobbyCategory.Cuisines },
  { id: 147, name: 'Punjabi', category: HobbyCategory.Cuisines },
  { id: 148, name: 'Gujarati', category: HobbyCategory.Cuisines },
  { id: 149, name: 'Rajasthani', category: HobbyCategory.Cuisines },
  { id: 150, name: 'Bengali', category: HobbyCategory.Cuisines },
  { id: 151, name: 'Konkan', category: HobbyCategory.Cuisines },
  { id: 152, name: 'Chinese', category: HobbyCategory.Cuisines },
  { id: 153, name: 'Continental', category: HobbyCategory.Cuisines },
  { id: 154, name: 'Moghlai', category: HobbyCategory.Cuisines },
  { id: 155, name: 'Italian', category: HobbyCategory.Cuisines },
  { id: 156, name: 'Arabic', category: HobbyCategory.Cuisines },
  { id: 157, name: 'Thai', category: HobbyCategory.Cuisines },
  { id: 158, name: 'Sushi', category: HobbyCategory.Cuisines },
  { id: 159, name: 'Mexican', category: HobbyCategory.Cuisines },
  { id: 160, name: 'Lebanese', category: HobbyCategory.Cuisines },
  { id: 161, name: 'Latin American', category: HobbyCategory.Cuisines },
  { id: 162, name: 'Spanish', category: HobbyCategory.Cuisines },
  { id: 163, name: 'Fast food', category: HobbyCategory.Cuisines },
  { id: 164, name: 'Jogging / Walking', category: HobbyCategory.SportsAndActivities },
  { id: 165, name: 'Cycling', category: HobbyCategory.SportsAndActivities },
  { id: 166, name: 'Swimming / Water sports', category: HobbyCategory.SportsAndActivities },
  { id: 167, name: 'Cricket', category: HobbyCategory.SportsAndActivities },
  { id: 168, name: 'Yoga / Meditation', category: HobbyCategory.SportsAndActivities },
  { id: 169, name: 'Martial Arts', category: HobbyCategory.SportsAndActivities },
  { id: 170, name: 'Hockey', category: HobbyCategory.SportsAndActivities },
  { id: 171, name: 'Football', category: HobbyCategory.SportsAndActivities },
  { id: 172, name: 'Volleyball', category: HobbyCategory.SportsAndActivities },
  { id: 173, name: 'Bowling', category: HobbyCategory.SportsAndActivities },
  { id: 174, name: 'Chess', category: HobbyCategory.SportsAndActivities },
  { id: 175, name: 'Carrom', category: HobbyCategory.SportsAndActivities },
  { id: 176, name: 'Scrabble', category: HobbyCategory.SportsAndActivities },
  { id: 177, name: 'Card games', category: HobbyCategory.SportsAndActivities },
  { id: 178, name: 'Billiards / Snooker / Pool', category: HobbyCategory.SportsAndActivities },
  { id: 179, name: 'Aerobics', category: HobbyCategory.SportsAndActivities },
  { id: 180, name: 'Weight training', category: HobbyCategory.SportsAndActivities },
  { id: 181, name: 'Golf', category: HobbyCategory.SportsAndActivities },
  { id: 182, name: 'Basketball', category: HobbyCategory.SportsAndActivities },
  { id: 183, name: 'Tennis', category: HobbyCategory.SportsAndActivities },
  { id: 184, name: 'Squash', category: HobbyCategory.SportsAndActivities },
  { id: 185, name: 'Table-tennis', category: HobbyCategory.SportsAndActivities },
  { id: 186, name: 'Badminton', category: HobbyCategory.SportsAndActivities },
  { id: 187, name: 'Baseball', category: HobbyCategory.SportsAndActivities },
  { id: 188, name: 'Rugby', category: HobbyCategory.SportsAndActivities },
  { id: 189, name: 'Adventure sports', category: HobbyCategory.SportsAndActivities },
  { id: 190, name: 'Puzzles', category: HobbyCategory.HobbyAndInterest },
];

export const HobbiesDataMap: { [key in number]: { name: string; id: number; category: HobbyCategory } } = HobbiesData.reduce((map, data) => {
  map[data.id] = data;
  return map;
}, {});
