import React, { useEffect, useState } from 'react';
import { FlatList, Image, Text, View } from 'react-native';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
// TODO: instead of react-native-elements, use react-native-paper or build it custom, across the codebase, not just this file
import { Avatar, Icon, ListItem } from 'react-native-elements';
import { useSelector } from 'react-redux';
import { firestore } from 'firebaseConfig';
import { Badge } from 'react-native-paper';
import firebase from 'firebase/compat';

import { inboxStyles } from 'src/components/InboxScreen.styles';
// import { Category, ChatGroups } from '../model/user.model';
import { strings } from 'src/util/LocalizationUtil';
import { Color } from 'src/constant/Color';
import { FullLoader } from 'src/components/loader/FullLoader';
import FullScreenImage from 'src/components/common/FullScreenImageViewer';
import DataUtil from 'src/util/DataUtil';
import { RootState } from 'src/store/store';
import { ChatCategory } from 'src/common/models/chat.model';
import { ProfileResp } from 'src/common/response/profile.resp';
import { ProfileService } from 'src/service/ProfileService';
import { ChatResp } from 'src/common/response/chat.resp';
import ChipWithBadge from 'src/components/common/chips/ChipWithBadge';
import SafeContainer from './SafeContainer';
import { GenUtil } from 'src/util/GenUtil';

const GROUP_SIZE = 10;

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;

interface Props {
  navigation: NavigationProp<ParamListBase>;
}

type Category = 'accepted' | 'declined' | 'pending' | 'filtered';
type ChatGroups = Record<Category, ProfileResp[]>;

const InboxScreen: React.FC<Props> = ({ navigation }) => {
  const [groups, setGroups] = useState<ProfileResp[]>([]);
  const [loading, setLoading] = useState(true);
  const [fullScreenVisible, setFullScreenVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState({});
  const [categories] = useState([
    ChatCategory.Accepted,
    ChatCategory.Filtered,
    ChatCategory.Declined,
    ChatCategory.Pending,
  ]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lastVisible, setLastVisible] = useState<DocumentSnapshot>();
  const [listeners, setListeners] = useState<firebase.Unsubscribe[]>([]);
  const [chatStats, setChatStats] = useState({
    accepted: 0,
    declined: 0,
    pending: 0,
    filtered: 0,
  });
  const [chatGroups, setChatGroups] = useState<ChatGroups>({
    accepted: [],
    declined: [],
    pending: [],
    filtered: [],
  });

  const loggedInUser = useSelector((state: RootState) => state.user.user);
  const loggedInUserProfileUuid = loggedInUser?.profileUuid;
  const loggedInUserUuid = loggedInUser?.uuid;

  const getStats = () => {
    setChatStats({
      accepted: 3,
      declined: 4,
      pending: 5,
      filtered: 6,
    });
  };

  const handleCategoryClicked = (index: number, category: ChatCategory) => {
    setSelectedIndex(index);
    getGroupForUser(category);
  };

  /// fetching this users groups
  const getGroupForUser = (chatCategory: ChatCategory) => {
    try {
      const category: Category = chatCategory.toLocaleLowerCase() as Category;
      if (chatGroups[category].length > 0) {
        setGroups(chatGroups[category]);
        return;
      }
      const collectionRef = firestore
        .collection('chat-groups')
        .where('profileUuids', 'array-contains-any', [loggedInUserProfileUuid])
        .where('excludedStatus', '==', null)
        .where('chatCategory', '==', chatCategory)
        .orderBy('updatedAt', 'desc')
        .limit(GROUP_SIZE);

      const unSubscribe = collectionRef.onSnapshot((querySnap) => {
        const lastVisible = querySnap.docs[querySnap.docs.length - 1];

        setLastVisible(lastVisible);
        const chats = querySnap.docs.map((docSnap) => {
          const data = docSnap.data() as ChatResp;
          return data;
        });

        const groupPromise = mergeChatGroupsWithUserDetails(chats);
        groupPromise
          .then((response) => {
            setGroups(response);
            saveChats(response, category);
          })
          .catch((error) => console.log('error in group promise=', error));
      });

      setListeners([unSubscribe]);
    } catch (error) {
      console.log('error in fetching grps', error);
    }
  };

  const saveChats = (groups: ProfileResp[], chatCategory: Category, appendGroups = false) => {
    setChatGroups((prevChatGroups) => {
      const updatedGroups: ProfileResp[] = appendGroups
        ? [...prevChatGroups[chatCategory], ...groups]
        : groups;
      return { ...prevChatGroups, [chatCategory]: updatedGroups };
    });
  };

  const loadMoreGroups = () => {
    const chatCategory: Category = categories[selectedIndex].toLocaleLowerCase() as Category;
    const query = firestore
      .collection('chat-groups')
      .where('profileUuids', 'array-contains-any', [loggedInUserProfileUuid])
      .where('excludedStatus', '==', null)
      .where('chatCategory', '==', chatCategory)
      .orderBy('updatedAt', 'desc')
      .startAfter(lastVisible)
      .limit(GROUP_SIZE);

    const unSubscribe: firebase.Unsubscribe = query.onSnapshot((querySnap) => {
      const lastVisible = querySnap.docs.length && querySnap.docs[querySnap.docs.length - 1];

      setLastVisible(lastVisible);

      const chats = querySnap.docs.map((docSanp) => {
        const data = docSanp.data() as ChatResp;
        return data;
      });

      const groupPromise = mergeChatGroupsWithUserDetails(chats);
      groupPromise
        .then((response) => {
          setGroups([...groups, ...response]);
          saveChats(response, chatCategory, true);
        })
        .catch((error) => console.log('error in groupPromise = ', error));
    });

    setListeners((prevListeners) => [...prevListeners, unSubscribe]);
  };

  const mergeChatGroupsWithUserDetails = async (chats: ChatResp[]) => {
    const profileUuids = [
      ...new Set(chats.map((chat) => [chat.profileUuid1, chat.profileUuid2]).flat()),
    ].filter((profileUuid) => profileUuid != loggedInUserProfileUuid);

    const chatMap: { [key: string]: ChatResp } = {};
    for (const chat of chats) {
      chatMap[chat.profileUuid1] = chat;
      chatMap[chat.profileUuid2] = chat;
    }

    const profiles = await ProfileService.listProfilesDetails(profileUuids);
    const profilesWithData = profiles.data.data;

    const profilesWithChat = profilesWithData;

    for (const profile of profilesWithChat) {
      profile.chat = chatMap[profile.uuid];
    }
    profilesWithChat.sort((a, b) => {
      const timestampA = getTimestamp(a);
      const timestampB = getTimestamp(b);

      return timestampB - timestampA;
    });

    return profilesWithChat;
  };

  function getTimestamp(data: ProfileResp) {
    const timestamp: string | Date = data.chat.updatedAt;

    if (typeof timestamp === 'object') {
      const { seconds, nanoseconds } = timestamp as firebase.firestore.Timestamp;

      const date = new Date(seconds * 1000 + nanoseconds / 1000000);
      return date.getTime() / 1000;
    }
    return Date.parse(timestamp) / 1000;
  }

  // // load screen data
  useEffect(() => {
    getGroupForUser(ChatCategory.Accepted);
    setSelectedIndex(0);
    setLoading(false);
    getStats();

    return () => {
      listeners.map((unSubscribe) => unSubscribe()); //unsubscribing listeners
    };
  }, []);

  const onChatPress = (index: number) => {
    const group = groups[index];
    navigation.navigate('MessageScreen', { group });
  };
  const emptyListMessage = () => {
    return (
      <View style={inboxStyles.emptyListContainer}>
        <Text style={inboxStyles.emptyListMessage}>{strings.empty_message_list}</Text>
        <Icon
          containerStyle={{ padding: 10 }}
          name="frown"
          type="font-awesome-5"
          color={Color.white}
          size={25}
        />
      </View>
    );
  };

  return (
    <SafeContainer
      showPageHeader={true}
      heading="Chats"
      style={inboxStyles.container}
      leftIcon={<Icon name="arrow-back" />}
      onPressLeftIcon={() => navigation.navigate(GenUtil.isDesktopWeb() ? 'data' : 'Home')}
    >
      {loading ? (
        <View style={inboxStyles.loaderContainer}>
          <FullLoader message={''} />
        </View>
      ) : (
        <View style={{ flex: 1 }}>
          <View style={inboxStyles.categoriesContainer}>
            {categories.map((category, index) => {
              const isSelected = index === selectedIndex;

              return (
                <View style={{ marginRight: 10, marginBottom: 10 }} key={index}>
                  <ChipWithBadge
                    isSelected={isSelected}
                    onPress={() => handleCategoryClicked(index, category)}
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    badgeValue={chatStats[category.toLocaleLowerCase()]}
                    title={category}
                  />
                </View>
              );
            })}
          </View>
          <View style={inboxStyles.groupsContainer}>
            {groups?.length ? (
              <FlatList
                data={groups}
                keyExtractor={(item) => item.uuid}
                initialNumToRender={2}
                renderItem={({ item: group, index }) => {
                  const maleAvatar = 'src/assets/images/placeholder-male.png';
                  const femaleAvatar = 'src/assets/images/placeholder-female.png';
                  const profilePhoto = group.profilePhoto?.originalUrl;
                  const photoUrl = !group.profilePhoto.originalUrl
                    ? group.gender === 'Male'
                      ? maleAvatar
                      : femaleAvatar
                    : profilePhoto;

                  const imageProps = { source: GenUtil.getProfilePic(group) };
                  const lastMessageType = group?.chat.lastChatMessage?.messageType;
                  const isUnreadMessage = group.chat?.usersUnreadMessages[loggedInUserUuid] > 0;
                  // console.log('is image url=', imageUrl);

                  // console.log({
                  //   ...(photoUrl.includes('src/assets/images')
                  //     ? { props: { ...imageProps } }
                  //     : { url: photoUrl }),
                  // });

                  return (
                    <View>
                      {imageUrl ? (
                        <FullScreenImage
                          hideFullScreen={() => setFullScreenVisible(false)}
                          visible={fullScreenVisible}
                          images={[imageUrl]}
                        />
                      ) : null}

                      <ListItem
                        containerStyle={inboxStyles.groupItem}
                        key={group.uuid}
                        onPress={() => onChatPress(index)}
                        bottomDivider
                      >
                        <Avatar
                          size="medium"
                          avatarStyle={inboxStyles.groupAvatar}
                          rounded
                          onPress={() => {
                            setImageUrl({
                              ...(photoUrl.includes('src/assets/images')
                                ? { props: { ...imageProps } }
                                : { url: photoUrl }),
                            });
                            setFullScreenVisible(true);
                          }}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...imageProps}
                        />

                        <ListItem.Content>
                          <View
                            style={{
                              flexDirection: 'row',
                              width: '100%',
                            }}
                          >
                            <Text style={inboxStyles.chatName} numberOfLines={1}>
                              {group.fullName}
                            </Text>
                            <Text style={inboxStyles.basicDetails} numberOfLines={1}>
                              {group?.age && group.age}Y Old
                              {group?.profession && `, ${group.profession}`}
                            </Text>
                          </View>
                          <View
                            style={{
                              width: '100%',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            {lastMessageType === 'image' ? (
                              <Icon
                                style={inboxStyles.cameraIcon}
                                size={18}
                                name="camera"
                                type="font-awesome"
                                color={Color.gray}
                              />
                            ) : (
                              <View style={{ flex: 1 }}>
                                <Text style={inboxStyles.lastMessage} numberOfLines={1}>
                                  {group?.chat?.lastChatMessage?.content &&
                                    DataUtil.getFirstLine(group.chat.lastChatMessage.content)}
                                </Text>
                              </View>
                            )}
                          </View>
                        </ListItem.Content>
                        {isUnreadMessage && (
                          <View>
                            <Badge style={{ backgroundColor: '#FFA242', color: Color.white }}>
                              {group?.chat?.usersUnreadMessages[loggedInUserUuid]}
                            </Badge>
                          </View>
                        )}
                        {group.chat.lastChatMessage.senderProfileUuid !== loggedInUserProfileUuid &&
                        !isUnreadMessage ? (
                          <View style={inboxStyles.enterIconContainer}>
                            <Image
                              style={{ height: 8, width: 8 }}
                              source={require('src/assets/icons/enter_icon.png')}
                            />
                          </View>
                        ) : null}
                      </ListItem>
                    </View>
                  );
                }}
                onEndReachedThreshold={0}
                onEndReached={() => {
                  if (groups.length < GROUP_SIZE) return;
                  loadMoreGroups();
                }}
              />
            ) : (
              emptyListMessage()
            )}
          </View>
        </View>
      )}
    </SafeContainer>
  );
};

export default InboxScreen;
