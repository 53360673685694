/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { StyleSheet, FlatList, ImageSourcePropType } from 'react-native';
import { View } from 'src/components/Themed';
import HeadingPoints from 'src/components/editProfileComponents/molecules/HeadingPoints';
import HeadingText from 'src/components/editProfileComponents/atoms/HeadingText';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';

const AboutMe = ({ userProfile }: { userProfile: Partial<ProfileResp> }) => {
  interface InterfaceDataArray {
    id: number;
    icon: ImageSourcePropType;
    title: string;
  }
  const separator = userProfile?.age && userProfile?.height ? ', ' : '';
  const dataArray: InterfaceDataArray[] = [
    {
      id: 1,
      icon: require('src/assets/images/profileIcons/user.png'),
      title:
        userProfile?.age || userProfile?.height
          ? `${userProfile?.age ? String(userProfile.age) + ' Years Old' : ''}` +
            separator +
            `${userProfile?.height ? String(userProfile.height) + 'cm Height' : ''}`
          : '',
    },
    {
      id: 2,
      icon: require('src/assets/images/profileIcons/ring.png'),
      title: userProfile?.maritalStatus,
    },
    {
      id: 3,
      icon: require('src/assets/images/profileIcons/job.png'),
      title: userProfile?.profession,
    },
    {
      id: 4,
      icon: require('src/assets/images/profileIcons/income.png'),
      title:
        userProfile?.currentCountry === 'India' && userProfile?.inrIncome
          ? '₹' + String(userProfile.inrIncome) + ' Lacs p.a.'
          : userProfile?.currentCountry !== 'India' && userProfile?.usdIncome
            ? '$' + String(userProfile.usdIncome) + 'K p.a.'
            : '',
    },
  ];

  const renderItem = ({ item, index }: { item: InterfaceDataArray; index: number }) => (
    <View style={{ flex: index % 2 == 0 ? 3 : 2 }}>
      <HeadingPoints title={item?.title} source={item?.icon} />
    </View>
  );
  const filteredData: InterfaceDataArray[] = dataArray?.filter((item) => item?.title);

  if (filteredData?.length > 0 || userProfile?.aboutMe)
    return (
      <View style={styles.aboutMe}>
        <HeadingText title="About Me" additionalStyle={{ color: Color.lightGrey }} />
        {userProfile?.aboutMe && (
          <HeadingText title={userProfile.aboutMe} additionalStyle={{ marginTop: 6 }} />
        )}
        {filteredData?.length > 0 && (
          <View>
            <FlatList
              data={filteredData}
              renderItem={({ item, index }) => renderItem({ item, index })}
              keyExtractor={(item, index) => index.toString()}
              numColumns={2}
              columnWrapperStyle={{ marginTop: 16 }}
            />
          </View>
        )}
      </View>
    );
};

const styles = StyleSheet.create({
  container: {
    flex: GenUtil.isWEB() ? null : 1,
  },
  aboutMe: {
    borderRadius: 8,
    margin: 16,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.08,
    shadowRadius: 20,
    elevation: 2,
    padding: 12,
    backgroundColor: Color.white,
  },
});

export default AboutMe;
