import { View, Text, StyleSheet } from 'react-native';
import React from 'react';

const CollapsibleContent = ({ content = <Text>Content</Text> }) => {
  return <View style={styles.wrapper}>{content ? content : <Text>CollapsibleContent</Text>}</View>;
};

const styles = StyleSheet.create({
  wrapper: {
    // paddingHorizontal: 10,
    paddingVertical: 10,
  },
});
export default CollapsibleContent;
