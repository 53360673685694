import React from 'react';
import { Modal, TouchableOpacity, View, StyleSheet, SafeAreaView, Text } from 'react-native';
import { IconButton } from 'react-native-paper';
import ImageViewer from 'react-native-image-zoom-viewer';
import { Color } from '../../constant/Color';
import { GenUtil } from 'src/util/GenUtil';

const { height, width } = GenUtil.getDimension();

export interface InterfaceImageURls {
  url: string;
}

interface InterfaceFullScreenImageProps {
  images: InterfaceImageURls[];
  visible: boolean;
  hideFullScreen: () => void;
  index?: number;
}
const FullScreenImage = ({
  images,
  visible,
  hideFullScreen,
  index = 0,
}: InterfaceFullScreenImageProps) => {
  const FullImage = () => (
    <ImageViewer
      renderHeader={() => (
        <TouchableOpacity onPress={hideFullScreen} style={styles.backButtonStyle}>
          <IconButton icon="arrow-left-thin" iconColor={Color.white} size={30} />
        </TouchableOpacity>
      )}
      index={index}
      saveToLocalByLongPress={false}
      useNativeDriver
      enableSwipeDown
      onSwipeDown={hideFullScreen}
      imageUrls={images}
      renderIndicator={(currentIndex: number, allSize: number) =>
        images?.length <= 1 ? null : (
          <View style={styles.indicatorContainor}>
            <Text style={styles.indicatorStyle}>
              {currentIndex}/{allSize}
            </Text>
          </View>
        )
      }
    />
  );
  return (
    <Modal
      animated
      hardwareAccelerated
      transparent
      animationType="fade"
      onRequestClose={hideFullScreen}
      visible={visible}
    >
      {images.length > 0 && (
        <>
          {GenUtil.isWEB() ? (
            <View style={styles.FullImageContainer}>
              <FullImage />
            </View>
          ) : GenUtil.isIOS() ? (
            <SafeAreaView style={styles.iosContainer}>
              <FullImage />
            </SafeAreaView>
          ) : (
            <FullImage />
          )}
        </>
      )}
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: GenUtil.isWEB() ? null : 1,
  },
  FullImageContainer: {
    width: width,
    height: height,
    margin: 'auto',
  },
  iosContainer: {
    height: height,
    width: width,
  },
  backButtonStyle: {
    alignSelf: 'flex-start',
    width: 30,
  },
  indicatorContainor: {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 1,
    top: '3%',
  },
  indicatorStyle: {
    color: Color.white,
  },
});

export default FullScreenImage;
