import React from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';
import SecondaryButton from 'src/components/common/buttons/SecondaryButton';
import { Text, View } from 'src/components/Themed';
import { LayoutConstant } from 'src/constant/LayoutConstant';
import { GenUtil } from 'src/util/GenUtil';
const { height } = GenUtil.getDimension();

const Welcome = ({ navigation }) => {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.header}>Welcome To</Text>
        <Text style={styles.title}>Matri Milan</Text>
        <View style={styles.buttonWrapper}>
          <PrimaryButton
            buttonText="Register for Free"
            handleKeyPress={() => navigation.push('onboardingdetails')}
            buttonStyle={{ maxWidth: '90%' }}
          />
        </View>
        <View style={styles.buttonWrapper}>
          <SecondaryButton
            buttonText="Login to your Account"
            handleKeyPress={() => navigation.push('login')}
            buttonStyle={{ maxWidth: '90%' }}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: LayoutConstant.paddingHorizontal,
  },
  content: {
    minHeight: height * 0.6,
    width: '100%',
    alignItems: 'center',
  },
  header: {
    fontFamily: 'sora-regular',
    fontSize: 25,
    marginBottom: 25,
  },
  title: {
    fontFamily: 'sora-bold',
    fontSize: 35,
    marginBottom: height * 0.1,
  },
  buttonWrapper: {
    marginBottom: 20,
    width: '100%',
    alignItems: 'center',
  },
});

export default Welcome;
