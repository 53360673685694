import { StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';
import React from 'react';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';

const { width } = GenUtil.getDimension();

const InputAdornment = ({
  icon = null,
  endIcon = null,
  value = '',
  secureTextEntry = false,
  setValue = (_) => {},
  placeholder = '',
  onIconClick = () => {},
  onEndIconClick = () => {},
  keyboardType = 'default',
  styleOverride = false,
  editable = true,
  styleOverrideAlter = false,
  setAdditionalValue = (_) => {},
  helperValue = null,
  setHelperValue = (_) => {},
  inputStyle = {},
  textStyle = {},
  autoCapitalize = 'words',
}) => {
  return (
    <View
      style={
        inputStyle
          ? StyleSheet.compose(styles.wrapper, inputStyle)
          : styleOverride
          ? StyleSheet.compose(styles.wrapper, styles.wrapperOverride)
          : styleOverrideAlter
          ? styles.wrapperAlter
          : styles.wrapper
      }
    >
      <TouchableOpacity
        style={styleOverrideAlter ? styles.iconAlter : styles.icon}
        onPress={onIconClick}
      >
        {icon}
      </TouchableOpacity>
      <TextInput
        style={
          textStyle
            ? StyleSheet.compose(styles.input, textStyle)
            : styleOverride
            ? StyleSheet.compose(styles.input, styles.inputOverride)
            : styleOverrideAlter
            ? styles.inputAlter
            : styles.input
        }
        editable={editable}
        value={value}
        onChangeText={(value) => {
          setHelperValue(helperValue);
          setValue(value);
          setAdditionalValue && setAdditionalValue(value);
        }}
        placeholder={placeholder}
        placeholderTextColor={styleOverrideAlter ? Color.black : Color.gray}
        keyboardType={keyboardType}
        secureTextEntry={secureTextEntry}
        autoCapitalize={autoCapitalize}
      />
      {endIcon && (
        <TouchableOpacity style={styles.icon} onPress={onEndIconClick}>
          {endIcon}
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  input: {
    backgroundColor: Color.backgroundGray,
    flex: 1,
    fontSize: 16,
    fontFamily: EFonts.DMSANS_BOLD,
    color: Color.gray,
    paddingRight: 5,
    left: 3,
    outlineStyle: 'none',
  },
  inputAlter: {
    backgroundColor: Color.white,
    flex: 1,
    fontSize: 12,
    lineHeight: 15,
    fontFamily: EFonts.SORA_REGULAR,
    color: Color.lightBlack,
    paddingRight: 5,
    marginLeft: 10.83,
    outlineStyle: 'none',
    width: width * 0.1,
    height: 25,
  },
  inputOverride: {
    backgroundColor: Color.white,
  },
  icon: {
    paddingVertical: 3,
    paddingHorizontal: 5,
  },
  wrapper: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: Color.backgroundGray,
    paddingVertical: 10,
    paddingHorizontal: 10,
    marginVertical: 10,
    borderWidth: 0,
    borderColor: Color.gray,
  },
  iconAlter: {
    paddingVertical: 3,
  },
  wrapperAlter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 20,
    backgroundColor: Color.white,
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginVertical: 5,
    marginRight: 5,
    borderWidth: 1,
    borderColor: '#D6D6D6',
  },
  wrapperOverride: {
    backgroundColor: Color.white,
    borderWidth: 1,
    borderRadius: 50,
  },
});

export default InputAdornment;
