import Button from 'src/components/common/buttons/Button';
import { StyleSheet, View, ViewStyle, TouchableOpacity } from 'react-native';
import React, { useState } from 'react';
import { ProfileResp } from 'src/common/response/profile.resp';

import { Color } from 'src/constant/Color';
import { Icon } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { ModalTypes, showModal } from 'src/store/reducer/uiReducer';
import { ActionItems, ActionT, useProfileActionItems } from 'src/hooks/useProfileActionItems';
import SendSvg from 'src/assets/icons/send.svg';
import XCircleSvg from 'src/assets/icons/x-circle.svg';
import TickCircleSvg from 'src/assets/icons/tick-circle.svg';
import SendInterest from 'src/components/modals/SendInterest';
import { GenUtil } from 'src/util/GenUtil';
import { useNavigation } from '@react-navigation/native';
import CustomModal from 'src/components/CustomModal';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';
import { MembershipPermissions, isFeatureEnabled } from 'src/common/data/membership.data';

type Props = {
  profile: ProfileResp;
  buttonStyles?: ViewStyle;
  variant?: 'primary' | 'secondary';
  showIcons?: boolean;
};

export const ACTIONS_ICONS = {
  [ActionItems.SendInterest]: <SendSvg style={{ marginRight: 8 }} />,
  [ActionItems.UnSendInterest]: (
    <View style={{ transform: [{ rotate: '180deg' }], marginBottom: 2, marginRight: 8 }}>
      <SendSvg />
    </View>
  ),
  [ActionItems.DeclineInterest]: <XCircleSvg />,
  [ActionItems.AcceptInterest]: <TickCircleSvg style={{ marginRight: 8 }} />,
  [ActionItems.Reminder]: (
    <Icon type="feather" name="bell" style={{ marginRight: 5 }} color={Color.white} size={16} />
  ),
  [ActionItems.InterestSent]: (
    <Icon type="feather" name="check" style={{ marginRight: 5 }} color={Color.white} />
  ),
};

export default function InterestAction(props: Props) {
  const { profile, variant = 'primary', showIcons = true } = props;
  const { actionItems, setInterestState } = useProfileActionItems(profile);
  const [showSendInterestModal, setShowInterestModal] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState<boolean>(false);

  const loggedInUserProfile = useSelector((state: RootState) => state?.user?.userProfile);
  const navigation = useNavigation();

  const dispatch = useDispatch();

  const showBottomSheet = () => {
    dispatch(
      showModal({
        type: ModalTypes.ProfileActionBottomSheet,
        childData: { profile },
      }),
    );
  };

  if (variant === 'secondary' && Array.isArray(actionItems) && actionItems.length > 1) {
    return (
      <Button
        onPress={showBottomSheet}
        size="small"
        afterIcon={
          showIcons ? (
            <Icon
              name={'more-vertical'}
              type={'feather'}
              color={Color.white}
              size={16}
              style={{ marginLeft: 10 }}
            />
          ) : undefined
        }
      >
        More Actions
      </Button>
    );
  }

  const navigateToMembership = () => {
    setShowReminderModal(false);
    const membershipScreen = GenUtil.isDesktopWeb() ? 'membership' : 'Membership';
    navigation.navigate(membershipScreen);
  };

  const hanldeReminderModal = (item) => {
    if (isFeatureEnabled(loggedInUserProfile, MembershipPermissions.CanSendInterestReminder))
      return item.onClick();
    return setShowReminderModal(true);
  };

  return (
    <View style={styles.container}>
      {actionItems ? (
        <>
          {Array.isArray(actionItems) ? (
            actionItems.map((item) => {
              return (
                <>
                  <ActionItem
                    key={item.label}
                    item={item}
                    showInterestModal={() => setShowInterestModal(true)}
                    showIcons={showIcons}
                    handleReminderModal={() => hanldeReminderModal(item)}
                  />
                  {item.type === ActionItems.Reminder && showReminderModal ? (
                    <CustomModal
                      visible={showReminderModal}
                      renderComponent={
                        <ConfirmationModal
                          title="Upgrade to Premium"
                          message="With Premium membership you can send reminders any time you want!"
                          cancelButtonText="Cancel"
                          primaryButtonText="Buy Premium"
                          onCancel={() => setShowReminderModal(false)}
                          onPrimaryButtonPressed={navigateToMembership}
                        />
                      }
                    />
                  ) : (
                    <></>
                  )}
                </>
              );
            })
          ) : (
            <>
              <ActionItem
                item={actionItems}
                showInterestModal={() => setShowInterestModal(true)}
                showIcons={showIcons}
              />
              {actionItems.type === ActionItems.SendInterest && showSendInterestModal ? (
                <SendInterest
                  profile={profile}
                  onFinish={() =>
                    setInterestState({ sentType: ActionItems.InterestSent, status: undefined })
                  }
                  closeModal={() => setShowInterestModal(false)}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </View>
  );
}

const ActionItem = ({
  item,
  showInterestModal,
  handleReminderModal,
  showIcons,
}: {
  item: ActionT;
  showInterestModal: () => void;
  handleReminderModal?: () => void;
  showIcons: boolean;
}) => {
  const icon = showIcons ? ACTIONS_ICONS[item.type] : undefined;
  const onClickHandler =
    item.type === ActionItems.SendInterest
      ? showInterestModal
      : item.type === ActionItems.Reminder
      ? handleReminderModal
      : item.onClick;
  return (
    <>
      {!item.showLabel ? (
        <TouchableOpacity style={styles.secondaryActionItem} onPress={onClickHandler}>
          {icon}
        </TouchableOpacity>
      ) : (
        <Button
          styles={{ marginRight: 8 }}
          onPress={onClickHandler}
          beforeIcon={icon}
          size={'small'}
          buttonDisabled={item.disabled}
        >
          {item.label}
        </Button>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  secondaryActionItem: {
    width: 35,
    height: 35,
    borderRadius: 100,
    borderColor: Color.white,
    borderWidth: 1.5,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonIcon: {
    marginRight: 8,
  },
  circle: {
    width: 15,
    height: 15,
    borderRadius: 100,
    backgroundColor: Color.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
