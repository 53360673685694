import { View } from 'react-native';
import React from 'react';
import ModalAdornment from 'src/components/editProfileComponents/atoms/ModalAdornment';

const AboutPartnerComp = ({
  aboutPartnerText,
  updatePartnerPref,
}: {
  aboutPartnerText: string;
  updatePartnerPref: (_) => Promise<void>;
}) => {
  const updateAboutPartner = async (text: string) => {
    await updatePartnerPref({ aboutPartner: text.trim() });
  };
  return (
    <View>
      <ModalAdornment
        value={aboutPartnerText ? aboutPartnerText : ''}
        isInputText={true}
        placeHolder="Enter Partner preference..."
        inputTextFunction={updateAboutPartner}
      />
    </View>
  );
};

export default AboutPartnerComp;
