import React from 'react';
import { StyleSheet, Image, FlatList, ImageSourcePropType } from 'react-native';

import { View, Text } from 'src/components/Themed';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import { Color } from 'src/constant/Color';
import TruncatedText from 'src/components/common/TruncatedText';
import { SiblingsGeneratedText } from '../editProfileComponents/edit-profile/AddSiblingsComp';

const UserProfileFamilyDetailsComp = ({ profileData }: { profileData: Partial<ProfileResp> }) => {
  interface InterfaceRowData {
    id: number;
    icon: ImageSourcePropType;
    label: string;
    color: string;
    value: string;
  }
  const FAMILY_INFO_ROWS: InterfaceRowData[] = [
    {
      id: 1,
      icon: require('src/assets/images/profileIcons/FrameFather.png'),
      label: 'Father',
      color: '#2049DA',
      value: profileData.fatherStatus,
    },
    {
      id: 2,
      icon: require('src/assets/images/profileIcons/FrameMother.png'),
      label: 'Mother',
      color: '#F01983',
      value: profileData.motherStatus,
    },
    {
      id: 3,
      icon: require('src/assets/images/profileIcons/FrameCurrentLoc.png'),
      label: 'Current Location',
      color: '#4FA51A',
      value: profileData.familyCurrentLocation,
    },
    {
      id: 4,
      icon: require('src/assets/images/profileIcons/FrameCurrentLoc.png'),
      label: 'Native Place',
      color: '#009B73',
      value: profileData.familyNativeLocation,
    },
    {
      id: 5,
      icon: require('src/assets/images/profileIcons/FrameType.png'),
      label: 'Type',
      color: '#BA9438',
      value: profileData.familyType,
    },
    {
      id: 6,
      icon: require('src/assets/images/profileIcons/FrameType.png'),
      label: 'Values',
      color: '#9E59C6',
      value: profileData.familyValue,
    },
  ];

  const userFamilyDetails = FAMILY_INFO_ROWS.filter(
    (item) => item.value && item.value !== '' && item.value !== null,
  );
  const familyExtra = profileData.familyExtra;
  const brotherAndSisterStatus = {
    numOfBrother: familyExtra?.numOfBrother ?? 0,
    numOfSister: familyExtra?.numOfSister ?? 0,
    numOfBrotherMarried: familyExtra?.numOfBrotherMarried ?? 0,
    numOfSisterMarried: familyExtra?.numOfSisterMarried ?? 0,
    numOfElderBrother: familyExtra?.numOfElderBrother ?? 0,
    numOfElderSister: familyExtra?.numOfElderSister ?? 0,
  };
  const renderItem = ({ item }: { item: InterfaceRowData }) => (
    <View style={styles.info}>
      <View style={[styles.iconContainer, { backgroundColor: item.color }]}>
        <Image
          source={item.icon}
          style={
            item.label === 'Father'
              ? [{ width: 18, height: 5 }]
              : item?.label === 'Type'
                ? [{ width: 20, height: 20 }]
                : item?.label === 'Values' || item?.label === 'Type'
                  ? [{ width: 20, height: 20 }]
                  : styles.iconStyle1
          }
        />
      </View>
      <View style={styles.detailContainer}>
        <Text style={styles.infoLabel}>{item.label}</Text>
        <Text style={styles.infoValue}>{item.value}</Text>
      </View>
    </View>
  );

  if (userFamilyDetails.length > 0 || (familyExtra && !GenUtil.isEmpty(familyExtra)))
    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.headerText}>Family Details</Text>
        </View>
        {profileData.aboutFamily && <TruncatedText text={profileData.aboutFamily} />}
        <FlatList
          data={userFamilyDetails}
          renderItem={renderItem}
          keyExtractor={(item) => item.id.toString()}
          numColumns={2}
          columnWrapperStyle={{ marginTop: 18 }}
          style={{ marginTop: !profileData.aboutFamily ? -15 : 0 }}
        />
        {familyExtra && !GenUtil.isEmpty(familyExtra) && (
          <View
            style={[
              styles.infoRow,
              userFamilyDetails.length === 0 && !profileData.aboutFamily && { marginTop: 15 },
            ]}
          >
            <View style={styles.info2}>
              <View style={[styles.iconContainer, { backgroundColor: '#D9860B' }]}>
                <Image
                  source={require('src/assets/images/profileIcons/FrameSibling.png')}
                  style={{ width: 17.89, height: 16 }}
                />
              </View>
              <View style={styles.siblingStyle}>
                <Text style={styles.infoSib}>Siblings</Text>
                <Text style={styles.numSib}>
                  {familyExtra?.numOfBrother > 0 && String(familyExtra?.numOfBrother) + ' Brother'}
                  {familyExtra?.numOfBrother > 1 ? 's' : ''}{' '}
                  {familyExtra?.numOfSister > 0 && String(familyExtra?.numOfSister) + ' Sister'}
                  {familyExtra?.numOfSister > 1 ? 's' : ''}
                  {familyExtra?.numOfBrother === 0 && 'No Brother '}
                  {familyExtra?.numOfSister === 0 && 'No Sister'}
                </Text>
                {(familyExtra?.numOfBrother > 0 || familyExtra?.numOfSister > 0) && (
                  <View style={styles.siblingTextStyle}>
                    <SiblingsGeneratedText siblingsData={brotherAndSisterStatus} />
                  </View>
                )}
              </View>
            </View>
          </View>
        )}
      </View>
    );
};

const styles = StyleSheet.create({
  container: {
    flex: GenUtil.isWEB() ? null : 1,
    backgroundColor: Color.white,
    paddingHorizontal: 12,
    paddingVertical: 16,
    marginHorizontal: 16,
    marginVertical: 5,
    borderRadius: 20,
    elevation: 6,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
  },
  header: {
    alignItems: 'flex-start',
    marginBottom: 18,
  },
  headerText: {
    fontFamily: EFonts.SORA_MEDIUM,
    fontSize: 17,
    lineHeight: 18,
    color: Color.lightBlack,
  },
  infoRow: {
    marginTop: 25,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoLabel: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
    color: Color.lightGrey,
  },
  infoSib: {
    fontFamily: EFonts.SORA_REGULAR,
    position: 'relative',
    top: 0,
    fontStyle: 'normal',
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
    color: Color.lightGrey,
  },
  numSib: {
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
  },
  infoValue: {
    flex: 1,
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontSize: GenUtil.isWEB() ? 12 : 10,
    width: 115,
    lineHeight: 20,
  },
  infoMessage: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: GenUtil.isWEB() ? 12 : 10,
    width: 250,
    lineHeight: 20,
  },
  info: {
    flex: 1,
    flexDirection: 'row',
  },
  info2: {
    flex: 1,
    flexDirection: 'row',
  },
  iconStyle1: {
    width: GenUtil.isWEB() ? 16 : 15,
    height: GenUtil.isWEB() ? 19 : 18,
  },
  iconContainer: {
    width: 34,
    height: 34,
    borderRadius: 16,
    backgroundColor: '#F0F0F0',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  siblingTextStyle: {
    flex: 1,
    width: GenUtil.getDimension().width * 0.73,
  },
  detailContainer: {
    flex: 1,
    marginTop: GenUtil.isWEB() ? -2 : -6,
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
  },
  siblingStyle: {
    marginTop: GenUtil.isWEB() ? -2 : -6,
  },
});

export default UserProfileFamilyDetailsComp;
