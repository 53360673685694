import { StyleSheet, Image, ScrollView, Platform } from 'react-native';
import { Text, View } from 'src/components/Themed';
import SignIn from './SignIn';
import React, { useState } from 'react';
import AppIntroSlider from 'react-native-app-intro-slider';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import StorageUtil from 'src/util/StorageUtil';
import { GenUtil } from 'src/util/GenUtil';
import Welcome from './Welcome';

interface slideData {
  key: number;
  headlineText: string;
  paragraphText: string;
  image: any;
}
const slides: slideData = [
  {
    key: 1,
    headlineText: 'Find your perfect match with ease',
    paragraphText:
      'Join our community and start your journey towards a happy marriage today! Join our community and start your journey towards a happy marriage today! Join our community and start your journey towards a happy marriage today!',
    image: require('../assets/images/onboardingImages/firstSlideImage.png'),
  },
  {
    key: 2,
    headlineText: 'Find your perfect match with ease',
    paragraphText:
      'Join our community and start your journey towards a happy marriage today! Join our community and start your journey towards a happy marriage today! Join our community and start your journey towards a happy marriage today!',
    image: require('../assets/images/onboardingImages/secondSlideImage.png'),
  },
  {
    key: 3,
    headlineText: 'Find your perfect match with ease',
    paragraphText:
      'Join our community and start your journey towards a happy marriage today! Join our community and start your journey towards a happy marriage today! Join our community and start your journey towards a happy marriage today!',
    image: require('../assets/images/onboardingImages/thirdSlideImage.png'),
  },
];
const { height, width } = GenUtil.getDimension();

export default function IntroSlider({ navigation }) {
  const [showMainApp, setShowMainApp] = useState(false);
  function renderItem({ item }) {
    return (
      <View style={styles.imageAndText}>
        <Image style={styles.imageStyle} source={item.image} />
        <Text style={styles.headlineTextStyle}>{item.headlineText}</Text>
        <Text style={styles.paragraphTextStyle}> {item.paragraphText} </Text>
      </View>
    );
  }
  function renderNextButton() {
    return (
      <View style={styles.nextButton}>
        <Image
          style={{ height: 30, width: 30 }}
          source={require('../assets/images/onboardingIcons/arrowRight.png')}
        />
      </View>
    );
  }
  function renderSkipButton() {
    return <Text style={styles.skipButton}>SKIP</Text>;
  }
  function renderDoneButton() {
    return (
      <View style={styles.doneButton}>
        <Text style={styles.buttonText}>Let's Start Finding</Text>
      </View>
    );
  }
  function introCompleted() {
    setShowMainApp(true);
    StorageUtil.updateFirstTimeLaunch();
  }

  return (
    <>
      {showMainApp ? (
        <Welcome navigation={navigation} />
      ) : (
        <AppIntroSlider
          style={{ backgroundColor: Color.white }}
          data={slides}
          renderItem={renderItem}
          showSkipButton={true}
          onDone={introCompleted}
          onSkip={introCompleted}
          renderNextButton={renderNextButton}
          renderSkipButton={renderSkipButton}
          renderDoneButton={renderDoneButton}
          activeDotStyle={styles.activeDotStyle}
          dotStyle={styles.dotStyle}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  imageAndText: {
    alignItems: 'center',
    height: height,
  },
  imageStyle: {
    width: width * 0.84,
    height: height * 0.49,
    top: GenUtil.isWEB() ? 55 : height * 0.11,
    borderRadius: 60,
  },
  headlineTextStyle: {
    fontSize: 18,
    position: 'absolute',
    textAlign: 'center',
    bottom: GenUtil.isAndroid() ? 200 : 250,
    color: Color.lightBlack,
    fontFamily: EFonts.SORA_MEDIUM,
  },
  paragraphTextStyle: {
    color: Color.halfBlack,
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: GenUtil.isWEB() ? 18 : 20,
    position: 'absolute',
    textAlign: 'center',
    letterSpacing: -0.1,
    bottom: GenUtil.isAndroid() ? 100 : 150,
    width: GenUtil.isWEB() ? width * 0.92 : width * 0.88,
  },
  nextButton: {
    backgroundColor: Color.purple,
    width: 70,
    height: 70,
    bottom: GenUtil.isWEB() ? 15 : 35,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 70 / 2,
    marginRight: width * 0.5 - 35 - 16,
  },
  buttonText: {
    color: Color.white,
    fontSize: 16,
    fontFamily: EFonts.SORA_MEDIUM,
    textAlign: 'center',
  },
  skipButton: {
    fontFamily: EFonts.SORA_MEDIUM,
    color: Color.lightGrey,
    fontSize: 15,
    bottom: GenUtil.isWEB() ? height * 0.84 : height * 0.82,
    left: GenUtil.isWEB() ? 330 : width * 0.82,
    position: 'absolute',
  },
  doneButton: {
    flex: 1,
    backgroundColor: Color.purple,
    width: 310,
    paddingVertical: 15,
    borderRadius: 24,
    marginRight: width * 0.5 - 155 - 16,
    bottom: GenUtil.isWEB() ? 15 : 35,
  },
  activeDotStyle: {
    backgroundColor: 'blue',
    width: 22,
    height: 4,
    bottom: GenUtil.isWEB() ? 280 : GenUtil.isAndroid() ? 250 : 245,
  },
  dotStyle: {
    backgroundColor: Color.gray,
    width: 6,
    height: 4,
    // bottom: 245,
    bottom: GenUtil.isWEB() ? 280 : GenUtil.isAndroid() ? 250 : 245,
  },
});
