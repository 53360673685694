import { ImageBackground, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import { LinearGradient } from 'expo-linear-gradient';
import { Color } from 'src/constant/Color';
import MoreOptionsAction from 'src/components/common/profileCard/actions/MoreOptionsAction';
import UserUtil from 'src/util/UserUtil';
import { useNavigation } from '@react-navigation/native';
import UserTickSvg from 'src/assets/icons/user-tick.svg';
import UserHeartSvg from 'src/assets/icons/user-heart.svg';
import BriefCaseSvg from 'src/assets/icons/briefcase.svg';
import { Verification } from 'src/common/models/profile.model';
import VerifySvg from 'src/assets/icons/verify.svg';

const { height, width } = GenUtil.getDimension();

const CARD_HEIGHT = !GenUtil.isWEB() ? height / 4 : height / 5;
const CARD_WIDTH = width * 0.4;

type Props = {
  item: ProfileResp;
};

const SmallDetailedProfileCard = ({ item }: Props): JSX.Element => {
  const navigation = useNavigation();

  const navigateToProfile = () => {
    navigation.navigate('otherpersonprofile', { uuid: item.uuid });
  };

  return (
    <TouchableWithoutFeedback onPress={navigateToProfile}>
      <View style={styles.container}>
        <View style={{ width: '100%', height: '100%' }}>
          <ImageBackground source={{ uri: item.profilePhoto?.originalUrl }} style={styles.image}>
            <LinearGradient
              colors={['#00000000', '#000000']}
              style={{ height: GenUtil.isIOS() ? height / 3.5 : height / 4.5, width: '100%' }}
            ></LinearGradient>
          </ImageBackground>
        </View>
        <View style={styles.info}>
          <View style={styles.infoContainer}>
            <Text style={styles.infoText}>{item.fullName}</Text>
            {item?.verifications?.find(
              (verification) => verification === Verification.IDVerified,
            ) ? (
              <VerifySvg style={{ marginRight: 8 }} />
            ) : (
              <></>
            )}
          </View>
          <View style={styles.infoContainer}>
            <UserTickSvg color={Color.white} />
            <Text style={styles.infoTextSmall}>{item.age} Y, </Text>
            <Text style={styles.infoTextSmall}>{UserUtil.getUserHeight(item.height)}</Text>
          </View>
          <View style={styles.infoContainer}>
            <UserHeartSvg color={Color.white} />
            <Text style={styles.infoTextSmall}>{item.maritalStatus}</Text>
          </View>
          <View style={styles.infoContainer}>
            <BriefCaseSvg color={Color.white} />
            <Text style={styles.infoTextSmall}>{item.profession}</Text>
          </View>
        </View>

        <MoreOptionsAction profile={item} styles={styles.moreOption} />
      </View>
    </TouchableWithoutFeedback>
  );
};

export default SmallDetailedProfileCard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
    minHeight: CARD_HEIGHT,
    maxHeight: CARD_HEIGHT,
    maxWidth: CARD_WIDTH,
    backgroundColor: '#fff',
    marginHorizontal: 5,
    borderRadius: 12,
  },
  image: {
    width: CARD_WIDTH,
    height: CARD_HEIGHT,
    resizeMode: 'cover',
  },
  info: {
    width: '100%',
    position: 'absolute',
    bottom: 10,
    left: 10,
  },
  infoText: {
    color: '#fff',
    fontSize: 12,
    fonWeight: '600',
    fontFamily: 'dmsans-bold',
    marginBottom: 5,
  },
  infoTextSmall: {
    fontSize: 11,
    color: Color.white,
    marginLeft: 10,
  },
  infoContainer: {
    width: '90%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  moreOption: {
    position: 'absolute',
    top: 15,
    right: 10,
  },
});
