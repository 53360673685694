import { View, Text, Image } from 'react-native';
import React from 'react';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import OutlinedButton from 'src/components/common/buttons/OutlinedButton';
import PhotoCollage from './PhotoCollage';
import { InterestService } from 'src/service/InterestService';

const InterestSentCard = ({
  name,
  interestUuid,
  gender,
  otherUserGender,
  otherUserProfilePhoto,
  profilePhoto,
}: {
  name: string;
  interestUuid: string;
  gender: string;
  otherUserGender: string;
  otherUserProfilePhoto: string;
  profilePhoto: string;
}) => {
  const interestSentMessage = `You sent an interest to ${name}. Now, wait for ${
    gender === 'Male' ? 'him' : 'her'
  } to Accept/Reject the Interest.`;

  const handleUnsendInterestPressed = async () => {
    const response = await InterestService.deleteInterest(interestUuid);
    console.log('unsend response = ', response);
  };
  return (
    <View
      style={{
        width: 230,
        backgroundColor: '#811EFF',
        borderRadius: 12,
        borderBottomRightRadius: 0,
        paddingHorizontal: 8,
      }}
    >
      <View
        style={{
          height: 68,
          borderBottomWidth: 1,
          borderColor: Color.white,
          paddingVertical: 8,
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            height: '100%',
            width: 72,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <PhotoCollage
            size={36}
            smallCircleSize={22}
            icon={
              <Image
                style={{ height: 12, width: 12, right: 1, top: 1 }}
                source={require('src/assets/icons/send_icon.png')}
              />
            }
            profilePhoto={profilePhoto}
            gender={gender}
            otherUserGender={otherUserGender}
            otherUserProfilePhoto={otherUserProfilePhoto}
          />
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            marginLeft: 8,
          }}
        >
          <Text
            style={{
              fontFamily: EFonts.SORA_SEMIBOLD,
              fontSize: 15,
              lineHeight: 20,
              color: Color.white,
            }}
          >
            Interest Sent
          </Text>
        </View>
      </View>
      <View style={{ marginTop: 8 }}>
        <Text
          style={{
            fontFamily: EFonts.SORA_REGULAR,
            fontSize: 12,
            lineHeight: 20,
            color: Color.white,
          }}
        >
          {interestSentMessage}
        </Text>
      </View>
      <View style={{ paddingHorizontal: 45, marginVertical: 8 }}>
        <OutlinedButton
          title="Unsend Interest"
          style={{ borderColor: Color.white, paddingVertical: 5 }}
          textStyle={{
            fontFamily: EFonts.SORA_REGULAR,
            fontSize: 12,
            lineHeight: 15,
            color: Color.white,
            marginVertical: 0,
            marginHorizontal: 0,
          }}
          onPress={handleUnsendInterestPressed}
        />
      </View>
    </View>
  );
};

export default InterestSentCard;
