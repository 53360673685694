import { Icon } from 'react-native-elements';
import { Color } from 'src/constant/Color';
import { TouchableOpacity, ViewStyle } from 'react-native';
import { ProfileResp } from 'src/common/response/profile.resp';
import { useDispatch } from 'react-redux';
import { ModalTypes, showModal } from 'src/store/reducer/uiReducer';

type Props = {
  profile: ProfileResp;
  styles?: ViewStyle | ViewStyle[];
  iconColor?: string;
};

export default function MoreOptionsAction({ profile, styles, iconColor = Color.white }: Props) {
  const dispatch = useDispatch();

  const showMoreOptions = () => {
    dispatch(
      showModal({
        type: ModalTypes.UserProfileOptionsModal,
        childData: { profile },
      }),
    );
  };

  return (
    <TouchableOpacity activeOpacity={100} onPress={showMoreOptions} style={styles}>
      <Icon name={'more-vertical'} type={'feather'} color={iconColor} size={16} />
    </TouchableOpacity>
  );
}
