import axios, { AxiosResponse } from 'axios';
import Endpoints from 'src/service/Endpoints';
import { BaseResp, HttpResponse } from 'src/common/response/base.resp';
import {
  PreSignedUrlBody,
  ProfileMediaBody,
  ProfileMediaUpdateBody,
} from 'src/common/models/media.model';

export class PreSignedUrlResp extends BaseResp {
  preSignedUploadUrl: string;
  destS3Key: string;
  publicUrl?: string;
}

export default class ProfileMediaService {
  static async getPreSignedUrlForImage(
    preSignedUrlBody: PreSignedUrlBody,
  ): Promise<HttpResponse<PreSignedUrlResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<PreSignedUrlResp>>>(
      `${Endpoints.PRESIGNED_URL}`,
      { ...preSignedUrlBody },
    );
    return HttpResponse.fromRes<PreSignedUrlResp>(response.data);
  }

  static async addMedia(profileMediaBodies: ProfileMediaBody[]): Promise<HttpResponse<BaseResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.ADD_MEDIA}`,
      profileMediaBodies,
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async updateMedia(
    profileMediaUpdateBodies: ProfileMediaUpdateBody[],
  ): Promise<HttpResponse<BaseResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.UPDATE_MEDIA}`,
      profileMediaUpdateBodies,
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async deleteMedia(uuid: string): Promise<HttpResponse<BaseResp>> {
    const response = await axios.delete<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.UPDATE_MEDIA}/${uuid}`,
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async uploadMedia(formData): Promise<HttpResponse<BaseResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.UPLOAD_MEDIA}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }
}
