import { View, Text, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';
import { Icon } from 'react-native-elements';

import PillShapeChip from 'src/components/common/chips/PillShapeChip';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import InputAdornment from 'src/components/common/input/InputAdornment';
import { SearchLocationResp } from 'src/common/response/location.resp';

const { width } = GenUtil.getDimension();

interface interfaceLocationArray {
  key: string;
  value: string;
}
interface interfaceLocation {
  next: () => void;
  prev: () => void;
  location: Partial<SearchLocationResp>;
  setLocation: (_) => void;
  setLocationSignUp: (_) => void;
  searchedLocation: string;
  setSearchedLocation: (_) => void;
  locationArray: Partial<SearchLocationResp>[];
  locationArrayFilter: interfaceLocationArray[];
}
const OnboardingLocationInputComp = ({
  next = () => {},
  prev = () => {},
  location = {} as Partial<SearchLocationResp>,
  setLocation = (_) => {},
  setLocationSignUp = (_) => {},
  searchedLocation = '',
  setSearchedLocation = (_) => {},
  locationArray = [],
  locationArrayFilter = [],
}: interfaceLocation) => {
  const [locationSelected, setLocationSelected] = useState([] as string[]);

  useEffect(() => {
    if (locationSelected?.length > 0) {
      locationArray?.forEach((value) => {
        if (String(value?.lat) + String(value?.lng) === locationSelected?.[0]) {
          if (String(location?.lat) + String(location?.lng) !== locationSelected?.[0]) {
            setLocation(value);
            setLocationSignUp({
              countryName: value?.countryName,
              stateName: value?.stateName ? value.stateName : null,
              cityName: value?.cityName ? value.cityName : null,
            });
          }
          const val = GenUtil.getLocationName(
            value?.cityName,
            value?.stateName,
            value?.countryName,
            value?.locationType,
          );

          setSearchedLocation(val);
          return;
        }
      });
    } else if (!GenUtil.isEmpty(location)) {
      setLocationSelected([String(location?.lat) + String(location?.lng)]);
    }
  }, [locationSelected]);

  const nextComponent = () => {
    setTimeout(() => {
      next();
    }, 500);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>You Are From</Text>
      <InputAdornment
        icon={<Icon name="location" type="ionicon" size={20} color={Color.gray} />}
        value={searchedLocation}
        setValue={setSearchedLocation}
        placeholder={'Enter location'}
        styleOverride
      />
      <PillShapeChip
        options={locationArrayFilter}
        selected={locationSelected}
        setSelected={setLocationSelected}
        unselectedIcon={require('src/assets/icons/building_light.png')}
        selectedIcon={require('src/assets/icons/building_dark.png')}
        iconDimension={{ width: 24, height: 24 }}
        chipClicked={nextComponent}
        callChipClicked={true}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontFamily: EFonts.SORA_LIGHT,
    fontSize: 28,
    lineHeight: 38,
    color: Color.lightBlack,
    marginTop: 10,
  },
  container: {
    paddingTop: 20,
    flex: GenUtil.isWEB() ? null : 1,
    paddingHorizontal: width * 0.05,
    backgroundColor: 'transparent',
  },
  back: {
    justifyContent: 'flex-start',
  },
});

export default OnboardingLocationInputComp;
