export enum ELanguage {
  Hindi = 'Hindi',
  English = 'English',
  Malyalam = 'Malyalam',
  Tamil = 'Tamil',
  Bhojpuri = 'Bhojpuri',
  Oriya = 'Oriya',
  Punjabi = 'Punjabi',
  Haryanvi = 'Haryanvi',
  Telugu = 'Telugu',
  Kannada = 'Kannada',
  Marathi = 'Marathi',
  Gujrati = 'Gujarati',
  Chhattisgarhi = 'Chhattisgarhi',
  Bengali = 'Bengali',
  Rajasthani = 'Rajasthani',
  Assamese = 'Assamese',
}
