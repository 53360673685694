import awaitTo from 'async-await-error-handling';
import axios, { AxiosResponse } from 'axios';
import Endpoints, { getError } from 'src/service/Endpoints';
import { IUser, IPhoneAuthResponse, IFirebaseTokenResponse } from 'src/model/user.model';
import { LoginBody, SignupBody } from 'src/common/dto/user.dto';
import { SendOtpBody, VerifyOtpBody } from 'src/common/dto/otp.dto';
import { LoginResp, UserResp } from 'src/common/response/user.resp';
import { BaseResp, HttpResponse } from 'src/common/response/base.resp';

export enum ESignupType {
  GMAIL = 'gmail',
  FACEBOOK = 'facebook',
  PHONE = 'phone',
  APPLE = 'apple',
}

export class AuthService {
  static async signUpUser(authUser: SignupBody): Promise<[IUser, string]> {
    const [err, data] = await awaitTo(axios.post(`${Endpoints.SIGN_UP}`, { ...authUser }));
    if (!err) return [data.data.data as IUser, ''];
    return [{} as IUser, getError(err)];
  }

  static async sendOTP(sendOtpBody: SendOtpBody): Promise<HttpResponse<BaseResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.SEND_OTP_FOR_PHONE_AUTH}`,
      { ...sendOtpBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async verifyOTP(verifyOtpBody: VerifyOtpBody): Promise<HttpResponse<BaseResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.VERIFY_OTP_FOR_PHONE_AUTH}`,
      { ...verifyOtpBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async eligibleOTP(eligibleOtpBody: SendOtpBody): Promise<HttpResponse<BaseResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.ELIGIBLE_OTP_FOR_PHONE_AUTH}`,
      { ...eligibleOtpBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async loginUser(loginBody: LoginBody): Promise<HttpResponse<LoginResp>> {
    const response = await axios.post<any, AxiosResponse<HttpResponse<LoginResp>>>(
      `${Endpoints.LOGIN}`,
      { ...loginBody },
    );
    return HttpResponse.fromRes<LoginResp>(response.data);
  }

  static async getFirebaseToken(): Promise<HttpResponse<IFirebaseTokenResponse>> {
    const response = await axios.get<any, AxiosResponse<HttpResponse<IFirebaseTokenResponse>>>(
      Endpoints.FIREBASE_TOKEN,
    );

    return HttpResponse.fromRes<IFirebaseTokenResponse>(response.data);
  }
}
