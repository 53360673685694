export default class EFonts {
  static SANS_LIGHT = 'OpenSans-Light';
  static ATYP_REGULAR = 'atyp-regular';
  static ATYP_MEDIUM = 'atyp-medium';
  static ATYP_BOLD = 'atyp-bold';
  static DMSANS_REGULAR = 'dmsans-regular';
  static DMSANS_MEDIUM = 'dmsans-medium';
  static DMSANS_BOLD = 'dmsans-bold';
  static SORA_LIGHT = 'sora-light';
  static SORA_BOLD = 'sora-bold';
  static SORA_MEDIUM = 'sora-medium';
  static SORA_REGULAR = 'sora-regular';
  static SORA_SEMIBOLD = 'sora-semibold';
}
