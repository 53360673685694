import { View } from 'react-native';
import React, { ReactNode } from 'react';
import { FlashList, ListRenderItem } from '@shopify/flash-list';

const VerticalFlashList = ({
  data,
  renderItem,
  estimatedItemSize = 90,
  onEndReached,
}: {
  data: any[];
  renderItem: ListRenderItem<any>;
  estimatedItemSize: number;
  onEndReached: () => void;
}) => {
  const handleEndReached = () => {
    if (onEndReached) {
      onEndReached();
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <FlashList
        data={data}
        renderItem={renderItem}
        estimatedItemSize={estimatedItemSize}
        onEndReachedThreshold={0.2}
        onEndReached={handleEndReached}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
};

export default VerticalFlashList;
