/* eslint-disable @typescript-eslint/no-var-requires */
import { Image, ImageSourcePropType } from 'react-native';
import React from 'react';

const image = require('src/assets/images/profileIcons/user.png') as ImageSourcePropType;

const HeadingIcon = ({
  iconDimension = { width: 10, height: 10 },
  source = image,
}: {
  iconDimension: { width: number; height: number };
  source: ImageSourcePropType;
}) => (
  <Image style={{ width: iconDimension.width, height: iconDimension.height }} source={source} />
);

export default HeadingIcon;
