export type QualificationId = number;
export type ProfessionId = number;

export const QualificationData: Array<{
  name: string;
  id: QualificationId;
  category: string;
}> = [
  { name: 'B.Arch', id: 1, category: 'Engineering/Design' },
  { name: 'B.Des', id: 2, category: 'Engineering/Design' },
  { name: 'B.E/B.Tech', id: 3, category: 'Engineering/Design' },
  { name: 'B.Pharma', id: 4, category: 'Engineering/Design' },
  { name: 'M.Arch', id: 5, category: 'Engineering/Design' },
  { name: 'M.Des', id: 6, category: 'Engineering/Design' },
  { name: 'M.E/M.Tech', id: 7, category: 'Engineering/Design' },
  { name: 'M.Pharma', id: 8, category: 'Engineering/Design' },
  { name: 'M.S. (Engineering)', id: 9, category: 'Engineering/Design' },
  { name: 'B.IT', id: 10, category: 'Computers' },
  { name: 'BCA', id: 11, category: 'Computers' },
  { name: 'MCA/PGDCA', id: 12, category: 'Computers' },
  { name: 'B.Com', id: 13, category: 'Finance/Commerce' },
  { name: 'CA', id: 14, category: 'Finance/Commerce' },
  { name: 'CFA', id: 15, category: 'Finance/Commerce' },
  { name: 'CS', id: 16, category: 'Finance/Commerce' },
  { name: 'ICWA', id: 17, category: 'Finance/Commerce' },
  { name: 'M.Com', id: 18, category: 'Finance/Commerce' },
  { name: 'BBA', id: 19, category: 'Management' },
  { name: 'BHM', id: 20, category: 'Management' },
  { name: 'MBA/PGDM', id: 21, category: 'Management' },
  { name: 'BAMS', id: 22, category: 'Medicine' },
  { name: 'BDS', id: 23, category: 'Medicine' },
  { name: 'BHMS', id: 24, category: 'Medicine' },
  { name: 'BPT', id: 25, category: 'Medicine' },
  { name: 'BVSc.', id: 26, category: 'Medicine' },
  { name: 'DM', id: 27, category: 'Medicine' },
  { name: 'M.D.', id: 28, category: 'Medicine' },
  { name: 'M.S. (Medicine)', id: 29, category: 'Medicine' },
  { name: 'MBBS', id: 30, category: 'Medicine' },
  { name: 'MCh', id: 31, category: 'Medicine' },
  { name: 'MDS', id: 32, category: 'Medicine' },
  { name: 'MPT', id: 33, category: 'Medicine' },
  { name: 'MVSc.', id: 34, category: 'Medicine' },
  { name: 'BL/LLB', id: 35, category: 'Law' },
  { name: 'ML/LLM', id: 36, category: 'Law' },
  { name: 'B.A', id: 37, category: 'Arts/Science' },
  { name: 'B.Ed', id: 38, category: 'Arts/Science' },
  { name: 'B.Sc', id: 39, category: 'Arts/Science' },
  { name: 'BFA', id: 40, category: 'Arts/Science' },
  { name: 'BJMC', id: 41, category: 'Arts/Science' },
  { name: 'M.A', id: 42, category: 'Arts/Science' },
  { name: 'M.Ed', id: 43, category: 'Arts/Science' },
  { name: 'M.Sc', id: 44, category: 'Arts/Science' },
  { name: 'MFA', id: 45, category: 'Arts/Science' },
  { name: 'MJMC', id: 46, category: 'Arts/Science' },
  { name: 'MSW', id: 47, category: 'Arts/Science' },
  { name: 'M.Phil', id: 48, category: 'Doctorate' },
  { name: 'Ph. D', id: 49, category: 'Doctorate' },
  { name: 'High School', id: 50, category: 'Non-Graduate' },
  { name: 'Trade School', id: 51, category: 'Non-Graduate' },
  { name: 'Diploma', id: 52, category: 'Non-Graduate' },
  { name: 'Other', id: 53, category: 'Others' },
];

export const ProfessionData: Array<{ name: string; id: ProfessionId; category: string }> = [
  { name: 'Government/Public Sector', category: '', id: 1000 },
  { name: 'Civil Services', category: '', id: 1001 },
  { name: 'Defense', category: '', id: 1002 },
  { name: 'Business/ Self Employed', category: '', id: 1003 },
  { name: 'Not Working', category: '', id: 1004 },
  { name: 'Admin Professional', category: 'Administration', id: 1005 },
  { name: 'Clerk', category: 'Administration', id: 1006 },
  { name: 'Operator/Technician', category: 'Administration', id: 1007 },
  { name: 'Secretary/Front Office', category: 'Administration', id: 1008 },
  { name: 'Actor/Model', category: 'Advertising, Media & Entertainment', id: 1009 },
  { name: 'Advertising Professional', category: 'Advertising, Media & Entertainment', id: 1010 },
  { name: 'Film/ Entertainment Professional', category: 'Advertising, Media & Entertainment', id: 1011 },
  { name: 'Journalist', category: 'Advertising, Media & Entertainment', id: 1012 },
  { name: 'Media Professional', category: 'Advertising, Media & Entertainment', id: 1013 },
  { name: 'PR Professional', category: 'Advertising, Media & Entertainment', id: 1014 },
  { name: 'Agriculture Professional', category: 'Agricultural', id: 1015 },
  { name: 'Farming', category: 'Agricultural', id: 1016 },
  { name: 'Airline Professional', category: 'Airline & Aviation', id: 1017 },
  { name: 'Flight Attendant', category: 'Airline & Aviation', id: 1018 },
  { name: 'Pilot', category: 'Airline & Aviation', id: 1019 },
  { name: 'Architect', category: 'Architecture', id: 1020 },
  { name: 'Air Force', category: 'Armed Forces', id: 1021 },
  { name: 'Army', category: 'Armed Forces', id: 1022 },
  { name: 'Defence Services', category: 'Armed Forces', id: 1023 },
  { name: 'Navy', category: 'Armed Forces', id: 1024 },
  { name: 'Accounting Professional', category: 'Banking & Finance', id: 1025 },
  { name: 'Auditor', category: 'Banking & Finance', id: 1026 },
  { name: 'Banking Professional', category: 'Banking & Finance', id: 1027 },
  { name: 'Chartered Accountant', category: 'Banking & Finance', id: 1028 },
  { name: 'Finance Professional', category: 'Banking & Finance', id: 1029 },
  { name: 'BPO/ITes Professional', category: 'BPO & Customer Service', id: 1030 },
  { name: 'Customer Service', category: 'BPO & Customer Service', id: 1031 },
  { name: 'Civil Services (IAS/ IPS/ IRS/ IES/ IFS)', category: 'Civil Services', id: 1032 },
  { name: 'Analyst', category: 'Corporate Management Professionals', id: 1033 },
  { name: 'Consultant', category: 'Corporate Management Professionals', id: 1034 },
  { name: 'Corporate Communication', category: 'Corporate Management Professionals', id: 1035 },
  { name: 'Corporate Planning', category: 'Corporate Management Professionals', id: 1036 },
  { name: 'HR Professional', category: 'Corporate Management Professionals', id: 1037 },
  { name: 'Marketing Professional', category: 'Corporate Management Professionals', id: 1038 },
  { name: 'Operations Management', category: 'Corporate Management Professionals', id: 1039 },
  { name: 'Product manager', category: 'Corporate Management Professionals', id: 1040 },
  { name: 'Program Manager', category: 'Corporate Management Professionals', id: 1041 },
  { name: 'Project Manager - Non IT', category: 'Corporate Management Professionals', id: 1042 },
  { name: 'Sales Professional', category: 'Corporate Management Professionals', id: 1043 },
  { name: 'Sr. Manager/ Manager', category: 'Corporate Management Professionals', id: 1044 },
  { name: 'Subject Matter Expert', category: 'Corporate Management Professionals', id: 1045 },
  { name: 'Dentist', category: 'Doctor', id: 1046 },
  { name: 'Doctor', category: 'Doctor', id: 1047 },
  { name: 'Surgeon', category: 'Doctor', id: 1048 },
  { name: 'Education Professional', category: 'Education & Training', id: 1049 },
  { name: 'Educational Institution Owner', category: 'Education & Training', id: 1050 },
  { name: 'Librarian', category: 'Education & Training', id: 1051 },
  { name: 'Professor/Lecturer', category: 'Education & Training', id: 1052 },
  { name: 'Research Assistant', category: 'Education & Training', id: 1053 },
  { name: 'Teacher', category: 'Education & Training', id: 1054 },
  { name: 'Electronics Engineer', category: 'Engineering', id: 1055 },
  { name: 'Hardware/Telecom Engineer', category: 'Engineering', id: 1056 },
  { name: 'Non - IT Engineer', category: 'Engineering', id: 1057 },
  { name: 'Quality Assurance Engineer', category: 'Engineering', id: 1058 },
  { name: 'Hotels/Hospitality Professional', category: 'Hospitality', id: 1059 },
  { name: 'Law Enforcement Officer', category: 'Law Enforcement', id: 1060 },
  { name: 'Police', category: 'Law Enforcement', id: 1061 },
  { name: 'Lawyer & Legal Professional', category: 'Legal', id: 1062 },
  { name: 'Mariner', category: 'Merchant Navy', id: 1063 },
  { name: 'Merchant Naval Officer', category: 'Merchant Navy', id: 1064 },
  { name: 'Looking for job', category: 'Not working', id: 1065 },
  { name: 'Not working', category: 'Not working', id: 1066 },
  { name: 'Retired', category: 'Not working', id: 1067 },
  { name: 'Student', category: 'Not working', id: 1068 },
  { name: 'Medical/ Healthcare Professional', category: 'Other Medical & Healthcare', id: 1069 },
  { name: 'Nurse', category: 'Other Medical & Healthcare', id: 1070 },
  { name: 'Paramedic', category: 'Other Medical & Healthcare', id: 1071 },
  { name: 'Pharmacist', category: 'Other Medical & Healthcare', id: 1072 },
  { name: 'Physiotherapist', category: 'Other Medical & Healthcare', id: 1073 },
  { name: 'Psychologist', category: 'Other Medical & Healthcare', id: 1074 },
  { name: 'Veterinary doctor', category: 'Other Medical & Healthcare', id: 1075 },
  { name: 'Research Professional', category: 'Science & Research', id: 1076 },
  { name: 'Science Professional', category: 'Science & Research', id: 1077 },
  { name: 'Scientist', category: 'Science & Research', id: 1078 },
  { name: 'Animator', category: 'Software & IT', id: 1079 },
  { name: 'Cyber/Network Security', category: 'Software & IT', id: 1080 },
  { name: 'Project Lead - IT', category: 'Software & IT', id: 1081 },
  { name: 'Project Manager - IT', category: 'Software & IT', id: 1082 },
  { name: 'Quality Assurance Engineer - IT', category: 'Software & IT', id: 1083 },
  { name: 'Software Professional', category: 'Software & IT', id: 1084 },
  { name: 'UI/UX designer', category: 'Software & IT', id: 1085 },
  { name: 'Web/Graphic Designer', category: 'Software & IT', id: 1086 },
  { name: 'CxO/ Chairman/ President/ Director', category: 'Top Management', id: 1087 },
  { name: 'VP/ AVP/ GM/ DGM', category: 'Top Management', id: 1088 },
  { name: 'Agent', category: 'Others', id: 1089 },
  { name: 'Artist', category: 'Others', id: 1090 },
  { name: 'Beautician', category: 'Others', id: 1091 },
  { name: 'Broker', category: 'Others', id: 1092 },
  { name: 'Business Owner/ Entrepreneur', category: 'Others', id: 1093 },
  { name: 'Businessperson', category: 'Others', id: 1094 },
  { name: 'Fashion Designer', category: 'Others', id: 1095 },
  { name: 'Fitness Professional', category: 'Others', id: 1096 },
  { name: 'Interior Designer', category: 'Others', id: 1097 },
  { name: 'Politician', category: 'Others', id: 1098 },
  { name: 'Security Professional', category: 'Others', id: 1099 },
  { name: 'Singer', category: 'Others', id: 1100 },
  { name: 'Social Services/ NGO/ Volunteer', category: 'Others', id: 1101 },
  { name: 'Sportsperson', category: 'Others', id: 1102 },
  { name: 'Travel Professional', category: 'Others', id: 1103 },
  { name: 'Writer', category: 'Others', id: 1104 },
  { name: 'Others', category: 'Others', id: 1105 },
  { name: 'Defence', category: 'Armed Forces', id: 1106 },
  { name: 'Veterinary Doctor', category: 'Doctor', id: 1106 },
  { name: 'Private Sector', category: 'Administration', id: 1106 },
];

export const QualificationDataMap: { [key in number]: { name: string; id: QualificationId; category: string } } = QualificationData.reduce((map, data) => {
  map[data.id] = data;
  return map;
}, {});

export const ProfessionDataMap: { [key in number]: { name: string; id: ProfessionId; category: string } } = ProfessionData.reduce((map, data) => {
  map[data.id] = data;
  return map;
}, {});
