import React, { createContext, useEffect, useState } from 'react';
import * as Notifications from 'expo-notifications';
import { INotificationsContext, INotificationsProviderProps } from 'src/model/notification.model';
import { GenUtil } from 'src/util/GenUtil';

export const NotificationsContext = createContext({} as INotificationsContext);

const NotificationsContextProvider = ({ children }: INotificationsProviderProps) => {
  if (GenUtil.isWEB()) return <></>;

  const [notificationBehavior, setNotificationBehavior] =
    useState<Notifications.NotificationBehavior>({
      shouldShowAlert: true,
      shouldPlaySound: true,
      shouldSetBadge: true,
    });

  const registerForPushNotificationsAsync = async () => {
    try {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        throw new Error('Permission not granted!');
      }

      const token = (await Notifications.getExpoPushTokenAsync()).data;
      return token;
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleNotificationBehavior = (
    newNotificationBehavior: Notifications.NotificationBehavior,
  ) => {
    setNotificationBehavior({ ...newNotificationBehavior });
  };

  useEffect(() => {
    Notifications.setNotificationHandler({
      handleNotification: async () => notificationBehavior,
    });
  }, [notificationBehavior]);

  return (
    <NotificationsContext.Provider
      value={{ registerForPushNotificationsAsync, handleNotificationBehavior }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContextProvider;
