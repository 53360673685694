import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { useEffect, useState } from 'react';
import { ProfileService } from 'src/service/ProfileService';
import { ProfileResp } from 'src/common/response/profile.resp';
import LargeProfileCard from 'src/components/common/profileCard/LargeProfileCard';
import HomeSection from 'src/components/common/HomeSection';
import { ProfileTabsEnum } from 'src/screens/DynamicProfileList';

const HandPickerProfilesSection = () => {
  const [handpickedProfiles, setHandpickedProfiles] = useState<ProfileResp[]>(null);

  const fetchHandPickedProfiles = async () => {
    try {
      const response = await ProfileService.getProfiles({});

      if (response.success) {
        setHandpickedProfiles(response.data);
      } else {
        console.log(response.message, response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchHandPickedProfiles();
  }, []);

  return (
    <HomeSection
      title={'Handpicked Profiles For You'}
      navigateOnViewAll={{
        to: 'dynamicprofileslist',
        state: {
          title: 'Handpicked Profiles',
          tabs: [ProfileTabsEnum.HandPickedProfiles],
        },
      }}
    >
      {handpickedProfiles ? (
        handpickedProfiles.length > 0 ? (
          <FlatList
            data={handpickedProfiles}
            renderItem={(item) => <LargeProfileCard item={item.item} />}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            keyExtractor={(item) => item.uuid}
          />
        ) : (
          <View style={{ minHeight: 50, justifyContent: 'center' }}>
            <Text>No Profiles found</Text>
          </View>
        )
      ) : (
        <View style={{ minHeight: 50, justifyContent: 'center' }}>
          <ActivityIndicator />
        </View>
      )}
    </HomeSection>
  );
};

export default HandPickerProfilesSection;
