import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { PropsWithChildren } from 'react';
import { useNavigation } from '@react-navigation/native';
import RightArrowLong from 'src/assets/icons/rightArrowLongBlack.svg';

type Props = {
  title: string;
  navigateOnViewAll?: { to: string; state?: Record<string, any> };
} & PropsWithChildren;

export default function HomeSection({ navigateOnViewAll, title, children }: Props) {
  const navigation = useNavigation();

  const navigateToViewAll = () => {
    navigation.navigate(navigateOnViewAll.to, navigateOnViewAll.state);
  };

  return (
    <View style={styles.container}>
      <View style={styles.topBar}>
        <Text style={styles.heading}>{title}</Text>
        {navigateOnViewAll ? (
          <TouchableOpacity onPress={navigateToViewAll} style={styles.viewAll}>
            <Text style={styles.text}>View All</Text>
            <RightArrowLong />
          </TouchableOpacity>
        ) : (
          <></>
        )}
      </View>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    width: '90%',
  },
  topBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  heading: {
    alignSelf: 'flex-start',
    fontWeight: '600',
    fontSize: 17,
  },
  text: {
    fontSize: 12,
    color: '#2C2C2C',
    marginRight: 10,
  },
  viewAll: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
