/* eslint-disable @typescript-eslint/no-floating-promises */
import { View } from 'react-native';
import React, { useState, useEffect } from 'react';
import RangeSlider from 'src/components/common/Slider';
import { PreferenceLevel } from 'src/common/models/profile.model';
import CheckboxAdornment from '../atoms/CheckboxAdornment';
import { debounce } from 'lodash';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';

const IncomeRangeComp = ({
  partnerMinIncome,
  partnerMaxIncome,
  country,
  updatePartnerPref,
  updatePrivateSettings,
  privacyPrefLevel,
  strictlyFollow = true,
  setSearchParams,
}: {
  partnerMinIncome: number;
  partnerMaxIncome: number;
  country: string;
  updatePartnerPref: (_) => Promise<void>;
  updatePrivateSettings: (_) => Promise<void>;
  privacyPrefLevel: PreferenceLevel;
  strictlyFollow?: boolean;
  setSearchParams?: (prevParams) => void;
}) => {
  const [incomeRange, setIncomeRange] = useState<number[]>([partnerMinIncome, partnerMaxIncome]);
  const [privacyLevel, setPrivacyLevel] = useState(privacyPrefLevel ?? 1);

  const applyDebounce = debounce(async (incomePref: Partial<ProfileSearchBody>) => {
    if (setSearchParams) {
      console.log('setSearchParams', setSearchParams);
      setSearchParams((prevParams: Partial<ProfileSearchBody>) => ({
        ...prevParams,
        ...incomePref,
      }));
      return;
    }
    await updatePartnerPref(incomePref);
  }, 1000);

  useEffect(() => {
    const minInrOrUsd = setSearchParams
      ? country === 'India'
        ? 'minInrIncome'
        : 'minUsdIncome'
      : country === 'India'
      ? 'inrMinIncome'
      : 'usdMinIncome';
    const MaxInrOrUsd = setSearchParams
      ? country === 'India'
        ? 'maxInrIncome'
        : 'maxUsdIncome'
      : country === 'India'
      ? 'inrMaxIncome'
      : 'usdMaxIncome';

    const incomePref = {
      [minInrOrUsd]: incomeRange[0],
      [MaxInrOrUsd]:
        (country === 'India' && incomeRange[1] === 50) ||
        (country !== 'India' && incomeRange[1] === 100)
          ? 1000
          : incomeRange[1],
    };

    if (!(incomeRange[0] === partnerMinIncome && incomeRange[1] === partnerMaxIncome))
      applyDebounce(incomePref);
    return () => {
      applyDebounce.cancel();
    };
  }, [incomeRange]);

  return (
    <View>
      <RangeSlider
        defaultMinVal={partnerMinIncome ?? 0}
        defaultMaxVal={partnerMaxIncome ?? 5}
        min={0}
        max={country === 'India' ? 50 : 100}
        onValueChange={setIncomeRange}
        step={1}
        minValueText={country === 'India' ? 'INR 0 LPA.' : '$ 0 k'}
        maxValueText={country === 'India' ? 'INR 50 LPA+' : '$ 100 k+'}
      />
      <View>
        {strictlyFollow && (
          <CheckboxAdornment
            label="Strictly Follow"
            updatePrivateSettings={updatePrivateSettings}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
            prefPrivacy={{ incomePref: privacyLevel }}
          />
        )}
      </View>
    </View>
  );
};

export default IncomeRangeComp;
