import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import React from 'react';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';

const ChipWithBadge = ({ title = '', isSelected = true, onPress = () => {}, badgeValue = 0 }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={isSelected ? styles.selectedChip : styles.oulinedChip}>
        <Text style={isSelected ? styles.selecteChipText : styles.outlinedChipText}>{title}</Text>
        <View style={styles.badgeStyle}>
          <Text
            style={{
              fontFamily: EFonts.SORA_SEMIBOLD,
              fontSize: 10,
              lineHeight: 13,
              color: Color.white,
            }}
          >
            {badgeValue}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  selectedChip: {
    backgroundColor: Color.purple,
    borderRadius: 80,
    flexDirection: 'row',
    paddingVertical: 6,
    paddingHorizontal: 16,
  },
  oulinedChip: {
    borderRadius: 80,
    flexDirection: 'row',
    paddingVertical: 6,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Color.purple,
  },
  selecteChipText: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 15,
    color: Color.white,
  },
  outlinedChipText: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 15,
    color: Color.purple,
  },
  badgeStyle: {
    position: 'absolute',
    top: -6,
    right: -2,
    height: 16,
    width: 16,
    borderRadius: 16 / 2,
    backgroundColor: Color.purple,
    borderWidth: 1,
    borderColor: Color.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default ChipWithBadge;
