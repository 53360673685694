/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { View, Text } from 'src/components/Themed';
import { Icon } from 'react-native-elements';
import Modal from 'react-native-modal';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import AddSiblingsModal from './editProfileComponents/edit-profile/AddSiblingsComp';

const { height, width } = GenUtil.getDimension();

const CustomBackdrop = ({ width, height }) => (
  <View
    style={{ width: width, height: height, margin: 'auto', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
  />
);

const CustomModal = ({
  visible,
  title,
  bodyText,
  onClose,
  renderComponent,
}: {
  visible: boolean;
  title?: string;
  bodyText?: string;
  onClose?: () => void;
  renderComponent?: React.ReactNode;
}) => {
  return (
    <Modal
      customBackdrop={<CustomBackdrop width={width} height={height} />}
      isVisible={visible}
      onBackdropPress={onClose}
    >
      {renderComponent ? (
        renderComponent
      ) : (
        <View style={GenUtil.isWEB() ? styles.modalContainerWeb : styles.modalContainerMob}>
          <View style={styles.modalHeader}>
            <Text style={styles.modalHeaderText}>{title}</Text>
            <TouchableOpacity onPress={onClose}>
              <Icon name="close" type="material" />
            </TouchableOpacity>
          </View>
          <View style={styles.modalBody}>
            <Text style={styles.modalBodyText}>{bodyText}</Text>
          </View>
        </View>
      )}
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainerWeb: {
    backgroundColor: '#fff',
    borderRadius: 6,
    width: width * 0.9,
    height: height * 0.3,
    margin: 'auto',
  },
  modalContainerMob: {
    backgroundColor: '#fff',
    borderRadius: 6,
    width: width * 0.9,
    height: height * 0.3,
  },

  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderBottomWidth: 1,
    borderRadius: 6,
    borderBottomColor: Color.lightGrey,
  },
  modalHeaderText: {
    fontSize: 17,
    fontFamily: EFonts.DMSANS_BOLD,
    color: Color.purple,
  },
  modalBody: {
    padding: 10,
  },
  modalBodyText: {
    fontSize: 14,
    fontFamily: EFonts.DMSANS_REGULAR,
    color: Color.lightGrey,
  },
});

export default CustomModal;
