import React, { useState, useEffect } from 'react';
import { View } from 'src/components/Themed';
import {
  ScrollView,
  StyleSheet,
  Animated,
  Text,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import Checkbox from 'expo-checkbox';

import ProfileHeader from 'src/components/editProfileComponents/ProfileHeader';
import AboutMe from 'src/components/editProfileComponents/AboutMe';
import CustomCollapsible from 'src/components/editProfileComponents/CustomCollapsible';
import UnlockPremium from 'src/components/editProfileComponents/UnlockPremium';
import { Color } from 'src/constant/Color';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { ProfileResp } from 'src/common/response/profile.resp';
import { ReligionData } from 'src/common/data/religion.data';
import { ReligionType } from 'src/common/models/profile.model';
import { RouteProp, useRoute } from '@react-navigation/native';

const availableTabs = ['Edit Profile', 'Set Partner Preference'];

const EditProfileSections = [
  'Personal Details',
  'Family Details',
  'Astro Details',
  'Religion Beliefs',
  'Educations',
  'Career Details',
  'Lifestyle And Hobbies',
];

const PartnerPreferenceSections = [
  'About Partner',
  'Looking For',
  'Age Range',
  'Height Range',
  'Income Range',
  'Marital Status',
  'Religion',
  // 'Community',
  // 'Sub Communityw',
  'Mother Tongue',
  'Countries',
  'State',
  'City',
  'Qualification',
  'Profession',
  'Diets',
  // 'Lifestyle',
  // 'Health Status',
  // 'Mangliks',
];

type RootStackParamList = {
  EditProfile: { tabIndex?: number };
};

type EditProfileRouteProp = RouteProp<RootStackParamList, 'EditProfile'>;

export const ExapndAll = ({
  expandAll = false,
  setExpandAll = (_) => {},
  styleOverride = false,
}) => {
  return (
    <View
      style={
        styleOverride
          ? StyleSheet.compose(styles.expandAll, styles.expandAllOverride)
          : styles.expandAll
      }
    >
      <View style={{ flex: 1 }} />
      <View style={styles.expandAllWrapper}>
        <Text style={styles.expandAllText}>Expand All</Text>
        <Checkbox value={expandAll} onValueChange={() => setExpandAll(!expandAll)} />
      </View>
    </View>
  );
};

const Tabs = ({ selectedTab = '', setSelectedTab = (_) => {} }) => {
  return (
    <View
      style={
        selectedTab === availableTabs[0]
          ? StyleSheet.compose(styles.tabWrapper, styles.tabWrapperOvveride)
          : styles.tabWrapper
      }
    >
      {availableTabs.map((tab, index) => (
        <View
          key={index}
          style={
            selectedTab === tab
              ? StyleSheet.compose(styles.tabBorder, styles.tabBorderSelectedOvveride)
              : styles.tabBorder
          }
        >
          <TouchableOpacity onPress={() => setSelectedTab(tab)}>
            <Text
              style={
                selectedTab === tab
                  ? StyleSheet.compose(styles.tabText, styles.tabTextSelectedOvveride)
                  : styles.tabText
              }
            >
              {tab}
            </Text>
          </TouchableOpacity>
        </View>
      ))}
    </View>
  );
};

const EditProfile = ({ route }: { route: EditProfileRouteProp }) => {
  const tabIndex: number = route?.params?.tabIndex ?? 0;

  const [sections, setSections] = useState(EditProfileSections);
  const [partnerPreferenceSections, setPartnerPreferenceSections] =
    useState(PartnerPreferenceSections);

  const profileData: Partial<ProfileResp> = useSelector(
    (state: RootState) => state.user.userProfile,
  );
  const [activeSections, setActiveSections] = useState([]);
  const [partnerPreferenceActiveSections, setPartnerPreferenceActiveSections] = useState([]);
  const [imageVisible, setImageVisible] = useState<boolean>(false);
  const [expandAll, setExpandAll] = useState(false);
  const [expandAllPartnerPreference, setExpandAllPartnerPreference] = useState(false);
  const [selectedTab, setSelectedTab] = useState(availableTabs[tabIndex]);
  const fadeAnim = new Animated.Value(1);

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: imageVisible ? 0 : 1,
      duration: 200,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  useEffect(() => {
    const religionFilter = ReligionData?.find((item) => item?.name === profileData?.religion);

    const religionQuestionArray = religionFilter?.religiousBeliefs?.filter(
      (value) => value?.gender?.split('.')?.[1] === profileData?.gender || !value?.gender,
    );

    if (!religionQuestionArray || religionQuestionArray?.length === 0) {
      setSections(EditProfileSections?.filter((value) => value !== 'Religion Beliefs'));
    }
  }, []);

  return (
    <SafeAreaView style={[styles.container]}>
      <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
        <Animated.View style={{ opacity: fadeAnim }}>
          <ProfileHeader
            imageVisible={imageVisible}
            setImageVisible={setImageVisible}
            userProfile={profileData}
          />
          <AboutMe userProfile={profileData} />
          {!profileData?.isMembershipActive && <UnlockPremium />}
          <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          {selectedTab === 'Edit Profile' && (
            <>
              <ExapndAll expandAll={expandAll} setExpandAll={setExpandAll} />
              <CustomCollapsible
                initialData={profileData}
                sections={sections}
                activeSections={activeSections}
                onChange={setActiveSections}
                expandAll={expandAll}
              />
            </>
          )}
          {selectedTab === 'Set Partner Preference' && (
            <>
              <ExapndAll
                expandAll={expandAllPartnerPreference}
                setExpandAll={setExpandAllPartnerPreference}
                styleOverride={selectedTab === 'Set Partner Preference'}
              />
              <CustomCollapsible
                initialData={profileData}
                sections={partnerPreferenceSections}
                activeSections={partnerPreferenceActiveSections}
                onChange={setPartnerPreferenceActiveSections}
                expandAll={expandAllPartnerPreference}
              />
            </>
          )}
        </Animated.View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 20,
    backgroundColor: Color.white,
  },
  expandAllWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
  expandAll: {
    borderTopWidth: 1,
    marginHorizontal: 10,
    borderTopColor: Color.lightBorder,
    flexDirection: 'row',
  },
  expandAllOverride: {
    borderTopWidth: 0,
  },
  expandAllText: {
    fontFamily: 'sora-light',
    fontSize: 13,
    color: Color.lightGrey,
    marginRight: 10,
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginHorizontal: 10,
    marginTop: 10,
    borderBottomWidth: 1,
    borderBottomColor: Color.lightBorder,
  },
  tabWrapperOvveride: {
    borderBottomWidth: 0,
  },
  tabText: {
    fontFamily: 'sora-regular',
    color: Color.gray,
    marginBottom: 5,
    fontSize: 15,
  },
  tabTextSelectedOvveride: {
    fontFamily: 'sora-bold',
    color: Color.purple,
  },
  tabBorder: {
    borderBottomColor: 'transparent',
    borderBottomWidth: 0,
    flex: 1,
  },
  tabBorderSelectedOvveride: {
    borderBottomColor: Color.purple,
    borderBottomWidth: 1,
  },
});
export default EditProfile;
