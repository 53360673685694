/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { SearchLocationResp } from 'src/common/response/location.resp';
import { CommonService } from 'src/service/CommonService';

export const useAutoCompleteLocation = (
  delay: number,
  defaultData: Partial<SearchLocationResp>[] = [],
): [Partial<SearchLocationResp>[], string, (val: string) => void] => {
  const [fetchedData, setFetchedData] = useState<Partial<SearchLocationResp>[]>(defaultData);
  const [input, setInput] = useState<string>('');

  const handleChange = (val: string) => {
    setInput(val);
    if (val.trim() === '') setFetchedData(defaultData);
  };

  const fetchLocation = async () => {
    const response = await CommonService.getLocationAutocomplete(input);
    const data: Partial<SearchLocationResp>[] = response?.data;
    setFetchedData(data);
  };

  const applyDebounce = debounce(fetchLocation, delay);

  useEffect(() => {
    if (input.trim()) applyDebounce();
    return () => {
      applyDebounce.cancel();
    };
  }, [input]);

  return [fetchedData, input, handleChange];
};
