import { ActivityIndicator, ScrollView, StyleSheet, Text, View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../types';
import { useEffect, useState } from 'react';
import Button from 'src/components/common/buttons/Button';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import ProfilesRecyclerList, { ProfileComponentEnum } from 'src/components/ProfilesRecyclerList';
import { ProfileService } from 'src/service/ProfileService';
import { InterestService } from 'src/service/InterestService';
import { InterestsStatus, SentType } from 'src/common/models/interest.model';
import { RecommendedType } from 'src/common/models/profile.model';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import SafeContainer from 'src/components/SafeContainer';
import { useIsFocused } from '@react-navigation/native';

type Props = NativeStackScreenProps<RootStackParamList, 'dynamicprofileslist'>;

export enum ProfileTabsEnum {
  SimilarProfiles = 'Similar Profiles',
  Nearyou = 'Near You',
  RecentlyJoined = 'Recently Joined',
  InterestReceived = 'Interest Received',
  InterestSent = 'Interest Sent',
  ShortlistedProfiles = 'Shortlisted Profiles',
  HandPickedProfiles = 'Handpicked profiles',
  ProfileVisitors = 'Profile Visitors',
  ProfileViewed = 'Profile Viewed',
  InterestExpiring = 'Interest Expiring',
  InterestFiltered = 'Interest Filtered',
  InterestArchived = 'Interest Archived',
  YouAccepted = 'You Accepted',
  TheyAccepted = 'They Accepted',
  YouDeclined = 'You Declined',
  TheyDeclined = 'They Declined',
}

const REQUEST_BY_TABS = {
  [ProfileTabsEnum.SimilarProfiles]: async (uuid: string) =>
    await ProfileService.fetchSimilarProfile(uuid),
  [ProfileTabsEnum.InterestReceived]: async () =>
    await InterestService.fetchInterests({
      sentType: SentType.Received,
      status: InterestsStatus.Pending,
    }),
  [ProfileTabsEnum.InterestSent]: async () =>
    await InterestService.fetchInterests({
      sentType: SentType.Sent,
      status: InterestsStatus.Pending,
    }),
  [ProfileTabsEnum.YouAccepted]: async () =>
    await InterestService.fetchInterests({
      sentType: SentType.Received,
      status: InterestsStatus.Accepted,
    }),
  [ProfileTabsEnum.TheyAccepted]: async () =>
    await InterestService.fetchInterests({
      sentType: SentType.Sent,
      status: InterestsStatus.Accepted,
    }),
  [ProfileTabsEnum.YouDeclined]: async () =>
    await InterestService.fetchInterests({
      sentType: SentType.Received,
      status: InterestsStatus.Declined,
    }),
  [ProfileTabsEnum.TheyDeclined]: async () =>
    await InterestService.fetchInterests({
      sentType: SentType.Sent,
      status: InterestsStatus.Declined,
    }),
  [ProfileTabsEnum.InterestFiltered]: async () =>
    await InterestService.fetchInterests({
      sentType: SentType.Received,
      status: InterestsStatus.Filtered,
    }),
  [ProfileTabsEnum.InterestArchived]: async () =>
    await InterestService.fetchInterests({
      sentType: SentType.Received,
      status: InterestsStatus.Archived,
    }),
  [ProfileTabsEnum.Nearyou]: async () =>
    // TODO: pass correct lat & lng of user
    await ProfileService.fetchRecommendedProfiles({
      recommendedType: RecommendedType.Nearby,
      lat: '28.6139',
      lng: '77.2090',
    }),
  [ProfileTabsEnum.RecentlyJoined]: async () =>
    await ProfileService.fetchRecommendedProfiles({
      recommendedType: RecommendedType.RecentlyJoined,
    }),
  [ProfileTabsEnum.ShortlistedProfiles]: async () => await ProfileService.getShortlistedProfiles(),
  // TODO: add correct api request for Handpicked profiles
  [ProfileTabsEnum.HandPickedProfiles]: async () => await ProfileService.getProfiles({}),
  [ProfileTabsEnum.ProfileVisitors]: async () => await ProfileService.getProfileViewers(),
  [ProfileTabsEnum.ProfileViewed]: async () => await ProfileService.getProfileViewed(),
  // TODO: add correct api request for Interest expiring profiles
  [ProfileTabsEnum.InterestExpiring]: async () => await ProfileService.getProfiles({}),
};

const { height, width } = GenUtil.getDimension();

export default function DynamicProfilesList({ route }: Props) {
  const { title, tabs } = route.params as {
    title: string;
    tabs: ProfileTabsEnum | ProfileTabsEnum[];
  };
  const isFocused = useIsFocused();
  const [pageTitle, setPageTitle] = useState(title);
  const pageTabs = [...(Array.isArray(tabs) ? tabs : tabs.split(','))];
  const [selectedTab, setSelectedTab] = useState<ProfileTabsEnum>(pageTabs[0] as ProfileTabsEnum);
  const [listData, setListData] = useState<ProfileResp[]>([]);
  const { uuid: profileUuid } = useSelector((state: RootState) => state.user.userProfile);

  const fetchProfiles = async () => {
    try {
      setListData(null);
      const fetchRequest = REQUEST_BY_TABS[selectedTab];
      const response = await fetchRequest(profileUuid);
      if (response.success) {
        setListData(response.data as ProfileResp[]);
      } else {
        console.log(response.message, response);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchProfiles().catch((err) => {
      console.error(err);
    });
    setPageTitle(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (isFocused && pageTabs[0] !== selectedTab) {
      setSelectedTab(pageTabs[0] as ProfileTabsEnum);
    }
  }, [isFocused]);

  return (
    <SafeContainer showPageHeader={true} heading={pageTitle} style={{ flex: 1 }}>
      {pageTabs?.length > 1 && (
        <View style={{ marginTop: -20, zIndex: -20 }}>
          <ScrollView horizontal={true} style={styles.containerStyle}>
            {pageTabs.map((tab) => {
              const isSelected = tab === selectedTab;
              return (
                <Button
                  onPress={() => setSelectedTab(tab as ProfileTabsEnum)}
                  key={tab}
                  variant={isSelected ? 'primary' : 'secondary'}
                  styles={{ marginLeft: 10 }}
                  showOpacity={false}
                >
                  {tab}
                </Button>
              );
            })}
          </ScrollView>
        </View>
      )}
      <View style={{ flex: 1, width: '100%' }}>
        {listData ? (
          listData?.length > 0 ? (
            <ProfilesRecyclerList
              data={listData}
              componentType={ProfileComponentEnum.HORIZONTAL_PROFILE_CARD}
            />
          ) : (
            <Text style={{ marginVertical: 15, marginHorizontal: 15 }}>No Profiles</Text>
          )
        ) : (
          <>
            <ActivityIndicator />
          </>
        )}
      </View>
    </SafeContainer>
  );
}

const styles = StyleSheet.create({
  containerStyle: {
    maxHeight: height * 0.8,
    maxWidth: width,
    marginRight: 0,
    backgroundColor: '#FAF5FF',
    paddingBottom: 10,
    paddingTop: 30,
  },
});
