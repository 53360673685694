export enum ProfileMediaStatus {
  Active = 'active',
  UnderScreening = 'under-screening',
  Hidden = 'hidden',
}

export enum ProfileMediaCategory {
  Cover = 'cover',
  Dp = 'dp',
  Normal = 'normal',
}

export enum ProfileMediaType {
  Photo = 'photo',
  Video = 'video',
}

export interface PhotoURLData {
  mainUrl: string;
  thumbnailUrl: string;
  profilePicUrl: string;
  profilePicThumbUrl: string;
  blurredUrl: string;
}
