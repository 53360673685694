import { StyleSheet, TouchableOpacity, Image } from 'react-native';
import { Text, View } from 'src/components/Themed';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import EFonts from 'src/constant/EFonts';

export default function PreferenceSeletedChip({
  preprocessSelectedItems = null,
  selectedValues = [],
  setSelectedValues = (_) => {},
  onPressRemove = null,
}) {
  const deleteChipHandler = async (item) => {
    const temp = [...selectedValues];
    temp.splice(
      (preprocessSelectedItems ? temp.map((i) => preprocessSelectedItems(i)) : temp).indexOf(
        preprocessSelectedItems ? preprocessSelectedItems(item) : item,
      ),
      1,
    );
    onPressRemove && (await onPressRemove(temp));
    setSelectedValues(temp);
  };
  return (
    <View style={styles.chipsContainer}>
      {selectedValues.map((res, index) => {
        return (
          <View key={index} style={styles.preferenceChip}>
            <Text style={styles.chipText}>
              {preprocessSelectedItems ? preprocessSelectedItems(res) : res}
            </Text>
            <TouchableOpacity onPress={() => deleteChipHandler(res)}>
              <Image
                source={require('src/assets/icons/cancel.png')}
                style={{ height: 10, width: 10, marginLeft: 12, marginRight: 4 }}
              />
            </TouchableOpacity>
          </View>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  preferenceChip: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: GenUtil.isIOS() ? 15 : 20,
    minWidth: 50,
    textAlign: 'center',
    marginVertical: 5,
    marginHorizontal: 8,
    padding: 8,
    color: Color.purple,
    backgroundColor: '#EEEEEE',
  },
  chipText: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 20,
    color: Color.lightBlack,
  },
  chipsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});
