import * as Device from 'expo-device';

export class DeviceInfoUtil {
  static getOS() {
    return Device.osName;
  }

  static getSystemVersion() {
    // return DeviceInfo.getSystemVersion();
  }

  static async getAPILevel() {
    // const apiLevel = await DeviceInfo.getApiLevel();
    // return apiLevel;
  }

  static getVersion() {
    // return DeviceInfo.getVersion();
  }

  static getBuildNumber() {
    // return DeviceInfo.getBuildNumber();
  }

  static getAndroidVersion() {
    // return DeviceInfo.getSystemVersion();
  }
}
