export const AgeData = [
  20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 32, 33, 34, 35, 36, 37, 38, 39, 40,
];

const IncomeData: number[] = [];
for (let i = 0; i < 1000; i++) {
  IncomeData.push(i);
}
const IncomeDataDollar: number[] = [];
for (let i = 10000; i < 10000000; i += 1000) {
  IncomeDataDollar.push(i);
}
const HeightData = [];
for (let i = 140; i < 200; i++) {
  HeightData.push(i);
}

export { IncomeData, IncomeDataDollar, HeightData };
