import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import BottomSheet from '../common/BottomSheet';
import { Color } from 'src/constant/Color';
import { ProfileResp } from 'src/common/response/profile.resp';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { hideModal } from 'src/store/reducer/uiReducer';
import { useProfileActionItems, ActionT, ActionItems } from 'src/hooks/useProfileActionItems';
import { ACTIONS_ICONS } from '../common/profileCard/actions/InterestAction';
import { useState } from 'react';
import SendInterest from './SendInterest';
import { InterestsStatus, SentType } from 'src/common/models/interest.model';

export const ProfileActionBottomSheet = () => {
  const [showSendInterestModal, setShowSendInterestModal] = useState(false);
  const { profile } = useSelector(
    (state: RootState) => state.ui.modal.childData as { profile: ProfileResp },
  );
  const { actionItems: actions, setInterestState } = useProfileActionItems(profile);

  const dispatch = useDispatch();

  const onDismiss = () => {
    dispatch(hideModal());
  };

  return (
    <BottomSheet snapPoints={['35%']} onDismiss={onDismiss} webModalHeight={150}>
      <View style={styles.container}>
        {Array.isArray(actions) ? (
          actions.map((action) => {
            return <ActionItem action={action} key={action.label} />;
          })
        ) : (
          <>
            <ActionItem action={actions} showInterestModal={() => setShowSendInterestModal(true)} />
            {actions.type === ActionItems.SendInterest && showSendInterestModal ? (
              <SendInterest
                profile={profile}
                onFinish={() =>
                  setInterestState({ sentType: SentType.Sent, status: InterestsStatus.Pending })
                }
                closeModal={() => setShowSendInterestModal(false)}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </View>
    </BottomSheet>
  );
};

const ActionItem = ({
  action,
  showInterestModal,
}: {
  action: ActionT;
  showInterestModal?: () => void;
}) => {
  const icon = ACTIONS_ICONS[action.type];
  const onClickHandler =
    action.type === ActionItems.SendInterest ? showInterestModal : action.onClick;
  return (
    <TouchableOpacity
      style={[styles.itemContainer, action.disabled ? styles.itemContainerDisabled : {}]}
      onPress={onClickHandler}
      disabled={action.disabled}
    >
      <View style={styles.leftIcon}>{icon && icon}</View>
      <Text style={styles.actionText}>{action.label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 15,
  },

  itemContainer: {
    borderRadius: 8,
    backgroundColor: Color.lightGrey,
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  itemContainerDisabled: {
    backgroundColor: '#ccc',
  },
  leftIcon: {
    position: 'absolute',
    left: 15,
    top: 20,
  },
  rightIcon: {
    position: 'absolute',
    right: 15,
    top: 20,
  },
  actionText: {
    fontSize: 16,
    color: Color.white,
  },
});
