import { View, Text } from 'src/components/Themed';
import React from 'react';
import { StyleSheet, TouchableOpacity, Image, ImageSourcePropType, ScrollView } from 'react-native';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import EFonts from 'src/constant/EFonts';

const { height, width } = GenUtil.getDimension();

interface selectorInterface {
  options: { key: string; value: string }[];
  selected: string[];
  setSelected?: (_) => void;
  multiSelect?: boolean;
  unselectedIcon?: ImageSourcePropType;
  selectedIcon?: ImageSourcePropType;
  iconDimension?: { width: number; height: number };
  chipClicked?: (_) => void;
  callChipClicked?: boolean;
  showTickIcon?: boolean;
  additionalChipStyle?: object;
  hideExtra: boolean;
}
const PillShapeChip = ({
  options = [],
  selected = [],
  setSelected = (_) => {},
  multiSelect = false,
  unselectedIcon,
  selectedIcon,
  iconDimension,
  chipClicked = (_) => {},
  callChipClicked = false,
  showTickIcon = true,
  additionalChipStyle = {},
  hideExtra = false,
}: selectorInterface) => {
  const handleOnPress = (key: string) => {
    if (multiSelect) {
      const alreadySelectedIndex = selected.indexOf(key);
      if (alreadySelectedIndex !== -1) {
        const updatedSelected = [...selected];
        updatedSelected.splice(alreadySelectedIndex, 1);
        setSelected(updatedSelected);
      } else {
        setSelected([...selected, key]);
      }
    } else {
      if (callChipClicked) {
        chipClicked(key);
      }
      if (selected.includes(key)) return;
      setSelected([key]);
    }
  };

  if (options?.length > 0)
    return (
      <View style={styles.container}>
        <ScrollView
          contentContainerStyle={styles.optionsWrapper}
          showsVerticalScrollIndicator={false}
          style={
            hideExtra
              ? StyleSheet.compose(styles.scrollView, styles.scrollViewExtraOverride)
              : styles.scrollView
          }
          horizontal={hideExtra}
        >
          {options.map(({ key, value }) => {
            const isSelected = selected.includes(key);
            return (
              <TouchableOpacity
                key={key}
                onPress={() => handleOnPress(key)}
                style={
                  isSelected
                    ? StyleSheet.compose(
                        { ...styles.optionPill, ...additionalChipStyle },
                        styles.selectedOptionPillOverride,
                      )
                    : styles.optionPill
                }
              >
                <View style={styles.chipWrapper}>
                  {(selectedIcon || unselectedIcon) && (
                    <Image
                      source={selected.includes(key) ? selectedIcon : unselectedIcon}
                      style={[styles.chipIcon, { ...iconDimension }]}
                    />
                  )}
                  <Text
                    style={
                      isSelected
                        ? StyleSheet.compose(styles.optionText, styles.selectedOptionTextOverride)
                        : styles.optionText
                    }
                  >
                    {value}
                  </Text>
                  {selected.includes(key) && showTickIcon && (
                    <Image source={require('src/assets/icons/tick.png')} style={styles.tickIcon} />
                  )}
                </View>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </View>
    );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 10,
    backgroundColor: 'transparent',
  },
  scrollView: {
    maxHeight: height * 0.6,
    maxWidth: width,
    marginRight: 0,
  },
  scrollViewExtraOverride: {
    maxWidth: width * 0.5,
    marginRight: 10,
  },
  chipWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  chipIcon: {
    marginRight: 10,
  },
  optionsWrapper: {
    marginVertical: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  tickIcon: {
    width: 24,
    height: 24,
    marginLeft: 10,
  },
  optionPill: {
    minWidth: 70,
    paddingVertical: 10,
    paddingHorizontal: 10,
    marginHorizontal: 5,
    borderRadius: 80,
    borderWidth: 1,
    borderColor: Color.lightGrey,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.white,
    marginBottom: 15,
    flexDirection: 'row',
  },
  optionText: {
    lineHeight: 20,
    fontSize: 14,
    color: Color.lightGrey,
    fontFamily: EFonts.SORA_REGULAR,
    maxWidth: GenUtil.getDimension().width * 0.6,
  },
  selectedOptionPillOverride: {
    borderColor: '#8041C6',
  },
  selectedOptionTextOverride: {
    color: '#8041C6',
  },
});

export default PillShapeChip;
