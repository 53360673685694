import React, { useState } from 'react';
import { Switch } from 'react-native-paper';

const PrimarySwitch = ({
  color,
  enabled = false,
  onPress,
}: {
  color: string;
  enabled: boolean;
  onPress: () => void;
}) => {
  const [isSwitchOn, setIsSwitchOn] = useState(enabled);

  const onToggleSwitch = () => {
    if (onPress) onPress();
    setIsSwitchOn(!isSwitchOn);
  };

  return (
    <Switch
      value={isSwitchOn}
      onValueChange={onToggleSwitch}
      style={{ transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }}
      color={color}
    />
  );
};

export default PrimarySwitch;
