import React, { PropsWithChildren } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import { Text } from 'src/components/Themed';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';

const { width } = GenUtil.getDimension();

type ButtonVariant = 'primary' | 'secondary' | 'tertiary';
type ButtonSize = 'small' | 'medium' | 'large';

type ButtonProps = {
  buttonDisabled?: boolean;
  onPress?: () => void;
  isLoading?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  styles?: ViewStyle;
  textStyles?: TextStyle;
  beforeIcon?: React.ReactElement;
  afterIcon?: React.ReactElement;
  showOpacity?: boolean;
} & PropsWithChildren;

const Button = ({
  buttonDisabled = false,
  isLoading = false,
  variant = 'primary',
  size = 'small',
  onPress,
  children = 'Button',
  styles: overrideStyles,
  textStyles,
  beforeIcon,
  afterIcon,
  showOpacity = true,
}: ButtonProps) => {
  const buttonStyles = getVariantStyles(variant);
  const buttonSizeStyles = getSizeStyles(size);

  return (
    <TouchableOpacity
      disabled={buttonDisabled}
      style={[
        styles.button,
        buttonStyles.button,
        buttonSizeStyles.button,
        buttonDisabled ? styles.buttonDisabledOverride : {},
        overrideStyles,
      ]}
      onPress={onPress}
      activeOpacity={showOpacity ? 0 : 100}
    >
      {isLoading ? (
        <ActivityIndicator size={'small'} color={Color.white} />
      ) : (
        <View style={styles.container}>
          {beforeIcon ? beforeIcon : <></>}
          <Text style={[buttonStyles.text, buttonSizeStyles.text, textStyles]}>{children}</Text>
          {afterIcon ? afterIcon : <></>}
        </View>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 24,
  },
  buttonDisabledOverride: {
    backgroundColor: Color.gray,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const getVariantStyles = (variant: ButtonVariant): { button: ViewStyle; text: TextStyle } => {
  switch (variant) {
    case 'primary':
      return {
        button: {
          borderWidth: 1,
          borderColor: 'transparent',
          backgroundColor: Color.purple,
        },
        text: {
          color: Color.white,
        },
      };

    case 'secondary':
      return {
        button: {
          borderWidth: 1,
          borderColor: Color.purple,
        },
        text: {
          color: Color.purple,
        },
      };

    case 'tertiary':
      return {
        button: {
          backgroundColor: Color.backgroundGray,
        },
        text: {
          color: Color.white,
        },
      };
  }
};

const getSizeStyles = (size: ButtonSize): { button: ViewStyle; text: TextStyle } => {
  switch (size) {
    case 'large':
      return {
        button: {
          width: width * 0.8,
          paddingVertical: 15,
        },
        text: {
          fontSize: 17,
          fontFamily: 'dmsans-bold',
        },
      };

    case 'medium':
      return {
        button: {
          width: width * 0.45,
          paddingVertical: 12,
        },
        text: {
          fontSize: 15,
        },
      };

    case 'small':
      return {
        button: {
          paddingVertical: 10,
          paddingHorizontal: 15,
        },
        text: {
          fontSize: 12,
        },
      };
  }
};

export default Button;
