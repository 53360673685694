import { View, Text, GestureResponderEvent } from 'react-native';
import React from 'react';
import { Button } from 'react-native-paper';
import { Color } from 'src/constant/Color';

const FilledButton = ({
  title = 'Search',
  textStyle,
  style,
  onPress,
}: {
  title: string;
  textStyle: object;
  style: object;
  onPress: (e: GestureResponderEvent) => void;
}) => {
  return (
    <Button mode="contained" labelStyle={textStyle} style={style} onPress={onPress}>
      {title}
    </Button>
  );
};

export default FilledButton;
