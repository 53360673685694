import { View, Text, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';
import { Icon } from 'react-native-elements';

import PillShapeChip from 'src/components/common/chips/PillShapeChip';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import InputAdornment from 'src/components/common/input/InputAdornment';
import { QualificationData } from 'src/common/data/education.data';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';

const { width } = GenUtil.getDimension();

const OnboardingDegreeSelectionComp = ({
  degree = null,
  setDegree = (_) => {},
  signUp = () => {},
  signUpValidating = false,
}) => {
  const [searchDegree, setSearchDegree] = useState<string>(degree?.name ?? '');
  const allDegreesNames = QualificationData.map((d) => d.name);
  const [filteredDegrees, setFilteredDegrees] = useState([]);
  const [degreeSelected, setDegreeSelected] = useState(degree?.name ? [degree.name] : []);

  useEffect(() => {
    if (allDegreesNames.length > 0) {
      let finalFiltered = [];
      if (searchDegree.trim()) {
        const allFitered = allDegreesNames.filter((d) =>
          d.toLowerCase().includes(searchDegree.toLowerCase()),
        );
        finalFiltered = allFitered.slice(0, 6);
      } else {
        finalFiltered = allDegreesNames
          .filter((d) => {
            const filter = d.toLowerCase();
            return filter.includes('b.') || filter.includes('m.');
          })
          .slice(0, 6);
      }
      setFilteredDegrees(
        finalFiltered.map((f) => {
          return { key: f, value: f };
        }),
      );
    }
  }, [searchDegree]);

  useEffect(() => {
    if (degreeSelected.length > 0) {
      setSearchDegree(degreeSelected[0]);
      setDegree(QualificationData.find((q) => q.name === degreeSelected[0]));
    }
  }, [degreeSelected]);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Degree</Text>
      <InputAdornment
        icon={<Icon name="ribbon-outline" type="ionicon" size={20} color={Color.gray} />}
        value={searchDegree}
        setValue={setSearchDegree}
        placeholder={'Search here...'}
        styleOverride
      />
      <PillShapeChip
        options={filteredDegrees}
        selected={degreeSelected}
        setSelected={setDegreeSelected}
        unselectedIcon={require('src/assets/icons/degree_light.png')}
        selectedIcon={require('src/assets/icons/degree_dark.png')}
        iconDimension={{ width: 24, height: 24 }}
      />
      <View style={styles.buttonWrapper}>
        <PrimaryButton
          buttonDisabled={degreeSelected.length === 0}
          buttonText="Confirm"
          isValidating={signUpValidating}
          handleKeyPress={signUp}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontFamily: EFonts.SORA_LIGHT,
    fontSize: 28,
    lineHeight: 38,
    color: Color.lightBlack,
    marginTop: 10,
  },
  container: {
    paddingTop: 20,
    flex: 1,
    paddingHorizontal: width * 0.05,
    backgroundColor: 'transparent',
  },
  back: {
    justifyContent: 'flex-start',
  },
  buttonWrapper: {
    position: 'absolute',
    bottom: 35,
    left: width * 0.1,
  },
});

export default OnboardingDegreeSelectionComp;
