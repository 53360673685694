import { View, Text } from 'src/components/Themed';
import { StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';
import ChoicesSelector from 'src/components/editProfileComponents/ChoicesSelector';
import { ReligionData } from 'src/common/data/religion.data';
import { ReligionType } from 'src/common/models/profile.model';
import SaveButton from '../SaveButton';
import { GenUtil } from 'src/util/GenUtil';
import { PartnerPrefBody, ProfileSearchBody } from 'src/common/dto/profile.dto';
import EFonts from 'src/constant/EFonts';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';

interface CommunityData {
  name: string;
  id: number;
  religionId: number;
  order: number;
}
interface SubCommunityData {
  name: string;
  id: number;
  religionId: number;
  casteId: number;
  order: number;
}

export default function ReligionSelectionComp({
  partnerReligions = [],
  updatePartnerPref,
  partnerCommunities = [],
  partnerSubCommunities = [],
  setSearchParams,
}: {
  partnerReligions: ReligionType[];
  updatePartnerPref: (_) => Promise<void>;
  partnerCommunities: number[];
  partnerSubCommunities: number[];
  setSearchParams?: (prevParams) => void;
}) {
  const religionData = [DOESNT_MATTER_OPTION.name, ...ReligionData.map((res) => res.name)];
  const [communityData, setCommunityData] = useState([] as CommunityData[]);
  const [subCommunityData, setSubCommunityData] = useState([] as SubCommunityData[]);
  const [selectedReligions, setSelectedReligions] = useState(partnerReligions.length ? partnerReligions : [DOESNT_MATTER_OPTION.name]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [selectedCommunitiesIds, setSelectedCommunitiesIds] = useState(partnerCommunities ?? []);
  const [selectedSubCommunities, setSelectedSubCommunities] = useState([]);
  const [selectedSubCommunitiesIds, setSelectedSubCommunitiesIds] = useState(
    partnerSubCommunities ?? [],
  );
  const [isValidating, setIsValidating] = useState(false);
  const [initializingSelectedCommunities, setInitializingSelectedCommunities] = useState(true);
  const [initializingSelectedSubCommunities, setInitializingSelectedSubCommunities] =
    useState(true);

  useEffect(() => {
    if (setSearchParams) {
      setSearchParams((prevParams: Partial<ProfileSearchBody>) => ({
        ...prevParams,
        ...{ religions: selectedReligions[0] === DOESNT_MATTER_OPTION.name ? [] : selectedReligions },
      }));
    }

    let tempComm = [] as CommunityData[];
    ReligionData.map((res) => {
      if (selectedReligions?.includes(res?.name) && !GenUtil.isEmpty(res?.communityMap))
        tempComm = [...tempComm, ...Object.values(res.communityMap)];
    });
    setCommunityData(tempComm);

    if (initializingSelectedCommunities) {
      const tempSelectedComm = [];
      tempComm.map((community) => {
        if (partnerCommunities?.includes(community.id)) tempSelectedComm.push(community.name);
      });
      setSelectedCommunities(tempSelectedComm);
      setInitializingSelectedCommunities(false);
    }
  }, [selectedReligions]);

  useEffect(() => {
    const tempCommIds: number[] = [];
    if (selectedCommunities.length) {
      communityData.map((res) => {
        if (selectedCommunities.includes(res.name)) tempCommIds.push(res.id);
      });
    }
    setSelectedCommunitiesIds(tempCommIds);

    let tempSubComm = [] as SubCommunityData[];
    ReligionData.map((res) => {
      if (selectedReligions?.includes(res.name) && !GenUtil.isEmpty(res.subCommunityMap))
        tempSubComm = [
          ...tempSubComm,
          ...Object.values(res.subCommunityMap).filter((value) =>
            tempCommIds.includes(value.casteId),
          ),
        ];
    });
    setSubCommunityData(tempSubComm);

    if (initializingSelectedSubCommunities) {
      const tempSelectedSubComm = [];
      tempSubComm.map((subCommunity) => {
        if (partnerSubCommunities?.includes(subCommunity.id))
          tempSelectedSubComm.push(subCommunity.name);
      });
      setSelectedSubCommunities(tempSelectedSubComm);
      setInitializingSelectedSubCommunities(false);
    }
  }, [selectedCommunities]);

  useEffect(() => {
    const tempSubCommIds: number[] = [];
    if (selectedSubCommunities.length) {
      subCommunityData.map((res) => {
        if (selectedSubCommunities.includes(res.name)) tempSubCommIds.push(res.id);
      });
    }
    setSelectedSubCommunitiesIds(tempSubCommIds);
  }, [selectedSubCommunities]);

  const handleSave = async () => {
    setIsValidating(true);
    const religionPref: Partial<PartnerPrefBody> = {
      religions: selectedReligions[0] === DOESNT_MATTER_OPTION.name ? [] : selectedReligions,
      communityIds: selectedCommunitiesIds,
      subCommunityIds: selectedSubCommunitiesIds,
    };
    setIsValidating(false);
    await updatePartnerPref(religionPref);
  };

  return (
    <View style={styles.container}>
      <ChoicesSelector
        dropdownItems={religionData}
        placeHolder="Select religions"
        selectedValues={selectedReligions}
        setSelectedValues={setSelectedReligions}
      />

      {!!communityData.length && !setSearchParams && (
        <View>
          <Text style={styles.title}>Community</Text>
          <ChoicesSelector
            dropdownItems={communityData.map((res) => res.name)}
            placeHolder="Select communities"
            selectedValues={selectedCommunities}
            setSelectedValues={setSelectedCommunities}
          />
        </View>
      )}

      {!!subCommunityData.length && !setSearchParams && (
        <View>
          <Text style={styles.title}>Sub Community</Text>
          <ChoicesSelector
            dropdownItems={subCommunityData.map((res) => res.name)}
            placeHolder="Select sub communities"
            selectedValues={selectedSubCommunities}
            setSelectedValues={setSelectedSubCommunities}
          />
        </View>
      )}

      {!setSearchParams && (
        <View style={styles.saveButtonWrapper}>
          <SaveButton buttonText="Save" handleKeyPress={handleSave} isValidating={isValidating} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    bottom: 20,
  },
  saveButtonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 15,
    fontFamily: EFonts.SORA_SEMIBOLD,
    marginLeft: 5,
    marginTop: 15,
  },
});
