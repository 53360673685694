/**
 * User who would login to the system,
 * can be a user who created profile, or his parents to view profiles
 */
export enum UserType {
  ProfileHandler = 'profile_handler',
  ProfileViewer = 'profile_viewer',
}

export enum UserRelation {
  Father = 'Father',
  Mother = 'Mother',
  Brother = 'Brother',
  Sister = 'Sister',
  Self = 'Self',
  Uncle = 'Uncle',
  Aunt = 'Aunt',
  Friend = 'Friend',
  Relative = 'Relative', //
  Other = 'Other',
  MarriageBureau = 'Marriage Bureau',
}
