import { View, Text, StyleSheet } from 'react-native';
import React, { useState, useEffect } from 'react';
import { Icon } from 'react-native-elements';

import PillShapeChip from 'src/components/common/chips/PillShapeChip';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import InputAdornment from 'src/components/common/input/InputAdornment';
import { ProfessionData } from 'src/common/data/education.data';

const { width } = GenUtil.getDimension();

const Profession = ({
  next = () => {},
  prev = () => {},
  profession = null,
  setProfession = (_) => {},
}) => {
  const [searchProfession, setSearchProfession] = useState<string>(profession?.name ?? '');
  const allProfessionNames = ProfessionData.map((d) => d.name);
  const [filteredProfession, setFilteredProfession] = useState([]);
  const [professionSelected, setProfessionSelected] = useState(
    profession?.name ? [profession.name] : [],
  );

  useEffect(() => {
    if (allProfessionNames.length > 0) {
      let finalFiltered = [];
      if (searchProfession.trim()) {
        const allFiltered = allProfessionNames.filter((d) =>
          d.toLowerCase().includes(searchProfession.toLowerCase()),
        );
        finalFiltered = allFiltered.slice(0, 6);
      } else {
        finalFiltered = allProfessionNames
          .filter((d) => {
            const filter = d.toLowerCase();
            return filter.includes('d') || filter.includes('e');
          })
          .slice(0, 6);
      }
      setFilteredProfession(
        finalFiltered.map((f) => {
          return { key: f, value: f };
        }),
      );
    }
  }, [searchProfession]);

  useEffect(() => {
    if (professionSelected.length > 0) {
      setSearchProfession(professionSelected[0]);
      setProfession(ProfessionData.find((q) => q.name === professionSelected[0]));
    }
  }, [professionSelected]);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Profession</Text>
      <InputAdornment
        icon={<Icon name="user-md" type="font-awesome-5" size={20} color={Color.gray} />}
        value={searchProfession}
        setValue={setSearchProfession}
        placeholder={'Enter Your Profession'}
        styleOverride
      />
      <PillShapeChip
        options={filteredProfession}
        selected={professionSelected}
        setSelected={setProfessionSelected}
        unselectedIcon={require('src/assets/icons/FrameprofessionLight.png')}
        selectedIcon={require('src/assets/icons/FrameprofessionDark.png')}
        iconDimension={{ width: 21, height: 24 }}
        callChipClicked
        chipClicked={() => {
          setTimeout(() => next(), 500);
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontFamily: EFonts.SORA_LIGHT,
    fontSize: 28,
    lineHeight: 38,
    color: Color.lightBlack,
    marginTop: 10,
  },
  container: {
    paddingTop: 20,
    flex: GenUtil.isWEB() ? null : 1,
    paddingHorizontal: width * 0.05,
    backgroundColor: 'transparent',
  },
  back: {
    justifyContent: 'flex-start',
  },
});

export default Profession;
