import { View, Text, Image, StyleSheet } from 'react-native';
import React from 'react';
import { Color } from 'src/constant/Color';

interface PhotoCollageProps {
  size: number;
  smallCircleSize: number;
  iconSize: number;
}

const PhotoCollage = ({
  size = 68,
  smallCircleSize = 42,
  icon,
  profilePhoto = '',
  gender = 'Male',
  otherUserGender = 'Female',
  otherUserProfilePhoto = '',
}) => {
  const ownImageProps = !profilePhoto
    ? gender === 'Male'
      ? {
          source: require('src/assets/images/placeholder-male.png'),
        }
      : {
          source: require('src/assets/images/placeholder-female.png'),
        }
    : {
        source: {
          uri: profilePhoto,
        },
      };

  const otherUserImageProps = !otherUserProfilePhoto
    ? otherUserGender === 'Male'
      ? {
          source: require('src/assets/images/placeholder-male.png'),
        }
      : {
          source: require('src/assets/images/placeholder-female.png'),
        }
    : {
        source: {
          uri: otherUserProfilePhoto,
        },
      };

  return (
    <View style={styles.container}>
      <View style={styles.circle}>
        <Image style={{ height: size, width: size, borderRadius: size / 2 }} {...ownImageProps} />
        <Image
          style={{ height: size, width: size, borderRadius: size / 2 }}
          {...otherUserImageProps}
        />
      </View>
      <View
        style={{
          width: smallCircleSize,
          height: smallCircleSize,
          borderRadius: smallCircleSize / 2,
          backgroundColor: 'white',
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          borderWidth: 1,
          borderColor: Color.purple,
        }}
      >
        {icon}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  circle: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    flexDirection: 'row',
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  smallCircle: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: 'white',
    position: 'absolute',
  },
});

export default PhotoCollage;
