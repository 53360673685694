import { View } from 'src/components/Themed';
import React, { useState } from 'react';
import ChoicesSelector from 'src/components/editProfileComponents/ChoicesSelector';
import { DietType } from 'src/common/models/profile.model';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';

type SetSearchParams = React.Dispatch<
  React.SetStateAction<{
    qualificationIds: number[];
  }>
>;

const DietSelectionComp = ({
  initialDiets = [],
  updatePartnerPref = async (_) => {},
  setSearchParams,
}) => {
  const [isDoneValidating, setIsDoneValidating] = useState(false);
  const [selectedDiets, setSelectedDiets] = useState(initialDiets.length ? initialDiets : [DOESNT_MATTER_OPTION.name]);
  const diets = [DOESNT_MATTER_OPTION.name, ...Object.values(DietType)];

  const onPressDone = async () => {
    const validPreferences = selectedDiets[0] === DOESNT_MATTER_OPTION.name ? [] : selectedDiets;

    try {
      setIsDoneValidating(true);
      if (setSearchParams) {
        setSearchParams((prevParams) => ({ ...prevParams, ...{ diets: validPreferences } }));
        setIsDoneValidating(false);
        return;
      }
      await updatePartnerPref({ diets: validPreferences });
      setIsDoneValidating(false);
    } catch (error) {
      console.log(error);
      setIsDoneValidating(false);
    }
  };

  const onPressRemove = async (updatedDiets = []) => {
    console.log(updatedDiets);
    try {
      if (setSearchParams) {
        setSearchParams((prevParams) => ({ ...prevParams, ...{ diets: selectedDiets } }));
        return;
      }
      await updatePartnerPref({ diets: updatedDiets });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <View style={{ bottom: 20 }}>
      <ChoicesSelector
        dropdownItems={diets}
        placeHolder="Search Diets here..."
        selectedValues={selectedDiets}
        setSelectedValues={setSelectedDiets}
        onPressDone={onPressDone}
        onPressRemove={onPressRemove}
        isDoneValidating={isDoneValidating}
      />
    </View>
  );
};

export default DietSelectionComp;
