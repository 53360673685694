import LocalizedStrings from 'localized-strings';

export const strings = new LocalizedStrings({
  en: {
    followingCheckUpdate: 'Check Updates',
    video_queued_for_deletion:
      'Your video has been queued for deletion it will be deleted in sometime!',
    content_language: 'Change videos language',
    create_inshot: 'Create with InShot',
    add_phonenumber: 'Please add phone number',
    phone_number_length: 'Please give a 10 digit number',
    report_bug: 'Report Bug',
    select_file: 'Please select a file',
    add_description: 'Please add description',
    description_text: 'Description of Bug',
    select_video: 'Select Video',
    continue_last: 'Edit last video',
    claim_video_success:
      'Video claim success, now you should be able to view the video in your profile',
    download_info_1: 'You can only Download Three Videos in a day!',
    download_info_2: 'Know More',
    download_info_3: 'Looks like you have not downloaded this video yet!',
    download_info_4: 'Download Now',
    error_fetching_user:
      "We can't find TikTok account that you requested, Are you sure you are using correct TikTok username?",
    claim_now: 'Claim Now',
    some_error_occurred: 'Some Error Occurred!! Please Try Again Later',
    error: 'Error',
    information: 'Information',
    check_for_claimed_status_1: 'There was an error, Please try again later',
    check_for_claimed_status_2:
      'You may not be able to see your video thumbnail, It is under Process',
    check_for_claimed_status_3:
      'You have no videos to claim, this can be either due to videos being private or you have no videos in your tiktok account',
    check_for_claimed_status_4:
      'Your videos have not been processed yet, Please try again after sometime',
    restrict: 'Restrict',
    tk_ad_title: 'Fetching Profile',
    tk_ad_text: 'View this awesome content while we fetch your Profile.',
    warning_tiktok_page_leave:
      'If you leave this page you will not be able to link with your tiktok account for the next 24 hours',
    disclaimer_content:
      'Please proceed only if You are sure that this is your username else you will not be able to link with your TikTok profile for next 24 hours',
    disclaimer: 'Disclaimer',
    confirmation: 'Confirm to Continue',
    go_back: 'Click here to go back',
    view_unclaimedvideos: 'View your Unclaimed \nTikTok Videos',
    unclaimed_videos: 'Unclaimed Videos',
    share_video_text: `Download Changa The Made In India Short Video App Click here to download now 👉`,
    share_profile_text: ` on Changa app - The Short Video App Click here to download now 👉`,
    share_profile_prefix: 'Watch ',
    language_updated: 'Language Updated',
    check_for_updates: 'Check for updates',
    done: 'Done',
    notification_settings: 'Notification settings',
    find_friends: ' Find your friends on \n changa app',
    edit_video: 'Select from Gallery',
    fill_phone_number: 'Please fill your Phone Number',
    recommended_apps: 'Recommended Apps',
    see_tutorial: 'Watch tutorial',
    create_video: 'Create Video',
    create_status: 'Create Status',
    write_about: 'Write about your video..',
    who_can_view: 'Who can view this video',
    allow_comment: 'Allow Comment',
    allow_duet: 'Allow Duet',
    save_on_device: 'Save on device',
    video_language: 'Video Language',
    add_category: 'Video Category',
    users: 'USERS',
    hashtags: 'HASHTAGS',
    type_here: 'Type here....',
    share_hashtag: 'Share Hashtag',
    empty_message_list: 'No messages, start a chat!',
    empty_message: 'This is the beginning of this chat, start sending messages',
    me: 'Me',
    home: 'Home',
    get_now: 'Get more',
    need_more_tiktok_videos: 'Need more TikTok \nvideos?',
    keep_using_changa: 'Keep using Changa. We will bring your more videos very shortly.',
    ready_to_fetch_more: 'Congratulations! You are all set to fetch more videos.',
    error_fetching_more_videos: 'There is some error fetching your videos please try a bit later.',
    please_login: 'Please login!',
    search: 'Search',
    search_user_or_hashtag: 'Search User or hashtag',
    explore: 'Explore',
    submit: 'Submit',
    record: 'Record',
    messages: 'Messages',
    activity: 'Activity',
    profile: 'Profile',
    post: 'Post',
    description: 'Description',
    description_post_activity: 'Enter description',
    post_video: 'Post Video',
    add_sound: 'Add Sound',
    upload: 'Upload',
    sample_sound_name: 'Name of the sounds',
    save: 'Save',
    create: 'Create',
    draft: 'Draft',
    comment_placeholder: 'Comment message here…',
    popular: 'Popular',
    first_name: 'First name',
    last_name: 'Last name',
    gender: 'Gender',
    male: 'Male',
    female: 'Female',
    age: 'Age',
    bio: 'Bio',
    bio_placeholder: 'Your Bio',
    no_video_text: 'There is no video so far.',
    video_count: '0 Video',
    following: 'Following',
    followers: 'Followers',
    message: 'Message',
    title: 'Title',
    username: 'Username',
    close: 'Close',
    delete: 'Delete',
    likes: 'likes',
    attribute_header: 'To make Changa experience much better please fill this out ',
    enter_name: 'Enter your name',
    name: 'Name',
    enter_gender: 'What is your gender',
    tags_follow: 'Which Tags would you like to follow',
    influencer_follow: 'Choose Influencers you want to follow',
    comment: 'comment',
    warning: 'Warning',
    warning_text: 'Do you want to report this video?',
    yes: 'Yes',
    no: 'No',
    logout: 'Logout',
    logout_message: 'Do you want to logout?',
    edit_profile: 'Edit Profile',
    share_profile: 'Share Profile',

    follow: 'Follow',
    unfollow: 'Following',
    original_sound_tag: 'original sound - \\',
    select_a_language: 'Select a Language',
    language_en: 'English',
    language_hi: 'हिंदी',
    back: 'back',
    contact_now: 'Contact now',
    tiktok: 'TikTok',
    tk_link_popup_desc:
      'Get your TikTok data back on Changa\nLink your TikTok account with Changa now',
    link_now: 'Link now',
    link_your_tik_tok: 'Link your TikTok',
    // tiktok_username_hint: 'TikTok ID/ Username',
    process: 'Process',
    settings: 'Settings',
    privacy: 'Privacy',
    suggestions: 'Suggestions',
    your_feedback_is_important_for_us: 'Your feedback is important for us',
    give_5star_rating: 'Enjoying Changa?\nGive 5 star rating and show your Love',
    thanks_for_rating: 'Thanks for rating',
    link_account: 'Link your TikTok Account',
    unlink_account: 'Unlink Account',
    support: 'Changa Support',
    chat: 'Chat with us',
    faq: 'FAQs',
    about: 'About',
    enter_a_message: 'Enter a message',
    terms_and_condition: 'Terms and Condition',
    log_out: 'Log Out',
    notification: 'Notification',
    select_a_language_hindi: 'अपनी भाषा चुनें',
    change_language: 'Change Language',
    tk_link_process_line:
      'Link your TikTok account to Changa to import your videos directly to your Changa account',
    tk_link_process_steps:
      '1. Input your Username or TikTok ID.\n2. Verify yourself if you have more than 500k followers.\n3. Give some time to import your videos. Only public videos will be imported.\n4. By submitting you are giving Changa server a consent to move your TikTok videos onto Changa servers.\n5. Changa is purely Indian app, and none of your data will be shared with TikTok or any other chinese apps.',
    confirm: 'Confirm',
    videos: 'videos',
    tk_link_error_desc:
      "This TikTok ID is already claimed.\nSorry we can't let you claim this ID\nIf this is your ID and claimed by someone else,please do contact us as soon as possible,and provide us your Identity proof",
    tk_link_success: 'Your profile has been successfully linked! Please check your profile now.',
    video_processing:
      'Please wait while we are trying to bring your profile content from TikTok to Changa! It can take 1 to 2 weeks. You can keep on uploading videos and use Changa as usual',
    view_all: 'View all >',
    show_messages: 'Show Messages',
    online: 'online',
    music: 'Music',
    use_song: 'Use This Music',
    videos_based_on_this_song: 'Videos based on this song',
    videos_using_this_song: 'videos using this song',
    tk_changa_link: 'Link your TikTok\nto Changa',
    other: 'Other',
    dob: 'DOB',
    date_hint: 'dd/mm/yyyy',
    no_username: 'No Username',
    tk_username_input_line: 'Enter your TikTok username',
    email_input_line: 'Enter your e-mail',
    phone_input_line: 'Enter your Phone Number',
    permissions: 'Permissions',
    support_input_line: 'What Issue are you facing?',
    support_option1: 'Need help with Linking',
    support_option2: 'Accidently linked another TikTok username',
    support_option3: 'Don’t remember my TikTok username',
    support_option4: 'my TikTok username is coming as not found',
    hint_email: 'abcd@gmail.com',
    hint_phone: 'Your Phone Number',
    required_fields_line: '* enter all required fields',
    forgot_username_title: "Don\\'t remember Username?",
    tk_forgot_title: 'Find your TikTok Username',
    tk_forgot_desc:
      'We need your TikTok username to fetch and link your TikTok account with Changa account. There is no other way for us to link the accounts.  Here are a few ways in which you can find your TikTok username:\n1) If you have the TikTok app still installed on your device,go to your profile,you should be able to see your TikTok username there. \n2) If you have downloaded your own video from TikTok,you should be able to see your TikTok username in the video as a watermark  After finding the username,enter it in the search bar for us to link your account.',
    tk_forgot_button: 'Need more Help',
    tk_confirm0: 'I understand that TikTok linking would take between 1 to 2 weeks',
    tk_confirm1: 'I confirm that this is my username',
    tk_confirm2:
      'I authorize Changa to pull my information from TikTok and store on Changa Indian servers.',
    tk_confirm3: "I understand that I can't unlink my TikTok \n account afterward.",
    tk_confirm4:
      "I understand that only my public videos will be recovered. Draft and private videos won't be recovered",
    no_messages_text: 'No messages here start chatting',
    tk_loading_desc: 'Fetching your data from TikTok\nplease wait…',
    error_video_too_long: 'video is too long',
    file_format_not_supported: 'File format not supported, try a different file!\\',
    large_file_size: "Video of size more than 14 MB isn't supported yet",
    change_language_restart: 'Changing language and restarting app!',
    restarting_app: 'restarting app',
    app_updated: 'App updated in background',
    question_correct_username:
      'Are you sure you have entered correct username\n\n1) If this is your username,contact support\n2) If you have entered an incorrect username,you can try again',
    contact_support: 'contact support',
    try_again: 'Try Again',
    fetch_tiktok:
      'Checking profile availability. It can take upto 5 minutes. Please wait on this screen and do not press back button or exit the app..',
    privacy_statement: 'Agreement and policy',
    statement_content:
      'Please carefully read and fully understand the terms and conditions of service agreement and privacy policy. Meitu only provides video editing and shooting services,and will not collect your personal information. You can read the \\Service Agreement\\ and \\Privacy Policy\\ for details. If you agree,please click agree to accept our service.',
    service_agreement: 'Service Agreement',
    privacy_policy: 'Privacy Policy',
    not_used: 'Not used',
    agree: 'Agree',
    ssl_error_prompt: 'The SSL certificate failed,whether to continue to visit?',
    douYinEffects: 'DouVideo',
    particleEffects: 'Particle',
    captureScene: 'VirtualKeyer',
    picInPic: 'PIP',
    flipSubtitles: 'FlipCaption',
    musicLyrics: 'MusicLyrics',
    pushMirrorFilm: 'SuperZoom',
    boomRang: 'Boomerang',
    makingCover: 'MakeCover',
    photosAlbum: 'Changa Status Maker',
    flashEffect: 'FlashEffect',
    mimo: 'Mimo',
    theme_shoot: 'ThemeShoot',
    initArsence: 'Arscene module is initializing',
    no_back_activity_cancel: 'Cancel',
    no_back_activity_setting: 'Setting',
    video_uploaded: 'Video have uploaded successfully! Pull down to refresh.',
    video_is_uploading: 'Your Video is Uploading',
    video_is_uploaded: 'Video Upload Complete',
    share_video_desc:
      'To increase the views of your video, share it on the following platforms and gain Changa boost. Video with Changa boost will get more views.',
    save_in_device: 'Save in device',
    // submit: 'submit',
    // confirm: 'confirm',
    share: 'Share',
    // music: 'Music',
    postVideo: 'Post video',
    profileUpdated: 'Profile successfully updated',
    exclusiveMembershipUpdated: 'Exclusive Membership status updated successfully',
    tkLink: 'Link your TikTok account',
    tkUnlink: 'Unlink your TikTok account',
    editProfile: 'Edit your profile',
    // settings: 'Settings',
    // messages: 'Messages',
    tkClaim: 'Claim your TikTok account',
    changeLanguage: 'Change your language',
    chooseLanguage: 'Choose your language',
    chooseVideoLanguage: 'Choose content language',
    chooseVideoCategory: 'Choose video category',
    forgotTkUsername: 'Forgot TikTok username?',
    steps: 'Steps',
    language_set: 'Language set. You can go to settings, and change your language anytime.',
    tk_link_dismiss: 'You can always go to settings to link TikTok profile to Changa',
    video_sdk_wait:
      'Please wait, we are preparing the best video editing experience for you! It can take few minutes',
    something_went_wrong: 'Something went wrong',
    report: 'Report',
    create_first_video: 'Create your first\nvideo at Changa',
    community_guidelines: 'Community Guidelines',
    no_videos: 'No Videos found',
    give_all_permission: 'Please provide the permissions',
    play_and_earn: 'Play and Earn',
    play_games: 'Play Games',
    advance_settings: 'Advance Settings',
    request_callback: 'Request Callback',
    send_rating: 'Give Rating',
    show_more: 'Show More',
    show_less: 'Show Less',
    notification_preferences: 'Notification Preferences',
    login_trying: 'Trying to login, please wait..',
    enter_query: 'Enter your query here, and our team will get back to you',
    activity_feed: 'Activity Feed',
    booster: 'Booster',
    join_boost: 'GET FREE BOOST',
    boost_applied: 'Already Applied to boost',
    boost_description:
      'Get a free Changa booster to make your videos reach more audience and in turn get more followers.',
    boost_confirm:
      'Thanks for applying for Changa booster. Your booster will be sent to you in form of scratch card very soon. Please stay tuned!',
    boost_desc1: 'Get a booster to make your videos reach more people and get more popularity',
    boost_desc2: 'Win Booster Scratch card upto 10X',
    boost_desc3: 'Boosters are a great way to get many people to see your content',
    boost_desc4: 'Booster is an upcoming feature',
    boost_desc5: 'You can join the pre release and get notified when boost is live',
    boost_desc6: 'Expand your Reach with Boosters',
    boost_desc7: 'Create a new Audience',
    dev_options_warning:
      'This screen is intended to used only for development purposes, if you dont know what it is, please dont enter, as it might make the app unusable.',
    pick_sound: 'Pick a Sound',
    updating_text: 'Updating the App, Please Wait...',
    feature_upgrade: 'An feature upgrade is available!',
    search_here: 'Search here',
    app_update_kill_restart: 'App updated in the background. Kill and restart to see changes.',

    people_you_may_like: 'People you may like',
    grant_permission: 'In order to use certain features of this app you need to grant permissions',
    permission_step1: '1.Open app settings (click button below)',
    permission_step2: '2.Click on permissions tab',
    permission_step3: '3.Allow the permissions.',
    referral_step1: '1. Share the referral link by clicking on "Share Referral".',
    referral_step2: '2. When your friends logs in, both of you win Changa points.',
    referral_step3: '3. You can earn upto 100 changa points when 2 friends join Changa app.',
    app_permission: 'Permissions',

    get_verified: 'Get Verified',
    know_more: 'Know More',
    add_gender: 'Add Gender',
    add_social: 'Add Social Links',
    add_bio: 'Add Bio',
    add_CelebZone: 'Add Celeb Zone',
    support_email: 'Support Email',

    copied_clipboard: 'Copied to clipboard',
    following_nobody: 'You are following nobody',
    follower_nobody: 'No followers yet',
    not_see_following: 'You might not see all followings. There can be multiple reasons.',
    not_see_follower: 'You might not see all followers. There can be multiple reasons.',
    verification_step_1: 'Create a video on Changa showing your face',
    verification_step_2: 'Encourage users to join this awesome platform in this video',
    verification_step_3: 'Upload this video with #changarocks on Changa app',
    verification_step_4: 'Also post this video on instagram with your account',
    refer: 'Refer and Earn',
    refer_title: 'Invite your friends',
    refer_description: 'You can earn upto 100 Changa points when your 2 friends join\n Changa app.',
    refer_description2:
      'Ask your friends to enter your referral code in their profile to earn Changa points.',
    clear_cache: 'Clear Cache',
    preparing_template: 'Please wait while we are preparing the template for you',
    follow_hashtags: 'Follow Hashtags',
    select_favorite_hashtags: 'Select Your Favorite Hashtags',
    select_hashtag_error: 'Select at least one Hashtag to continue',
    select_influencer_error: 'Select at least one influencer to continue',
    select_influencers_heading: 'Select Your Influencers',
    select_influencers_description: 'Select Influencers You Want to Follow',
    enter_info: 'Please Enter your Info',
    enter_info_error1: 'Please enter details to continue!',
    enter_info_error2: 'Please select your age to continue!',
    skip_for_now: 'Skip for Now',
    yes_publicity: 'I want Changa boost',
    no_publicity: "I don't want Changa boost",
    really_skip: 'Do you really want to skip Boost?',
    boost_unavailable_line1: 'Share your videos on minimum 2 platforms to enable the boost',
    boost_unavailable_line2: 'Shared videos results in better reach to desired audience',
    current_progress: 'Your Current Reach',
    get_exclusive: 'Get Exclusive',
    exclusive_step_1: 'Get a golden badge for seven days',
    exclusive_step_2: 'Get 3x times more reach',
    exclusive_step_3: 'Become a trend setter',
    exclusive_step_4: 'Stand apart from others',
    exclusive_step_5: 'Create your own fame',
    exclusive_inactivity_title: " Exclusive Isn't Permanent, Hurry!!",
    exclusive_inactivity_desc:
      ' Its been quite a while you have posted a video on Changa, better not lose your exclusivity. Follow exclusive guideline and save your Exclusiveness.',
    exclusive_expire_title: 'Sorry You Just Lost Your Exclusive Membership',
    exclusive_expire_desc: 'To regain membership continue uploading videos',
    sign_with: 'Signed In with',
    last_access: 'Last accessed from',
    exclusive_title: 'What it feels to be Exclusive',
    back_warning: 'Your current recording will be lost if you will go back! Want to go back?',
    choose_cover: 'Choose cover',
    follow_changa: 'Follow Changa on Instagram',
    profile_completed: 'Profile Done',
    watch_video: 'Watch complete video to proceed',
    content_language_updated: 'You have successfully updated videos language',
    broadcaster_first_time_description:
      'Welcome to Changa Live Stream!!\n\n' +
      'As a broadcaster you have access to delete an inappropriate comment and even block the user from your stream.\n' +
      'To delete or block a user press and hold the comment, and select the action',
    audience_first_time_description:
      'Welcome to Changa Live Stream!!\n\n' +
      'If the broadcaster is streaming inappropriate content you can report it by pressing share and then report button',
    your_scratch_cards: 'Your Scratch Cards',
    show_chat_message: 'Touch again to\n show the chat',
    hide_chat_message: 'Touch to\n hide the chat',
  },
  hi: {
    followingCheckUpdate: 'Check Updates',
    content_language: 'वीडियो की भाषा बदलें',
    phone_number_length: 'कृपया 10 अंकों का फोन नंबर दें',
    add_phonenumber: 'कृपया फ़ोन नंबर जोड़ें',
    report_bug: 'रिपोर्ट बग',
    select_file: 'कृपया एक फ़ाइल चुनें',
    add_description: 'कृपया विवरण जोड़ें',
    description_text: 'बग का वर्णन',
    select_video: 'वीडियो का चयन करें',
    claim_video_success: 'वीडियो मिल गया हे, अब आप अपनी वीडियो प्रोफाइल मै देख पाएंगे ',
    tk_ad_title: 'Fetching Profile',
    tk_ad_text: 'View this awesome content while we fetch your Profile',
    go_back: 'वापिस जाये',
    download_info_1: 'आप एक दिन में केवल तीन वीडियो डाउनलोड कर सकते हैं!',
    download_info_2: 'अधिक जानिए',
    download_info_3: 'लगता है कि आपने अभी तक यह वीडियो डाउनलोड नहीं किया है!',
    download_info_4: 'अभी डाउनलोड करें',
    error_fetching_user:
      'हम आपके द्वारा अनुरोध किए गए TikTok खाते का पता नहीं लगा पा रहे है, क्या आप सुनिश्चित हैं कि आप सही TikTok username डाल रहे है?',
    claim_now: 'वीडियो वापिस पाए',
    tk_rewarded_ad_text: 'Watch this awesome video while we fetch your data',
    some_error_occurred: 'कुछ त्रुटि हुई !! बाद में पुन: प्रयास करें।',
    error: 'समस्या',
    information: 'जानकारी',
    check_for_claimed_status_1: 'एक तसमस्या हुई। बाद में पुन: प्रयास करें।',
    check_for_claimed_status_2:
      'अब आप अपने वीडियो थंबनेल को देखने में सक्षम नहीं हो सकते, यह प्रक्रियाधीन है।',
    check_for_claimed_status_3:
      'आपके पास डाउनलोड करने के लिए कोई वीडियो नहीं है। यह या तो वीडियो निजी होने के कारण हो सकता है या आपके पास अपने TikTok खाते में कोई वीडियो नहीं है।',
    check_for_claimed_status_4:
      'आपके वीडियो अभी तक संसाधित नहीं हुए हैं। कृपया कुछ समय बाद पुनः प्रयास करें।',
    warning_tiktok_page_leave:
      'यदि आप इस पृष्ठ को छोड़ देते हैं तो आप अगले 24 घंटों के लिए अपने टिक्टॉक खाते से लिंक नहीं कर पाएंगे',
    share_hashtag: 'शेयर हैशटैग',
    restrict: 'Restrict',
    disclaimer_content:
      'कृपया केवल तभी आगे बढ़ें जब आप सुनिश्चित हों कि यह आपका उपयोगकर्ता नाम है और आप अगले 24 घंटों के लिए अपने टिकटॉक प्रोफाइल के साथ लिंक नहीं कर पाएंगे',
    disclaimer: 'जानकारी',
    chooseVideoLanguage: 'वीडियो भाषा चुनें',
    chooseVideoCategory: 'वीडियो केटेगरी चुनें',
    confirmation: 'जारी रखने की पुष्टि करें',
    view_unclaimedvideos: 'अपने TikTok वीडियो देखें',
    unclaimed_videos: 'वीडियो पाएं',
    language_updated: 'भाषा परिवर्तन हो गया है',
    check_for_updates: 'Check for updates',
    done: 'समाप्त',
    videos_using_this_song: 'इस गाने से जुड़े वीडियोस',
    date_hint: 'dd/mm/yyyy',
    support_option1: 'जोड़ने हेतु सहायता प्राप्त करे',
    support_option2: 'गलती से कोई और TikTok यूजरनाम लिंक किये',
    support_option3: 'TikTok यूजरनेम याद नहीं ',
    support_option4: 'मेरा TikTok यूजरनाम not found आ रहा है',
    hint_email: 'abcd@gmail.com',
    privacy_statement: 'समझौता और नीतिया ',
    statement_content:
      'कृपया सब नियम, नीति और समझौता को बोहोत ध्यान से पढ़े और समझे , हम केवल वीडियो बनाने और एडिट करने की सुविधाए प्रदान करते है . हम आपकी कोई निजी जानकारी को जमा नहीं करते . आप हमारे समझौते दस्ताबेज और पालिसी पढ़ सकते है . यदि आप इससे सहमत है तो क्लिक करके हमारी सेवाओं से सहमति जताए ',
    service_agreement: 'सेवा अनुबंध ',
    privacy_policy: 'गोपनीयता नीति ',
    not_used: 'उपयोग नहीं किया',
    agree: 'सहमत है ',
    ssl_error_prompt: 'The SSL certificate failed,whether to continue to visit?',
    douYinEffects: 'DouVideo',
    particleEffects: 'Particle',
    captureScene: 'VirtualKeyer',
    picInPic: 'PIP',
    flipSubtitles: 'FlipCaption',
    musicLyrics: 'MusicLyrics',
    pushMirrorFilm: 'SuperZoom',
    boomRang: 'Boomerang',
    makingCover: 'MakeCover',
    photosAlbum: 'Changa Status Maker',
    flashEffect: 'FlashEffect',
    video_language: 'Video Language',
    mimo: 'Mimo',
    theme_shoot: 'ThemeShoot',
    initArsence: 'Arscene module is initializing',
    no_back_activity_cancel: 'Cancel',
    no_back_activity_setting: 'Setting',
    // submit: 'submit',
    // confirm: 'confirm',
    share: 'शेयर करे',
    // music: 'Music',
    postVideo: 'वीडियो पोस्ट करे',
    content_language_updated: 'आपने सफलतापूर्वक वीडियो भाषा अपडेट की है',
    profileUpdated: 'सफलतापूर्वक प्रोफाइल अपडेट हो गयी',
    tkLink: 'अपना TikTok अकाउंट लिंक करे ',
    tkUnlink: 'Unlink your TikTok account',
    editProfile: 'अपनी प्रोफाइल में बदलाव करे',
    // settings: 'Settings',
    // messages: 'Messages',
    tkClaim: 'अपना TikTok अकाउंट वापिस पाए',
    changeLanguage: 'अपनी भाषा बदले ',
    chooseLanguage: 'अपनी भाषा चुने  ',
    forgotTkUsername: 'अपना TikTok username भूल गए ?',
    add_category: 'Video Category',
    steps: 'चरण',
    language_set: 'भाषा लागू , आप सेटिंग्स में जाके कभी भी भाषा बदल सकते है ',
    tk_link_dismiss: 'आप कभी भी सेटिंगस में जाके अपना TikTok अकाउंट Changa से लिंक कर सकते है ',
    video_sdk_wait:
      'कृपया प्रतीक्षा करें, हम आपके लिए सर्वश्रेष्ठ वीडियो संपादन अनुभव तैयार कर रहे हैं! इसमें कुछ मिनट लग सकते हैं।',
    something_went_wrong: 'Something went wrong',
    empty_message_list: 'कोई संदेश नहीं, एक चैट शुरू करें!',
    empty_message: 'यह इस चैट की शुरुआत है, संदेश भेजना शुरू करें',
    me: 'अकाउंट',
    video_uploaded: 'वीडियो अपलोड हो गया है। रिफ्रेश करने के लिए नीचे खींचे',
    save_in_device: 'िवाइस में सहेजें',
    // submit: 'submit',
    warning: 'चेतावनी',
    warning_text: 'क्या आप इस वीडियो की रिपोर्ट करना चाहते हैं?',
    yes: 'हाँ',
    permissions: 'अनुमतियां',
    keep_using_changa: 'Changa एप का उपयोग करते रहें। हम बहुत जल्द आपके और वीडियो लाएंगे',
    ready_to_fetch_more: 'बधाई हो! अब आप अधिक TikTok वीडियो ला सकते हैं.',
    error_fetching_more_videos:
      'आपके वीडियो लाने में कुछ त्रुटि है कृपया थोड़ा बाद में प्रयास करें.',
    no: 'नहीं',
    get_now: 'अब प्राप्त करें',
    need_more_tiktok_videos: 'अधिक TikTok वीडियो की \nआवश्यकता है?',
    follow: 'फॉलो करें',
    unfollow: 'फॉलोइंग',
    share_profile: 'प्रोफाइल शेयर करें',
    edit_profile: 'प्रोफाइल एडिट करें',
    original_sound_tag: 'ओरिजिनल साउंड - ',
    logout: 'लोग आउट करें',
    logout_message: 'क्या आप लॉगआउट करना चाहते हैं',
    comment: 'कमेंट',
    likes: 'लाइक्स',
    close: 'बंद करें',
    title: 'टाइटल',
    followers: 'फॉलोवर्सं',
    message: 'संदेश',
    bio: 'बायो',
    enter_a_message: 'एक संदेश दर्ज करें',
    search_user_or_hashtag: 'उपयोगकर्ता या हैशटैग खोजें',
    bio_placeholder: 'आपकी बायो',
    following: 'फॉलोइंग',
    video_count: 'वीडियोस',
    no_video_text: 'अभी कोई वीडियो नहीं',
    male: 'पुरुष',
    female: 'महिला',
    sample_sound_name: 'ऑडियो का नाम',
    other: 'अन्य',
    gender: 'लिंग',
    tags_follow: 'आप किन टैग को follow करना चाहेंगे',
    influencer_follow: 'आप किन influencer को follow करना चाहेंगे',
    first_name: 'पहला नाम',
    last_name: ' आखरी नाम',
    popular: 'पॉपुलर',
    comment_placeholder: 'अपना कमेंट लिखें.. ',
    save: 'सेव करें',
    description_post_activity: 'विवरण दर्ज करें',
    create: 'क्रिएट',
    language_en: 'English',
    upload: 'अपलोड करे',
    add_sound: 'म्यूजिक जोड़ें',
    post_video: 'वीडियो पोस्ट करें',
    post: 'पोस्ट करें',
    profile: 'प्रोफाइल',
    messages: 'मैसेज',
    activity: 'गतिविधि',
    search: 'खोज करें',
    explore: 'खोजे',
    submit: 'सबमिट',
    record: 'अभिलेख',
    share_profile_text: `. को changa app पर देखें- इंडिया का अपना TikTok. डाउनलोड करने के लिए इस लिंक पर क्लिक करें 👉`,
    home: 'होम',
    please_login: 'कृपया लॉगिन करें!',
    select_a_language: 'भाषा चुने',
    language_hi: 'हिंदी',
    see_tutorial: 'ट्यूटोरियल देखें',
    back: 'वापिस जाएँ',
    select_a_language_hindi: 'अपनी भाषा चुनें',
    contact_now: 'संपर्क करें',
    tk_link_popup_desc: 'अपने TikTok की सारी वीडियोस Changa पर वापिस पाएं| अपना username अभी जोड़ें',
    link_now: 'लिंक करें',
    link_your_tik_tok: 'TikTok लिंक करें',
    process: 'तरीका',
    settings: 'सेटिंग्स',
    privacy: 'निजता',
    suggestions: 'सुझाव',
    link_account: 'TikTok अकाउंट लिंक करें',
    support: 'Changa सहायता',
    chat: 'हम से Chat करे',
    faq: 'FAQs',
    about: 'जानकारी',
    terms_and_condition: 'नियम व शर्तें',
    log_out: 'लॉग आउट',
    notification: 'सूचनाएं',
    change_language: 'अपनी भाषा बदलें',
    tk_link_process_line:
      'अपना TikTok अकाउंट Changa से जोड़ें और अपनी सारी वीडियोस सीधे Changa पर पाएं।',
    tk_link_process_steps:
      '\n1. अपना username या TikTok ID डालें।\n2. अगर आपको 5 लाख से ज्यादा लोग फॉलो करते हैं तो verify करें।\n3. आपकी वीडियोस लाने के लिए कुछ समय दें। केवल पब्लिक वीडियोस ही आएँगी।\n4. सबमिट करने से आप Changa सर्वर पर अपने टिक्कॉक वीडियो को स्थानांतरित करने के लिए Changa सर्वर को सहमति दे रहे हैं।\n5.Changa एक भारतीय अप्प है, और यहाँ पर डाला गया आपका कोई भी डाटा टिकटोक या अन्य किसी भी चाइनीज़ कंपनी से शेयर नहीं करा जायेगा',
    confirm: 'पुष्टि करें',
    videos: 'वीडियोस',
    tk_link_error_desc: 'इस TikTok ID पर कोई और पहले ही दवा कर चुका है',
    unlink_account: 'अकाउंट अनलिंक करें',
    description: 'विवरण',
    notification_settings: 'अधिसूचना सेटिंग',
    tk_link_success: 'आपका प्रोफ़ाइल सफलतापूर्वक लिंक कर दिया गया है! कृपया अपनी प्रोफ़ाइल जांचें।',
    video_processing:
      'कृपया प्रतीक्षा करें जब तक हम आपके प्रोफ़ाइल को TikTok से Changa पर ला रहे हैं! इसमें 1 से 2 सप्ताह का समय लग सकता है। आप अपने वीडियोस अपलोड कर सकते है और Changa app का इस्तेमाल कर सकते है।',
    view_all: 'सभी देखें>',
    share_video_text: `डाउनलोड Changa - भारत में बनी शॉर्ट वीडियो ऐप! डाउनलोड करने के लिए क्लिक करें 👉`,
    show_messages: 'मैसेज देखे ',
    attribute_header: 'Changa अनुभव को बेहतर बनाने के लिए कृपया इसे भरें',
    enter_name: 'अपना नाम दर्ज करें',
    name: 'नाम',
    enter_gender: 'अपना लिंग दर्ज करें',
    online: 'ऑनलाइन',
    use_song: 'सांग इस्तेमाल करें',
    videos_based_on_this_song: 'इस गीत पर आधारित वीडियो',
    tk_changa_link: 'TikTok अकाउंट Changa\n से लिंक करे ',
    dob: 'जन्म तिथि',
    no_username: 'username नहीं है',
    tk_username_input_line: 'अपना TikTok का username डालें',
    email_input_line: 'अपनी ई-मेल डालें',
    phone_input_line: 'अपना फ़ोन नंबर डालें',
    support_input_line: 'आपकी किस तरह सहायता कर सकते हैं?',
    hint_phone: 'आपका फ़ोन नंबर',
    required_fields_line: 'यह जानकारी जरूरी है',
    tk_loading_desc: 'आपकी जानकारी TikTok से लाई जा रही है\n कृपया रुकें',
    error_video_too_long: 'video बहुत लंबा है',
    forgot_username_title: 'username याद नहीं?',
    tk_forgot_title: ' अपना TikTok यूजरनाम ढूंढे',
    tk_forgot_desc:
      'आपका tikTok अकाउंट Changa से जोड़ने के लिए हमे आपका TikTok username चाहिए। इसके आलावा कोई दूसरा उपाय नहीं हैं। \nयहां कुछ तरीके दिए गए हैं जिनसे आप अपने टिकटोक username पा सकते हैं: \n1) यदि आपके पास अभी भी अपने डिवाइस पर टिकटोक ऐप इंस्टॉल है, तो अपने प्रोफ़ाइल पर जाएं, आपको अपने TikTok username को देखने में सक्षम होना चाहिए। \n2)यदि आपने TikTok से अपना स्वयं का वीडियो डाउनलोड किया है, तो आपको वीडियो में एक वॉटरमार्क के रूप में अपने TikTok username को देखने में सक्षम होना चाहिए। username खोजने के बाद, अपने खाते को लिंक करने के लिए खोज बार में दर्ज करें।',
    tk_forgot_button: 'मदद चाहिए',
    tiktok: 'TikTok',
    tk_confirm0: 'मैं समझता हूं कि TikTok से लिंक करने में 1 से 2 सप्ताह लगेंगे',
    tk_confirm1: 'मैं वादा करता हु ये मेरा username है',
    tk_confirm2:
      'मैं Changa को इजाज़त देता हूँ की वो मेरी जानकारी Tiktok से ले कर अपने भारतीय सर्वर पर रखे',
    tk_confirm3: 'मैं समझता हूँ की मैं यह Tiktok अकाउंट वापिस अलग नहीं कर सकता',
    tk_confirm4:
      'मैं समझता हूं कि केवल मेरे पब्लिक वीडियो वापस आएंगे, ड्राफ्ट और प्राइवेट वीडियोस रिकवर नहीं होंगे। ',
    no_messages_text: 'कोई मैसेज नहीं',
    file_format_not_supported: 'फ़ाइल प्रारूप समर्थित नहीं है, एक अलग फ़ाइल आज़माएं!',
    large_file_size: '14 MB से अधिक आकार का वीडियो अभी तक समर्थित नहीं है!',
    change_language_restart: 'भाषा बदलकर एप्लिकेशन पुनरारंभ हो रही है',
    restarting_app: 'एप्लिकेशन पुनरारंभ हो रही है',
    app_updated: 'app अपडेटेड',
    question_correct_username:
      'क्या आप सुनिश्चित हैं कि आपने सही username दर्ज किया है 1)यदि यह आपका उपयोगकर्ता नाम है, तो support से संपर्क करें \n\n2) यदि आपने गलत उपयोगकर्ता नाम दर्ज किया है, तो आप फिर से कोशिश कर सकते हैं',
    contact_support: 'सहयोग टीम से संपर्क करें',
    try_again: 'पुनः प्रयास करे',
    username: 'उपयोगकर्ता नाम',
    fetch_tiktok:
      'आपके प्रोफाइल की उपलब्धता जांच की जा रही है,  कृपया इस स्क्रीन पर प्रतीक्षा करें और बैक बटन न दबाएं या ऐप से बाहर न निकलें..',
    draft: 'ड्राफ्ट',
    music: 'म्यूजिक',
    type_here: 'यहा लिखो ....',
    users: 'यूज़र्स',
    hashtags: 'हैशटैग',
    report: 'रिपोर्ट',
    create_first_video: 'Changa पर अपना\nपहला वीडियो बनाये',
    community_guidelines: 'कम्युनिटी गाइडलाइन्स',
    no_videos: 'कोई वीडियो नहीं मिले',
    give_all_permission: 'कृपया permission प्रदान करे',
    play_and_earn: 'खेले और जीते',
    play_games: 'गेम्स खेले',
    advance_settings: 'एडवांस सेटिंग',
    edit_video: 'गैलरी से चयन करें',
    fill_phone_number: 'कृपया अपना फ़ोन नंबर भरें',
    recommended_apps: 'ट्रेंडिंग apps',
    create_video: 'वीडियो बनाये',
    create_status: 'स्टेटस बनाये',
    send_rating: 'रेटिंग दे',
    login_trying: 'लॉगिन हो रहा है, कृपया इंतज़ार करे..',
    activity_feed: ' एक्टिविटी फीड',
    enter_query: 'यहां अपनी क्वेरी दर्ज करें, और हमारी टीम आपके आपको वापस संपर्क करेगी',
    write_about: 'वीडियो के बारे में लिखें.. ',
    who_can_view: 'Who can view this video',
    allow_comment: 'Allow Comment',
    allow_duet: 'Allow Duet',
    save_on_device: 'Save on device',
    feature_upgrade: 'एक फीचर अपग्रेड उपलब्ध है!',
    your_feedback_is_important_for_us: 'आपका फीडबैक हमारे लिए आवश्यक है',
    give_5star_rating: 'क्या आपने Changa एप एन्जॉय  किया ? \n 5 स्टार रेटिंग दें !',
    thanks_for_rating: 'रेटिंग के लिए धन्यवाद ',
    request_callback: 'Request Callback',
    notification_preferences: 'सूचना की प्राथमिकताएं',
    booster: 'बूस्टर ',
    join_boost: 'फ्री बूस्ट पाएं ',
    boost_applied: 'आप बूस्ट के लिए अप्लाई कर चुके है',
    boost_description:
      'अपने वीडियो को अधिक दर्शकों तक पहुंचाने के लिए एक मुफ्त Changa बूस्टर प्राप्त करें और बदले में अधिक फोल्लोवेर्स को प्राप्त करें.',
    boost_confirm:
      'Changa बूस्टर के लिए आवेदन करने के लिए धन्यवाद। आपका बूस्टर आपको बहुत जल्द स्क्रैच कार्ड के रूप में भेजा जाएगा। कृपया अनुकूलित रहें!',
    boost_desc1:
      'अपने वीडियो को अधिक लोगों तक पहुंचाने और अधिक लोकप्रियता पाने के लिए एक बूस्टर प्राप्त करें',
    continue_last: 'एडिट लास्ट वीडियो',
    boost_desc2: 'जीतें बूस्टर स्क्रैच कार्ड 10X तक',
    boost_desc3:
      'बूस्टर आपकी कंटेंट को देखने के लिए कई लोगों को प्राप्त करने का एक शानदार तरीका है',
    boost_desc4: 'बूस्टर एक आगामी सुविधा है',
    boost_desc5: 'आप प्री रिलीज में शामिल हो सकते हैं और बूस्ट लाइव होने पर अधिसूचित हो सकते हैं',
    boost_desc6: 'बूस्टर के साथ अपने रीच को बढ़ाएं ',
    boost_desc7: 'नयी ऑडियंस बनाएं',
    dev_options_warning:
      'This screen is intended to used only for development purposes, if you dont know what it is, please dont enter, as it might make the app unusable.',
    pick_sound: 'साउंड चुने ',
    updating_text: 'ऐप अपडेट हो रहा है।  कृपया प्रतीक्षा करे ',
    search_here: 'सर्च करे',
    delete: 'हटाएं',
    show_more: 'और देखे ..',
    create_inshot: 'InShot के साथ बनाएँ',
    show_less: 'कम देखें ..',
    find_friends: 'Changa ऐप पर अपने दोस्तों \nको खोजें',
    app_update_kill_restart: 'बैकग्राउंड में ऐप अपडेट किया गया। app को बंद करके फिर से खोले',

    people_you_may_like: 'लोग जिन्हें आप पसंद कर सकते हैं',
    grant_permission: 'इस ऐप की कुछ विशेषताओं का उपयोग करने के लिए आपको अनुमति देने की आवश्यकता है',
    permission_step1: '1. एप्लिकेशन सेटिंग खोलें (नीचे बटन क्लिक करें)',
    permission_step2: '2. परमिशन टैब पर क्लिक करें',
    permission_step3: '3. अनुमति दें',
    app_permission: 'पर्मिशन्स',

    get_verified: 'वेरिफ़िकेशन करे',
    know_more: 'अधिक जानिए',
    add_gender: 'लिंग ऐड करे',
    add_social: 'सोशल लिंक्स ऐड करे',
    add_bio: 'बायो ऐड करे',
    support_email: 'सहयोग ईमेल',
    copied_clipboard: 'कॉपी हो चूका है',
    following_nobody: 'You are following nobody',
    follower_nobody: 'No followers yet',
    not_see_following: 'You might not see all followings. There can be multiple reasons.',
    not_see_follower: 'You might not see all followers. There can be multiple reasons.',
    verification_step_1: 'अपना चेहरा दिखाते हुए Changa पर एक वीडियो बनाएं',
    verification_step_2:
      'उपयोगकर्ताओं को इस वीडियो में इस मनोरंजक मंच से जुड़ने के लिए प्रोत्साहित करें',
    verification_step_3: 'इस वीडियो को Changa ऐप पर #changarocks के साथ अपलोड करें',
    verification_step_4: 'इस वीडियो को अपने अकाउंट से इंस्टाग्राम पर भी पोस्ट करें',
    refer: 'संदर्भ दे और कमाएँ',
    refer_title: 'अपने दोस्तों को आमंत्रित करें',
    refer_description:
      'जब आपके 2 दोस्त जुड़ते हैं तो आप 100 Changa पॉइंट तक कमा सकते हैं Changa एप',
    refer_description2: 'Changa ऐप लिंक शेयर करें.',
    clear_cache: 'क्लियर कैश ',
    preparing_template: 'Please wait while we are preparing the template for you',
    follow_hashtags: 'हैशटैग फॉलो करे',
    select_favorite_hashtags: 'अपने मनपसंद हैशटैग चुने',
    select_hashtag_error: 'कम से कम एक हैशटैग चुने ',
    select_influencer_error: 'कम से कम एक इन्फ्लुएंसर चुने ',
    select_influencers_heading: 'इन्फ्लुएंसर चुने',
    select_influencers_description: 'अपनी पसंद के इन्फ्लुएंसर्स चुने ',
    age: 'उम्र',
    enter_info: 'कृपया अपनी जानकारी दे ',
    enter_info_error1: 'आगे भड़ने के लिए अपनी जानकारी दे',
    enter_info_error2: 'आगे भड़ने के लिए अपनी उम्र बताये',
    video_is_uploading: 'वीडियो अपलोड हो रहा है ',
    video_is_uploaded: 'आपका वीडियो अपलोड हो गया ',
    share_video_desc:
      'अपने वीडियोस के व्यूज बढ़ने के लिए इस वीडियो को नीचे दिए गए प्लेटफॉर्म्स पे शेयर करे और Changa बूस्ट पाए। Changa बूस्ट वाले वीडियोस को ज्यादा व्यूज मिलते है',
    skip_for_now: 'छोड़ें',
    yes_publicity: 'मुझे boost चाहिए',
    no_publicity: 'मुझे boost नहीं चाहिए',
    really_skip: 'क्या आप सही में बूस्ट छोड़ना चाहते है',
    boost_unavailable_line1:
      'Boost का उपयोग करने के लिए कम से कम 2 प्लेटफॉर्म्स पे वीडियो शेयर करे ',
    boost_unavailable_line2: 'शेयर किये वीडियोस आपको ज्यादा लोगो तक पोहोचने में मदद करते है',
    current_progress: 'वर्तमान प्रचार प्रगति ',
    get_exclusive: 'Exclusive बने ',
    exclusive_step_1: 'सात दिनों के लिए एक सुनहरा badge प्राप्त करें',
    exclusive_step_2: '3x अधिक लोगो तक पहुंचें',
    exclusive_step_3: 'ट्रेंड सेटर बनें',
    exclusive_step_4: 'दूसरों से अलग दिखे ',
    exclusive_step_5: 'अपनी खुद की प्रसिद्धि बनाएँ',
    exclusive_inactivity_title: ' Exclusive स्थायी नहीं है, जल्दी करो !!',
    exclusive_inactivity_desc:
      ' काफी समय हो गया है जब आपने Changa पर एक भी वीडियो पोस्ट नहीं किया है,अपने Exclusive Membership को न खोएं. Exclusive दिशानिर्देश का पालन करें और अपने Exclusive Membership को बचाएं.',
    exclusive_expire_title: 'क्षमा करें आपने अपना Exclusive Membership खो दिया है।',
    exclusive_expire_desc: 'Exclusive Membership हासिल करने के लिए वीडियो अपलोड करना जारी रखें ',
    sign_with: 'Signed In with',
    last_access: 'Last accessed from',
    exclusive_title: 'Exclusive होना कैसा लगता है ',
    exclusiveMembershipUpdated: 'Exclusive Membership status updated successfully',
    back_warning: 'यदि आप वापस जाएंगे तो आपका वर्तमान डेटा खो जाएगा',
    choose_cover: 'Choose cover',
    follow_changa: 'इंस्टाग्राम पर Changa को फॉलो करें',
    profile_completed: 'प्रोफाइल पूरी है',
    share_profile_prefix: 'Watch',
    watch_video: 'आगे बढ़ने के लिए पूरा वीडियो देखें',
    video_queued_for_deletion:
      'आपके वीडियो को हटाने के लिए कतार में लगा दिया गया है, इसे कुछ समय में हटा दिया जाएगा',
    broadcaster_first_time_description:
      'चंगा लाइव स्ट्रीम में आपका स्वागत है !!\n\n' +
      'आप किसी के आपत्तिजनक कमेंट को डिलीट कर सकते हैं या किसी यूजर को भी ब्लॉक कर सकते है।\n' +
      'ऐसा करने के लिए उस कमेंट को क्लिक करके होल्ड करें फिर Block User पर क्लिक करें',
    audience_first_time_description:
      'Welcome to Changa Live Stream!!\n\n' +
      'अगर कोई लाइव स्ट्रीम  पर आपत्तिजनक वीडियो चला रहा है तो आप उसको रिपोर्ट कर सकते हैं।  रिपोर्ट करने के लिए नीचे दिए गए शेयर बटन पर क्लिक करके Report पर क्लिक करें।',
    your_scratch_cards: 'आपके स्क्रैच कार्ड्स',
  },
});
