import { ProfileResp } from 'src/common/response/profile.resp';

export default class UserUtil {
  static getUserIncome(profileData: ProfileResp) {
    return profileData?.currentCountry === 'India' && profileData?.inrIncome
      ? '₹' + String(profileData.inrIncome) + ' Lacs p.a.'
      : profileData?.currentCountry !== 'India' && profileData?.usdIncome
      ? '$' + String(profileData.usdIncome) + 'K p.a.'
      : 'Not Provided';
  }

  static getUserHeight(height: number) {
    const feet = Number((height / 30.48).toFixed(2));
    const inch = Math.ceil((feet - Math.floor(feet)) * 12);

    return `${Math.floor(feet)}' ${inch}'' H`;
  }

  static isPremiumMember(profileData: Partial<ProfileResp>) {
    const now = new Date();
    const expiryDate = new Date(profileData?.membershipExpiredOn);

    return profileData?.isMembershipActive && expiryDate.getTime() > now.getTime();
  }
}
