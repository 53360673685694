import { View } from 'src/components/Themed';
import React, { useState } from 'react';
import ChoicesSelector from 'src/components/editProfileComponents/ChoicesSelector';
import { QualificationData, QualificationDataMap } from 'src/common/data/education.data';
import DOESNT_MATTER_OPTION from 'src/constant/DoesntMatter';
const QualificationSelectionComp = ({
  initialQualificationIds = [],
  updatePartnerPref = async (_) => {},
  setSearchParams,
}) => {
  const [isDoneValidating, setIsDoneValidating] = useState(false);
  const [selectedQualifications, setSelectedQualifications] = useState(
    initialQualificationIds.length ? initialQualificationIds.map((id) => QualificationDataMap[id]) : [DOESNT_MATTER_OPTION],
  );

  const onPressDone = async () => {
    try {
      setIsDoneValidating(true);
      const selectedQualificationsIds =
        selectedQualifications[0] === DOESNT_MATTER_OPTION
          ? []
          : selectedQualifications.map((s) => s.id);
      if (setSearchParams) {
        setSearchParams((prevParams) => ({
          ...prevParams,
          ...{ qualificationIds: selectedQualificationsIds },
        }));
        setIsDoneValidating(false);
        return;
      }
      await updatePartnerPref({ qualificationIds: selectedQualificationsIds });
      setIsDoneValidating(false);
    } catch (error) {
      console.log(error);
      setIsDoneValidating(false);
    }
  };

  const onPressRemove = async (updatedQualifications = []) => {
    console.log(updatedQualifications);
    try {
      const selectedQualificationsIds = updatedQualifications.map((s) => s.id);
      if (setSearchParams) {
        setSearchParams((prevParams) => ({
          ...prevParams,
          ...{ qualificationIds: selectedQualificationsIds },
        }));
        return;
      }
      await updatePartnerPref({ qualificationIds: selectedQualificationsIds });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <View style={{ bottom: 20 }}>
      <ChoicesSelector
        dropdownItems={[DOESNT_MATTER_OPTION, ...QualificationData]}
        preprocessItems={(item: { name: string }) => item.name}
        placeHolder="Search Qualification here..."
        selectedValues={selectedQualifications}
        setSelectedValues={setSelectedQualifications}
        onPressDone={onPressDone}
        isDoneValidating={isDoneValidating}
        onPressRemove={onPressRemove}
      />
    </View>
  );
};

export default QualificationSelectionComp;
