import { useDispatch, useSelector } from 'react-redux';
import BottomSheet from '../common/BottomSheet';
import { hideModal } from 'src/store/reducer/uiReducer';
import { RootState } from 'src/store/store';
import { CanViewContactsResp, ViewContactResp } from 'src/common/dto/contact.dto';
import { Image, StyleSheet, Text, View } from 'react-native';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';
import { Icon } from 'react-native-elements';
import { useEffect, useState } from 'react';
import { ContactService } from 'src/service/ContactService';
import { ProfileResp } from 'src/common/response/profile.resp';
import Button from '../common/buttons/Button';
import { ActivityIndicator } from 'react-native-paper';

const UserContactBottomSheet = () => {
  const dispatch = useDispatch();
  const { profile } = useSelector(
    (state: RootState) =>
      state.ui.modal.childData as { profile: ProfileResp; canViewContact: boolean },
  );
  const [canViewContact, setCanViewConact] = useState(false);
  const closeModal = () => {
    dispatch(hideModal());
  };

  return (
    <BottomSheet
      snapPoints={canViewContact ? ['45%'] : ['40%']}
      webModalHeight={canViewContact ? 350 : 220}
      onDismiss={closeModal}
    >
      <View style={styles.container}>
        {canViewContact ? (
          <ContactDetails profile={profile} />
        ) : (
          <ContactConfirmation
            profile={profile}
            showContactView={() => setCanViewConact(true)}
            closeModal={closeModal}
          />
        )}
      </View>
    </BottomSheet>
  );
};

const ContactConfirmation = ({
  profile,
  showContactView,
  closeModal,
}: {
  profile: ProfileResp;
  showContactView: () => void;
  closeModal: () => void;
}) => {
  const [canViewResp, setCanViewResp] = useState<Partial<CanViewContactsResp>>(undefined);

  const fetchCanViewContact = async () => {
    try {
      const response = await ContactService.canViewContact(profile.uuid);
      if (response.success) {
        if (response.data.hasAlreadyViewed) {
          showContactView();
          return;
        }
        setCanViewResp(response.data);
      } else {
        console.log(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCanViewContact();
  }, []);

  return (
    <>
      {canViewResp ? (
        <View style={{ width: '100%' }}>
          <Text style={styles.confirmationHeading}>
            Are you sure you want to view {profile.fullName} contact?
          </Text>
          <View style={{ width: '100%' }}>
            {!canViewResp.canViewPhone && (
              <Text style={{ textAlign: 'center', marginHorizontal: 5, marginVertical: 10 }}>
                The user has chosen to keep her/his phone number private.
              </Text>
            )}
            <Text
              style={[styles.text, { textAlign: 'center', maxWidth: '100%', marginBottom: 20 }]}
            >
              (1 view will be deducted from remaining {canViewResp.contactViewsLeft} contact views.)
            </Text>
          </View>
          <View style={{ flexDirection: 'row', width: '100%' }}>
            <Button key="cancel" onPress={closeModal} variant="secondary" styles={{ width: '49%' }}>
              Cancel
            </Button>
            <Button
              key="view-contact"
              onPress={showContactView}
              styles={{ width: '49%', marginLeft: 10 }}
            >
              View Contact
            </Button>
          </View>
        </View>
      ) : (
        <View style={{ height: '100%', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <ActivityIndicator />
        </View>
      )}
    </>
  );
};

const ContactDetails = ({ profile }: { profile: ProfileResp }) => {
  const [viewResp, setViewResp] = useState<Partial<ViewContactResp>>(undefined);

  const sendViewContact = async () => {
    try {
      const response = await ContactService.sendViewContact(profile.uuid);
      if (response.success) {
        setViewResp(response.data);
      } else {
        console.log(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    sendViewContact();
  }, []);

  return (
    <View>
      <View style={styles.topContainer}>
        <Image source={{ uri: profile.profilePhoto.originalUrl }} style={styles.userPhoto} />
        <Text style={styles.info}>{`${profile.fullName}, ${profile.age}`}</Text>
      </View>
      <View style={styles.contactDetails}>
        {viewResp ? (
          <>
            {viewResp.contact.contactDetails.map((contact) => {
              const isEmail = contact.type === 'email';
              const bgColor = isEmail ? 'rgba(80, 150, 86, 0.2)' : 'rgba(52, 103, 186, 0.2)';
              const color = isEmail ? 'rgba(80, 150, 86, 1)' : 'rgba(80, 150, 86, 1)';
              return (
                <View key={contact.mobileOrEmail} style={styles.contactItem}>
                  <View style={[styles.iconContainer, { backgroundColor: bgColor }]}>
                    <Icon
                      name={isEmail ? 'mail' : 'phone'}
                      type="feather"
                      color={color}
                      size={17}
                    />
                  </View>
                  <Text style={styles.text}>{contact.mobileOrEmail}</Text>
                </View>
              );
            })}
          </>
        ) : (
          <View>
            <ActivityIndicator />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  topContainer: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
  },
  userPhoto: {
    width: 80,
    height: 80,
    borderRadius: 100,
    marginRight: 10,
  },
  info: {
    fontSize: 17,
    fontFamily: EFonts.DMSANS_BOLD,
    color: Color.halfBlack,
    paddingTop: 17,
  },
  confirmationHeading: {
    fontSize: 17,
    fontFamily: EFonts.DMSANS_BOLD,
    color: Color.black,
    paddingTop: 17,
    textAlign: 'center',
    marginBottom: 20,
  },
  contactDetails: {
    marginTop: 20,
    borderRadius: 20,
    borderWidth: 0.6,
    borderColor: Color.lightBorder,
  },
  contactItem: {
    paddingHorizontal: 25,
    paddingVertical: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemTopBorder: {
    borderColor: Color.lightBorder,
    borderTopWidth: 0.6,
  },
  iconContainer: {
    width: 40,
    height: 40,
    borderRadius: 100,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
  },
  text: {
    fontFamily: EFonts.DMSANS_REGULAR,
    color: Color.halfBlack,
    fontSize: 16,
    maxWidth: '80%',
  },
});

export default UserContactBottomSheet;
