import { MembershipCategory } from 'src/common/models/profile.model';

export enum MembershipPermissions {
  CanSeeActivityLog = 'can see activity log',
  CanSeeProfileVisitors = 'can see profile visitors',
  CanSeeProfileVisited = 'can see profile visited',
  CanSendChatMessage = 'can send chat message',
  CanViewChatMessage = 'can view chat message',
  CanSendInterestReminder = 'can send interest reminder',
  CanSendCustomInterestMessage = 'can send custom interest message',
  CanSeePhoneBook = 'can see phone book',
}

export const SilverMembershipPermissions: MembershipPermissions[] = [
  MembershipPermissions.CanSeeActivityLog,
  MembershipPermissions.CanSeeProfileVisitors,
  MembershipPermissions.CanSeeProfileVisited,
  MembershipPermissions.CanSendChatMessage,
  MembershipPermissions.CanViewChatMessage,
  MembershipPermissions.CanSendInterestReminder,
  MembershipPermissions.CanSendCustomInterestMessage,
  MembershipPermissions.CanSeePhoneBook,
];

export const GoldMembershipPermissions: MembershipPermissions[] = [...SilverMembershipPermissions];

export const DiamondMembershipPermissions: MembershipPermissions[] = [...GoldMembershipPermissions];

export const isFeatureEnabled = (profile: any, feature: MembershipPermissions) => {
  const membership: MembershipCategory = profile.membershipCategory;
  if (membership === MembershipCategory.Silver && SilverMembershipPermissions.includes(feature))
    return true;
  if (membership === MembershipCategory.Gold && GoldMembershipPermissions.includes(feature))
    return true;
  if (membership === MembershipCategory.Diamond && DiamondMembershipPermissions.includes(feature))
    return true;
};
