import { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import Constant from 'src/constant/Constant';
import { setUser, setUserProfile } from 'src/store/reducer/userReducer';
import { store } from 'src/store/store';
import StorageUtil from 'src/util/StorageUtil';

type ConfigData = {
  time: {
    startTime: Date;
    endTime?: Date;
  };
};

export const onRequest = (
  config: InternalAxiosRequestConfig & ConfigData,
): InternalAxiosRequestConfig => {
  const token = StorageUtil.getAuthTokenFromLocalStorage();
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  config.time = { startTime: new Date(), endTime: undefined };
  return config;
};

export const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.log(`[request error] [ ${JSON.stringify(error)} ]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse & { duration: number }): AxiosResponse => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  response.config.time.endTime = new Date();
  response.duration =
    response.config.time.endTime.getTime() - response.config.time.startTime.getTime();

  // console.info(`[response] [${JSON.stringify(response, null, 2)}]`);
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  // const errorMessage: string | undefined = err.response?.data?.message?.split(':')?.[1];
  //   GenUtil.isWEB()
  //     ? alert(errorMessage ? errorMessage : 'error')
  //     : Alert.alert('Error', errorMessage ? errorMessage : 'error', [
  //       { text: 'OK', onPress: () => console.log('OK Pressed') },
  //     ]);
  //   void Promise.reject(err);
  // console.error(`[response error] [${JSON.stringify(error)}]`);
  if (error.response && error.response.status === 401) {
    store.dispatch(setUser(null));
    store.dispatch(setUserProfile(null));
    StorageUtil.removeKey(Constant.AUTH_TOKEN);
    StorageUtil.removeKey(Constant.FIREBASE_TOKEN);
  }
  return Promise.reject(error);
};

export function setupApiInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
