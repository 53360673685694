import React from 'react';
import { StyleSheet, Image, FlatList, ImageSourcePropType } from 'react-native';

import { View, Text } from 'src/components/Themed';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import { Color } from 'src/constant/Color';

const UserProfileLifestyleAndHobbiesComp = ({
  profileData,
}: {
  profileData: Partial<ProfileResp>;
}) => {
  interface lifeStylesAndHobbies {
    id: number;
    icon: ImageSourcePropType;
    label: string;
    color: string;
    value: string;
  }
  const INFO_ROWS: lifeStylesAndHobbies[] = [
    {
      id: 1,
      icon: require('src/assets/images/profileIcons/Vectordiet.png'),
      label: 'Diet',
      color: '#43A047',
      value: profileData.diet,
    },
    {
      id: 2,
      icon: require('src/assets/images/profileIcons/Framesmoke.png'),
      label: 'Smoking',
      color: '#BBDDBB',
      value: profileData.smoking,
    },
    {
      id: 3,
      icon: require('src/assets/images/profileIcons/Framedrink.png'),
      label: 'Drinking',
      color: '#26A69A',
      value: profileData.drinking,
    },
    {
      id: 4,
      icon: require('src/assets/images/profileIcons/Frameassets.png'),
      label: 'Assets',
      color: '#00BCD4',
      value: profileData.assets?.join(', '),
    },
    {
      id: 5,
      icon: require('src/assets/images/profileIcons/Framepets.png'),
      label: 'Pets',
      color: '#FE5E94',
      value: profileData.pets?.join(', '),
    },
    {
      id: 6,
      icon: require('src/assets/images/profileIcons/Framehobbies.png'),
      label: 'Hobbies and Interests',
      color: '#BA68C8',
      value: profileData.favHobbyAndInterests?.join(', '),
    },
    {
      id: 7,
      icon: require('src/assets/images/profileIcons/Framemusic.png'),
      label: 'Music',
      color: '#4CAF50',
      value: profileData.favMusics?.join(', '),
    },
    {
      id: 8,
      icon: require('src/assets/images/profileIcons/Vectorbooks.png'),
      label: 'Books',
      color: '#FFA726',
      value: profileData.favBookGenres?.join(', '),
    },
    {
      id: 9,
      icon: require('src/assets/images/profileIcons/Vectorsports.png'),
      label: 'Activity and Sports',
      color: '#C3D31E',
      value: profileData.favActivityAndSports?.join(', '),
    },
    {
      id: 10,
      icon: require('src/assets/images/profileIcons/Framecuisines.png'),
      label: 'Cuisines',
      color: '#8D6E63',
      value: profileData.favCuisines?.join(', '),
    },
    {
      id: 11,
      icon: require('src/assets/images/profileIcons/Vectormovies.png'),
      label: 'Movies',
      color: '#FF7043',
      value: profileData.lifestyleExtra?.favMovies?.join(', '),
    },
  ];

  const userLifestyleDetails = INFO_ROWS.filter(
    (item) => item.value && item.value !== '' && item.value !== null && item.id <= 4,
  );
  const userHobbyDetails = INFO_ROWS.filter(
    (item) => item.value && item.value !== '' && item.value !== null && item.id > 4,
  );

  const renderLifeStyles = ({ item }: { item: lifeStylesAndHobbies }) => (
    <View style={styles.info}>
      <View style={[styles.iconContainer, { backgroundColor: item.color }]}>
        <Image source={item.icon} style={styles.iconStyle1} />
      </View>
      <View>
        <Text style={styles.infoLabel}>{item.label}</Text>
        <Text style={styles.infoValue}>{item.value}</Text>
      </View>
    </View>
  );

  const renderHobbies = ({ item }: { item: lifeStylesAndHobbies }) => (
    <View style={[styles.info, { marginTop: 18 }]}>
      <View style={[styles.iconContainer, { backgroundColor: item.color }]}>
        <Image source={item.icon} style={styles.iconStyle1} />
      </View>
      <View>
        <Text style={styles.infoLabel}>{item.label}</Text>
        <Text style={[styles.infoValue, { width: 250 }]}>{item.value}</Text>
      </View>
    </View>
  );

  if (userLifestyleDetails.length > 0 || userHobbyDetails.length > 0)
    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.headerText}>LifeStyle and Hobbies</Text>
        </View>
        <FlatList
          data={userLifestyleDetails}
          renderItem={renderLifeStyles}
          keyExtractor={(item) => item.id.toString()}
          numColumns={2}
          columnWrapperStyle={{ marginTop: 18 }}
        />
        <FlatList
          data={userHobbyDetails}
          renderItem={renderHobbies}
          keyExtractor={(item) => item.id.toString()}
          numColumns={1}
        />
      </View>
    );
};

const styles = StyleSheet.create({
  container: {
    flex: GenUtil.isWEB() ? null : 1,
    backgroundColor: Color.white,
    paddingHorizontal: 12,
    paddingVertical: 16,
    marginHorizontal: 16,
    marginVertical: 5,
    borderRadius: 20,
    elevation: 6,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
  },
  header: {
    alignItems: 'flex-start',
  },
  headerText: {
    fontFamily: EFonts.SORA_MEDIUM,
    fontSize: 17,
    lineHeight: 18,
    color: Color.lightBlack,
  },
  infoLabel: {
    flex: 1,
    fontFamily: EFonts.SORA_REGULAR,
    position: 'relative',
    top: 0,
    fontStyle: 'normal',
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
    color: Color.lightGrey,
  },
  infoValue: {
    flex: 1,
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
  },
  info: {
    flex: 1,
    flexDirection: 'row',
  },
  iconStyle1: {
    width: 18,
    height: 18,
  },
  iconContainer: {
    width: 34,
    height: 34,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
});

export default UserProfileLifestyleAndHobbiesComp;
