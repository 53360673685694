import axios, { AxiosResponse } from 'axios';
import Endpoints from './Endpoints';
import { HttpResponse } from 'src/common/response/base.resp';
import {
  CanViewContactsResp,
  ContactsViewedResponse,
  ViewContactResp,
} from 'src/common/dto/contact.dto';

export class ContactService {
  static getContactViewed = async (): Promise<HttpResponse<ContactsViewedResponse>> => {
    const response = await axios.get<any, AxiosResponse<HttpResponse<ContactsViewedResponse>>>(
      Endpoints.CONTACT_VIEWED,
    );

    return HttpResponse.fromRes(response.data);
  };

  static getContactViewers = async (): Promise<HttpResponse<ContactsViewedResponse>> => {
    const response = await axios.get<any, AxiosResponse<HttpResponse<ContactsViewedResponse>>>(
      Endpoints.CONTACT_VIEWERS,
    );

    return HttpResponse.fromRes(response.data);
  };

  static canViewContact = async (
    profileUuid: string,
  ): Promise<HttpResponse<CanViewContactsResp>> => {
    const response = await axios.get<any, AxiosResponse<HttpResponse<CanViewContactsResp>>>(
      `${Endpoints.CAN_VIEW_CONTACT}/${profileUuid}`,
    );

    return HttpResponse.fromRes(response.data);
  };

  static sendViewContact = async (profileUuid: string): Promise<HttpResponse<ViewContactResp>> => {
    const response = await axios.post<any, AxiosResponse<HttpResponse<ViewContactResp>>>(
      Endpoints.SEND_CONTACT_VIEW,
      {
        profileUuid,
      },
    );

    return HttpResponse.fromRes(response.data);
  };
}
