import { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, Image, ImageSourcePropType } from 'react-native';

import { Text, View } from 'src/components/Themed';
import { Divider } from 'react-native-paper';
import { Color } from 'src/constant/Color';
import { Icon } from 'react-native-elements';
import { GenUtil } from 'src/util/GenUtil';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { ProfileResp } from 'src/common/response/profile.resp';
import { ProfessionDataMap, QualificationDataMap } from 'src/common/data/education.data';
import EFonts from 'src/constant/EFonts';
import { Gender } from 'src/common/models/profile.model';
import { LanguageDataMap } from 'src/common/data/language.data';
import { ReligionData } from 'src/common/data/religion.data';

const UserProfilePartnerPreferenceComp = ({
  profileData,
}: {
  profileData: Partial<ProfileResp>;
}) => {
  const partnerPreference = profileData.partnerPreference;
  const user: Partial<ProfileResp> = useSelector((state: RootState) => state?.user?.userProfile);
  const [totalCount, setToatalCount] = useState(0);
  const [totalMatchCount, setToatalMatchCount] = useState(0);
  const [isAgeMatch, setIsAgeMatch] = useState(false);
  const [isHeightMatch, setIsheightMatch] = useState(false);
  const [isIncomeMatch, setIsIncomeMatch] = useState(false);
  const [isMotherTongueMatch, setIsMotherTongueMatch] = useState(false);
  const [isReligionMatch, setIsReligionMatch] = useState(false);
  const [isCommunityMatch, setIsCommunityMatch] = useState(false);
  const [isSubCommunityMatch, setIsSubCommunityMatch] = useState(false);
  const [isMaritalStatusMatch, setIsMaritalStatusMatch] = useState(false);
  const [isProfileHandlerMatch, setIsProfileHandlerMatch] = useState(false);
  const [isEducationMatch, setIsEducationMatch] = useState(false);
  const [isCountryMatch, setIsCountryMatch] = useState(false);
  const [isStateMatch, setIsStateMatch] = useState(false);
  const [isCityMatch, setIsCityMatch] = useState(false);
  const incomeCurrency = user?.currentCountry === 'India' ? 'Rs ' : '$ ';
  const incomeMultiplier = user?.currentCountry === 'India' ? ' Lpa' : ' K';
  const [isDietMatch, setIsDietMatch] = useState(false);
  const [isProfessionMatch, setIsProfessionMatch] = useState(false);
  const minPartnerIncome =
    profileData.currentCountry === 'India'
      ? partnerPreference?.inrMinIncome
      : partnerPreference?.usdMinIncome;
  const maxPartnerIncome =
    profileData.currentCountry === 'India'
      ? partnerPreference?.inrMaxIncome
      : partnerPreference?.usdMaxIncome;
  const malePlaceHolderImg =
    require('src/assets/images/placeholder-male.png') as ImageSourcePropType;
  const femalePlaceHolderImg =
    require('src/assets/images/placeholder-female.png') as ImageSourcePropType;
  const loggedInUseravatar =
    user?.profilePhoto === undefined || GenUtil.isEmpty(user?.profilePhoto)
      ? user?.gender === Gender.Male
        ? malePlaceHolderImg
        : femalePlaceHolderImg
      : {
          uri: user?.profilePhoto?.originalUrl,
        };
  const otherUseravatar =
    profileData.profilePhoto === undefined || GenUtil.isEmpty(profileData.profilePhoto)
      ? profileData.gender === Gender.Male
        ? malePlaceHolderImg
        : femalePlaceHolderImg
      : {
          uri: profileData.profilePhoto?.originalUrl,
        };

  interface subCommunityInterface {
    name: string;
    id: number;
    religionId: number;
    casteId: number;
    order: number;
  }

  const educationString = () => {
    let educations = '';

    partnerPreference?.qualificationIds?.map((value, index) => {
      educations = educations + QualificationDataMap[value]?.name ?? '';
      if (index < partnerPreference?.qualificationIds?.length - 1) educations += ', ';
    });
    return educations;
  };
  const professionString = () => {
    let professions = '';

    partnerPreference?.professionIds?.map((value, index) => {
      professions = professions + ProfessionDataMap[value]?.name ?? '';
      if (index < partnerPreference?.professionIds?.length - 1) professions += ', ';
    });
    return professions;
  };
  const motherTongueString = () => {
    let motherTongues = '';

    partnerPreference?.motherTongueIds?.map((value, index) => {
      motherTongues = motherTongues + LanguageDataMap[value]?.name ?? '';
      if (index < partnerPreference?.motherTongueIds?.length - 1) motherTongues += ', ';
    });
    return motherTongues;
  };
  const communityString = () => {
    let communities = '';
    ReligionData?.map((res) => {
      if (
        partnerPreference?.religions?.includes(res?.type) &&
        !GenUtil.isEmpty(res?.communityMap)
      ) {
        partnerPreference?.communityIds?.map((id, index) => {
          if (res?.communityMap?.[id]) {
            communities = communities + res?.communityMap?.[id]?.name;
            if (index < partnerPreference?.communityIds?.length - 1) communities += ', ';
          }
        });
      }
    });
    return communities;
  };

  const subCommunityString = () => {
    let subCommunities = '';
    const subCommunitiesObj = {} as { [key: string]: subCommunityInterface };
    ReligionData?.map((res) => {
      if (
        partnerPreference?.religions?.includes(res?.type) &&
        !GenUtil.isEmpty(res?.subCommunityMap)
      ) {
        partnerPreference?.subCommunityIds?.map((id: number) => {
          if (res?.subCommunityMap?.[id]) {
            subCommunitiesObj[id] = res?.subCommunityMap?.[id];
          }
        });
      }
    });
    Object.keys(subCommunitiesObj)?.map((val, key) => {
      if (partnerPreference?.communityIds?.includes(subCommunitiesObj?.[val]?.casteId)) {
        subCommunities = subCommunities + subCommunitiesObj?.[val]?.name;
        if (key < partnerPreference?.subCommunityIds?.length - 1) subCommunities += ', ';
      }
    });
    return subCommunities;
  };

  const countMatches = () => {
    let totalCount = 0,
      matchCount = 0;
    if (partnerPreference?.minAge && partnerPreference?.maxAge) {
      totalCount++;
      if (
        user?.age &&
        user?.age >= partnerPreference?.minAge &&
        user?.age <= partnerPreference?.maxAge
      ) {
        matchCount++;
        setIsAgeMatch(true);
      }
    }

    if (partnerPreference?.minHeight && partnerPreference?.maxHeight) {
      totalCount++;
      if (
        user?.height &&
        user?.height >= partnerPreference?.minHeight &&
        user?.height <= partnerPreference?.maxHeight
      ) {
        matchCount++;
        setIsheightMatch(true);
      }
    }

    if (minPartnerIncome >= 0 && maxPartnerIncome >= 0) {
      totalCount++;
      const userIncome = user?.currentCountry === 'India' ? user?.inrIncome : user?.usdIncome;
      if (userIncome && userIncome >= minPartnerIncome && userIncome <= maxPartnerIncome) {
        matchCount++;
        setIsIncomeMatch(true);
      }
    }

    if (partnerPreference?.maritalStatuses && partnerPreference?.maritalStatuses?.length != 0) {
      totalCount++;
      if (
        user?.maritalStatus &&
        partnerPreference?.maritalStatuses?.includes(user?.maritalStatus)
      ) {
        matchCount++;
        setIsMaritalStatusMatch(true);
      }
    }

    if (partnerPreference?.profileHandlers && partnerPreference?.profileHandlers?.length != 0) {
      totalCount++;
      if (
        user?.profileHandler &&
        partnerPreference?.profileHandlers?.includes(user?.profileHandler)
      ) {
        matchCount++;
        setIsProfileHandlerMatch(true);
      }
    }

    if (partnerPreference?.motherTongueIds && partnerPreference?.motherTongueIds?.length != 0) {
      totalCount++;
      if (
        user?.motherTongueId &&
        partnerPreference?.motherTongueIds?.includes(user?.motherTongueId)
      ) {
        matchCount++;
        setIsMotherTongueMatch(true);
      }
    }

    if (partnerPreference?.religions && partnerPreference?.religions?.length != 0) {
      totalCount++;
      if (user?.religion && partnerPreference?.religions?.includes(user?.religion)) {
        matchCount++;
        setIsReligionMatch(true);
      }
    }

    if (partnerPreference?.communityIds && partnerPreference?.communityIds?.length != 0) {
      totalCount++;
      if (user?.communityId && partnerPreference?.communityIds?.includes(user?.communityId)) {
        matchCount++;
        setIsCommunityMatch(true);
      }
    }

    if (partnerPreference?.subCommunityIds && partnerPreference?.subCommunityIds?.length != 0) {
      totalCount++;
      if (
        user?.subCommunityId &&
        partnerPreference?.subCommunityIds?.includes(user?.subCommunityId)
      ) {
        matchCount++;
        setIsSubCommunityMatch(true);
      }
    }

    if (partnerPreference?.countries && partnerPreference?.countries?.length != 0) {
      totalCount++;
      if (user?.currentCountry && partnerPreference?.countries?.includes(user?.currentCountry)) {
        matchCount++;
        setIsCountryMatch(true);
      }
    }

    if (partnerPreference?.states && partnerPreference?.states?.length != 0) {
      totalCount++;
      if (user?.currentState && partnerPreference?.states?.includes(user?.currentState)) {
        matchCount++;
        setIsStateMatch(true);
      }
    }

    if (partnerPreference?.cities && partnerPreference?.cities?.length != 0) {
      totalCount++;
      if (user?.currentCity && partnerPreference?.cities?.includes(user?.currentCity)) {
        matchCount++;
        setIsCityMatch(true);
      }
    }

    if (partnerPreference?.professionIds && partnerPreference?.professionIds?.length != 0) {
      totalCount++;
      if (user?.professionId && partnerPreference?.professionIds?.includes(user?.professionId)) {
        matchCount++;
        setIsProfessionMatch(true);
      }
    }

    if (partnerPreference?.diets && partnerPreference?.diets?.length != 0) {
      totalCount++;
      if (user?.diet && partnerPreference?.diets?.includes(user?.diet)) {
        matchCount++;
        setIsDietMatch(true);
      }
    }

    if (partnerPreference?.qualificationIds && partnerPreference?.qualificationIds?.length != 0) {
      totalCount++;
      if (
        user?.qualificationIds &&
        partnerPreference?.qualificationIds?.some((item) => user?.qualificationIds?.includes(item))
      ) {
        matchCount++;
        setIsEducationMatch(true);
      }
    }
    setToatalCount(totalCount);
    setToatalMatchCount(matchCount);
  };

  useEffect(() => {
    countMatches();
  }, []);

  return (
    <>
      {!GenUtil.isEmpty(partnerPreference) ? (
        <View style={styles.container}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <View style={styles.rectangle}>
              <Text style={styles.heading}>Preferencess</Text>
              <View style={styles.circularImageContainer}>
                <View style={styles.circularImageView}>
                  <Image source={loggedInUseravatar} style={styles.circularImage} />
                </View>
                <View style={styles.circularImageView}>
                  <Image source={otherUseravatar} style={styles.circularImage} />
                </View>
              </View>
            </View>
            <View style={styles.heartCircle}>
              <Image
                source={require('src/assets/images/pofileDetailIcons/hearts.png')}
                style={styles.heartImage}
                resizeMode="contain"
              />
            </View>

            <Text style={styles.matchedPreferenceCount}>
              You and {profileData?.fullName} match {totalMatchCount}/{totalCount} preferences
            </Text>

            <View>
              {partnerPreference?.minAge && partnerPreference?.maxAge && (
                <>
                  <View style={styles.row}>
                    <View style={{ flex: 5 }}>
                      <Text style={styles.preferenceType}>Age</Text>
                      <Text style={styles.preferenceValue}>
                        {partnerPreference?.minAge} - {partnerPreference?.maxAge}
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Icon
                        name="check-all"
                        type="material-community"
                        color={isAgeMatch ? '#4CAF50' : '#B6B6B6'}
                        size={22}
                      />
                    </View>
                  </View>

                  <Divider style={styles.divider} />
                </>
              )}

              {partnerPreference?.minHeight && partnerPreference?.maxHeight && (
                <>
                  <View style={styles.row}>
                    <View style={{ flex: 5 }}>
                      <Text style={styles.preferenceType}>Height</Text>
                      <Text style={styles.preferenceValue}>
                        {GenUtil.convertHeighttoHeightString(partnerPreference?.minHeight)} -{' '}
                        {GenUtil.convertHeighttoHeightString(partnerPreference?.maxHeight)}
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Icon
                        name="check-all"
                        type="material-community"
                        color={isHeightMatch ? '#4CAF50' : '#B6B6B6'}
                        size={22}
                      />
                    </View>
                  </View>

                  <Divider style={styles.divider} />
                </>
              )}
              {minPartnerIncome >= 0 && maxPartnerIncome >= 0 && (
                <>
                  <View style={styles.row}>
                    <View style={{ flex: 5 }}>
                      <Text style={styles.preferenceType}>Income</Text>
                      <Text style={styles.preferenceValue}>
                        {incomeCurrency + String(minPartnerIncome) + incomeMultiplier} -{' '}
                        {incomeCurrency + String(maxPartnerIncome) + incomeMultiplier}
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Icon
                        name="check-all"
                        type="material-community"
                        color={isIncomeMatch ? '#4CAF50' : '#B6B6B6'}
                        size={22}
                      />
                    </View>
                  </View>

                  <Divider style={styles.divider} />
                </>
              )}

              {partnerPreference?.maritalStatuses &&
                partnerPreference?.maritalStatuses?.length != 0 && (
                  <>
                    <View style={styles.row}>
                      <View style={{ flex: 5 }}>
                        <Text style={styles.preferenceType}>Marital Status</Text>
                        <Text style={styles.preferenceValue}>
                          {partnerPreference?.maritalStatuses?.join(', ')}
                        </Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Icon
                          name="check-all"
                          type="material-community"
                          color={isMaritalStatusMatch ? '#4CAF50' : '#B6B6B6'}
                          size={22}
                        />
                      </View>
                    </View>
                    <Divider style={styles.divider} />
                  </>
                )}

              {partnerPreference?.profileHandlers &&
                partnerPreference?.profileHandlers?.length != 0 && (
                  <>
                    <View style={styles.row}>
                      <View style={{ flex: 5 }}>
                        <Text style={styles.preferenceType}>Profile Handler</Text>
                        <Text style={styles.preferenceValue}>
                          {partnerPreference?.profileHandlers?.join(', ')}
                        </Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Icon
                          name="check-all"
                          type="material-community"
                          color={isProfileHandlerMatch ? '#4CAF50' : '#B6B6B6'}
                          size={22}
                        />
                      </View>
                    </View>
                    <Divider style={styles.divider} />
                  </>
                )}
              {partnerPreference?.motherTongueIds &&
                partnerPreference?.motherTongueIds?.length != 0 && (
                  <>
                    <View style={styles.row}>
                      <View style={{ flex: 5 }}>
                        <Text style={styles.preferenceType}>Mother Tongue</Text>
                        <Text style={styles.preferenceValue}>{motherTongueString()}</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Icon
                          name="check-all"
                          type="material-community"
                          color={isMotherTongueMatch ? '#4CAF50' : '#B6B6B6'}
                          size={22}
                        />
                      </View>
                    </View>
                    <Divider style={styles.divider} />
                  </>
                )}
              {partnerPreference?.religions && partnerPreference?.religions?.length != 0 && (
                <>
                  <View style={styles.row}>
                    <View style={{ flex: 5 }}>
                      <Text style={styles.preferenceType}>Religion</Text>
                      <Text style={styles.preferenceValue}>
                        {partnerPreference?.religions?.join(', ')}
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Icon
                        name="check-all"
                        type="material-community"
                        color={isReligionMatch ? '#4CAF50' : '#B6B6B6'}
                        size={22}
                      />
                    </View>
                  </View>
                  <Divider style={styles.divider} />
                </>
              )}

              {partnerPreference?.communityIds && partnerPreference?.communityIds?.length != 0 && (
                <>
                  <View style={styles.row}>
                    <View style={{ flex: 5 }}>
                      <Text style={styles.preferenceType}>Communities</Text>
                      <Text style={styles.preferenceValue}>{communityString()}</Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Icon
                        name="check-all"
                        type="material-community"
                        color={isCommunityMatch ? '#4CAF50' : '#B6B6B6'}
                        size={22}
                      />
                    </View>
                  </View>
                  <Divider style={styles.divider} />
                </>
              )}
              {partnerPreference?.subCommunityIds &&
                partnerPreference?.subCommunityIds?.length != 0 && (
                  <>
                    <View style={styles.row}>
                      <View style={{ flex: 5 }}>
                        <Text style={styles.preferenceType}>Sub Communities</Text>
                        <Text style={styles.preferenceValue}>{subCommunityString()}</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Icon
                          name="check-all"
                          type="material-community"
                          color={isSubCommunityMatch ? '#4CAF50' : '#B6B6B6'}
                          size={22}
                        />
                      </View>
                    </View>
                    <Divider style={styles.divider} />
                  </>
                )}
              {partnerPreference?.countries && partnerPreference?.countries?.length != 0 && (
                <>
                  <View style={styles.row}>
                    <View style={{ flex: 5 }}>
                      <Text style={styles.preferenceType}>Country</Text>
                      <Text style={styles.preferenceValue}>
                        {partnerPreference?.countries?.join(', ')}
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Icon
                        name="check-all"
                        type="material-community"
                        color={isCountryMatch ? '#4CAF50' : '#B6B6B6'}
                        size={22}
                      />
                    </View>
                  </View>
                  <Divider style={styles.divider} />
                </>
              )}
              {partnerPreference?.states && partnerPreference?.states?.length != 0 && (
                <>
                  <View style={styles.row}>
                    <View style={{ flex: 5 }}>
                      <Text style={styles.preferenceType}>State</Text>
                      <Text style={styles.preferenceValue}>
                        {partnerPreference?.states?.join(', ')}
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Icon
                        name="check-all"
                        type="material-community"
                        color={isStateMatch ? '#4CAF50' : '#B6B6B6'}
                        size={22}
                      />
                    </View>
                  </View>
                  <Divider style={styles.divider} />
                </>
              )}
              {partnerPreference?.cities && partnerPreference?.cities?.length != 0 && (
                <>
                  <View style={styles.row}>
                    <View style={{ flex: 5 }}>
                      <Text style={styles.preferenceType}>City</Text>
                      <Text style={styles.preferenceValue}>
                        {partnerPreference?.cities?.join(', ')}
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Icon
                        name="check-all"
                        type="material-community"
                        color={isCityMatch ? '#4CAF50' : '#B6B6B6'}
                        size={22}
                      />
                    </View>
                  </View>
                  <Divider style={styles.divider} />
                </>
              )}
              {partnerPreference?.professionIds &&
                partnerPreference?.professionIds?.length != 0 && (
                  <>
                    <View style={styles.row}>
                      <View style={{ flex: 5 }}>
                        <Text style={styles.preferenceType}>Professional Area</Text>
                        <Text style={styles.preferenceValue}>{professionString()}</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Icon
                          name="check-all"
                          type="material-community"
                          color={isProfessionMatch ? '#4CAF50' : '#B6B6B6'}
                          size={22}
                        />
                      </View>
                    </View>
                    <Divider style={styles.divider} />
                  </>
                )}

              {partnerPreference?.diets && partnerPreference?.diets?.length != 0 && (
                <>
                  <View style={styles.row}>
                    <View style={{ flex: 5 }}>
                      <Text style={styles.preferenceType}>Diet</Text>
                      <Text style={styles.preferenceValue}>
                        {partnerPreference?.diets?.join(', ')}
                      </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Icon
                        name="check-all"
                        type="material-community"
                        color={isDietMatch ? '#4CAF50' : '#B6B6B6'}
                        size={22}
                      />
                    </View>
                  </View>
                  <Divider style={styles.divider} />
                </>
              )}

              {partnerPreference?.qualificationIds &&
                partnerPreference?.qualificationIds?.length != 0 && (
                  <>
                    <View style={styles.row}>
                      <View style={{ flex: 5 }}>
                        <Text style={styles.preferenceType}>Education</Text>
                        <Text style={styles.preferenceValue}>{educationString()}</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Icon
                          name="check-all"
                          type="material-community"
                          color={isEducationMatch ? '#4CAF50' : '#B6B6B6'}
                          size={22}
                        />
                      </View>
                    </View>
                  </>
                )}
            </View>
          </ScrollView>
        </View>
      ) : (
        <></>
      )}
    </>
  );
};
const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    paddingTop: 16,
    paddingBottom: 6,
    paddingHorizontal: 12,
    margin: 5,
    marginHorizontal: 16,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
    elevation: 6,
  },
  rectangle: {
    borderRadius: 20,
    borderBottomLeftRadius: 40,
    borderBottomRightRadius: 40,
    width: '100%',
    height: 130,
    backgroundColor: '#e2d5f0',
    alignItems: 'center',
    marginBottom: '20%',
  },
  circularImageView: {
    borderRadius: 140 / 2,
    height: 140,
    width: 140,
  },
  circularImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    borderRadius: 140 / 2,
  },
  row: { flexDirection: 'row' },
  heartCircle: {
    borderWidth: 2,
    width: GenUtil.isWEB() ? 64 : 75,
    height: GenUtil.isWEB() ? 64 : 'auto',
    borderRadius: GenUtil.isWEB() ? 64 / 2 : 75 / 2,
    position: 'absolute',
    alignSelf: 'center',
    marginTop: '25%',
    backgroundColor: Color.purple,
    borderColor: '#FFFFFF',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heartImage: {
    width: '35%',
    height: '35%',
  },
  heading: {
    marginTop: 16,
    marginBottom: 4,
    fontSize: 17,
    fontFamily: EFonts.SORA_MEDIUM,
  },
  circularImageContainer: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
  matchedPreferenceCount: {
    color: Color.lightGrey,
    fontSize: 12,
    fontFamily: EFonts.SORA_REGULAR,
    lineHeight: 15,
    alignSelf: 'center',
    paddingBottom: 30,
  },
  preferenceType: {
    fontSize: GenUtil.isWEB() ? 12 : 10,
    fontFamily: EFonts.SORA_REGULAR,
    color: Color.lightGrey,
    marginBottom: 5,
  },
  preferenceValue: {
    color: Color.lightBlack,
    fontSize: GenUtil.isWEB() ? 12 : 10,
    fontFamily: EFonts.SORA_SEMIBOLD,
    lineHeight: 20,
    marginBottom: 10,
  },
  divider: {
    marginBottom: 10,
  },
});

export default UserProfilePartnerPreferenceComp;
