/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { View, Image, Text } from 'react-native';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import EFonts from 'src/constant/EFonts';
import { Icon } from 'react-native-elements';

export default function ProfileListAndChatHeader({
  heading,
  rightIcon = null,
  onRightIconPressed = () => {},
}) {
  const navigation = useNavigation();
  function onBackPress() {
    navigation.navigate('search');
  }

  return (
    <View style={styles.container}>
      <View style={styles.iconAndText}>
        <TouchableOpacity onPress={onBackPress}>
          {/* <Image source={require('src/assets/icons/leftArrowBlack.png')} style={styles.icon} /> */}
          <Icon name="arrow-back" />
        </TouchableOpacity>
        <Text style={styles.text}>{heading}</Text>
      </View>
      <TouchableOpacity onPress={onRightIconPressed}>{rightIcon}</TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingVertical: 17,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    backgroundColor: '#FFFFFF',
    elevation: 6,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
  },
  icon: {
    height: 16,
    width: 16,
  },
  text: {
    marginLeft: 19,
    fontSize: 17,
    fontFamily: EFonts.SORA_MEDIUM,
    color: '#2E2835',
  },
  iconAndText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
