import { Image, StyleSheet, Text, View } from 'react-native';
import { Color } from 'src/constant/Color';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { GenUtil } from 'src/util/GenUtil';

const WelcomeCard = () => {
  const { userProfile } = useSelector((state: RootState) => state.user);

  return (
    <View style={styles.cardContainer}>
      <Image style={styles.image} source={GenUtil.getProfilePic(userProfile)} />
      <View>
        <Text style={[styles.text, styles.heading]}>
          Welcome, {userProfile?.fullName?.split(' ')[0]}
        </Text>
        <Text style={styles.text}>Find your forever love with us.</Text>
      </View>
    </View>
  );
};

export default WelcomeCard;

const styles = StyleSheet.create({
  cardContainer: {
    flex: 1,
    alignItems: 'center',
    borderRadius: 12,
    backgroundColor: Color.purple,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 10,
    padding: 20,
    minHeight: 92,
    maxHeight: 92,
    width: '90%',
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 50,
    marginRight: 20,
    borderWidth: 3,
    borderColor: Color.white,
  },
  text: {
    color: Color.white,
    fontSize: 15,
    lineHeight: 20,
  },
  heading: {
    fontWeight: '600',
    fontSize: 20,
    marginBottom: 5,
  },
});
