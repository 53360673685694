import React, { useEffect, useState } from 'react';
import { Snackbar, Text } from 'react-native-paper';
import { AlertTypeEnum, closeError } from 'src/store/reducer/errorReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { Color } from 'src/constant/Color';
import { StyleSheet, View } from 'react-native';
import { LayoutConstant } from 'src/constant/LayoutConstant';

const ErrorComponent = () => {
  const { open, alertType, message } = useSelector((state: RootState) => state.error);
  const dispatch = useDispatch();

  const [alertStyle, setAlertStyle] = useState({
    backgroundColor: Color.blue,
  });

  useEffect(() => {
    switch ((open && alertType) || AlertTypeEnum.Default) {
      case AlertTypeEnum.Info:
        setAlertStyle({
          backgroundColor: Color.blue,
        });
        break;
      case AlertTypeEnum.Error:
        setAlertStyle({
          backgroundColor: Color.red,
        });
        break;
      case AlertTypeEnum.Success:
        setAlertStyle({
          backgroundColor: Color.green,
        });
        break;
      default:
        setAlertStyle({
          backgroundColor: Color.purple,
        });
    }
  }, [open, alertType]);

  const closeMe = () => {
    dispatch(closeError());
  };

  return (
    <>
      {open && (
        <View style={styles.snackbarContainer}>
          <Snackbar
            style={alertStyle}
            visible
            duration={2000}
            onDismiss={closeMe}
            action={{
              label: 'Ok',
              onPress: closeMe,
              labelStyle: { color: Color.white },
            }}
          >
            <Text style={{ color: Color.white }}>{message}</Text>
          </Snackbar>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  snackbarContainer: {
    width: '100%',
    maxWidth: LayoutConstant.desktopWidth,
    alignSelf: 'center',
  },
});

export default ErrorComponent;
