import { View, Text, StyleSheet, Image } from 'react-native';
import React from 'react';

import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';

const CollegePill = () => {
  return (
    <View style={styles.rowWrapper}>
      <Image source={require('src/assets/images/school.png')} style={styles.image} />
      <View style={StyleSheet.compose(styles.pillWrapper, styles.pillWrapperCollege)}>
        <Text style={styles.pillText}>{'You both went to same college'}</Text>
      </View>
    </View>
  );
};

const ProfessionPill = ({ profession = null }: { profession: string }) => {
  return (
    <View style={styles.rowWrapper}>
      <Image source={require('src/assets/images/programmer.png')} style={styles.image} />
      <View style={StyleSheet.compose(styles.pillWrapper, styles.pillWrapperProfession)}>
        <Text style={styles.pillText}>
          {profession ? `You both are ${profession}` : 'You both have work match'}
        </Text>
      </View>
    </View>
  );
};

const ReligionPill = () => {
  return (
    <View style={styles.rowWrapper}>
      <Image source={require('src/assets/images/hands.png')} style={styles.image} />
      <View style={StyleSheet.compose(styles.pillWrapper, styles.pillWrapperReligion)}>
        <Text style={styles.pillText}>{'You both have same religion'}</Text>
      </View>
    </View>
  );
};

const CityPill = () => {
  return (
    <View style={styles.rowWrapper}>
      <Image source={require('src/assets/images/city-building.png')} style={styles.image} />
      <View style={StyleSheet.compose(styles.pillWrapper, styles.pillWrapperCity)}>
        <Text style={styles.pillText}>{'You both belong to same city'}</Text>
      </View>
    </View>
  );
};

const UserProfileMutualMatchesComp = ({
  mutualMatches = {} as ProfileResp['mutualMatchData'],
}: {
  mutualMatches: ProfileResp['mutualMatchData'];
}) => {
  const matches =
    mutualMatches && !GenUtil.isEmpty(mutualMatches) ? Object.keys(mutualMatches) : [];
  if (matches.length > 0)
    return (
      <View style={styles.container}>
        <Text style={styles.headerText}>Mutual Matches</Text>
        <Text style={styles.subText}>{`You both have ${matches.length} mutual match${
          matches.length > 1 ? 'es' : ''
        }`}</Text>
        <View>
          {matches.map((match, index) => (
            <View key={index}>
              {match === 'College' && <CollegePill />}
              {match === 'Profession' && (
                <ProfessionPill profession={mutualMatches?.[match]?.[0]} />
              )}
              {match === 'Religion' && <ReligionPill />}
              {match === 'City' && <CityPill />}
            </View>
          ))}
        </View>
      </View>
    );
};

const styles = StyleSheet.create({
  container: {
    flex: GenUtil.isWEB() ? null : 1,
    backgroundColor: Color.white,
    paddingHorizontal: 12,
    paddingVertical: 16,
    marginHorizontal: 16,
    marginVertical: 5,
    borderRadius: 20,
    elevation: 6,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
  },
  rowWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    fontFamily: 'sora-medium',
    fontSize: 17,
  },
  subText: {
    fontFamily: 'sora-medium',
    fontSize: 12,
    color: Color.gray,
    marginTop: 5,
    marginBottom: 10,
  },
  pillWrapper: {
    marginVertical: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: 'rgba(246, 195, 88, 0.2)',
    borderRadius: 80,
    alignItems: 'center',
    marginLeft: 15,
  },
  pillWrapperCollege: {
    backgroundColor: '#3495B7',
  },
  pillWrapperProfession: {
    backgroundColor: '#FF867C',
  },
  pillWrapperReligion: {
    backgroundColor: '#F3C308',
  },
  pillWrapperCity: {
    backgroundColor: '#43B38D',
  },
  image: {
    height: 24,
    width: 24,
  },
  pillText: {
    fontFamily: 'sora-regular',
    fontSize: 12,
    color: Color.white,
  },
});

export default UserProfileMutualMatchesComp;
