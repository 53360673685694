/**
 * Actual Profile, that contains the required information
 */
import { QualificationId } from 'src/common/data/education.data';
import { UserRelation } from 'src/common/models/user.model';

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export enum ProfileHandler {
  Self = 'Self',
  Parent = 'Parent',
  Relative = 'Relative',
  Sibling = 'Sibling',
  Friend = 'Friend',
  MarriageBureau = 'Marriage Bureau',
  Other = 'Others',
}

export enum MaritalStatus {
  NeverMarried = 'Never Married',
  Divorced = 'Divorced',
  AwaitingDivorce = 'Awaiting Divorce',
  Annulled = 'Annulled',
  Widow = 'Widow',
}

export enum ReligionType {
  Hindu = 'Hindu',
  Muslim = 'Muslim',
  Sikh = 'Sikh',
  Christian = 'Christian',
  Buddhist = 'Buddhist',
  Jain = 'Jain',
  Parsi = 'Parsi',
  Jewish = 'Jewish',
  Bahai = 'Bahai',
  Spiritual = 'Spiritual',
  Atheist = 'Atheist',
  Other = 'Other',
}

export enum ChildStatus {
  No = 'No',
  LivingTogether = 'Yes, Living Together',
  LivingSeparately = 'No, Living Separately',
}

export enum HealthStatus {
  NoHealthProblems = 'No Health Problem',
  HIVPositive = 'HIV+',
  Diabetes = 'Diabetes',
}

export enum BloodGroup {
  Aplus = 'A+',
  Aminus = 'A-',
  Bplus = 'B+',
  Bminus = 'B-',
  ABplus = 'AB+',
  ABminus = 'AB-',
  Oplus = 'O+',
  Ominus = 'O-',
}

export enum LivingWith {
  Parent = 'Parent',
  Independent = 'Independent',
  GrandParent = 'Grand parents',
  Relatives = 'Relatives',
}

export enum FatherStatus {
  EmployedPrivate = 'Private Job',
  EmployedGovernment = 'Government Job',
  Business = 'Business',
  Retired = 'Retired',
  ArmedForce = 'Army/Armed Force',
  Police = 'Police',
  CivilService = 'Civil Service',
  NotEmployed = 'Not Working',
  PassedAway = 'Expired',
  Teacher = 'Teacher',
}

export type MotherStatus = FatherStatus | 'House Wife';

export enum FamilyType {
  Nuclear = 'Nuclear',
  Joint = 'Joint',
  Other = 'Other',
}

export enum FamilyValue {
  Liberal = 'Liberal',
  Moderate = 'Moderate',
  Traditional = 'Traditional',
  Conservative = 'Conservative',
  Orthodox = 'Orthodox',
  Other = 'Other',
}

export enum FamilyStatus {
  RichAffluent = 'Rich/Affluent',
  UpperMiddleClass = 'Upper Middle Class',
  MiddleClass = 'Middle Class',
  LowerMiddleClass = 'Lower Middle Class',
}

export enum ResidencyStatus {
  Citizen = 'Citizen',
  PermanentResident = 'Permanent Resident',
  StudentVisa = 'Student Visa',
  TemporaryVisa = 'Temporary Visa',
  WorkPermit = 'Work Permit',
}

export enum EducationLevel {
  HighSchoolAndBelow = 'High School And Below',
  Matrix = 'Matrix Passed',
  AssociateAndDiploma = 'Diploma',
  Bachelor = 'Bachelor',
  Master = 'Masters',
  Doctorate = 'Doctorate',
}

export interface EducationRecord {
  qualification?: string; // Populated in toJson from qualification ID
  qualificationId: QualificationId;
  specialization?: string;
  college: string;
  yearStarted: number;
  yearCompleted: number;
}

export enum EducationMedium {
  Hindi = 'Hindi',
  English = 'English',
  Regional = 'Regional',
}

export enum EmploymentType {
  GovernmentOrPSU = 'Government/PSU',
  Defense = 'Defense/Armed Forces',
  Corporate = 'Private Corporate',
  BusinessOrSelfEmployed = 'Business/Self-Employed',
  Startup = 'Startup',
  CivilServices = 'Civil Service',
  NotWorking = 'Not Working',
}

export enum SettingAbroad {
  Interested = 'Interested in setting abroad',
  NotInterested = 'Not Interested in settling abroad',
  NotYetDecided = 'Not Yet Decided',
  AlreadyInProcess = 'Already in Process',
}

export interface EmploymentRecord {
  companyName: string;
  position: string;
  yearStarted: number;
  yearCompleted: number;
}

export enum ManglikStatus {
  NonManglik = 'Non Manglik',
  Manglik = 'Manglik',
  Angshik = 'Angshik',
}

export enum DietType {
  NonVeg = 'Non Vegetarian',
  Vegan = 'Vegan',
  Eggetarian = 'Eggetarian',
  Vegetarian = 'Vegetarian',
  Jain = 'Jain',
}

export enum HabitType {
  Yes = 'Yes',
  Occasionally = 'Occasionally',
  Rarely = 'Rarely',
  StrictlyNo = 'Strictly No',
}

export enum PrivacyLevel {
  VisibleToAll = 'Visible to All',
  VisibleToVerified = 'Visible to Verified Members',
  VisibleToPremium = 'Visible to Paid Members',
  VisibleToAccepted = 'Visible to Accepted Members',
  VisibleToNone = 'Visible to None',
}

export enum InterestPrivacy {
  All = 'All',
  OnlyVerifiedMembers = 'Only Verified Members',
  OnlyPaidMembers = 'Only Paid Members',
  VerifiedOrPaidMembers = 'Verified or Paid Members',
  VerifiedAndPaidMembers = 'Verified And Paid Members',
}

export enum MembershipCategory {
  Silver = 'Silver',
  Gold = 'Gold',
  Diamond = 'Diamond',
}

export enum Verification {
  IDVerified = 'ID Verified',
  VideoCallVerified = 'Verified by Video call',
  PersonalVisitVerified = 'Verified by Personal visit',
  SelfieVerified = 'Selfie Verified',
  IncomeVerified = 'Income Verified',
  EmploymentVerified = 'Employment Verified',
}

export enum RashiSign {
  Gemini = 'Gemini (Mithun)',
  Aquarius = 'Aquarius (Kumbh)',
  Taurus = 'Taurus (Vrishabh)',
  Aries = 'Aries (Mesh)',
  Capricorn = 'Capricorn (Makar)',
  Libra = 'Libra (Tula)',
  Virgo = 'Virgo (Kanya)',
  Sagittarius = 'Sagittarius (Dhanu)',
  Scorpio = 'Scorpio (Vrishchik)',
  Leo = 'Leo (Simha)',
  Cancer = 'Cancer (Karka)',
  Pisces = 'Pisces (Meen)',
}

export interface AstroDetails {
  birthDate?: string;
  birthTime?: string;
  birthPlace?: string;
}

export enum ProfileStatus {
  Draft = 'draft',
  Live = 'live',
  Hidden = 'hidden',
  Deleted = 'deleted',
}

export enum VisibilityLevel {
  Hidden = 'Hidden',
  Visible = 'Visible',
}
export enum AssetsType {
  Car = 'Car',
  House = 'House',
}

export enum Pets {
  All = 'all',
  Cats = 'Cats',
  Dogs = 'Dogs',
  Rabbit = 'Rabbit',
  Bird = 'Bird',
}

export interface LifestyleExtra {
  favBooks?: string[];
  favMovies?: string[];
  favTvShows?: string[];
  favDestination?: string[];
  favSports?: string[];
  dressStyle?: string[];
}

export interface FamilyExtra {
  numOfBrother: number;
  numOfSister: number;
  numOfBrotherMarried: number;
  numOfSisterMarried: number;
  numOfElderBrother: number;
  numOfElderSister: number;
}

export interface BasicExtra {
  bloodGroup: BloodGroup;
}

export interface ReligionExtra {
  religiousBeliefs: Array<{ label: string; value: string | 'Yes' | 'No' }>;
}

export enum ProfileType {
  Default = 0,
  JS = 1,
  Shaadi = 2,
}

export enum PreferenceLevel {
  Ease = 1,
  Strict = 3,
}

export enum ContactType {
  Mobile = 'mobile',
  Email = 'email',
}

export interface ProfileStats {
  yesterday: number;
  lastWeek: number;
  lastMonth: number;
}

export enum ExcludedStatus {
  Block = 'Block',
  Ignore = 'Ignore',
  Report = 'Report',
}

export enum MutualMatchKey {
  Qualification = 'Qualification',
  Profession = 'Profession',
  Company = 'Company',
  College = 'College',
  Location = 'Location',
  Hashtag = 'Hashtag',
  FamilyValues = 'Family Values',
  FamilyStatus = 'Family Status',
  SettlingAbroad = 'Settling Abroad',
  HobbiesInterest = 'Hobbies & Interest',
}
export type TypeOf = 'undefined' | 'boolean' | 'string' | 'number' | 'object' | 'function' | 'symbol' | 'bigint';

export enum RecommendedType {
  Nearby = 'Nearby',
  Recommendation = 'Recommendation',
  DailyMatches = 'DailyMatches',
  RecentlyJoined = 'RecentlyJoined',
  RecentlyPaid = 'RecentlyPaid',
  RecentlyActive = 'RecentlyActive',
}

export interface RecommendedProfileData {
  profile_uuid: string;
  recommended_score: number;
  last_active_score: number;
  similarity_score: number;
  nearby_distance: number;
  recently_joined_score: number;
}

export enum ContactPrivacy {
  AllVerifiedMembers = 'All Verified Members',
  OnlyAcceptedMembers = 'Only Accepted Members',
  None = 'None',
}

export interface ProfileContactDetails {
  mobileOrEmail: string;
  type: ContactType;
  isVerified: boolean;
  userRelation: UserRelation;
  contactPersonName: string;
  otp?: string;
  otpSentOn?: Date;
  otpVerifiedOn?: Date;
}

export enum ProfileAction {
  SendInterest = 'Send Interest',
  SendFilterInterest = 'Send Filter Interest',
  SendReminder = 'Send Reminder',
  AcceptInterest = 'Accept Interest',
  DeclineInterest = 'Decline Interest',
  IgnoreInterest = 'Ignore Interest',
  DeleteInterest = 'Delete Interest',
  Chat = 'Chat',
  Shortlist = 'Shortlist',
  RemoveFromShortlist = 'Remove From Shortlist',
  Unblock = 'Unblock',
  Block = 'Block',
}

export enum ProfilePermission {
  CanViewName = 'Can View Name',
  CanViewPhoto = 'Can View Photo',
  CanViewAstro = 'Can View Astro',
  CanSendInterest = 'Can Send Interest',
}
