import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import moment from 'moment';
import DateTimePickerModal from 'react-native-modal-datetime-picker';

import { GenUtil } from 'src/util/GenUtil';
import InputAdornment from 'src/components/common/input/InputAdornment';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';
import { Gender } from 'src/common/models/profile.model';

const { width } = GenUtil.getDimension();

const OnboardingNameAndDOBComp = ({
  relation = 'Self',
  gender = Gender.Male,
  next = () => {},
  prev = () => {},
  name = '',
  setName = () => {},
  formattedDate = '',
  setFormattedDate = (_) => {},
  infoDisabled = true,
  setInfoDisabled = (_) => {},
  setTempName = (_) => {},
}) => {
  const [nameQuestion, setNameQuestion] = useState('Your Name');
  const [bdayQuestion, setBdayQuestion] = useState('When was you born?');
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const currentDate = moment().startOf('day').toDate();
  const [date, setDate] = useState(currentDate);

  const showDatePicker = () => setIsDatePickerVisible(true);
  const hideDatePicker = () => setIsDatePickerVisible(false);

  const handleConfirm = (date: Date) => {
    const d = moment(date).format('YYYY-MM-DD');
    setFormattedDate(d);
    setDate(date);
    hideDatePicker();
  };

  const generateQuestions = () => {
    if (relation === 'Self') {
      setNameQuestion('Your Name');
      setBdayQuestion('When were you born?');
    } else if (relation === 'Brother' || relation === 'Nephew' || relation === 'Son') {
      setNameQuestion(`Your ${relation} Name`);
      setBdayQuestion('When was he born?');
    } else if (relation === 'Sister' || relation === 'Niece' || relation === 'Daughter') {
      setNameQuestion(`Your ${relation} Name`);
      setBdayQuestion('When was she born?');
    } else if (relation === 'Friend' || relation === 'Client' || relation === 'Relative') {
      setNameQuestion(`Your ${relation} Name`);
      setBdayQuestion(gender === Gender.Male ? 'When was he born?' : 'When was she born?');
    }
  };

  useEffect(() => {
    generateQuestions();
  }, []);

  useEffect(() => {
    if (!name.trim() || !formattedDate.trim()) {
      setInfoDisabled(true);
    } else {
      setInfoDisabled(false);
    }
  }, [name, formattedDate]);

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.item}>
          <Text style={styles.questionText}>{nameQuestion}</Text>
          <InputAdornment
            placeholder="Enter name"
            icon={<Icon name="user" type="antdesign" size={20} />}
            endIcon={<Icon name="settings-outline" type="ionicon" size={20} />}
            value={name}
            setValue={setName}
            styleOverride
          />
        </View>
        <View style={styles.item}>
          <Text style={styles.questionText}>{bdayQuestion}</Text>
          <TouchableOpacity onPress={showDatePicker}>
            <InputAdornment
              placeholder="Birth Date"
              icon={<Icon name="calendar" type="antdesign" size={20} />}
              endIcon={<Icon name="settings-outline" type="ionicon" size={20} />}
              onIconClick={showDatePicker}
              editable={false}
              styleOverride
              value={formattedDate}
            />
          </TouchableOpacity>
        </View>
      </ScrollView>
      <DateTimePickerModal
        isVisible={isDatePickerVisible}
        mode="date"
        onConfirm={handleConfirm}
        onCancel={hideDatePicker}
        maximumDate={currentDate}
        date={date}
      />
      <View style={styles.buttonWrapper}>
        <PrimaryButton
          buttonDisabled={infoDisabled}
          isValidating={false}
          buttonText={'Next'}
          handleKeyPress={() => {
            setTempName(name);
            next();
          }}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: width * 0.05,
    paddingTop: 20,
    backgroundColor: 'transparent',
  },
  questionText: {
    fontSize: 28,
    fontFamily: 'sora-light',
    marginVertical: 10,
  },
  item: {
    marginBottom: 25,
  },
  buttonWrapper: {
    position: 'absolute',
    bottom: 35,
    left: width * 0.1,
  },
});

export default OnboardingNameAndDOBComp;
