import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { modalsList } from 'src/components/modals/modalsList';

export default function ModalProvider() {
  const modal = useSelector((state: RootState) => state.ui.modal);

  const Modal = modalsList[modal.type];

  return Modal ? <Modal /> : <></>;
}
