import { PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { BottomSheetBackdrop, BottomSheetModal } from '@gorhom/bottom-sheet';
import { GenUtil } from 'src/util/GenUtil';
import Modal from 'react-native-modal';
import { View } from 'react-native';
import { Color } from 'src/constant/Color';

type Props = {
  onDismiss?: () => void;
  snapPoints?: Array<string>;
  webModalHeight?: number;
} & PropsWithChildren;

export default (props: Props) => {
  const { snapPoints = ['30%'], onDismiss = () => {}, webModalHeight } = props;
  const ref = useRef<BottomSheetModal>(null);

  useEffect(() => {
    if (GenUtil.isWEB()) return;

    ref.current.present();
  }, []);

  const renderBackdrop = useCallback(
    (props) => <BottomSheetBackdrop {...props} appearsOnIndex={0} disappearsOnIndex={-1} />,
    [],
  );

  const onClose = useCallback(() => {
    onDismiss();
  }, [onDismiss]);

  if (GenUtil.isWEB()) {
    return (
      <Modal
        isVisible={true}
        onDismiss={onClose}
        onBackButtonPress={onClose}
        onBackdropPress={onClose}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: Color.white,
            borderRadius: 10,
            width: 360,
            height: 200,
            minHeight: 200,
            maxHeight: webModalHeight ?? 200,
            alignSelf: 'center',
            justifyContent: 'center',
          }}
        >
          {props.children}
        </View>
      </Modal>
    );
  }

  return (
    <BottomSheetModal
      ref={ref}
      snapPoints={snapPoints}
      backgroundStyle={{ borderRadius: 20 }}
      enableDismissOnClose
      backdropComponent={renderBackdrop}
    >
      {props.children}
    </BottomSheetModal>
  );
};
