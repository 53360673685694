import { useMemo } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import BuyMembershipComp from 'src/components/membership/BuyMembershipComp';
import UserMembershipComp from 'src/components/membership/UserMembershipComp';
import { RootState } from 'src/store/store';

export default function PremiumMemberScreen() {
  const { membershipExpiredOn } = useSelector((state: RootState) => state.user.userProfile);

  const isMember = useMemo(() => {
    const now = new Date();
    const expiryDate = new Date(membershipExpiredOn);
    return expiryDate.getTime() > now.getTime();
  }, [membershipExpiredOn]);

  return (
    <View style={{ flex: 1 }}>{isMember ? <UserMembershipComp /> : <BuyMembershipComp />}</View>
  );
}
