import React from 'react';
import { StyleSheet, Image, FlatList, ImageSourcePropType } from 'react-native';

import { View, Text } from 'src/components/Themed';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileResp } from 'src/common/response/profile.resp';
import { ReligionType } from 'src/common/models/profile.model';
import { Color } from 'src/constant/Color';
import TruncatedText from 'src/components/common/TruncatedText';

const UserProfilePersonalDetailsComp = ({ profileData }: { profileData: Partial<ProfileResp> }) => {
  const hashtags = profileData.hashtags?.length ? '#' + profileData.hashtags?.join('\t#') : '';

  interface InterfaceRowData {
    id: number;
    icon: ImageSourcePropType;
    label: string;
    color: string;
    value: string;
  }

  const INFO_ROWS: InterfaceRowData[] = [
    {
      id: 1,
      icon: require('src/assets/images/profileIcons/FrameDOB.png'),
      color: '#FF508B',
      label: 'DOB',
      value: profileData.dob,
    },
    {
      id: 2,
      icon: require('src/assets/images/profileIcons/FrameAge.png'),
      color: '#C754DB',
      label: 'Age',
      value: !profileData.age ? '' : `${profileData.age} years old`,
    },
    {
      id: 3,
      icon: require('src/assets/images/profileIcons/FrameHeight.png'),
      color: '#8E5CE4',
      label: 'Height',
      value: !profileData.height ? '' : `${profileData.height} cm`,
    },
    {
      id: 4,
      icon: require('src/assets/images/profileIcons/FrameHealthStatus.png'),
      label: 'Health Status',
      color: '#29B6F6',
      value: profileData.healthStatuses?.join(', '),
    },
    {
      id: 5,
      icon: require('src/assets/images/profileIcons/FrameProfileHandler.png'),
      label: 'Profile Handler',
      color: '#FF4F4B',
      value: profileData.profileHandler,
    },
    {
      id: 6,
      icon: require('src/assets/images/profileIcons/FrameLivingWith.png'),
      label: 'Living With',
      color: '#5C6BC0',
      value: profileData.livingWith,
    },
    {
      id: 7,
      icon: require('src/assets/images/profileIcons/FrameMotherTongue.png'),
      label: 'Mother tongue',
      color: '#20BDAE',
      value: profileData.motherTongue,
    },
    {
      id: 8,
      icon: require('src/assets/images/profileIcons/FrameLanguageSpeak.png'),
      color: '#FF9800',
      label: 'Language Speak',
      value: profileData.languages?.join(', '),
    },
    {
      id: 9,
      icon: require('src/assets/images/profileIcons/FrameReligion.png'),
      label: 'Religion',
      color: '#C0D20F',
      value: profileData.religion,
    },
    {
      id: 10,
      icon:
        profileData.religion === ReligionType.Muslim
          ? ''
          : require('src/assets/images/profileIcons/FrameReligion.png'),
      label: profileData.religion === ReligionType.Muslim ? '' : 'Caste',
      color: '#FF9271',
      value: profileData.religion === ReligionType.Muslim ? '' : profileData.community,
    },
    {
      id: 11,
      icon:
        profileData?.religion === ReligionType.Muslim
          ? ''
          : require('src/assets/images/profileIcons/FrameReligion.png'),
      label: profileData.religion === ReligionType.Muslim ? '' : 'Subcaste',
      color: '#66BB6A',
      value: profileData.religion === ReligionType.Muslim ? '' : profileData.subCommunity,
    },
  ];

  const userPersonalDetails = INFO_ROWS.filter(
    (item) => item?.value && item?.value !== '' && item?.value !== null,
  );

  const renderItem = ({ item }: { item: InterfaceRowData }) => (
    <View style={styles.info}>
      <View style={[styles.iconContainer, { backgroundColor: item.color }]}>
        <Image source={item.icon} style={styles.iconStyle1} />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.infoLabel}>{item.label}</Text>
        <Text style={styles.infoValue}>{item.value}</Text>
      </View>
    </View>
  );

  if (userPersonalDetails.length > 0)
    return (
      <View style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.headerText}>Personal Details</Text>
        </View>
        {profileData.aboutMe && <TruncatedText text={profileData.aboutMe} />}
        {hashtags && <Text style={styles.hashtagText}>{hashtags}</Text>}
        <FlatList
          data={userPersonalDetails}
          renderItem={renderItem}
          keyExtractor={(item) => item.id.toString()}
          numColumns={2}
          columnWrapperStyle={{ marginTop: 18 }}
          style={{ marginTop: !profileData.aboutMe && !hashtags ? -15 : 0 }}
        />
      </View>
    );
};

const styles = StyleSheet.create({
  container: {
    flex: GenUtil.isWEB() ? null : 1,
    backgroundColor: Color.white,
    paddingHorizontal: 12,
    paddingVertical: 16,
    marginHorizontal: 16,
    marginVertical: 5,
    borderRadius: 20,
    elevation: 6,
    shadowColor: '#BEBEBE',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.22,
    shadowRadius: 24,
  },
  header: {
    alignItems: 'flex-start',
    marginBottom: 18,
  },
  headerText: {
    fontFamily: EFonts.SORA_MEDIUM,
    fontSize: 17,
    lineHeight: 18,
    color: Color.lightBlack,
  },
  hashtagText: {
    marginTop: 10,
    fontFamily: EFonts.SORA_MEDIUM,
    fontStyle: 'normal',
    fontSize: 13,
    lineHeight: 24,
    color: '#007DF0',
  },
  infoLabel: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
    color: Color.lightGrey,
  },
  infoValue: {
    flex: 1,
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontSize: GenUtil.isWEB() ? 12 : 10,
    lineHeight: 20,
  },
  info: {
    flex: 1,
    flexDirection: 'row',
  },
  iconStyle1: {
    width: 18,
    height: 18,
  },
  iconContainer: {
    width: 34,
    height: 34,
    borderRadius: 16,
    backgroundColor: '#F0F0F0',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  textContainer: {
    marginTop: GenUtil.isWEB() ? -2 : -6,
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
    flex: 1,
  },
});

export default UserProfilePersonalDetailsComp;
