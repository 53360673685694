import React, { useState } from 'react';
import { StyleSheet, View, Image, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import { Card, Text, IconButton } from 'react-native-paper';
import Swiper from 'react-native-swiper/src';
import { GenUtil } from 'src/util/GenUtil';
import ProfileAction from './ProfileAction';
import EFonts from 'src/constant/EFonts';
import { useNavigation } from '@react-navigation/native';
import { ProfileService } from 'src/service/ProfileService';
import { Color } from 'src/constant/Color';
import Button from './common/buttons/Button';
import { Icon } from 'react-native-elements';

const { height } = GenUtil.getDimension();

const ProfileCell = ({ profile, isBlockedProfile = false, getBlockedProfiles }) => {
  const navigation = useNavigation();
  const uuid: string = profile?.uuid;

  const handleInfoPress = () => {
    navigation.navigate('otherpersonprofile', { uuid });
  };
  const onPressUnblock = async () => {
    const response = await ProfileService.undoRestrictProfile(uuid);

    console.log(response);
    getBlockedProfiles();
  };
  return (
    <TouchableWithoutFeedback onPress={handleInfoPress} key={profile?.uuid}>
      <Card style={styles.cardBoard}>
        <View style={{ width: '100%', height: '100%' }}>
          <Swiper showsButtons={false} dotColor="white" paginationStyle={{ top: -440, left: 270 }}>
            <Image source={{ uri: profile?.profilePhoto?.originalUrl }} style={styles.cardImage} />
          </Swiper>
          <View style={styles.imageCountContainer}>
            <TouchableOpacity
              onPress={() => console.log('View all images')}
              style={{ flexDirection: 'row', alignItems: 'center' }}
            >
              <IconButton
                icon="image-multiple-outline"
                style={{ padding: 0, margin: 0, width: 25, height: 25 }}
                size={17}
                iconColor="white"
              />
              <Text style={styles.imageCount}>1</Text>
            </TouchableOpacity>
          </View>
          {isBlockedProfile ? (
            <View style={styles.blockBtnContainer}>
              <Button
                beforeIcon={
                  <Icon name="block" iconStyle={{ marginRight: 5 }} color={Color.white} />
                }
                size="medium"
                textStyles={styles.unblockText}
                onPress={onPressUnblock}
              >
                Unblock
              </Button>
            </View>
          ) : (
            <View style={styles.cardBottomContainer}>
              <ProfileAction uuid={profile?.uuid} />
            </View>
          )}
        </View>
        <View style={styles.cardContainer}>
          <TouchableOpacity onPress={handleInfoPress} style={styles.info2Container}>
            <Text style={styles.cardInfo1}>{profile?.fullName}</Text>
            <Text style={styles.cardInfo1}>, {profile?.age}</Text>
            {profile?.verifications?.length !== 0 ? (
              <IconButton
                icon="check-decagram"
                style={{ width: 30, height: 25, marginTop: 20 }}
                size={23}
                iconColor="#00aced"
              />
            ) : null}
          </TouchableOpacity>
          <TouchableOpacity onPress={handleInfoPress} style={styles.info2Container}>
            <Text style={styles.cardInfo2}>{profile?.height}cm</Text>
            <Text style={styles.cardInfo2}> • {profile?.currentLocation}</Text>
            <Text style={styles.cardInfo2}> • {profile?.religion}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={handleInfoPress} style={styles.info1Container}>
            <IconButton
              icon="inbox-outline"
              iconColor="#D3D3D3"
              size={21}
              onPress={() => console.log('occupation')}
            />
            <Text style={styles.cardInfo3}>{profile?.profession}</Text>
            {profile?.currentLocation.includes('India') ? (
              <Text style={styles.cardInfo4}> • Earns ₹{profile?.inrIncome} Lacs p.a.</Text>
            ) : (
              <Text style={styles.cardInfo4}> • Earns {profile?.usdIncome}k USD p.a.</Text>
            )}
          </TouchableOpacity>

          <TouchableOpacity onPress={handleInfoPress} style={styles.info3Container}>
            <View style={styles.info1Container}>
              <IconButton
                icon="school-outline"
                iconColor="#D3D3D3"
                size={21}
                onPress={() => console.log('education')}
              />
              <Text style={styles.cardInfo3}>{profile?.highestQualification}</Text>
            </View>
            <IconButton
              icon="heart-multiple-outline"
              iconColor="#D3D3D3"
              size={21}
              onPress={() => console.log('marital')}
            />
            <Text style={styles.cardInfo3}>{profile?.maritalStatus}</Text>
          </TouchableOpacity>
        </View>
      </Card>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    position: 'absolute',
    zIndex: 1,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
    bottom: 60,
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  info2Container: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardInfo1: {
    fontFamily: EFonts.DMSANS_REGULAR,
    fontWeight: 'bold',
    color: '#fff',
    fontSize: 26,
    marginTop: 15,
  },
  cardInfo2: {
    fontSize: GenUtil.isWEB() ? 14 : 15,
    fontFamily: EFonts.DMSANS_REGULAR,
    color: '#D3D3D3',
    marginTop: 10,
  },
  info1Container: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: -11,
  },
  info3Container: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -12,
  },
  cardInfo3: {
    fontFamily: EFonts.DMSANS_REGULAR,
    fontSize: GenUtil.isWEB() ? 14 : 15,
    color: '#D3D3D3',
    marginLeft: -6,
  },
  cardInfo4: {
    fontFamily: EFonts.DMSANS_REGULAR,
    fontSize: GenUtil.isWEB() ? 14 : 15,
    color: '#D3D3D3',
  },
  cardSeparator: {
    margin: 5,
  },
  imageText: {
    right: -32,
    top: -11,
  },
  emptyImage: {
    fontSize: 30,
    alignSelf: 'center',
    top: 150,
  },
  cardBottomContainer: {
    position: 'absolute',
    borderTopColor: '#C0C0C0',
    borderTopWidth: 1,
    zIndex: 1,
    // padding: 10,
    bottom: 0,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: 'rgba(0,0,0,0.8)',
    height: 65,
  },
  cardCancel: {
    position: 'absolute',
    right: 11,
    padding: 10,
    alignSelf: 'flex-end',
  },
  multiImages: {
    position: 'absolute',
    right: 11,
    padding: 10,
    alignSelf: 'flex-end',
  },
  cardBoard: {
    // overflow: 'hidden',
    height: GenUtil.isAndroid() ? height * 0.8 : GenUtil.isIOS() ? height * 0.75 : height * 0.75,
    backgroundColor: '#fff',
  },
  cardImage: {
    width: '100%',
    height: '100%',
  },
  imageCountContainer: {
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 10,
    paddingRight: 5,
  },
  imageCount: {
    fontFamily: EFonts.DMSANS_REGULAR,
    color: 'white',
    fontSize: 14,
  },
  blockBtnContainer: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 2,
    width: '100%',
  },
  unblockText: {
    color: 'white',
    textAlign: 'center',
    fontSize: 17,
    fontFamily: EFonts.SORA_MEDIUM,
  },
});

export default ProfileCell;
