import { TouchableOpacity, ViewStyle } from 'react-native';
import { Icon } from 'react-native-elements';
import { Color } from 'src/constant/Color';
import { useMemo, useState } from 'react';
import { ProfileService } from 'src/service/ProfileService';
import { ProfileResp } from 'src/common/response/profile.resp';

type Props = {
  profile: Partial<ProfileResp>;
  variant?: 'primary' | 'secondary';
};

const getVariantStyles = (variant: 'primary' | 'secondary'): ViewStyle => {
  switch (variant) {
    case 'primary':
      return {
        width: 35,
        height: 35,
        borderRadius: 50,
        backgroundColor: Color.white,
        alignItems: 'center',
        justifyContent: 'center',
      };

    case 'secondary':
      return {
        width: 30,
        height: 30,
        borderRadius: 50,
        backgroundColor: 'rgba(128, 65, 198, 0.1)',
        alignItems: 'center',
        justifyContent: 'center',
      };

    default:
      return {
        width: 35,
        height: 35,
        borderRadius: 50,
        backgroundColor: Color.white,
        alignItems: 'center',
        justifyContent: 'center',
      };
  }
};

export default function StarProfileAction(props: Props) {
  const { variant = 'primary', profile } = props;

  const [isStar, setIsStar] = useState(!!(profile.shortlists?.length > 0));

  const styles = useMemo(() => getVariantStyles(variant), [variant]);

  const handlePress = async () => {
    try {
      if (isStar) {
        const response = await ProfileService.removeShortlistedProfiles(profile.uuid);
        if (response.success) {
          setIsStar(false);
        } else {
          console.error(response.message, response);
        }
        return;
      }

      const response = await ProfileService.addShortlistedProfiles(profile.uuid);
      if (response.success) {
        setIsStar(true);
      } else {
        console.error(response.message, response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <TouchableOpacity onPress={handlePress} style={styles}>
      <Icon
        name={'star'}
        type={!isStar ? 'feather' : 'material'}
        color={Color.purple}
        size={!isStar ? 18 : 21}
      />
    </TouchableOpacity>
  );
}
