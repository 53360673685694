import React, { useCallback, useState } from 'react';
import { Keyboard, StyleSheet, TouchableOpacity, Image, Alert } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import OtpInputs from 'react-native-otp-inputs';

import { Color } from 'src/constant/Color';
import { KeyboardAvoidingView, Text, View } from 'src/components/Themed';
import EFonts from 'src/constant/EFonts';
import { GenUtil } from 'src/util/GenUtil';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';
import { AuthService } from 'src/service/AuthService';
import { useDispatch } from 'react-redux';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';

const { width } = GenUtil.getDimension();

const OnboardingOTPComp = ({
  phoneNumber,
  country,
  next = () => {},
  prev = () => {},
  setOtpToken = (_) => {},
}) => {
  const code = country?.callingCode;
  const [otp, setOtp] = useState('');
  const [time, setTime] = useState(25);
  const [isFocused, setIsFocused] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [resendCount, setResendCount] = useState(0);
  const [isValidating, setIsValidating] = useState(false);
  const dispatch = useDispatch();

  const borderColor = isFocused ? Color.purple : '#D6D6D6';

  const data = {
    title: 'Enter OTP',
    buttonDisabled: false,
  };

  useFocusEffect(
    useCallback(() => {
      setTime(25);
      setResendCount(0);
      startTimer();
      return () => {
        stopTimer();
      };
    }, []),
  );

  let timerId = null;

  const startTimer = () => {
    let start = null;
    const animate = (time) => {
      if (start === null) start = time;
      const elapsedTime = Math.floor((time - start) / 1000);
      setTime(25 - elapsedTime);
      if (elapsedTime < 25) {
        timerId = requestAnimationFrame(animate);
      }
    };
    timerId = requestAnimationFrame(animate);
  };

  const stopTimer = () => {
    cancelAnimationFrame(timerId);
    timerId = null;
  };

  const handleKeyPress = async () => {
    if (otp.length === 6 && !otp.includes(' ')) {
      try {
        setIsValidating(true);
        const res = await AuthService.verifyOTP({ mobile: '+' + code + phoneNumber, otp: otp });
        setIsValidating(false);
        if (res.success) {
          Keyboard.dismiss();
          setButtonDisabled(false);
          setOtpToken(res.data?.otpToken);
          next();
        } else {
          Alert.alert('Invalid OTP. Please enter a valid 6 digit OTP.');
        }
      } catch (error) {
        console.log(error);
        dispatch(
          showError({
            message: GenUtil.getMessageFromError(error),
            alertType: AlertTypeEnum.Error,
          }),
        );
        setOtp('');
        setButtonDisabled(true);
        setIsValidating(false);
      }
    }
  };

  const handleSendOtp = () => {
    if (resendCount < 2) {
      setOtp('');
      AuthService.sendOTP({ mobile: '+' + code + phoneNumber });
      setResendCount(resendCount + 1);
      setTime(25);
      startTimer();
    }
  };

  return (
    <>
      <View style={styles.container}>
        <Text style={styles.header}>{data.title}</Text>

        <Text style={styles.message}>
          Enter the code we've sent by text to{'\n'}+{code}-{phoneNumber}{' '}
          <TouchableOpacity onPress={prev}>
            <Image
              source={require('src/assets/icons/VectorchangeNum.png')}
              style={styles.imageStyle}
            />
          </TouchableOpacity>
        </Text>
        <View style={styles.otpContainer}>
          <OtpInputs
            key={resendCount}
            handleChange={(code) => setOtp(code)}
            numberOfInputs={6}
            autofillFromClipboard={false}
            style={styles.otpStyle}
            inputStyles={[styles.otpInput, { borderColor }]}
            textContentType="oneTimeCode"
            keyboardType="phone-pad"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </View>
        {time > 0 ? (
          <Text style={styles.otpInfo}>This Otp should arrive within {time}s</Text>
        ) : (
          <TouchableOpacity>
            {resendCount < 2 ? (
              <Text onPress={handleSendOtp} style={styles.otpInfo}>
                Resend Otp?
              </Text>
            ) : (
              <Text onPress={prev} style={styles.otpInfo}>
                Otp Request Limit Reached! Go Back
              </Text>
            )}
          </TouchableOpacity>
        )}
        <KeyboardAvoidingView behavior={'position'} style={styles.buttonWrapper}>
          <PrimaryButton
            buttonDisabled={otp.trim().length < 6}
            isValidating={isValidating}
            buttonText="Verify OTP"
            handleKeyPress={handleKeyPress}
          />
        </KeyboardAvoidingView>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: width * 0.05,
    paddingTop: GenUtil.isWEB() ? 20 : 10,
    backgroundColor: 'transparent',
  },
  buttonWrapper: {
    position: 'absolute',
    width: '100%',
    left: width * 0.1,
    bottom: 35,
  },
  header: {
    fontSize: 30,
    fontFamily: EFonts.SORA_REGULAR,
    lineHeight: 38,
    color: Color.lightBlack,
    marginTop: GenUtil.isWEB() ? 10 : 40,
  },
  otpInfo: {
    color: Color.purple,
    marginBottom: 10,
    fontSize: 15,
    lineHeight: 19,
    font: EFonts.SORA_SEMIBOLD,
  },
  otpContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: GenUtil.isWEB() ? 21 : null,
    left: -9,
    marginTop: 30,
    top: GenUtil.isWEB() ? 0 : -30,
  },
  otpInput: {
    backgroundColor: Color.backgroundGray,
    borderRadius: 80,
    height: 40,
    width: 50,
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontSize: 20,
    borderWidth: 1.2,
    textAlign: 'center',
    outlineStyle: 'none',
    marginRight: 11,
    color: Color.purple,
  },
  message: {
    fontFamily: EFonts.SORA_LIGHT,
    marginTop: 10,
    fontSize: 15,
    marginRight: 49,
    lineHeight: 26,
    color: Color.lightBlack,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  imageStyle: {
    width: 13.48,
    height: 13.48,
    marginLeft: 5,
    marginBottom: GenUtil.isWEB() ? null : 4.5,
  },
  otpStyle: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
    marginLeft: 7,
    marginTop: GenUtil.isWEB() ? null : 39,
  },
});

export default OnboardingOTPComp;
