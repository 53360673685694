import React, { useRef } from 'react';
import { Button, Carousel } from 'antd';
import { Icon } from 'react-native-elements';

export const SwiperFlatList = ({ data = [], renderItem = () => <></> }) => {
  const carouselRef = useRef(null);

  const next = () => {
    if (carouselRef.current) {
      carouselRef.current?.next();
    }
  };

  const prev = () => {
    if (carouselRef?.current) {
      carouselRef.current?.prev();
    }
  };
  return (
    <>
      <Carousel ref={carouselRef} dotPosition={'top'}>
        {data.map((item, index) => (
          <div key={index}>{renderItem({ item })}</div>
        ))}
      </Carousel>
      <Button
        style={{
          position: 'absolute',
          right: 5,
          top: 295,
          backgroundColor: 'rgba(0,0,0,.3)',
          borderRadius: 50,
          borderWidth: 0,
          height: 40,
          width: 40,
        }}
        onClick={next}
      >
        <Icon name="caretright" type="antdesign" size={15} />
      </Button>
      <Button
        style={{
          position: 'absolute',
          left: 5,
          top: 295,
          backgroundColor: 'rgba(0,0,0,.3)',
          borderRadius: 50,
          borderWidth: 0,
          height: 40,
          width: 40,
        }}
        onClick={prev}
      >
        <Icon name="caretleft" type="antdesign" size={15} />
      </Button>
    </>
  );
};

export default SwiperFlatList;
