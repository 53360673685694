import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import useCachedResources from 'src/hooks/useCachedResources';
import useColorScheme from 'src/hooks/useColorScheme';
import { Provider as PaperProvider } from 'react-native-paper';
import Navigation from 'src/navigation';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import { GenUtil } from 'src/util/GenUtil';
import axios from 'axios';
import { Provider, useDispatch, useSelector } from 'react-redux';
import AnimatedSplash from 'react-native-animated-splash-screen';
import { RootState, persistor, store } from 'src/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import StorageUtil from 'src/util/StorageUtil';
import ErrorComponent from 'src/components/ErrorComponent';
import { useCallback, useEffect } from 'react';
import { isFbUserLoggedIn, signInToFirebase } from 'firebaseConfig';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import NotificationsContextProvider from 'src/context/NotificationsContextProvider';
import * as SplashScreen from 'expo-splash-screen';
import { setupApiInterceptors } from 'src/service/axios';
import WebNavigation from 'src/navigation/WebNavigation';
import { updateDimension } from 'src/store/reducer/dimensionsReducer';
import { LayoutConstant } from 'src/constant/LayoutConstant';

// Keep the splash screen visible while we fetch resources
// eslint-disable-next-line @typescript-eslint/no-floating-promises
SplashScreen.preventAutoHideAsync();

setupApiInterceptors(axios);

function WebContainer({ children }) {
  const { width, height } = useSelector((state: RootState) => state.dimension);

  return <View style={[styles.webContainer, { height: height, width: width }]}>{children}</View>;
}

function MobileContainer({ children }) {
  if (GenUtil.isWEB()) {
    return (
      <View style={styles.mobileWebContainer}>
        <View style={styles.webContainerInner}>{children}</View>
      </View>
    );
  }

  return <NotificationsContextProvider>{children}</NotificationsContextProvider>;
}

export default function AppContent() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  const dispatch = useDispatch();
  const { width, height } = useSelector((state: RootState) => state.dimension);

  const handleDimensionChange = ({ window }) => {
    dispatch(updateDimension({ width: window.width, height: window.height }));
  };

  useEffect(() => {
    if (GenUtil.isDesktopWeb()) {
      Dimensions.addEventListener('change', handleDimensionChange);
      return () => {
        Dimensions.removeEventListener('change', handleDimensionChange);
      };
    }
  }, []);

  useEffect(() => {
    const firebaseToken = StorageUtil.getFirebaseAuthTokenFromLocalStorage();
    if (!isFbUserLoggedIn() && firebaseToken) {
      signInToFirebase(firebaseToken);
    }
  });

  const onLayoutRootView = useCallback(async () => {
    if (isLoadingComplete) {
      await SplashScreen.hideAsync();
    }
  }, [isLoadingComplete]);

  if (!isLoadingComplete) {
    return null;
  }

  return (
    <View style={{ flex: 1 }} onLayout={onLayoutRootView} key={height + width}>
      <PaperProvider>
        <Provider store={store}>
          <PersistGate loading={<Text>Loading...</Text>} persistor={persistor}>
            <SafeAreaProvider>
              {/* <AnimatedSplash
                translucent={true}
                isLoaded={isLoadingComplete}
                backgroundColor={Color.purple}
              > */}
              {GenUtil.isDesktopWeb() ? (
                <WebContainer>
                  <BottomSheetModalProvider>
                    <WebNavigation colorScheme={colorScheme} />
                    <StatusBar />
                  </BottomSheetModalProvider>
                </WebContainer>
              ) : (
                <MobileContainer>
                  <BottomSheetModalProvider>
                    <Navigation colorScheme={colorScheme} />
                    <StatusBar />
                  </BottomSheetModalProvider>
                </MobileContainer>
              )}

              {/* </AnimatedSplash> */}
            </SafeAreaProvider>
            <ErrorComponent />
          </PersistGate>
        </Provider>
      </PaperProvider>
    </View>
  );
}

const styles = StyleSheet.create({
  webContainer: {
    height: GenUtil.getDimension().height,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 10,
  },
  mobileWebContainer: {
    height: GenUtil.getDimension().height,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  webContainerInner: {
    height: '100%',
    width: '100%',
    maxWidth: LayoutConstant.maxMobileWidth,
    overflow: 'hidden',
  },
});
