import React from 'react';
import { Slider as _Slider } from 'antd';

const MultiSlider = ({
  values = [],
  min = 1,
  max = 30,
  sliderLength = 300,
  onValuesChange = () => {},
  trackStyle = {},
  selectedStyle = null,
  markerStyle = null,
  enableLabel = true,
  step = 1,
  customLabel = null,
}) => (
  <>
    <_Slider
      range={true}
      defaultValue={[values[0], values[1]]}
      max={max}
      min={min}
      onChange={onValuesChange}
      step={step}
      railStyle={trackStyle}
      trackStyle={[selectedStyle]}
      // handleStyle={[markerStyle]}
      // tooltip={{ open: enableLabel }}
    />
  </>
);

export default MultiSlider;
