import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dimensions, ScaledSize } from 'react-native';

const initialState: ScaledSize = Dimensions.get('window');

const dimensionSlice = createSlice({
  name: 'dimension',
  initialState,
  reducers: {
    updateDimension: (state, action: PayloadAction<{ width: number; height: number }>) => {
      return {
        width: action.payload.width,
        height: action.payload.height,
      };
    },
  },
});

export const { updateDimension } = dimensionSlice.actions;

export default dimensionSlice.reducer;
