import { Dimensions, Linking, Platform } from 'react-native';
import * as Updates from 'expo-updates';
import { v4 as uuidv4 } from 'uuid';

// @ts-ignore
import Toast from 'react-native-toast-message';
import { strings } from 'src/util/LocalizationUtil';
import Endpoints from 'src/service/Endpoints';
import NetInfo from '@react-native-community/netinfo';
import { DeviceInfoUtil } from 'src/util/DeviceInfoUtil';
import compareVersions from 'compare-versions';
// import {Action} from '../store/Action';
import { IUser } from 'src/model/user.model';
import { LayoutConstant } from 'src/constant/LayoutConstant';
import * as queryString from 'querystring';
import { ParsedUrlQueryInput } from 'querystring';
import { LocationType } from 'src/common/models/location.model';
import { differenceInDays, format, isToday, isYesterday } from 'date-fns';
import { ProfileResp } from 'src/common/response/profile.resp';
import { Gender } from 'src/common/models/profile.model';

export class GenUtil {
  static isAdmin: boolean;
  static currentUser: IUser | undefined;

  static waitForTime(ms: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, ms);
    });
  }

  static getDimension() {
    const { width, height } = Dimensions.get('window');
    const isWeb = this.isWEB();
    if (isWeb) {
      return { width: Math.min(LayoutConstant.desktopWidth, width), height };
    }
    return { width, height };
  }

  static snackWithDismiss(msg: string) {
    // Snackbar.dismiss();
    // Snackbar.show({
    //     text: msg,
    //     duration: Snackbar.LENGTH_INDEFINITE,
    //     backgroundColor: Color.themeSilverGray,
    //     textColor: Color.white,
    //     action: {
    //         text: 'DONE',
    //         textColor: 'green',
    //         onPress: () => Snackbar.dismiss()
    //     }
    // });
  }

  static snack(msg: string) {
    // Snackbar.dismiss();
    // Snackbar.show({
    //     text: msg,
    //     duration: Snackbar.LENGTH_SHORT,
    //     backgroundColor: Color.themeSilverGray,
    //     textColor: Color.white
    // });
  }

  static snackLong(msg: string) {
    // Snackbar.dismiss();
    // Snackbar.show({
    //     text: msg,
    //     duration: Snackbar.LENGTH_LONG,
    //     backgroundColor: Color.darkBlack,
    //     textColor: Color.white
    // });
  }

  static convertObjectToQueryString(obj: object): string {
    return queryString.stringify(obj as ParsedUrlQueryInput);
  }

  static deepCloneArray(array: object[]) {
    return JSON.parse(JSON.stringify(array));
  }

  static isEmpty(obj: object): boolean {
    return obj == null || Object.keys(obj).length === 0;
  }

  static getNullIfEmpty(str: string): string | null {
    return !str ? null : str;
  }

  static getFullName(firstName: string, lastName: string): string {
    firstName = firstName || '';
    lastName = lastName || '';
    return `${firstName} ${lastName}`;
  }

  static getTitle(firstName: string, lastName: string): string {
    return (firstName?.charAt(0) || '') + ((lastName || '')?.charAt(0) || '');
  }

  static handleError(err: string | object) {
    GenUtil.tryCatchWrap(() => {
      GenUtil.log('error: ', err);
      // Snackbar.dismiss();

      if (typeof err === 'object') {
        GenUtil.showErrorToast(strings.something_went_wrong);
      } else {
        GenUtil.showErrorToast(err);
      }
    });
  }

  static handleSuccess(success: string) {
    GenUtil.showSuccessToast(success);
  }

  static tryCatchWrap(func: Function) {
    try {
      func();
    } catch (e) {
      GenUtil.log(e);
    }
  }

  static showInfoToast(text: string) {
    Toast.show({
      type: 'info',
      text1: text,
      visibilityTime: 500,
      autoHide: true,
    });
  }

  static showErrorToast(text: string) {
    Toast.show({
      type: 'error',
      text1: text,
      visibilityTime: 500,
      autoHide: true,
    });
  }

  static showSuccessToast(text: string, time?: number) {
    Toast.show({
      type: 'success',
      text1: text,
      visibilityTime: time || 500,
      autoHide: true,
    });
  }

  static openAppUpdate() {
    Linking.openURL(`${Endpoints.APP_LINK}&refresh_token=${uuidv4()}`);
  }

  static async checkInternet() {
    const { isConnected } = await NetInfo.fetch();
    if (!isConnected) {
      GenUtil.showErrorToast('Internet Connection is not available');
    }
  }

  static async restartApp() {
    Updates.reloadAsync();
  }

  static isTest(): boolean {
    return __DEV__;
  }

  static randomizeBetween(initial: number, final: number) {
    return Math.floor(initial + Math.random() * (final - initial));
  }

  static isIOS(): boolean {
    return Platform.OS === 'ios';
  }

  static isAndroid(): boolean {
    return Platform.OS === 'android';
  }

  static isWEB(): boolean {
    return Platform.OS === 'web';
  }

  static isDesktopWeb(): boolean {
    return this.isWEB() && window.innerWidth > 768;
  }

  static isMobileWeb(): boolean {
    return this.isWEB() && !this.isDesktopWeb();
  }

  static log(message?: any, ...optionalParams: any[]) {
    // if (LoaderUtil.dispatch !== undefined && this.isAdmin) {
    //     LoaderUtil.dispatch(Action.updateLogs(message + optionalParams));
    // }
    // console.log(message, optionalParams);
  }

  static warn(message?: any, ...optionalParams: any[]) {
    console.warn(message, optionalParams);
  }

  static isCurrentAppVersionGreaterThan(version: string) {
    return compareVersions(DeviceInfoUtil.getVersion().replace('-DEBUG', ''), version) === 1;
  }

  static identifyURLAndLongMessage(text: string) {
    if (
      new RegExp(
        '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?',
      ).test(text)
    ) {
      GenUtil.showErrorToast('You cannot send links');
      return true;
    } else if (text.length > 255) {
      GenUtil.showErrorToast('You cannot send more than 255 characters at a time');
      return true;
    } else {
      return false;
    }
  }

  static zeroPad = (number: number, size = 2) => {
    let s = String(number);
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  };

  static timeFormat = (miliseconds: number) => {
    let seconds = miliseconds / 1000;

    const hh = parseInt(String(seconds / 3600), 10);

    seconds %= 3600;

    const mm = parseInt(String(seconds / 60), 10);
    const ss = parseInt(String(seconds % 60), 10);

    return `${GenUtil.zeroPad(hh)}:${GenUtil.zeroPad(mm)}:${GenUtil.zeroPad(ss)}`;
  };

  static getMessageFromError = (error: { response: { data: { message: string } } }): string => {
    const errMessageFormat1 = error.response?.data?.message?.split(':')[1]?.trim();
    const errMessageFormat2 = error.response?.data?.message;
    return errMessageFormat1 ? errMessageFormat1 : errMessageFormat2 ? errMessageFormat2 : 'Error';
  };

  static convertHeighttoHeightString = (height: number) => {
    const inches = height * 0.393701;
    const feet = Math.floor(inches / 12);
    const remainingInches = Math.round(inches % 12);
    return `${feet}'${remainingInches}''`;
  };

  static getLocationName = (
    cityName: string,
    stateName: string,
    countryName: string,
    locationType: string,
    includeCountry: boolean = false,
  ) => {
    const val1 = cityName ? cityName : stateName ? stateName : countryName;
    const val2 =
      locationType === LocationType.City
        ? stateName
        : locationType === LocationType.State
        ? countryName
        : '';
    const val3 = includeCountry ? `, ${countryName}` : '';
    if (val2) return val1 + ', ' + val2 + val3;
    else return val1;
  };

  static formatTimeAccDuration(lastActiveAt: Date, showMoreThanWeekTime = false) {
    const lastActive = new Date(lastActiveAt);
    const date = new Date();

    if (isToday(lastActive)) {
      return format(lastActive, 'h:mm a');
    } else if (isYesterday(lastActive)) {
      return 'Yesterday';
    } else if (differenceInDays(date, lastActive) < 7) {
      return format(lastActive, 'do MMM');
    } else if (showMoreThanWeekTime) {
      return format(lastActive, 'do MMM yy');
    } else {
      return '';
    }
  }

  static getProfilePic(userProfile: Partial<ProfileResp>) {
    return GenUtil.isEmpty(userProfile?.profilePhoto)
      ? userProfile?.gender === Gender.Male
        ? require('src/assets/images/placeholder-male.png')
        : require('src/assets/images/placeholder-female.png')
      : { uri: userProfile?.profilePhoto?.originalUrl };
  }
}
