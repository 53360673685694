import { StyleSheet, Text, View, ViewStyle } from 'react-native';
import { Avatar } from 'src/components/common/avatar/Avatar';
import { Color } from 'src/constant/Color';

type Props = {
  data: Array<{ uri: string }>;
  style?: ViewStyle;
  maxCount?: number;
  blurImages?: boolean;
  showRemainingNumber: boolean;
};

const DEFAULT_MAX_COUNT = 3;

export const AvatarGroup = ({
  style,
  data,
  maxCount = DEFAULT_MAX_COUNT,
  blurImages = false,
  showRemainingNumber = false,
}: Props) => {
  return (
    <View style={[styles.group, style]}>
      {data.slice(0, maxCount).map((av, index) => {
        return (
          <Avatar
            key={av.uri}
            src={av.uri}
            blurImage={blurImages}
            style={index === 0 ? {} : { marginLeft: -25 }}
          />
        );
      })}
      {data.length > maxCount && !showRemainingNumber && (
        <View style={styles.count}>
          <Avatar src={data[maxCount + 1].uri} />
          <View style={styles.overlay} />
          <Text style={styles.countText}> +{data.length - maxCount}</Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  group: {
    flexDirection: 'row',
  },

  count: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -25,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 50,
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  countText: {
    position: 'absolute',
    top: 16,
    left: 13,
    color: Color.white,
    fontSize: 15,
    fontWeight: '600',
  },
});
