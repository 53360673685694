import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import React from 'react';
import { Icon } from 'react-native-elements';

import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';

const { width } = GenUtil.getDimension();

const OnboardingHeaderComp = ({ prev = () => {}, title = 'Create MM Profile' }) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={prev} style={styles.backButton}>
        <Icon name="arrow-back" type="ionicon" size={20} />
      </TouchableOpacity>
      <Text style={styles.titleText}>{title}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: GenUtil.isWEB() ? 20 : 50,
    marginBottom: 20,
    marginHorizontal: width * 0.05,
  },
  backButton: {
    left: 0,
    position: 'absolute',
    color: Color.gray,
  },
  titleText: {
    fontFamily: 'sora-light',
    fontSize: 15,
  },
});

export default OnboardingHeaderComp;
