import { useMemo, useState } from 'react';
import { InterestActionType, InterestsStatus, SentType } from 'src/common/models/interest.model';
import { HttpResponse } from 'src/common/response/base.resp';
import { ProfileResp } from 'src/common/response/profile.resp';
import { InterestService } from 'src/service/InterestService';

export type PostRequestState = {
  sentType: SentType | ActionItems;
  status: InterestsStatus;
};

export type ActionT = {
  label: string;
  onClick: () => Promise<void>;
  showLabel: boolean;
  type: ActionItems;
  disabled?: boolean;
};

type ActionHandler = {
  request: (profile: Partial<ProfileResp>) => Promise<HttpResponse<any>>;
  interestState: PostRequestState;
};

export enum ActionItems {
  SendInterest,
  UnSendInterest,
  DeclineInterest,
  AcceptInterest,
  Reminder,
  InterestSent,
}

const ONCLICK_ACTIONS: Record<ActionItems, ActionHandler> = {
  [ActionItems.AcceptInterest]: {
    request: async (profile: Partial<ProfileResp>) =>
      await InterestService.actionOnInterest({
        interestUuid: profile.interest?.uuid,
        type: InterestActionType.Accept,
        customMessage: '',
      }),
    interestState: {
      sentType: SentType.Received,
      status: InterestsStatus.Accepted,
    },
  },
  [ActionItems.DeclineInterest]: {
    request: async (profile: Partial<ProfileResp>) =>
      await InterestService.actionOnInterest({
        interestUuid: profile.interest?.uuid,
        type: InterestActionType.Decline,
        customMessage: '',
      }),
    interestState: {
      sentType: SentType.Received,
      status: InterestsStatus.Declined,
    },
  },
  [ActionItems.UnSendInterest]: {
    request: async (profile: Partial<ProfileResp>) =>
      await InterestService.deleteInterest(profile.interest.uuid),
    interestState: {
      sentType: ActionItems.SendInterest,
      status: undefined,
    },
  },
};

export const useProfileActionItems = (profile: Partial<ProfileResp>) => {
  const [interestState, setInterestState] = useState<PostRequestState>({
    sentType: profile.interest?.sentType,
    status: profile.interest?.status,
  });
  const [reminderSent, setReminderSent] = useState(false);

  const handleOnClick = (type: ActionItems) => async () => {
    try {
      const { interestState, request } = ONCLICK_ACTIONS[type];

      if (type === ActionItems.SendInterest) {
        return;
      }
      const response = await request(profile);
      if (response.success) {
        setInterestState(interestState);
      } else {
        console.log(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSendreminder = async () => {
    try {
      const response = await InterestService.sendInterestReminder({
        interestUuid: profile.interest.uuid,
        customMessage: '',
      });
      if (response.success) {
        setReminderSent(true);
      } else {
        console.log('Error: ', response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const actionItems = useMemo(() => {
    const getInterestReceivedAction = (status: InterestsStatus): ActionT | ActionT[] => {
      switch (status) {
        case InterestsStatus.Pending:
        case InterestsStatus.Archived:
        case InterestsStatus.Ignored:
          return [
            {
              onClick: handleOnClick(ActionItems.DeclineInterest),
              label: 'Decline',
              showLabel: false,
              type: ActionItems.DeclineInterest,
            },
            {
              label: 'Accept',
              onClick: handleOnClick(ActionItems.AcceptInterest),
              showLabel: true,
              type: ActionItems.AcceptInterest,
            },
          ];

        case InterestsStatus.Accepted:
          return {
            label: 'Decline',
            onClick: handleOnClick(ActionItems.DeclineInterest),
            showLabel: true,
            type: ActionItems.DeclineInterest,
          };

        case InterestsStatus.Declined:
          return {
            label: 'Re-Accept',
            onClick: handleOnClick(ActionItems.AcceptInterest),
            showLabel: true,
            type: ActionItems.AcceptInterest,
          };
      }
    };

    const getInterestSentAction = (status: InterestsStatus): ActionT | ActionT[] => {
      switch (status) {
        case InterestsStatus.Pending:
        case InterestsStatus.Archived:
        case InterestsStatus.Ignored:
          return [
            {
              label: 'Unsend Interest',
              onClick: handleOnClick(ActionItems.UnSendInterest),
              showLabel: true,
              type: ActionItems.UnSendInterest,
            },
            ...(profile?.interest?.reminders?.length === 2
              ? []
              : [
                  {
                    label: reminderSent ? 'Reminder Sent' : 'Send Reminder',
                    showLabel: true,
                    onClick: handleSendreminder,
                    type: ActionItems.Reminder,
                    disabled: reminderSent,
                  },
                ]),
          ];

        case InterestsStatus.Accepted:
          return {
            label: 'Decline',
            onClick: handleOnClick(ActionItems.DeclineInterest),
            showLabel: true,
            type: ActionItems.DeclineInterest,
          };

        case InterestsStatus.Declined:
          return;
      }
    };

    switch (interestState.sentType) {
      case SentType.Received:
        return getInterestReceivedAction(interestState.status);

      case SentType.Sent:
        return getInterestSentAction(interestState.status);

      case ActionItems.InterestSent:
        return {
          label: 'Interest Sent',
          disabled: true,
          showLabel: true,
          type: ActionItems.InterestSent,
        };

      case ActionItems.SendInterest:
        return {
          label: 'Send Interest',
          onClick: handleOnClick(ActionItems.SendInterest),
          showLabel: true,
          type: ActionItems.SendInterest,
        };

      default:
        return {
          label: 'Send Interest',
          onClick: handleOnClick(ActionItems.SendInterest),
          showLabel: true,
          type: ActionItems.SendInterest,
        };
    }
  }, [interestState, reminderSent]);

  return {
    actionItems,
    setInterestState,
  };
};
