import { ActivityIndicator, FlatList, Text, View } from 'react-native';
import { useEffect, useState } from 'react';
import { ProfileService } from 'src/service/ProfileService';
import SmallProfileCard from 'src/components/common/profileCard/SmallProfileCard';
import HomeSection from 'src/components/common/HomeSection';
import { ProfileResp } from 'src/common/response/profile.resp';
import { ProfileTabsEnum } from 'src/screens/DynamicProfileList';

const ShortlistedProfilesSection = () => {
  const [profilesData, setProfilesData] = useState<ProfileResp[]>([]);

  const fetchShortlistedProfiles = async () => {
    try {
      const response = await ProfileService.getShortlistedProfiles();

      if (response.success) {
        setProfilesData(response.data);
      } else {
        console.log(response.message, response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchShortlistedProfiles();
  }, []);
  return (
    <HomeSection
      title={'Shortlisted Profiles'}
      navigateOnViewAll={{
        to: 'dynamicprofileslist',
        state: {
          title: 'Shortlisted Profiles',
          tabs: [ProfileTabsEnum.ShortlistedProfiles],
        },
      }}
    >
      {profilesData ? (
        profilesData.length > 0 ? (
          <FlatList
            data={profilesData}
            renderItem={({ item }) => <SmallProfileCard item={item} />}
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            keyExtractor={(item) => item.uuid}
          />
        ) : (
          <View style={{ minHeight: 100, justifyContent: 'center', alignItems: 'center' }}>
            <Text>No Profiles found</Text>
          </View>
        )
      ) : (
        <View style={{ minHeight: 50, justifyContent: 'center' }}>
          <ActivityIndicator />
        </View>
      )}
    </HomeSection>
  );
};

export default ShortlistedProfilesSection;
