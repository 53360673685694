const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export const Color = {
  purple: '#8041c6',
  black: '#2e2835',
  backgroundGray: '#fafafa',
  gray: '#b8bcd2',
  lightBorder: '#d6d6d6',
  white: '#ffffff',
  themeSilverGray: '#1c1c1c',
  orange: '#FF4D4D',
  lightblue: '#20d5ea',
  halfBlack: 'rgba(52, 52, 52, 0.7)',
  transparent: '#ffffff',
  red: '#ba232b',
  lightYellow: '#faf3d2',
  lightPurple: '#f8e8ff',
  blue: '#0000FF',
  green: '#008000',
  lightGrey: '#A2A2A2',
  lightBlack: '#2C2C2C',
  darkPurple: '#2E2835',
  brown: 'rgba(141, 110, 99, 1)',
  lightBrown: 'rgba(159, 128, 116, 1)',
};

export default {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
};
