import { View, Text, StyleSheet, Image } from 'react-native';
import React, { useState, useEffect } from 'react';

import PillShapeChip from 'src/components/common/chips/PillShapeChip';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import InputAdornment from 'src/components/common/input/InputAdornment';
import { ReligionData } from 'src/common/data/religion.data';

const { width } = GenUtil.getDimension();

interface subCommunityOptions {
  key: string;
  value: string;
}

const OnboardingSubCommunityInputComp = ({
  prev = () => {},
  next = () => {},
  subCommunity = null,
  setSubCommunity = (_) => {},
  religion = '',
}) => {
  const [subCommunitySelected, setSubCommunitySelected] = useState(
    subCommunity ? [subCommunity] : [],
  );
  const [searchedSubCommunity, setSearchedSubCommunity] = useState<string>('');
  const [subCommunityArray, setSubCommunityArray] = useState([] as subCommunityOptions[]);
  const [subCommunityArrayFilter, setSubCommunityArrayFilter] = useState(
    [] as subCommunityOptions[],
  );

  useEffect(() => {
    const temp = [];
    // setsubCommunity('')
    ReligionData?.map((value) => {
      if (value.name === religion) {
        Object.values(value.communityMap).map((res) => {
          temp.push({ key: res?.name, value: res?.name });
        });
      }
    });
    setSubCommunityArray(temp);

    const temp2 = temp.slice(0, 6);
    setSubCommunityArrayFilter(temp2);
  }, []);

  useEffect(() => {
    if (searchedSubCommunity === subCommunitySelected?.[0]) return;
    if (searchedSubCommunity !== '') {
      setSubCommunityArrayFilter(
        subCommunityArray
          .filter((value) => {
            return value.value.toLowerCase().includes(searchedSubCommunity.toLowerCase());
          })
          .slice(0, 6),
      );
    }
  }, [searchedSubCommunity]);

  useEffect(() => {
    if (subCommunitySelected.length > 0) {
      ReligionData?.map((res) => {
        if (res.name === religion) {
          setSubCommunity(
            Object.values(res.communityMap).find((c) => c.name === subCommunitySelected?.[0]),
          );
        }
      });
    }
  }, [subCommunitySelected]);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Your Sub Community</Text>
      <InputAdornment
        value={searchedSubCommunity}
        setValue={setSearchedSubCommunity}
        placeholder={'Search here...'}
        styleOverride
        icon={
          <Image
            source={require('src/assets/images/onboardingIcons/searchIcon.png')}
            style={{ height: 15.24, width: 15.24 }}
          />
        }
      />
      <PillShapeChip
        options={subCommunityArrayFilter}
        selected={subCommunitySelected}
        setSelected={setSubCommunitySelected}
        callChipClicked
        chipClicked={() => {
          setTimeout(() => next(), 500);
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontFamily: EFonts.SORA_LIGHT,
    fontSize: 28,
    lineHeight: 38,
    color: Color.lightBlack,
    marginTop: 10,
  },
  container: {
    paddingTop: 20,
    flex: GenUtil.isWEB() ? null : 1,
    paddingHorizontal: width * 0.05,
    backgroundColor: 'transparent',
  },
});

export default OnboardingSubCommunityInputComp;
