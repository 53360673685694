import { StyleSheet, Text, View } from 'react-native';
import { Color } from 'src/constant/Color';
import { Dialog } from 'react-native-simple-dialogs';
import { MembershipModel } from 'src/common/models/membership.model';
import React from 'react';
import EFonts from 'src/constant/EFonts';
import { Feather } from '@expo/vector-icons';
import SecondaryButton from 'src/components/common/buttons/SecondaryButton';

type Props = {
  visible: boolean;
  order: MembershipModel;
  onPress: () => void;
};

const PaymentSuccessModal = ({ visible, order, onPress }: Props) => {
  return (
    <Dialog animationType={'fade'} visible={visible}>
      <View>
        <Text style={styles.heading}>Welcome to Matri Milan Premium</Text>
      </View>
      <View>
        <View style={styles.benefitsDesc}>
          {order.membershipBenefits.contacts && (
            <View style={styles.textContainer}>
              <View style={styles.featherContainer}>
                <Feather name={'check'} size={10} color={Color.white} />
              </View>
              <Text style={styles.benefitsDescItem}>
                {`Get upto ${order.membershipBenefits.contacts} Contacts`}
              </Text>
            </View>
          )}
          {order.membershipBenefits.highlights && (
            <View style={styles.textContainer}>
              <View style={styles.featherContainer}>
                <Feather name={'check'} size={10} color={Color.white} />
              </View>
              <Text style={styles.benefitsDescItem}>
                {`Get upto ${order.membershipBenefits.highlights} Highlights`}
              </Text>
            </View>
          )}
          {order.membershipBenefits.premiumInterests && (
            <View style={styles.textContainer}>
              <View style={styles.featherContainer}>
                <Feather name={'check'} size={10} color={Color.white} />
              </View>
              <Text style={styles.benefitsDescItem}>
                {`Send upto ${order.membershipBenefits.premiumInterests} Interests`}
              </Text>
            </View>
          )}
        </View>
        <SecondaryButton buttonText={'Go to Home'} handleKeyPress={onPress} />
      </View>
    </Dialog>
  );
};

const styles = StyleSheet.create({
  heading: {
    color: Color.purple,
    fontSize: 18,
    fontWeight: '600',
    marginBottom: 15,
  },
  benefitsDesc: {
    fontFamily: EFonts.DMSANS_REGULAR,
    marginTop: 7,
  },
  featherContainer: {
    marginRight: 12,
    backgroundColor: Color.purple,
    width: 17,
    height: 17,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  benefitsDescItem: {
    color: Color.themeSilverGray,
    fontFamily: EFonts.DMSANS_REGULAR,
    fontSize: 13,
    marginLeft: 5,
    marginBottom: 3,
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
});

export default PaymentSuccessModal;
