import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { GenUtil } from 'src/util/GenUtil';
import { ProfileService } from 'src/service/ProfileService';
import ProfileCell from 'src/components/ProfileCell';
import VerticalFlashList from 'src/components/VerticalFlashList';

const { height, width } = GenUtil.getDimension();

const BlockedProfiles = () => {
  const [profilesData, setProfilesData] = useState([]);
  const [page, setPage] = useState(0);

  const getBlockedProfiles = async (page = 0) => {
    const response = await ProfileService.fetchRestrictedProfiles(page);
    if (response.success) {
      if (response.data.length === 0) return [];
      setPage(page + 1);
      return response.data;
    } else {
      console.log(response.message);
    }
  };

  const fetchMoreBlockedProfiles = async () => {
    try {
      const moreProfiles = await getBlockedProfiles(page);
      setProfilesData([...profilesData, ...moreProfiles]);
    } catch (error) {
      console.log('error in fetching more blocked profiles', error);
    }
  };

  useEffect(() => {
    getBlockedProfiles()
      .then((response) => setProfilesData(response))
      .catch((error) => console.log('error in getting blocked profiles', error));
  }, []);

  return (
    <View style={styles.container}>
      <View style={{ minWidth: width * 0.9, flex: 1, paddingTop: 5 }}>
        {profilesData.length > 0 && (
          <VerticalFlashList
            data={profilesData}
            renderItem={({ item, index }) => (
              <View style={{ margin: 10 }} key={index}>
                <ProfileCell
                  profile={item}
                  isBlockedProfile={true}
                  getBlockedProfiles={getBlockedProfiles}
                />
              </View>
            )}
            onEndReached={fetchMoreBlockedProfiles}
            estimatedItemSize={500}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default BlockedProfiles;
