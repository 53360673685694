import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Color } from 'src/constant/Color';
import GalleryImageSvg from 'src/assets/icons/gallery-images.svg';

export default function GalleryAction({ imagesCount }: { imagesCount: number | string }) {
  return (
    <TouchableOpacity style={styles.container}>
      <GalleryImageSvg />
      {imagesCount > 0 ? (
        <View style={styles.imagesCount}>
          <Text style={styles.imagesText}>{imagesCount}</Text>
        </View>
      ) : (
        <></>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 35,
    height: 35,
    borderRadius: 50,
    backgroundColor: Color.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imagesCount: {
    width: 17,
    height: 17,
    backgroundColor: '#FF0000',
    borderRadius: 50,
    position: 'absolute',
    top: -5,
    right: -5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  imagesText: {
    fontSize: 11,
    color: Color.white,
    font: 'dmsans-bold',
    fontWeight: '600',
  },
});
