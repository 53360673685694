/* eslint-disable @typescript-eslint/no-floating-promises */
import { View } from 'react-native';
import React, { useState, useEffect } from 'react';
import RangeSlider from 'src/components/common/Slider';
import CheckboxAdornment from 'src/components/editProfileComponents/atoms/CheckboxAdornment';
import { Gender, PreferenceLevel } from 'src/common/models/profile.model';
import { debounce } from 'lodash';
import { ProfileSearchBody } from 'src/common/dto/profile.dto';

const AgeRangeComp = ({
  userGender,
  userAge,
  partnerMinAge,
  partnerMaxAge,
  privacyPrefLevel,
  updatePartnerPref,
  updatePrivateSettings,
  strictlyFollow = true,
  setSearchParams,
}: {
  userGender: string;
  userAge: number;
  partnerMinAge: number;
  partnerMaxAge: number;
  privacyPrefLevel: PreferenceLevel;
  updatePartnerPref: (_) => Promise<void>;
  updatePrivateSettings: (_) => Promise<void>;
  strictlyFollow?: boolean;
  setSearchParams?: (prevParams) => void;
}) => {
  const sliderRange = {
    min: userGender ? userAge - (userGender === Gender.Male ? 7 : 3) : 18,
    max: userGender ? userAge + (userGender === Gender.Male ? 3 : 7) : 99,
  };

  const [ageRange, setAgeRange] = useState<number[]>([partnerMinAge ?? sliderRange.min, partnerMaxAge ?? sliderRange.max]);
  const [privacyLevel, setPrivacyLevel] = useState(privacyPrefLevel ?? 1);

  const applyDebounce = debounce(async (agePref: Partial<ProfileSearchBody>) => {
    if (setSearchParams) {
      setSearchParams((prevParams: Partial<ProfileSearchBody>) => ({ ...prevParams, ...agePref }));
      return;
    }
    await updatePartnerPref(agePref);
  }, 1000);

  useEffect(() => {
    const agePref = {
      minAge: ageRange[0],
      maxAge: ageRange[1] === 60 ? 99 : ageRange[1],
    };

    if (!(ageRange[0] === partnerMinAge && ageRange[1] === partnerMaxAge)) applyDebounce(agePref);
    return () => {
      applyDebounce.cancel();
    };
  }, [ageRange]);

  return (
    <View>
      <RangeSlider
        defaultMinVal={partnerMinAge ?? sliderRange.min}
        defaultMaxVal={partnerMaxAge ?? sliderRange.max}
        min={18}
        max={60}
        onValueChange={setAgeRange}
        minValueText="18 years"
        maxValueText="60 years+"
      />
      <View>
        {strictlyFollow && (
          <CheckboxAdornment
            label="Strictly Follow"
            updatePrivateSettings={updatePrivateSettings}
            privacyLevel={privacyLevel}
            setPrivacyLevel={setPrivacyLevel}
            prefPrivacy={{ ageRangePref: privacyLevel }}
          />
        )}
      </View>
    </View>
  );
};

export default AgeRangeComp;
