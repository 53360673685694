import {
  ActivityIndicator,
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { Color } from 'src/constant/Color';
import { AvatarGroup } from 'src/components/common/avatar';
import React, { useEffect, useState } from 'react';
import { ProfileService } from 'src/service/ProfileService';
import { ProfileResp } from 'src/common/response/profile.resp';
import RightArrowLongSvg from 'src/assets/icons/rightArrowLongWhite.svg';
import LockSvg from 'src/assets/icons/lock.svg';
import { useNavigation } from '@react-navigation/native';
import { ProfileTabsEnum } from 'src/screens/DynamicProfileList';
import { GenUtil } from 'src/util/GenUtil';
import { Gender } from 'src/common/models/profile.model';

const ProfileVisitorsCard = ({ hideProfiles }: { hideProfiles: boolean }) => {
  const [profilesData, setProfilesData] = useState<ProfileResp[]>([]);
  const navigation = useNavigation();

  const fetchProfileVisitors = async () => {
    try {
      const response = await ProfileService.getProfileViewers();

      if (response.success) {
        setProfilesData(response.data);
      } else {
        console.log(response.message, response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchProfileVisitors();
  }, []);

  const handlePress = () => {
    if (hideProfiles) {
      const membershipScreen = GenUtil.isDesktopWeb() ? 'membership' : 'Membership';
      return navigation.navigate(membershipScreen);
    }

    navigation.navigate('dynamicprofileslist', {
      title: 'Profile Visitors',
      tabs: [ProfileTabsEnum.ProfileVisitors],
    });
  };

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require('src/assets/images/background/bubbles.png')}
        style={styles.imgBg}
      >
        <View style={styles.header}>
          <Text style={styles.textHeading}>Profile Visitors</Text>
          {hideProfiles && <LockSvg />}
        </View>
        <View style={styles.avatarContainer}>
          {profilesData ? (
            profilesData.length > 0 ? (
              <AvatarGroup
                data={profilesData.map((p) => {
                  return {
                    uri: GenUtil.isEmpty(p.profilePhoto)
                      ? p.gender === Gender.Male
                        ? require('src/assets/images/placeholder-male.png')
                        : require('src/assets/images/placeholder-female.png')
                      : p.profilePhoto?.originalUrl,
                  };
                })}
                blurImages={hideProfiles}
                showRemainingNumber={hideProfiles}
              />
            ) : (
              <View style={{ height: '100%', justifyContent: 'center' }}>
                <Text style={styles.actionText}>0 Profiles Viewed</Text>
              </View>
            )
          ) : (
            <View style={{ minHeight: 50, justifyContent: 'center' }}>
              <ActivityIndicator />
            </View>
          )}
        </View>
        {profilesData && profilesData.length > 0 && (
          <TouchableOpacity style={styles.action} onPress={handlePress}>
            <Text style={styles.actionText}>{hideProfiles ? 'Upgrade Profile' : 'View All'}</Text>
            <RightArrowLongSvg />
          </TouchableOpacity>
        )}
      </ImageBackground>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '49%',
    backgroundColor: '#FF725E',
    borderRadius: 12,
    height: 190,
    marginRight: 10,
  },
  imgBg: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
  },
  header: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textHeading: {
    color: Color.white,
    fontWeight: '600',
    fontSize: 15,
    letterSpacing: -0.01,
    fontFamily: 'dmsans-bold',
    alignSelf: 'flex-start',
  },
  avatarContainer: {
    alignSelf: 'center',
  },
  action: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionText: {
    color: Color.white,
    fontFamily: 'dmsans-bold',
  },
});

export default ProfileVisitorsCard;
