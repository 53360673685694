import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import React from 'react';
import { Icon } from 'react-native-elements';
import { Color } from 'src/constant/Color';
interface drawerProps {
  screenName?: string;
  navigation?: { [key: string]: (s: string) => string };
  displayName?: string;
  isActive?: boolean;
}

const CustomDrawerItem = ({
  screenName = 'data',
  navigation = { navigate: (screenName: string) => screenName },
  displayName = 'My Membership',
  isActive = false,
}: drawerProps) => {
  return (
    <TouchableOpacity
      style={
        isActive
          ? StyleSheet.compose(styles.drawerItem, styles.activeDrawerItemOvveride)
          : styles.drawerItem
      }
      onPress={() => navigation.navigate(screenName)}
    >
      <Text
        style={
          isActive
            ? StyleSheet.compose(styles.drawerItemText, styles.activeDrawerTextOvveride)
            : styles.drawerItemText
        }
      >
        {displayName}
      </Text>
      <Icon
        name="keyboard-arrow-right"
        type="material"
        size={22}
        color={isActive ? Color.red : Color.gray}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  drawerItem: {
    marginHorizontal: 10,
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Color.white,
  },
  drawerItemText: {
    fontFamily: 'dmsans-regular',
    fontSize: 14,
    color: Color.lightBlack,
  },
  activeDrawerItemOvveride: {
    backgroundColor: Color.lightPurple,
  },
  activeDrawerTextOvveride: {
    color: Color.red,
  },
});

export default CustomDrawerItem;
