import React from 'react';
import { View, StyleSheet, ImageBackground, TouchableOpacity, Text } from 'react-native';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';

const PillShapeCounter = ({
  onIncrementPressed = () => {},
  onDecrementPressed = () => {},
  count = 0,
}) => {
  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={onDecrementPressed}
        style={{
          height: 14,
          width: 14,
          justifyContent: 'center',
        }}
      >
        <ImageBackground
          style={{ height: 2, width: 14 }}
          source={require('src/assets/icons/minus1x.png')}
        />
      </TouchableOpacity>
      <Text style={styles.count}>{count}</Text>
      <TouchableOpacity
        onPress={onIncrementPressed}
        style={{
          height: 14,
          width: 14,
          justifyContent: 'center',
        }}
      >
        <ImageBackground
          style={{ height: 14, width: 14 }}
          source={require('src/assets/icons/plus1x.png')}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 122,
    height: 38,
    borderRadius: 80,
    borderWidth: 1,
    borderColor: Color.gray,
    justifyContent: 'space-around',
  },
  count: {
    fontSize: 12,
    fontFamily: EFonts.SORA_BOLD,
    lineHeight: 15,
    color: '#2E2835',
  },
});

export default PillShapeCounter;
