import { View, SafeAreaView, StyleProp, ViewStyle } from 'react-native';
import React from 'react';
import PageHeader from './common/PageHeader';

type SafeContainerProps = {
  showPageHeader?: boolean;
  heading?: string;
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
  renderHeading?: React.ReactNode;
  leftIcon?: React.ReactNode;
  onPressLeftIcon?: () => void;
};

const SafeContainer = ({
  showPageHeader = false,
  heading,
  style,
  children,
  renderHeading,
  leftIcon = null,
  onPressLeftIcon = () => {},
}: SafeContainerProps) => {
  return (
    <View style={{ flex: 1 }}>
      {showPageHeader && (
        <PageHeader
          heading={heading}
          renderHeading={renderHeading}
          leftIcon={leftIcon}
          onPressLeftIcon={onPressLeftIcon}
        />
      )}
      <SafeAreaView style={style}>{children}</SafeAreaView>
    </View>
  );
};

export default SafeContainer;
