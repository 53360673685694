import React from 'react';
import { TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native';
import { Text } from 'src/components/Themed';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';

const { width } = GenUtil.getDimension();

const SecondaryButton = ({
  buttonDisabled = false,
  isValidating = false,
  buttonText = 'Secondary',
  handleKeyPress = () => {},
  buttonStyle = {},
}) => {
  return (
    <TouchableOpacity
      disabled={buttonDisabled}
      style={
        !buttonDisabled
          ? buttonStyle
            ? StyleSheet.compose(styles.button, buttonStyle)
            : styles.button
          : StyleSheet.compose(styles.button, styles.buttonDisabledOverride)
      }
      onPress={handleKeyPress}
    >
      {isValidating ? (
        <ActivityIndicator size={'small'} color={Color.white} />
      ) : (
        <Text style={styles.buttonText}>{buttonText}</Text>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: Color.black,
    width: width * 0.8,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 15,
    borderRadius: 24,
  },
  buttonDisabledOverride: {
    backgroundColor: Color.gray,
  },
  buttonText: {
    color: Color.white,
    fontSize: 17,
    fontFamily: 'dmsans-bold',
  },
});
export default SecondaryButton;
