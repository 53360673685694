import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export const CircularProgressBase = ({
  value,
  radius,
  duration,
  activeStrokeColor,
  inActiveStrokeColor,
  activeStrokeWidth,
  inActiveStrokeWidth,
  maxValue,
}) => (
  <div style={{ width: radius * 2, height: radius * 2 }}>
    <CircularProgressbar
      value={value}
      maxValue={maxValue}
      strokeWidth={activeStrokeWidth || inActiveStrokeWidth}
      styles={buildStyles({
        pathColor: activeStrokeColor,
        trailColor: inActiveStrokeColor,
        textSize: 26,
        backgroundColor: 'red',
      })}
    />
  </div>
);
