import { View } from 'react-native';
import React from 'react';
import ImageGalleryComp from 'src/components/ImageGalleryComp';
import SafeContainer from 'src/components/SafeContainer';

const EditPhotoScreen = () => {
  return (
    <SafeContainer style={{ flex: 1 }} showPageHeader={true} heading="My Photos">
      <View style={{ flex: 1, marginTop: 20 }}>
        <ImageGalleryComp />
      </View>
    </SafeContainer>
  );
};

export default EditPhotoScreen;
