import axios, { AxiosResponse } from 'axios';
import Endpoints from 'src/service/Endpoints';
import { BaseResp, HttpResponse } from 'src/common/response/base.resp';
import {
  AstroDetailsBody,
  BasicProfileBody,
  CareerDetailsBody,
  EducationDetailsBody,
  FamilyDetailsBody,
  HobbiesAndInterestBody,
  LifeStyleBody,
  PartialProfileBody,
  PartnerPrefBody,
  PrivacySettingsBody,
  ReligionDetailsBody,
  ReligiousBelief,
} from 'src/common/dto/profile.dto';
import { ProfileResp } from 'src/common/response/profile.resp';
import { NotificationSettingsResp } from 'src/common/response/profile-notification-setting.resp';

export class ProfileUpdateService {
  static async updateBasic(basicProfileBody: BasicProfileBody): Promise<HttpResponse<BaseResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.PROFILE_UPDATE_BASIC}`,
      { ...basicProfileBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async updateFamily(familyDetailsBody: FamilyDetailsBody): Promise<HttpResponse<BaseResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.PROFILE_UPDATE_FAMILY}`,
      { ...familyDetailsBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async updateEducation(
    educationDetailsBody: EducationDetailsBody,
  ): Promise<HttpResponse<ProfileResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<ProfileResp>>>(
      `${Endpoints.PROFILE_UPDATE_EDUCATION}`,
      { ...educationDetailsBody },
    );
    return HttpResponse.fromRes<ProfileResp>(response.data);
  }

  static async updateCareer(
    careerDetailsBody: CareerDetailsBody,
  ): Promise<HttpResponse<ProfileResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<ProfileResp>>>(
      `${Endpoints.PROFILE_UPDATE_CAREER}`,
      { ...careerDetailsBody },
    );
    return HttpResponse.fromRes<ProfileResp>(response.data);
  }

  static async updateLifeStyle(lifeStyleBody: LifeStyleBody): Promise<HttpResponse<BaseResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.PROFILE_UPDATE_LIFESTYLE}`,
      { ...lifeStyleBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async updateReligion(
    religionDetailsBody: ReligionDetailsBody,
  ): Promise<HttpResponse<BaseResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.PROFILE_UPDATE_RELIGION}`,
      { ...religionDetailsBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async updateAstro(astroDetailsBody: AstroDetailsBody): Promise<HttpResponse<ProfileResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<ProfileResp>>>(
      `${Endpoints.PROFILE_UPDATE_ASTRO}`,
      { ...astroDetailsBody },
    );
    return HttpResponse.fromRes<ProfileResp>(response.data);
  }

  static async updateReligiousBeliefs(religiousBeliefs: {
    religiousBeliefs: ReligiousBelief[];
  }): Promise<HttpResponse<ProfileResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<ProfileResp>>>(
      `${Endpoints.PROFILE_UPDATE_RELIGIOUS_BELIEFS}`,
      { ...religiousBeliefs },
    );
    return HttpResponse.fromRes<ProfileResp>(response.data);
  }

  static async updateHobbies(
    hobbiesAndInterestBody: HobbiesAndInterestBody,
  ): Promise<HttpResponse<BaseResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.PROFILE_UPDATE_HOBBIES}`,
      { ...hobbiesAndInterestBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async updatePartialUpdate(
    partialProfileBody: PartialProfileBody,
  ): Promise<HttpResponse<BaseResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<BaseResp>>>(
      `${Endpoints.PROFILE_UPDATE_PARTIAL_UPDATE}`,
      { ...partialProfileBody },
    );
    return HttpResponse.fromRes<BaseResp>(response.data);
  }

  static async updatePartnerPreference(
    partnerPrefBody: PartnerPrefBody,
  ): Promise<HttpResponse<ProfileResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<ProfileResp>>>(
      `${Endpoints.PROFILE_UPDATE_PARTNER_PREFERENCE}`,
      { ...partnerPrefBody },
    );
    return HttpResponse.fromRes<ProfileResp>(response.data);
  }

  static async updatePrivacySettings(
    privacySettingsBody: PrivacySettingsBody,
  ): Promise<HttpResponse<ProfileResp>> {
    const response = await axios.put<any, AxiosResponse<HttpResponse<ProfileResp>>>(
      `${Endpoints.PROFILE_UPDATE_PRIVACY_SETTINGS}`,
      { ...privacySettingsBody },
    );
    return HttpResponse.fromRes<ProfileResp>(response.data);
  }

  static async updateNotificationSettings(notificationSettingsBody) {
    const response = await axios.put<any, AxiosResponse<HttpResponse<NotificationSettingsResp>>>(
      `${Endpoints.NOTIFICATIONS_SETTINGS}`,
      { ...notificationSettingsBody },
    );
    return HttpResponse.fromRes<NotificationSettingsResp>(response.data);
  }
}
