export const getDayRemainingPercentage = (dateString: string | Date) => {
  const now = new Date();
  const expiryDate = new Date(dateString);
  const timeDiff = (expiryDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24);

  const daysRemaining = Math.ceil(timeDiff);

  const daysTotal = Math.ceil(timeDiff);

  return Math.round((daysRemaining / daysTotal) * 100);
};

export const getDaysRemaining = (dateString: string | Date) => {
  const now = new Date();
  const expiryDate = new Date(dateString);

  const timeDiff = expiryDate.getTime() - now.getTime();
  const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  const monthsRemaining: number = Math.floor(
    (expiryDate.getFullYear() - now.getFullYear()) * 12 + (expiryDate.getMonth() - now.getMonth()),
  );

  // Return result based on difference in months
  if (monthsRemaining >= 1) {
    return `${monthsRemaining} month(s)`;
  } else {
    return `${daysRemaining} day(s)`;
  }
};
