import { FileExtension, MimeType } from 'file-type';
import { ProfileMediaCategory, ProfileMediaType } from 'src/common/models/profile-media.model';

export const FileExtensions: FileExtension[] = [
  'jpg',
  'png',
  'apng',
  'gif',
  'webp',
  'flif',
  'xcf',
  'cr2',
  'cr3',
  'orf',
  'arw',
  'dng',
  'nef',
  'rw2',
  'raf',
  'tif',
  'bmp',
  'icns',
  'jxr',
  'psd',
  'indd',
  'zip',
  'tar',
  'rar',
  'gz',
  'bz2',
  '7z',
  'dmg',
  'mp4',
  'mid',
  'mkv',
  'webm',
  'mov',
  'avi',
  'mpg',
  'mp2',
  'mp3',
  'm4a',
  'ogg',
  'opus',
  'flac',
  'wav',
  'qcp',
  'amr',
  'pdf',
  'epub',
  'mobi',
  'elf',
  'exe',
  'swf',
  'rtf',
  'woff',
  'woff2',
  'eot',
  'ttf',
  'otf',
  'ico',
  'flv',
  'ps',
  'xz',
  'sqlite',
  'nes',
  'crx',
  'xpi',
  'cab',
  'deb',
  'ar',
  'rpm',
  'Z',
  'lz',
  'cfb',
  'mxf',
  'mts',
  'wasm',
  'blend',
  'bpg',
  'docx',
  'pptx',
  'xlsx',
  '3gp',
  '3g2',
  'jp2',
  'jpm',
  'jpx',
  'mj2',
  'aif',
  'odt',
  'ods',
  'odp',
  'xml',
  'heic',
  'cur',
  'ktx',
  'ape',
  'wv',
  'asf',
  'dcm',
  'mpc',
  'ics',
  'glb',
  'pcap',
  'dsf',
  'lnk',
  'alias',
  'voc',
  'ac3',
  'm4b',
  'm4p',
  'm4v',
  'f4a',
  'f4b',
  'f4p',
  'f4v',
  'mie',
  'ogv',
  'ogm',
  'oga',
  'spx',
  'ogx',
  'arrow',
  'shp',
  'aac',
  'mp1',
  'it',
  's3m',
  'xm',
  'ai',
  'skp',
  'avif',
  'eps',
  'lzh',
  'pgp',
  'asar',
  'stl',
  'chm',
  '3mf',
  'zst',
  'jxl',
  'vcf',
  'jls',
  'pst',
  'dwg',
  'parquet',
];
export const MimeTypes: MimeType[] = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/flif',
  'image/x-xcf',
  'image/x-canon-cr2',
  'image/x-canon-cr3',
  'image/tiff',
  'image/bmp',
  'image/vnd.ms-photo',
  'image/vnd.adobe.photoshop',
  'application/x-indesign',
  'application/epub+zip',
  'application/x-xpinstall',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/zip',
  'application/x-tar',
  'application/x-rar-compressed',
  'application/gzip',
  'application/x-bzip2',
  'application/x-7z-compressed',
  'application/x-apple-diskimage',
  'application/x-apache-arrow',
  'video/mp4',
  'audio/midi',
  'video/x-matroska',
  'video/webm',
  'video/quicktime',
  'video/vnd.avi',
  'audio/vnd.wave',
  'audio/qcelp',
  'audio/x-ms-asf',
  'video/x-ms-asf',
  'application/vnd.ms-asf',
  'video/mpeg',
  'video/3gpp',
  'audio/mpeg',
  'audio/mp4', // RFC 4337
  'audio/opus',
  'video/ogg',
  'audio/ogg',
  'application/ogg',
  'audio/x-flac',
  'audio/ape',
  'audio/wavpack',
  'audio/amr',
  'application/pdf',
  'application/x-elf',
  'application/x-msdownload',
  'application/x-shockwave-flash',
  'application/rtf',
  'application/wasm',
  'font/woff',
  'font/woff2',
  'application/vnd.ms-fontobject',
  'font/ttf',
  'font/otf',
  'image/x-icon',
  'video/x-flv',
  'application/postscript',
  'application/eps',
  'application/x-xz',
  'application/x-sqlite3',
  'application/x-nintendo-nes-rom',
  'application/x-google-chrome-extension',
  'application/vnd.ms-cab-compressed',
  'application/x-deb',
  'application/x-unix-archive',
  'application/x-rpm',
  'application/x-compress',
  'application/x-lzip',
  'application/x-cfb',
  'application/x-mie',
  'application/mxf',
  'video/mp2t',
  'application/x-blender',
  'image/bpg',
  'image/jp2',
  'image/jpx',
  'image/jpm',
  'image/mj2',
  'audio/aiff',
  'application/xml',
  'application/x-mobipocket-ebook',
  'image/heif',
  'image/heif-sequence',
  'image/heic',
  'image/heic-sequence',
  'image/icns',
  'image/ktx',
  'application/dicom',
  'audio/x-musepack',
  'text/calendar',
  'text/vcard',
  'model/gltf-binary',
  'application/vnd.tcpdump.pcap',
  'audio/x-dsf', // Non-standard
  'application/x.ms.shortcut', // Invented by us
  'application/x.apple.alias', // Invented by us
  'audio/x-voc',
  'audio/vnd.dolby.dd-raw',
  'audio/x-m4a',
  'image/apng',
  'image/x-olympus-orf',
  'image/x-sony-arw',
  'image/x-adobe-dng',
  'image/x-nikon-nef',
  'image/x-panasonic-rw2',
  'image/x-fujifilm-raf',
  'video/x-m4v',
  'video/3gpp2',
  'application/x-esri-shape',
  'audio/aac',
  'audio/x-it',
  'audio/x-s3m',
  'audio/x-xm',
  'video/MP1S',
  'video/MP2P',
  'application/vnd.sketchup.skp',
  'image/avif',
  'application/x-lzh-compressed',
  'application/pgp-encrypted',
  'application/x-asar',
  'model/stl',
  'application/vnd.ms-htmlhelp',
  'model/3mf',
  'image/jxl',
  'application/zstd',
  'image/jls',
  'application/vnd.ms-outlook',
  'image/vnd.dwg',
  'application/x-parquet',
];

export enum MediaCategory {
  ProfilePhoto = 'profile_photo',
  ChatUpload = 'chat_upload',
}

export interface PreSignedUrlBody {
  category: MediaCategory;
  uploads: {
    ext: FileExtension;
  }[];
}

export interface ProfileMediaBody {
  mediaType: ProfileMediaType;
  originalImgS3Key: string;
  blurredImgS3Key: string;
  headshotImgS3Key: string;
  category?: ProfileMediaCategory;
  description?: string;
  serial: number;
}

export interface ProfileMediaUpdateBody {
  uuid: string;
  category?: ProfileMediaCategory;
  description?: string;
  serial: number;
}
