/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Image, ImageBackground, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import EFonts from 'src/constant/EFonts';
import HeadingText from 'src/components/editProfileComponents/atoms/HeadingText';
import { Color } from 'src/constant/Color';

export default function UnlockPremium() {
  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      <ImageBackground
        source={require('src/assets/images/background/BgUnlockPremium.png')}
        resizeMode="contain"
      >
        <View style={styles.detailsContainer}>
          <Image
            source={require('src/assets/images/editprofileicons/Crown.png')}
            style={styles.crownDimensions}
          />
          <View style={styles.content}>
            <Text style={styles.heading}>Unlock Premium</Text>
            <HeadingText title="Purchase any of the memebrship plan and get the access of our Premium Features." />
            <TouchableOpacity
              style={styles.subscribeButton}
              onPress={() => {
                navigation.navigate('membership');
              }}
            >
              <HeadingText title="Subscribe Now" additionalStyle={{ color: '#FFFFFF' }} />
            </TouchableOpacity>
          </View>
        </View>
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    borderColor: Color.purple,
    marginBottom: 16,
    borderWidth: 1,
    borderRadius: 10,
  },
  subscribeButton: {
    backgroundColor: Color.purple,
    borderRadius: 8,
    width: 126,
    alignItems: 'center',
    paddingVertical: 9,
    marginTop: 12,
  },
  heading: {
    fontFamily: EFonts.SORA_MEDIUM,
    fontSize: 17,
    lineHeight: 21,
    color: Color.black,
  },
  content: {
    marginLeft: 18,
    flex: 1,
  },
  crownDimensions: {
    height: 52,
    width: 52,
  },
  detailsContainer: {
    margin: 16,
    flexDirection: 'row',
  },
});
