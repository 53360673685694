import React, { useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Button, Divider } from 'react-native-paper';
import EFonts from 'src/constant/EFonts';

import PillShapeCounter from 'src/components/common/counter/PillShapeCounter';
import { GenUtil } from 'src/util/GenUtil';
import { FamilyExtra } from 'src/common/models/profile.model';

interface AddSiblingsCompProps {
  onPress: () => void;
  familyExtra: FamilyExtra | undefined;
  setFamilyExtra: (siblingsData: FamilyExtra) => void;
}

const LABELS = {
  numOfBrother: 'Brother',
  numOfSister: 'Sister',
  numOfBrotherMarried: 'Married',
  numOfSisterMarried: 'Married',
  numOfElderBrother: 'Elder than you',
  numOfElderSister: 'Elder than you',
};

const OutlinedButton = ({ title, onPress = () => {} }) => {
  return (
    <Button
      mode="outlined"
      textColor="#8041C6"
      style={{ borderColor: '#8041C6', borderRadius: 8, minHeight: 44, minWidth: 120 }}
      onPress={onPress}
    >
      <Text
        style={{
          fontFamily: EFonts.SORA_SEMIBOLD,
          fontStyle: 'normal',
          fontSize: 15,
          lineHeight: 19,
        }}
      >
        {title}
      </Text>
    </Button>
  );
};

export const SiblingsGeneratedText = ({
  siblingsData = {
    numOfBrother: 0,
    numOfSister: 0,
    numOfBrotherMarried: 0,
    numOfSisterMarried: 0,
    numOfElderBrother: 0,
    numOfElderSister: 0,
  },
}) => {
  return (
    <Text
      style={{
        fontFamily: EFonts.SORA_REGULAR,
        fontSize: 12,
        lineHeight: 20,
        color: '#2E2835',
      }}
    >
      I have {siblingsData.numOfBrother - siblingsData.numOfElderBrother || 'no'} younger and{' '}
      {siblingsData.numOfElderBrother || 'no'} elder brother, of which{' '}
      {siblingsData.numOfBrotherMarried || 'no'} brother{' '}
      {siblingsData.numOfBrotherMarried > 1 ? 'are' : 'is'} married.{' '}
      {siblingsData.numOfSister - siblingsData.numOfElderSister || 'No'} younger and{' '}
      {siblingsData.numOfElderSister || 'no'} elder sister, of which{' '}
      {siblingsData.numOfSisterMarried || 'no'} sister{' '}
      {siblingsData.numOfSisterMarried > 1 ? 'are' : 'is'} married.
    </Text>
  );
};
const AddSiblingsComp = ({ onPress, familyExtra, setFamilyExtra }: AddSiblingsCompProps) => {
  const [siblingsData, setSiblingsData] = useState({
    numOfBrother: familyExtra?.numOfBrother || 0,
    numOfSister: familyExtra?.numOfSister || 0,
    numOfBrotherMarried: familyExtra?.numOfBrotherMarried || 0,
    numOfSisterMarried: familyExtra?.numOfSisterMarried || 0,
    numOfElderBrother: familyExtra?.numOfElderBrother || 0,
    numOfElderSister: familyExtra?.numOfElderSister || 0,
  });

  const handleIncrement = (key: keyof FamilyExtra) => {
    if (siblingsData[key] >= 5 && (key === 'numOfBrother' || key === 'numOfSister')) {
      const siblingType = key === 'numOfBrother' ? 'brother' : 'sister';
      alert(`You can't add more than 5 ${siblingType}`);
      return;
    }
    const errorMessage = validateIncrement(key, siblingsData);

    if (errorMessage) {
      alert(errorMessage);
      return;
    }

    const updatedSiblingsData = {
      ...siblingsData,
      [key]: siblingsData[key] + 1,
    };
    setSiblingsData(updatedSiblingsData);
  };

  const validateIncrement = (
    key: string,
    {
      numOfBrother,
      numOfBrotherMarried,
      numOfElderBrother,
      numOfSister,
      numOfSisterMarried,
      numOfElderSister,
    }: FamilyExtra,
  ) => {
    switch (key) {
      case 'numOfBrotherMarried':
        if (numOfBrotherMarried === numOfBrother) {
          return "Number of married brother can't be greater than number of total brothers";
        }
        break;

      case 'numOfElderBrother':
        if (numOfElderBrother === numOfBrother) {
          return "Number of Elder brother can't be greater than number of total brothers";
        }
        break;

      case 'numOfSisterMarried':
        if (numOfSisterMarried === numOfSister) {
          return "Number of married sister can't be greater than number of total sisters";
        }
        break;

      case 'numOfElderSister':
        if (numOfElderSister === numOfSister) {
          return "Number of Elder sister can't be greater than number of total sisters";
        }
        break;

      default:
        break;
    }

    return '';
  };

  const validateDecrement = (key: string, siblingsData: FamilyExtra) => {
    if (key === 'numOfBrother' || key === 'numOfSister') {
      const isBrother = key === 'numOfBrother';

      const totalSiblingsCount = isBrother ? siblingsData.numOfBrother : siblingsData.numOfSister;
      const elderSiblingsCount = isBrother
        ? siblingsData.numOfElderBrother
        : siblingsData.numOfElderSister;
      const marriedSiblingsCount = isBrother
        ? siblingsData.numOfBrotherMarried
        : siblingsData.numOfSisterMarried;

      if (totalSiblingsCount <= elderSiblingsCount + marriedSiblingsCount) {
        const elderSiblingsType = isBrother ? 'elder brother' : 'elder sister';
        const marriedSiblingsType = isBrother ? 'married brother' : 'married sister';
        const errorPrefix = `Number of total ${
          isBrother ? 'brother' : 'sister'
        } can't be less than number of `;

        if (marriedSiblingsCount === 0) {
          return `${errorPrefix}${elderSiblingsType}`;
        } else if (elderSiblingsCount === 0) {
          return `${errorPrefix}${marriedSiblingsType}`;
        } else {
          return `${errorPrefix}married and elder ${isBrother ? 'brother' : 'sister'}`;
        }
      }
    }
  };

  const handleDecrement = (key: string) => {
    if (siblingsData[key] === 0) return;

    const errorMessage = validateDecrement(key, siblingsData);

    if (errorMessage) {
      alert(errorMessage);
      return;
    }
    const updatedSiblingsData = {
      ...siblingsData,
      [key]: siblingsData[key] - 1,
    };
    setSiblingsData(updatedSiblingsData);
  };

  const handleSavePressed = () => {
    onPress();
    setFamilyExtra(siblingsData);
  };

  return (
    <View
      style={{
        backgroundColor: 'white',
        paddingHorizontal: 20,
        borderRadius: 20,
        paddingBottom: 20,
        maxWidth: 376,
        maxHeight: 434,
        margin: GenUtil.isWEB() ? 'auto' : 0,
      }}
    >
      <View
        style={{
          paddingTop: 16,
          paddingBottom: 10,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text style={styles.title}>Add Siblings</Text>
        <TouchableOpacity onPress={onPress}>
          <Image style={{ height: 11, width: 11 }} source={require('src/assets/icons/cross.png')} />
        </TouchableOpacity>
      </View>
      <Divider />
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          paddingTop: 15,
          paddingHorizontal: 2,
        }}
      >
        {Object.keys(LABELS).map((item: keyof FamilyExtra, index) => {
          return (
            <View
              key={index}
              style={{
                width: '50%',
                marginBottom: 15,
              }}
            >
              <View style={{ paddingLeft: 10, marginBottom: 6 }}>
                <Text
                  style={{
                    fontFamily: EFonts.SORA_REGULAR,
                    fontSize: 12,
                    lineHeight: 15,
                    color: '#A2A2A2',
                  }}
                >
                  {LABELS[item]}
                </Text>
              </View>
              <PillShapeCounter
                onIncrementPressed={() => handleIncrement(item)}
                onDecrementPressed={() => handleDecrement(item)}
                count={siblingsData[item]}
              />
            </View>
          );
        })}
      </View>
      <View style={{ borderWidth: 1, borderColor: '#D6D6D6', borderStyle: 'dashed' }}></View>
      <View style={{ marginTop: 16 }}>
        <SiblingsGeneratedText siblingsData={siblingsData} />
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>
        <OutlinedButton title={'Save'} onPress={handleSavePressed} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontStyle: 'normal',
    fontSize: 17,
    lineHeight: 21,
    color: '#2E2835',
    flex: 1,
    textAlign: 'center',
  },
});

export default AddSiblingsComp;
