import { Image, StyleSheet, Text, View } from 'react-native';
import { Color } from 'src/constant/Color';
import React from 'react';
import Button from 'src/components/common/buttons/Button';
import { useNavigation } from '@react-navigation/native';

const UnlockProfileViews = () => {
  const navigation = useNavigation();

  const handleUnlockPremium = () => {
    navigation.navigate('membership');
  };

  return (
    <View style={styles.container}>
      <Text style={styles.text}>2 Person view your profile</Text>
      <Text style={[styles.text, styles.textSmall]}>
        Upgrade to premium and find out who has visited your profile.
      </Text>
      <Image
        blurRadius={3}
        style={[styles.blurImage, styles.imageLeft]}
        source={require('src/assets/images/onboardingImages/firstSlideImage.png')}
      />
      <Image
        blurRadius={3}
        style={[styles.blurImage, styles.imageRight]}
        source={require('src/assets/images/onboardingImages/secondSlideImage.png')}
      />
      <Button
        onPress={handleUnlockPremium}
        variant={'primary'}
        size={'small'}
        styles={{ borderRadius: 8, backgroundColor: Color.black, paddingHorizontal: 5 }}
      >
        Unlock Premium Now
      </Button>
    </View>
  );
};

export default UnlockProfileViews;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FEE45A',
    textAlign: 'center',
    marginTop: 20,
    minHeight: 140,
    maxHeight: 140,
    width: '90%',
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20,
  },
  text: {
    fontSize: 15,
    fontWeight: '600',
    marginBottom: 10,
    color: Color.black,
  },
  textSmall: {
    fontSize: 12,
    fontWeight: '400',
    width: '70%',
    textAlign: 'center',
    color: Color.black,
    lineHeight: 20,
  },
  blurImage: {
    position: 'absolute',
    width: 40,
    height: 40,
    borderRadius: 100,
  },
  imageLeft: {
    top: 20,
    left: 15,
  },
  imageRight: {
    top: 80,
    right: 20,
  },
});
