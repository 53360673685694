import React from 'react';
import { Avatar } from 'react-native-paper';

interface IImageWithPlaceholderProps {
  url?: string;
  style: any;
  gender?: string;
  type?: string;
}
export const ImageWithPlaceholder = ({ url, style, gender, type }: IImageWithPlaceholderProps) => {
  const imageProps = !url
    ? gender === 'Male'
      ? { source: require('../assets/images/placeholder-male.png') }
      : gender === 'Neutral'
      ? { source: require('../assets/images/neutral.png') }
      : { source: require('../assets/images/placeholder-female.png') }
    : {
        source: {
          uri: url,
        },
      };

  return <Avatar.Image size={50} {...imageProps} />;
};
