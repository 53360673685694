import React from 'react';
import ReadMore from 'react-native-read-more-text';
import { StyleSheet } from 'react-native';

import { Text } from 'src/components/Themed';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';

const TruncatedText = ({ text = '', numberOfLines = 3 }) => {
  const renderTruncatedFooter = (handlePress) => {
    return (
      <Text style={styles.expandable} onPress={handlePress}>
        More
      </Text>
    );
  };

  const renderRevealedFooter = (handlePress) => {
    return (
      <Text style={styles.expandable} onPress={handlePress}>
        Less
      </Text>
    );
  };
  return (
    <React.Fragment>
      {text && (
        <ReadMore
          numberOfLines={numberOfLines}
          renderTruncatedFooter={renderTruncatedFooter}
          renderRevealedFooter={renderRevealedFooter}
        >
          <Text style={styles.expandableText}>{text}</Text>
        </ReadMore>
      )}
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  expandable: {
    fontFamily: EFonts.SORA_SEMIBOLD,
    fontStyle: 'normal',
    textDecorationLine: 'underline',
    fontSize: 12,
    lineHeight: 15,
    color: Color.lightBlack,
    top: 2,
  },
  expandableText: {
    fontFamily: EFonts.SORA_REGULAR,
    fontStyle: 'normal',
    fontSize: 12,
    lineHeight: 20,
    color: Color.lightBlack,
  },
});

export default TruncatedText;
