import { StyleSheet, TouchableOpacity } from 'react-native';
import React from 'react';
import { Color } from 'src/constant/Color';
import HeartSvg from 'src/assets/icons/heart.svg';

const AddToFavAction = () => {
  return (
    <TouchableOpacity style={styles.chatIcon} onPress={() => {}}>
      <HeartSvg />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  chatIcon: {
    width: 38,
    height: 38,
    borderRadius: 100,
    borderColor: Color.white,
    borderWidth: 1.5,
    marginLeft: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Color.white,
  },
});

export default AddToFavAction;
