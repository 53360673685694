import { View, ImageSourcePropType, StyleSheet } from 'react-native';
import React from 'react';
import HeadingIcon from 'src/components/editProfileComponents/atoms/HeadingIcon';
import HeadingText from 'src/components/editProfileComponents/atoms/HeadingText';
import { GenUtil } from 'src/util/GenUtil';

const HeadingPoints = ({
  title = 'Title',
  iconDimension = { width: 18, height: 18 },
  source,
  textAdditionalStyle,
}: {
  title: string;
  iconDimension?: { width: number; height: number };
  source: ImageSourcePropType;
  textAdditionalStyle?: React.CSSProperties;
}) => {
  return (
    <View style={styles.container}>
      <HeadingIcon iconDimension={iconDimension} source={source} />
      <HeadingText
        title={title}
        additionalStyle={textAdditionalStyle ? textAdditionalStyle : styles.textStyle}
      />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  textStyle: {
    marginLeft: 10,
    flex: 1,
    marginTop: !GenUtil.isWEB() ? -3 : 0,
  },
});

export default HeadingPoints;
