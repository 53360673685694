import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Color } from 'src/constant/Color';
import ChatSvg from 'src/assets/icons/chat.svg';

type Props = {
  messageCount: number | string;
  style?: ViewStyle;
};

export default function MessageAction({ messageCount, style }: Props) {
  return (
    <TouchableOpacity style={[styles.chatIcon, style]} onPress={() => {}}>
      <ChatSvg color={Color.white} />
      {messageCount > 0 ? (
        <View style={styles.messageCount}>
          <Text style={styles.messageText}>{messageCount}</Text>
        </View>
      ) : (
        <></>
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  chatIcon: {
    width: 38,
    height: 38,
    borderRadius: 100,
    borderColor: Color.white,
    borderWidth: 1.5,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageCount: {
    width: 17,
    height: 17,
    backgroundColor: '#FF0000',
    borderRadius: 50,
    position: 'absolute',
    top: -5,
    right: -5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageText: {
    fontSize: 11,
    color: Color.white,
    font: 'dmsans-bold',
    fontWeight: '600',
  },
});
