/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { View } from 'react-native';
import React from 'react';
import PillShapeChip from 'src/components/common/chips/PillShapeChip';
import { Gender } from 'src/common/models/profile.model';

const LookingForComp = ({ gender }) => {
  const lookingFor = gender === Gender.Male ? 'Bride' : 'Groom';
  const chipIcon =
    gender === Gender.Male
      ? require('src/assets/images/partnerPreferencesIcons/Bride.png')
      : require('src/assets/images/partnerPreferencesIcons/Groom.png');

  return (
    <View style={{ marginVertical: -20 }}>
      <PillShapeChip
        options={[{ key: lookingFor, value: lookingFor }]}
        selected={[lookingFor]}
        selectedIcon={chipIcon}
        iconDimension={{ width: 24, height: 24 }}
        showTickIcon={false}
      />
    </View>
  );
};

export default LookingForComp;
