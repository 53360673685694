import { StyleSheet, Text, View } from 'react-native';
import { Color } from 'src/constant/Color';
import CoupleSvg from 'src/assets/icons/couple.svg';
import SandSvg from 'src/assets/icons/sand.svg';

const AppStatsCard = () => {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <CoupleSvg style={{ marginRight: 40 }} />
        <SandSvg style={styles.sandImage} />
        <View>
          <View>
            <Text style={styles.largeText}>500+</Text>
            <Text style={styles.smallText}>Couples find there partners</Text>
          </View>
          <View style={{ marginTop: 20, marginBottom: 20 }}>
            <Text style={styles.largeText}>500+</Text>
            <Text style={styles.smallText}>Couples find there partners</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: Color.purple,
    padding: 20,
    marginTop: 20,
    paddingBottom: 50,
  },
  sandImage: {
    position: 'absolute',
    bottom: -20,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  largeText: {
    fontWeight: '600',
    fontSize: 40,
    color: Color.white,
  },
  smallText: {
    fontWeight: '600',
    fontSize: 12,
    color: Color.white,
  },
});

export default AppStatsCard;
