import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import { IChatUser, IUser } from '../model/user.model';

export default class DataUtil {
  static getUuid(): string {
    return uuidv4();
  }

  static getTheOtherUser = (users: IChatUser[], loggedInUser: IUser): IChatUser => {
    if (users[0].id === loggedInUser.profileUuid) {
      return users[1];
    }
    return users[0];
  };

  static getFirstLine(text: string) {
    return text.split('\n')[0];
  }
}
