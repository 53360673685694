import { StyleSheet, Image } from 'react-native';
import { Text, View } from 'src/components/Themed';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';
import ModalAdornment from './atoms/ModalAdornment';
import SelectedChoiceChips from './molecules/SelectedChoiceChips';

export default function ChoicesSelector({
  title = '',
  selectedValues = [],
  setSelectedValues = (_) => {},
  preprocessItems = null,
  dropdownItems = [],
  placeHolder = 'Search here...',
  onPressDone = async (_) => {},
  isDoneValidating = false,
  onPressRemove = async (_) => {},
  searchedLocation = '',
  setSearchedLocation = () => {},
  isLocation = false,
}) {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <ModalAdornment
        placeHolder={placeHolder}
        preprocessItems={preprocessItems}
        modalItems={dropdownItems}
        isMultiSelect
        styleOverride
        icon={
          <Image
            source={require('../../assets/icons/search.png')}
            style={{ width: 18, height: 18 }}
          />
        }
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        onPressDone={onPressDone}
        isDoneValidating={isDoneValidating}
        searchedLocation={searchedLocation}
        setSearchedLocation={setSearchedLocation}
        isLocation={isLocation}
      />
      {selectedValues.length > 0 && (
        <SelectedChoiceChips
          preprocessSelectedItems={preprocessItems}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          onPressRemove={onPressRemove}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // padding: 16,
    marginTop: 16,
  },
  title: {
    fontFamily: EFonts.SORA_REGULAR,
    color: Color.lightGrey,
    fontSize: 12,
    marginLeft: 16,
  },
});
