import data from 'src/common/data/religion.json';
import { Gender, ReligionType } from 'src/common/models/profile.model';

export interface ReligionDataInf {
  name: string;
  id: number;
  type: ReligionType;
  order: number;
  communityMap: { [key in string | number]: { name: string; id: number; religionId: number; order: number } };
  subCommunityMap: {
    [key in string | number]: { name: string; id: number; religionId: number; casteId: number; order: number };
  };
  jamaatMap: {
    [key in string | number]: { name: string; id: number; religionId: number; casteId: number; order: number };
  };
  communityGroups: Array<{
    name: string;
    id: number;
    religionId: number;
    communityIds: number[];
    subCommunityIds: number[];
    order: number;
  }>;
  religiousBeliefs: Array<{
    question: string;
    correctOptions?: Array<string | 'Yes' | 'No'>;
    religionId: number;
    gender?: Gender;
  }>;
}

const ReligionData: ReligionDataInf[] = [];
// @ts-ignore
for (const elem of data.religionData) {
  const religion: ReligionDataInf = {
    name: elem.label,
    id: parseInt(elem.value),
    type: elem.label as ReligionType,
    order: elem.SORTBY,
    communityMap: {},
    subCommunityMap: {},
    jamaatMap: {},
    communityGroups: [],
    religiousBeliefs: [],
  };
  ReligionData.push(religion);
}

for (const religion of ReligionData) {
  let casteData = [];
  if (religion.type === ReligionType.Muslim || religion.type === ReligionType.Christian) {
    // @ts-ignore
    casteData = [...data.sectNew.data, ...data.casteNew.data];
  } else {
    // @ts-ignore
    casteData = data.casteNew.data;
  }

  for (const elem of casteData) {
    const parentId = parseInt(elem.parent);
    if (parentId === religion.id) {
      religion.communityMap[elem.value] = {
        name: elem.label,
        id: parseInt(elem.value),
        order: elem.SORTBY,
        religionId: parentId,
      };
    } else if (religion.type === ReligionType.Muslim && (parentId === 151 || parentId === 152)) {
      // special case for shia and sunni for muslims
      religion.subCommunityMap[elem.value] = {
        name: elem.label,
        id: parseInt(elem.value),
        order: elem.SORTBY,
        casteId: parentId,
        religionId: 2,
      };
    }
  }
}
// @ts-ignore
for (const elem of data.subcasteNew.data) {
  const casteId = parseInt(elem.parent); // parent would be casteId
  const religion = ReligionData.find((religion) => religion.communityMap[casteId]);
  religion.subCommunityMap[elem.value] = {
    name: elem.label,
    id: parseInt(elem.value),
    order: elem.SORTBY,
    casteId,
    religionId: religion.id,
  };
}

// @ts-ignore
for (const elem of data.jamaatNew.data) {
  const casteId = parseInt(elem.parent);
  const religion = ReligionData.find((r) => r.type === ReligionType.Muslim);
  religion.jamaatMap[casteId] = {
    name: elem.label,
    id: parseInt(elem.value),
    casteId,
    religionId: religion.id,
    order: elem.SORTBY,
  };
}

// @ts-ignore
for (const elem of data.casteGroup.data) {
  const parentId = parseInt(elem.parent);
  const religion = ReligionData.find((r) => r.id === parentId);
  religion.communityGroups.push({
    name: elem.label,
    id: parseInt(elem.value),
    communityIds: elem.dependentCastes.map(parseInt),
    subCommunityIds: elem.dependentSubCastes?.map(parseInt) ?? [],
    order: elem.sortBy,
    religionId: religion.id,
  });
}

// @ts-ignore
for (const elem of data.religiousBelief.data) {
  const parentId = parseInt(elem.parent);
  const religion = ReligionData.find((r) => r.id === parentId);
  religion.religiousBeliefs.push({
    question: elem.question,
    correctOptions: elem.correctOptions,
    religionId: parentId,
    gender: elem.gender as Gender,
  });
}

export { ReligionData };
