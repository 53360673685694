import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Color } from 'src/constant/Color';
import { useEffect, useState } from 'react';
import { InterestService, UserStatsResponse } from 'src/service/InterestService';
import ActiveChatSvg from 'src/assets/icons/activeChats.svg';
import PaperPlaneSvg from 'src/assets/icons/paperPlane.svg';
import UserMatchSvg from 'src/assets/icons/userMatch.svg';
import { useNavigation } from '@react-navigation/native';
import { ProfileTabsEnum } from 'src/screens/DynamicProfileList';
import { ActivityIndicator } from 'react-native-paper';
import { InterestStatsCategories } from 'src/common/models/interest.model';

const ICON_AND_PATHS = {
  [InterestStatsCategories.Accepted]: {
    icon: <UserMatchSvg />,
    tabs: [
      ProfileTabsEnum.YouAccepted,
      ProfileTabsEnum.TheyAccepted,
    ],
  },
  [InterestStatsCategories.Declined]: {
    icon: <ActiveChatSvg />,
    tabs: [
      ProfileTabsEnum.YouDeclined,
      ProfileTabsEnum.TheyDeclined,
    ],
  },
  [InterestStatsCategories.Filtered]: {
    icon: <PaperPlaneSvg />,
    tabs: [
      ProfileTabsEnum.InterestFiltered,
      ProfileTabsEnum.InterestReceived,
      ProfileTabsEnum.InterestSent,
      ProfileTabsEnum.InterestArchived,
    ],
  },
  [InterestStatsCategories.Received]: {
    icon: <UserMatchSvg />,
    tabs: [
      ProfileTabsEnum.InterestReceived,
      ProfileTabsEnum.InterestSent,
      ProfileTabsEnum.InterestFiltered,
      ProfileTabsEnum.InterestArchived,
    ],
  },
  [InterestStatsCategories.Shortlisted]: {
    icon: <ActiveChatSvg />,
    tabs: [
      ProfileTabsEnum.ShortlistedProfiles,
      ProfileTabsEnum.InterestReceived,
      ProfileTabsEnum.InterestSent,
    ],
  },
  [InterestStatsCategories.Sent]: {
    icon: <PaperPlaneSvg />,
    tabs: [
      ProfileTabsEnum.InterestSent,
      ProfileTabsEnum.InterestReceived,
    ],
  },
};

const UserNotificationsSection = () => {
  const navigation = useNavigation();

  const [userNotifications, setuserNotifications] = useState<Partial<UserStatsResponse>>([]);

  const fetchAllNotifications = async () => {
    try {
      const { data } = await InterestService.getStats();
      setuserNotifications(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllNotifications();
  }, []);

  if (!userNotifications && userNotifications.length > 0) {
    return <ActivityIndicator />;
  }

  return (
    <View style={styles.container}>
      {userNotifications?.map((notification) => {
        const { icon, tabs } = ICON_AND_PATHS[notification.category];
        return (
          <NotificationIcon
            key={notification.category}
            title={notification.category}
            count={notification.totalCount}
            image={icon}
            onPress={() =>
              navigation.navigate('dynamicprofileslist', {
                title: tabs[0],
                tabs,
              })
            }
          />
        );
      })}
    </View>
  );
};

const NotificationIcon = ({
  title,
  count,
  notificationCount,
  onPress,
  image,
}: {
  title: string;
  notificationCount?: number;
  count: number | string;
  image: React.ReactElement;
  onPress: () => void;
}) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.notificationContainer}>
        <View style={styles.iconContainer}>
          {image}
          {notificationCount && notificationCount > 0 && (
            <View style={styles.notificationCount}>
              <Text style={styles.notificationTextCount}>{notificationCount}</Text>
            </View>
          )}
        </View>
        <Text style={styles.count}>{count}</Text>
        <Text style={styles.text}>{title}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
    backgroundColor: Color.white,
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 20,
    paddingVertical: 15,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 36,
    height: 36,
  },
  count: {
    color: Color.purple,
    fontWeight: '600',
    fontSize: 16,
    marginTop: 10,
  },
  text: {
    color: '#A2A2A2',
    fontWeight: '400',
    fontSize: 12,
    marginTop: 5,
  },
  notificationCount: {
    position: 'absolute',
    top: -5,
    right: -10,
    width: 18,
    height: 18,
    backgroundColor: '#FF0000',
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  notificationTextCount: {
    color: Color.white,
    font: 'dmsans-bold',
    fontWeight: '600',
    fontSize: 12,
  },
  notificationContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginVertical: 15,
    marginHorizontal: 35,
  },
});

export default UserNotificationsSection;
