import React, { useState } from 'react';
import { SafeAreaView, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { Divider, Icon } from 'react-native-elements';
import { StackActions, useNavigation } from '@react-navigation/native';

import { View } from 'src/components/Themed';
import { Color } from 'src/constant/Color';
import EFonts from 'src/constant/EFonts';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'src/store/reducer/userReducer';
import StorageUtil from 'src/util/StorageUtil';
import Constant from 'src/constant/Constant';
import SafeContainer from 'src/components/SafeContainer';
import CustomModal from 'src/components/CustomModal';
import { GenUtil } from 'src/util/GenUtil';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';
import OutlinedButton from 'src/components/common/buttons/OutlinedButton';
import InputAdornment from 'src/components/common/input/InputAdornment';
import UserService from 'src/service/UserService';
import { UpdateUserBody } from 'src/common/dto/user.dto';
import { RootState } from 'src/store/store';
import { AlertTypeEnum, showError } from 'src/store/reducer/errorReducer';
import { ProfileUpdateService } from 'src/service/ProfileUpdateService';
import { PrivacySettingsBody } from 'src/common/dto/profile.dto';
import { ProfileStatus } from 'src/common/models/profile.model';
import ConfirmationModal from 'src/components/modals/ConfirmationModal';

const { height, width } = GenUtil.getDimension();

const RenderItem = ({
  title,
  icon,
  screen,
  onPress,
}: {
  title: string;
  icon: string;
  screen?: string;
  onPress?: () => void;
}) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={{ borderWidth: 0, flexDirection: 'row', paddingVertical: 10 }}
      onPress={() => {
        if (screen) navigation.navigate(screen);
        else if (onPress) onPress();
      }}
    >
      <View style={styles.iconContainer}>
        <Icon name={icon} color={Color.lightGrey} type="ionicon" />
      </View>
      <View style={styles.titleContainer}>
        <Text style={styles.titleStyle}>{title}</Text>
      </View>
      <View style={styles.iconContainer}>
        <Icon name="chevron-right" color={Color.lightGrey} />
      </View>
    </TouchableOpacity>
  );
};

const ChangePasswordModal = ({
  setChangePasswordModal,
}: {
  setChangePasswordModal: (boolean) => void;
}) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);
  const uuid = user.uuid;

  const validatePassword = (currentPassword, newPassword, confirmPassword) => {
    let alertMessage = 'success';
    if (currentPassword === '' || newPassword === '' || confirmPassword === '') {
      alertMessage = 'Please fill every password field';
      return alertMessage;
    }
    if (newPassword !== confirmPassword) {
      alertMessage = 'New password and confirmation password do not match';
      return alertMessage;
    }

    return alertMessage;
  };

  const handleChangePassword = async () => {
    try {
      const validationResponse = validatePassword(currentPassword, newPassword, confirmPassword);
      if (validationResponse !== 'success') {
        alert(validationResponse);
        return;
      }

      setChangePasswordModal(false);
      const updateUserBody: UpdateUserBody = {
        password: currentPassword,
        newPassword,
      };
      const response = await UserService.updateUser(uuid, updateUserBody);
      if (!response.success) return;
      dispatch(showError({ message: 'Password changed!!!', alertType: AlertTypeEnum.Success }));
      console.log('response ==== ', response);
    } catch (error) {
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
    }
  };

  return (
    <View style={GenUtil.isWEB() ? styles.modalContainerWeb : styles.modalContainerMob}>
      <View style={{ paddingHorizontal: 20, borderRadius: 6, paddingTop: 10 }}>
        <InputAdornment
          placeholder="Current Password"
          secureTextEntry={true}
          value={currentPassword}
          setValue={setCurrentPassword}
          autoCapitalize="none"
        />
        <InputAdornment
          placeholder="New Password"
          secureTextEntry={true}
          value={newPassword}
          setValue={setNewPassword}
          autoCapitalize="none"
        />
        <InputAdornment
          placeholder="Confirm Password"
          secureTextEntry={true}
          value={confirmPassword}
          setValue={setConfirmPassword}
          autoCapitalize="none"
        />
      </View>
      <View
        style={{
          height: 40,
          width: '100%',
          // bottom: 5,
          flexDirection: 'row',
          justifyContent: 'space-around',
          marginTop: 10,
        }}
      >
        <OutlinedButton
          style={{
            width: '40%',
            borderRadius: 12,
            paddingVertical: 0,
            borderColor: 'red',
          }}
          textStyle={{ color: 'red', fontSize: 13 }}
          title="Cancel"
          onPress={() => {
            setChangePasswordModal(false);
          }}
        />
        <PrimaryButton
          buttonText="Change Password"
          buttonStyle={{ paddingVertical: 10, width: '40%', borderRadius: 12 }}
          textStyle={{ fontSize: 13 }}
          handleKeyPress={handleChangePassword}
        />
      </View>
    </View>
  );
};

const HideProfileModal = ({
  setHideProfileModal,
  signOut,
}: {
  setHideProfileModal: (boolean) => void;
  signOut: () => void;
}) => {
  const dispatch = useDispatch();
  const onCancel = () => {
    setHideProfileModal(false);
  };

  const handleHideProfile = async () => {
    try {
      console.log('hide profile');
      setHideProfileModal(false);
      const privacySettingsBody: PrivacySettingsBody = {
        status: ProfileStatus.Hidden,
      };
      const response = await ProfileUpdateService.updatePrivacySettings(privacySettingsBody);
      if (!response.success) return;
      dispatch(
        showError({
          message: 'Settings updated successfully, logging out',
          alertType: AlertTypeEnum.Success,
        }),
      );
      signOut();
    } catch (error) {
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
    }
  };

  return (
    <ConfirmationModal
      title="Are you sure you want to hide your profile?"
      message="Nobody would be able to view your profile, not even your matches."
      cancelButtonText="Cancel"
      primaryButtonText="Hide Profile"
      onCancel={onCancel}
      onPrimaryButtonPressed={handleHideProfile}
    />
  );
};

const DeleteProfileModal = ({
  setDeleteProfileModal,
  signOut,
}: {
  setDeleteProfileModal: (boolean) => void;
  signOut: () => void;
}) => {
  const dispatch = useDispatch();
  const onCancel = () => {
    setDeleteProfileModal(false);
  };

  const onDelete = async () => {
    try {
      console.log('delete profile');
      setDeleteProfileModal(false);
      const privacySettingsBody: PrivacySettingsBody = {
        status: ProfileStatus.Deleted,
      };
      const response = await ProfileUpdateService.updatePrivacySettings(privacySettingsBody);
      if (!response.success) return;
      dispatch(
        showError({ message: 'Profile deleted, logging out', alertType: AlertTypeEnum.Success }),
      );
      signOut();
    } catch (error) {
      dispatch(
        showError({ message: GenUtil.getMessageFromError(error), alertType: AlertTypeEnum.Error }),
      );
    }
  };

  return (
    <ConfirmationModal
      title="Are you sure you want to delete your profile?"
      message="You will lose all your data, including your membership plans (if you have any). And you'll not be able to recover them if you create the account again."
      cancelButtonText="Cancel"
      primaryButtonText="Delete Profile"
      onCancel={onCancel}
      onPrimaryButtonPressed={onDelete}
    />
  );
};

export default function Settings() {
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [hideProfileModal, setHideProfileModal] = useState(false);

  const [deleteProfileModal, setDeleteProfileModal] = useState(false);

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const signOut = () => {
    dispatch(setUser(null));
    StorageUtil.removeKey(Constant.AUTH_TOKEN);
    StorageUtil.removeKey(Constant.FIREBASE_TOKEN);
    navigation.dispatch(StackActions.replace('welcome'));
  };

  return (
    <SafeContainer
      showPageHeader={true}
      heading="Account & Settings"
      style={{ flex: 1, backgroundColor: Color.white }}
      leftIcon={<Icon name="arrow-back" />}
      onPressLeftIcon={() => navigation.navigate(GenUtil.isDesktopWeb() ? 'home' : 'data')}
    >
      <View style={{ paddingHorizontal: 16, paddingTop: 10, marginTop: 28 }}>
        <View style={{ borderWidth: 0, marginBottom: 20 }}>
          <View>
            <Text style={styles.sectionHeading}>Manage Account</Text>
          </View>
          <View style={{ marginTop: 4 }}>
            <RenderItem
              title={'Privacy Settings'}
              icon={'settings-outline'}
              screen={'editprivacypolicyscreen'}
            />
            <Divider />
            <RenderItem
              title={'Change Password'}
              icon={'lock-closed-outline'}
              onPress={() => setChangePasswordModal(true)}
            />
            <Divider />
            <RenderItem
              title={'Hide Profile'}
              icon={'eye-off-outline'}
              onPress={() => setHideProfileModal(true)}
            />
            <Divider />
            <RenderItem
              title={'Delete Profile'}
              icon={'trash-outline'}
              onPress={() => setDeleteProfileModal(true)}
            />
          </View>
        </View>
        <View style={{ borderWidth: 0, marginBottom: 20 }}>
          <View>
            <Text style={styles.sectionHeading}>Notifications</Text>
          </View>
          <View style={{ marginTop: 4 }}>
            <RenderItem
              title={'Notification Settings'}
              icon={'notifications-outline'}
              screen={'notifications'}
            />
          </View>
        </View>
        {changePasswordModal && (
          <CustomModal
            visible={changePasswordModal}
            renderComponent={
              <ChangePasswordModal setChangePasswordModal={setChangePasswordModal} />
            }
          />
        )}
        {hideProfileModal && (
          <CustomModal
            visible={hideProfileModal}
            renderComponent={
              <HideProfileModal setHideProfileModal={setHideProfileModal} signOut={signOut} />
            }
          />
        )}
        {deleteProfileModal && (
          <CustomModal
            visible={deleteProfileModal}
            renderComponent={
              <DeleteProfileModal setDeleteProfileModal={setDeleteProfileModal} signOut={signOut} />
            }
          />
        )}
      </View>
      <TouchableOpacity style={styles.logOutBtnContainer} onPress={signOut}>
        <Text
          style={{
            fontSize: 18,
            fontFamily: EFonts.SORA_SEMIBOLD,
            color: 'red',
          }}
        >
          Log out
        </Text>
      </TouchableOpacity>
    </SafeContainer>
  );
}

const styles = StyleSheet.create({
  iconContainer: {
    width: 30,
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleContainer: {
    borderWidth: 0,
    flex: 1,
    justifyContent: 'center',
    paddingLeft: 10,
  },
  titleStyle: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 13,
    lineHeight: 20,
    color: Color.lightGrey,
  },
  sectionHeading: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 10,
    lineHeight: 15,
    color: Color.lightBlack,
  },
  modalContainerWeb: {
    backgroundColor: '#fff',
    borderRadius: 6,
    width: width * 0.9,
    // height: height * 0.3,
    margin: 'auto',
    paddingBottom: 10,
  },
  modalContainerMob: {
    backgroundColor: '#fff',
    borderRadius: 6,
    width: width * 0.9,
    minHeight: height * 0.3,
    paddingBottom: 10,
  },
  logOutBtnContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 70,
    width: '100%',
  },
});
