import { View, Text } from 'react-native';
import React from 'react';
import { Button } from 'react-native-paper';
import { Color } from 'src/constant/Color';

const OutlinedButton = ({ title = 'Search', textStyle, style, onPress }) => {
  return (
    <Button mode="outlined" labelStyle={textStyle} style={style} onPress={onPress}>
      {title}
    </Button>
  );
};

export default OutlinedButton;
