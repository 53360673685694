import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Image, TextInput, TouchableOpacity } from 'react-native';
import { Icon, Input } from 'react-native-elements';
import { Card, Text, IconButton } from 'react-native-paper';
import { Color } from 'src/constant/Color';
import { InterestService } from 'src/service/InterestService';
import { GenUtil } from 'src/util/GenUtil';

export default function ProfileAction({ uuid }) {
  const [sendRequestButton, setSendRequestButton] = useState(true);
  const [showInputField, setShowInputField] = useState(false);
  const [message, setMessage] = useState('');

  function pressSendRequestHandler() {
    setShowInputField(true);
    setSendRequestButton(false);
  }
  function pressCancelRequestHandler() {
    InterestService.deleteInterest(uuid)
      .then(() => setSendRequestButton(true))
      .catch((err) => console.log(err));
  }
  function pressChatHandler() {}
  function pressContactHandler() {}
  function onSend() {
    const request = {
      profileUuid: uuid,
      customMessage: message,
    };
    InterestService.sendInterest(request)
      .then((res) => setShowInputField(false))
      .catch((err) => console.log(err));
  }

  return (
    <>
      {showInputField ? (
        <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', marginTop: -4 }}>
          <TextInput
            placeholder="write a message for them"
            style={styles.textInput}
            onChangeText={(value) => setMessage(value)}
          />

          <TouchableOpacity style={{ left: 10, justifyContent: 'center' }}>
            <Icon
              name="ios-send-outline"
              reverse
              size={23}
              containerStyle={{ backgroundColor: Color.black }}
              type="ionicon"
              onPress={onSend}
            />
          </TouchableOpacity>
        </View>
      ) : (
        <View style={styles.cardBottomContainer}>
          <View>
            <IconButton
              icon="chat-outline"
              size={30}
              iconColor="white"
              onPress={pressChatHandler}
            />
            <Text style={styles.textChat}>Chat</Text>
          </View>
          <View>
            <IconButton icon="phone" size={30} iconColor="white" onPress={pressContactHandler} />
            <Text style={styles.textContact}>Contact</Text>
          </View>
          {sendRequestButton ? (
            <View>
              <IconButton
                icon="email-outline"
                size={30}
                iconColor="white"
                onPress={pressSendRequestHandler}
                style={{ marginLeft: 10 }}
              />
              <Text style={styles.textRequest}>Send Interest</Text>
            </View>
          ) : (
            <View>
              <IconButton
                icon="email-remove-outline"
                size={30}
                iconColor="white"
                onPress={pressCancelRequestHandler}
                style={{ marginLeft: 10 }}
              />
              <Text style={styles.textRequest}>Cancel Interest</Text>
            </View>
          )}
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  cardBottomContainer: {
    marginTop: GenUtil.isWEB() ? -12 : -12.5,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  textRequest: {
    color: Color.white,
    fontSize: 12,
    alignSelf: 'center',
  },
  textContact: {
    color: Color.white,
    fontSize: 12,
    alignSelf: 'center',
  },
  textChat: {
    color: Color.white,
    fontSize: 12,
    alignSelf: 'center',
  },
  textInput: {
    fontSize: 18,
    fontWeight: '700',
    color: Color.purple,
    backgroundColor: Color.backgroundGray,
    paddingVertical: 10,
    paddingHorizontal: GenUtil.isWEB() ? 7 : 15,
    borderRadius: 24,
  },
});
