import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum AlertTypeEnum {
  Info = 'info',
  Error = 'error',
  Success = 'success',
  Default = 'default',
}

export interface ErrorMessage {
  alertType: AlertTypeEnum;
  message?: string;
}

export interface ErrorState extends ErrorMessage {
  open: boolean;
}

const initialState: ErrorState = {
  open: false,
  alertType: AlertTypeEnum.Info,
};

export const userSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    closeError: (state) => {
      state.open = false;
      state.alertType = AlertTypeEnum.Info;
    },
    showError: (state, action: PayloadAction<ErrorMessage>) => {
      state.open = true;
      state.alertType = action.payload.alertType;
      state.message = action.payload.message;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showError, closeError } = userSlice.actions;

export default userSlice.reducer;
