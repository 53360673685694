import { GenUtil } from '../util/GenUtil';
import { Color } from '../constant/Color';
import { StyleSheet } from 'react-native';
import EFonts from 'src/constant/EFonts';

const { width, height } = GenUtil.getDimension();

export const inboxStyles = StyleSheet.create({
  container: {
    backgroundColor: '#FAF5FF',
    flex: 1,
    paddingTop: GenUtil.isWEB() || GenUtil.isAndroid() ? 0 : 30,
    marginTop: GenUtil.isAndroid() ? 30 : 0,
  },
  topBand: {
    backgroundColor: Color.black,
  },
  topBandText: {
    padding: 10,
    fontSize: 22,
    textAlign: 'center',
    color: Color.white,
  },
  messageHeader: {
    color: Color.purple,
    fontFamily: EFonts.DMSANS_BOLD,
    fontSize: 15,
    marginBottom: 5,
    left: 20,
  },
  groupsContainer: {
    // marginHorizontal: 5,
    // marginTop: 5,
    padding: 10,
    paddingLeft: 2,
    paddingRight: 2,
    paddingVertical: 5,
    color: Color.black,
    backgroundColor: Color.white,
    flex: 1,
  },
  groupItem: {
    padding: 20,
    color: Color.white,
    backgroundColor: Color.white,
    borderBottomWidth: 0.3,
    borderBottomColor: '#e3e3e3',
    alignItems: 'center',
  },
  groupAvatar: {},
  chatName: {
    fontFamily: EFonts.SORA_BOLD,
    color: Color.lightBlack,
    fontSize: 15,
    lineHeight: 20,
    marginBottom: GenUtil.isIOS() ? 5 : 2,
  },
  lastMessage: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 15,
    color: '#595959',
    width: '100%',
  },
  groupText: {
    color: Color.black,
  },
  loaderContainer: {
    height: height / 1.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyListContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: height - 120, // online container height + padding
  },
  emptyListMessage: {
    color: Color.white,
    fontWeight: '800',
    fontSize: 18,
  },
  SafeLottieView: { height: 200 },
  cameraIcon: {
    alignSelf: 'center',
  },
  basicDetails: {
    marginBottom: GenUtil.isIOS() ? 3 : 2,
    marginLeft: 10,
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 15,
    color: '#595959',
    alignSelf: 'center',
    width: '30%',
    flex: 1,
  },
  categoriesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 10,
    paddingHorizontal: 16,
  },
  enterIconContainer: {
    height: 20,
    width: 20,
    borderRadius: 20 / 2,
    borderWidth: 1,
    borderColor: '#FFA242',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
