import { StyleSheet } from 'react-native';
import React from 'react';
import { Text } from 'src/components/Themed';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';

const HeadingText = ({ title = 'Title', additionalStyle = null }) => (
  <Text style={[styles.titleStyle, additionalStyle && additionalStyle]}>{title}</Text>
);

const styles = StyleSheet.create({
  titleStyle: {
    fontFamily: EFonts.SORA_REGULAR,
    fontSize: 12,
    lineHeight: 20,
    color: Color.black,
  },
});

export default HeadingText;
