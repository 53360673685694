import { StyleSheet, Text, View } from 'react-native';
import { Color } from 'src/constant/Color';
import React from 'react';
import Button from 'src/components/common/buttons/Button';
import CrownSvg from 'src/assets/icons/crown.svg';
import { useNavigation } from '@react-navigation/native';

const UnlockPremiumCard = () => {
  const navigation = useNavigation();
  return (
    <View style={styles.cardContainer}>
      <View style={styles.container}>
        <CrownSvg style={styles.image} />
        <View style={styles.text}>
          <Text style={styles.heading}>Unlock Premium</Text>
          <Text style={styles.subHeading}>
            Purchase any of the memebrship plan and get the access of our Premium Features.
          </Text>
          <Button
            onPress={() => navigation.navigate('membership')}
            variant={'primary'}
            size={'small'}
            styles={{ borderRadius: 8, width: 140 }}
          >
            Subscribe Now
          </Button>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: 'white',
    borderRadius: 10,
    padding: 15,
    margin: 10,
    width: '90%',
    borderColor: Color.purple,
    borderWidth: 2,
    marginTop: 20,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    marginRight: 20,
    alignSelf: 'flex-start',
  },
  text: {
    width: '75%',
  },
  heading: {
    fontSize: 17,
    fontWeight: '600',
    marginBottom: 10,
  },
  subHeading: {
    fontSize: 12,
    fonWeight: '400',
    marginBottom: 10,
  },
});

export default UnlockPremiumCard;
