import React from 'react';
import { Text, View, KeyboardAvoidingView } from 'src/components/Themed';
import { Color } from 'src/constant/Color';
import { GenUtil } from 'src/util/GenUtil';
import { StyleSheet } from 'react-native';
import PrimaryButton from './common/buttons/PrimaryButton';
import { LayoutConstant } from 'src/constant/LayoutConstant';

const { height, width } = GenUtil.getDimension();

const Onboarding = ({
  title = '',
  Component = null,
  buttonText = 'Continue',
  handleKeyPress = () => {},
  buttonDisabled = false,
  verticalAlignCenter = false,
  isValidating = false,
  isMinimumPaddingTop = false,
}) => {
  return (
    <View
      style={
        isMinimumPaddingTop
          ? StyleSheet.compose(styles.container, styles.minimunPaddingTopOverride)
          : verticalAlignCenter
          ? StyleSheet.compose(styles.container, styles.verticalCenterOverride)
          : styles.container
          ? StyleSheet.compose(styles.container, styles.verticalCenterOverride)
          : styles.container
      }
    >
      <Text style={styles.header}>{title}</Text>
      {Component}
      <KeyboardAvoidingView behavior={'position'} style={styles.buttonWrapper}>
        <PrimaryButton
          buttonDisabled={buttonDisabled}
          isValidating={isValidating}
          buttonText={buttonText}
          handleKeyPress={handleKeyPress}
          buttonStyle={{ maxWidth: '90%' }}
        />
      </KeyboardAvoidingView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: height * 0.25,
    paddingHorizontal: LayoutConstant.paddingHorizontal * 2,
    justifyContent: 'flex-start',
  },
  minimunPaddingTopOverride: {
    paddingTop: height * 0.15,
  },
  verticalCenterOverride: {
    paddingTop: 0,
    justifyContent: 'center',
  },
  buttonWrapper: {
    position: 'absolute',
    width: '100%',
    bottom: 55,
    backgroundColor: 'transparent',
    alignItems: 'center',
    alignSelf: 'center',
  },
  header: {
    fontSize: 32,
    fontFamily: 'dmsans-bold',
    marginBottom: 20,
  },
});

export default Onboarding;
