import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useEffect, useState } from 'react';
import { Color } from 'src/constant/Color';
import MimialProfileCard from 'src/components/common/profileCard/MinimalProfileCard';
import { ProfileResp } from 'src/common/response/profile.resp';
import { InterestService } from 'src/service/InterestService';
import { InterestsStatus, SentType } from 'src/common/models/interest.model';

enum Tab {
  ACCEPTED = 'Interest Accepted',
  SENT = 'Interest Sent',
}

const InterestsSection = () => {
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.ACCEPTED);
  const [profilesData, setProfilesData] = useState<ProfileResp[]>(null);
  const fetchInterestProfiles = async () => {
    setProfilesData(null);
    const response = await InterestService.fetchInterests({
      sentType: SentType.Sent,
      status: currentTab === Tab.SENT ? InterestsStatus.Pending : InterestsStatus.Accepted,
    });

    if (response.success) {
      setProfilesData(response.data?.length > 0 ? response.data : null);
    } else {
      setProfilesData(null);
      console.log(response.message, response);
    }
  };

  useEffect(() => {
    fetchInterestProfiles();
  }, [currentTab]);

  return (
    <View style={styles.container}>
      <View style={styles.tabContainer}>
        {Object.values(Tab).map((tab) => {
          const isSelected = currentTab === tab;
          return (
            <TouchableOpacity
              style={[styles.tab, isSelected ? styles.selectedTab : {}]}
              activeOpacity={100}
              key={tab}
              onPress={() => setCurrentTab(tab)}
            >
              <Text style={[styles.tabText, isSelected ? styles.selectedTabText : {}]}>{tab}</Text>
            </TouchableOpacity>
          );
        })}
      </View>
      {profilesData?.length > 0 ? (
        <FlatList
          data={profilesData}
          renderItem={({ item }) => <MimialProfileCard item={item} />}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item) => item.uuid}
        />
      ) : (
        <>
          {!profilesData ? (
            <View style={{ minHeight: 50, justifyContent: 'center' }}>
              <Text style={{ textAlign: 'center' }}>No Profiles Found</Text>
            </View>
          ) : (
            <View style={{ minHeight: 50, justifyContent: 'center' }}>
              <ActivityIndicator />
            </View>
          )}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '90%',
    marginTop: 20,
  },
  tabContainer: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: 10,
  },
  tab: {
    width: '50%',
    padding: 15,
    borderBottomWidth: 2,
    borderBottomColor: Color.gray,
  },
  selectedTab: {
    borderBottomWidth: 2.5,
    borderBottomColor: Color.purple,
  },
  selectedTabText: {
    color: Color.purple,
    fontWeight: '600',
  },
  tabText: {
    color: Color.black,
    textAlign: 'center',
  },
  button: {
    backgroundColor: Color.black,
    alignSelf: 'center',
    borderRadius: 8,
    marginTop: 15,
  },
});

export default InterestsSection;
