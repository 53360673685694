import * as React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import Settings from 'src/screens/Settings';
import OtherPersonProfile from 'src/screens/UserProfileScreen';
import Notifications from 'src/screens/Notifications';
import EditProfile from 'src/screens/EditProfile';
import EditPhotoScreen from 'src/screens/EditPhotoScreen';
import EditPrivacyPolicyScreen from 'src/screens/EditPrivacyPolicyScreen';
import SearchScreen from 'src/screens/SearchScreen';
import SearchFiltersScreen from 'src/screens/SearchFiltersScreen';
import ListSearchedProfiles from 'src/screens/ListSearchedProfiles';
import ActivityScreen from 'src/screens/ActivityScreen';
import DynamicProfilesList from 'src/screens/DynamicProfileList';

const Stack = createNativeStackNavigator();

export function SettingsScreenStack() {
  return (
    <Stack.Navigator initialRouteName="settings">
      <Stack.Screen
        name="settings"
        component={Settings}
        options={{ headerShown: false, title: 'Settings' }}
      />
      <Stack.Screen
        name="editprivacypolicyscreen"
        component={EditPrivacyPolicyScreen}
        options={{ headerShown: false, title: 'Privacy Settings' }}
      />
      <Stack.Screen
        name="notifications"
        component={Notifications}
        options={{ headerShown: false, title: 'Notification Settings' }}
      />
    </Stack.Navigator>
  );
}

export function SearchScreenStack() {
  return (
    <Stack.Navigator initialRouteName="search">
      <Stack.Screen
        name="search"
        component={SearchScreen}
        options={{ headerShown: false, title: 'Search' }}
      />
      <Stack.Screen
        name="searchedprofilesscreen"
        component={ListSearchedProfiles}
        options={{ headerShown: false, title: 'Searched Profiles' }}
      />
      <Stack.Screen
        name="searchfiltersscreen"
        component={SearchFiltersScreen}
        options={{ headerShown: false, title: 'Search' }}
      />
      <Stack.Screen
        name="otherpersonprofile"
        component={OtherPersonProfile}
        options={{ headerShown: false, title: 'Matrimilan' }}
      />
    </Stack.Navigator>
  );
}

export function EditProfileStack() {
  return (
    <Stack.Navigator initialRouteName="editprofile">
      <Stack.Screen
        name="editprofile"
        component={EditProfile}
        options={{ headerShown: false, title: 'My Profile' }}
      />
      <Stack.Screen
        name="editphotoscreen"
        component={EditPhotoScreen}
        options={{ headerShown: false, title: 'My Photos' }}
      />
      <Stack.Screen
        name="otherpersonprofile"
        component={OtherPersonProfile}
        options={{ headerShown: false, title: 'Matrimilan' }}
      />
    </Stack.Navigator>
  );
}

export function ActivityScreenStack() {
  return (
    <Stack.Navigator initialRouteName="activity">
      <Stack.Screen
        name="activity"
        component={ActivityScreen}
        options={{ headerShown: false, title: 'Profile Activities' }}
      />
      <Stack.Screen
        name="otherpersonprofile"
        component={OtherPersonProfile}
        options={{ headerShown: false, title: 'Matrimilan' }}
      />
    </Stack.Navigator>
  );
}

export function DynamicProfilesStack({ route }) {
  return (
    <Stack.Navigator initialRouteName="dynamicprofileslist">
      <Stack.Screen
        name={'dynamicprofileslist'}
        component={DynamicProfilesList}
        initialParams={route.params}
        options={{ headerShown: false, title: 'MatriMilan' }}
      />
      <Stack.Screen
        name="otherpersonprofile"
        component={OtherPersonProfile}
        options={{ headerShown: false, title: 'Matrimilan' }}
      />
    </Stack.Navigator>
  );
}
