export type NakshatraId = number;
export type NakshatraName = string;

export const NakshatraData: Array<{ name: NakshatraName; id: NakshatraId }> = [
  { name: 'Krittika', id: 1 },
  { name: 'Chitra/ Chitha', id: 2 },
  { name: 'Dhanista/ Avittam', id: 3 },
  { name: 'Vishaka/ Vishakam', id: 4 },
  { name: 'Makha/ Magam', id: 5 },
  { name: 'Poorvapalguni/Puram/Pubbhe', id: 7 },
  { name: 'Poorvashada/ Pooradam', id: 8 },
  { name: 'Shravan/ Thiruvonam', id: 9 },
  { name: 'Krithika/ Karthika', id: 10 },
  { name: 'Punarvasu', id: 11 },
  { name: 'Punarvasu/ Punarpusam', id: 12 },
  { name: 'Bharani', id: 13 },
  { name: 'Rohini', id: 14 },
  { name: 'Ashlesha', id: 15 },
  { name: 'Chitra', id: 16 },
  { name: 'Ashwini/ Ashwathi', id: 17 },
  { name: 'PurvaBhadra', id: 18 },
  { name: 'UttraShadha', id: 19 },
  { name: 'Swati/ Chothi', id: 20 },
  { name: 'Moolam/ Moola', id: 21 },
  { name: 'Hastha/ Atham', id: 22 },
  { name: 'Anuradha', id: 23 },
  { name: 'Pushya', id: 24 },
  { name: 'Mula', id: 25 },
  { name: 'Shatabhi', id: 26 },
  { name: 'Revati', id: 27 },
  { name: 'Mrigasira/ Makayiram', id: 28 },
  { name: 'Hasta', id: 29 },
  { name: 'Sravana', id: 30 },
  { name: 'UttaraBhadra', id: 31 },
  { name: 'Ardra/ Thiruvathira', id: 32 },
  { name: 'Jyeshtha', id: 33 },
  { name: 'Jyesta/ Kettai', id: 34 },
  { name: 'Poorvabadrapada/ Puratathi', id: 35 },
  { name: 'Ardra', id: 36 },
  { name: 'Revathi', id: 37 },
  { name: 'Uttarapalguni/ Uthram', id: 38 },
  { name: 'Uttrabadrapada/ Uthratadhi', id: 39 },
  { name: 'Makha/Magam', id: 40 },
  { name: 'Ashlesha/ Ayilyam', id: 41 },
  { name: 'Uttarashada/ Uthradam', id: 42 },
  { name: 'Shatataraka/ Sadayam/ Sadabist', id: 43 },
  { name: 'UttaraPhalguni', id: 44 },
  { name: 'Mrigashira', id: 45 },
  { name: 'Svati', id: 46 },
  { name: 'Pushya/ Poosam/ Pooyam', id: 47 },
  { name: 'Pushya/Poosam/Pooyam', id: 48 },
  { name: 'Magha', id: 49 },
  { name: 'Vishaka/Vishakam', id: 50 },
  { name: 'Ashvini', id: 51 },
  { name: 'Chitra/Chitha', id: 52 },
  { name: 'Poorvabadrapada/Puratathi', id: 53 },
  { name: 'Dhanista', id: 54 },
  { name: 'Moolam/Moola', id: 55 },
  { name: 'PurvaPhalguni', id: 56 },
  { name: 'Swati/Chothi', id: 57 },
  { name: 'PurvaShadha', id: 58 },
  { name: 'Punarvasu/Punarpusam', id: 59 },
  { name: 'Mrigasira/Makayiram', id: 60 },
  { name: 'Punarvasu/ Punarpusam', id: 61 },
  { name: 'Poorvapalguni/ Puram/ Pubbhe', id: 62 },
  { name: 'Jyesta/Kettai', id: 63 },
  { name: 'Shatataraka/Sadayam/Sadabist', id: 64 },
  { name: 'Vishakha', id: 65 },
  { name: 'Anuradha/ Anusham/ Anizham', id: 66 },
  { name: 'Uttarapalguni/Uthram', id: 67 },
  { name: 'Ashlesha/Ayilyam', id: 68 },
  { name: 'Anuradha/Anusham/Anizham', id: 69 },
  { name: 'Krithika/Karthika', id: 70 },
  { name: 'Hastha/Atham', id: 71 },
  { name: 'Poorvashada/Pooradam', id: 72 },
  { name: 'Dhanista/Avittam', id: 73 },
  { name: 'Ardra/Thiruvathira', id: 74 },
  { name: 'Shravan/Thiruvonam', id: 75 },
  { name: 'Uttarashada/Uthradam', id: 76 },
  { name: 'Uttrabadrapada/Uthratadhi', id: 77 },
  { name: 'Ashwini/Ashwathi', id: 78 },
  { name: 'Poorvapalguni', id: 79 },
  { name: 'Anuradha', id: 81 },
  { name: 'Shatataraka', id: 82 },
  { name: 'Uttara Phalguni', id: 83 },
  { name: 'Shatataraka', id: 84 },
  { name: 'Hastham', id: 85 },
];

export const NakshatraDataMap: { [key in number]: { name: NakshatraName; id: NakshatraId } } = NakshatraData.reduce((map, data) => {
  map[data.id] = data;
  return map;
}, {});
