export enum InterestsStatus {
  Filtered = 'Filtered', // Pending
  Pending = 'Pending', // Pending
  Accepted = 'Accepted', // Accepted
  Declined = 'Declined', // Declined
  Archived = 'Archived', // Archived, not responded for long time
  // Ignored = 'Ignored',
}

// export enum InterestCategory {
//   Normal = 'Normal',
//   Filtered = 'Filtered',
//   Premium = 'Premium',
// }

export enum SentType {
  Sent = 'Sent',
  Received = 'Received',
}

export enum InterestActionType {
  Accept = 'accept',
  Decline = 'decline',
  Ignore = 'ignore',
  Archived = 'archived',
}

export interface InterestReminder {
  reminderSentOn: Date;
  reminderSentMessage: string;
  reminderViewedOn?: Date;
}

export enum InterestStatsCategories {
  Filtered = 'Filtered',
  Accepted = 'Accepted',
  Declined = 'Declined',
  Received = 'Received',
  Sent = 'Sent',
  Shortlisted = 'Shortlisted',
}

export interface InterestStats {
  category: InterestStatsCategories;
  totalCount: number;
  newCount: number;
}
