import { Keyboard, StyleSheet, TextInput } from 'react-native';

import { Text } from 'src/components/Themed';
import { Color } from 'src/constant/Color';
import React, { useState } from 'react';
import Onboarding from 'src/components/Onboarding';

export default function MyMembership({ navigation }) {
  const [firstName, setFirstName] = useState('');

  const handleKeyPress = () => {
    console.log(firstName);
    Keyboard.dismiss();
  };

  return (
    <Onboarding
      title="What's your first name?"
      Component={
        <React.Fragment>
          <TextInput
            style={styles.textInput}
            value={firstName}
            onChangeText={(value) => setFirstName(value)}
            placeholder={'Davis'}
          />

          <Text style={styles.info}>You won't be able to change it later.</Text>
        </React.Fragment>
      }
      handleKeyPress={handleKeyPress}
      buttonDisabled={firstName.trim().length < 3}
    />
  );
}

const styles = StyleSheet.create({
  textInput: {
    fontSize: 18,
    fontWeight: '700',
    color: Color.purple,
    backgroundColor: Color.backgroundGray,
    width: '100%',
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderRadius: 24,
  },
  info: {
    color: Color.gray,
    marginVertical: 10,
    left: 5,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
