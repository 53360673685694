export type LanguageId = number;

export interface LanguageDetail {
  id: LanguageId;
  name: string;
  category: string;
}
export const LanguageData: Array<LanguageDetail> = [
  { id: 1, name: 'Assamese', category: 'East' },
  { id: 2, name: 'Bengali', category: 'East' },
  { id: 3, name: 'Oriya', category: 'East' },
  { id: 4, name: 'Sikkim/ Nepali', category: 'East' },
  { id: 5, name: 'Arabic', category: 'Foreign' },
  { id: 6, name: 'French', category: 'Foreign' },
  { id: 7, name: 'German', category: 'Foreign' },
  { id: 8, name: 'Hebrew', category: 'Foreign' },
  { id: 9, name: 'Italian', category: 'Foreign' },
  { id: 10, name: 'Japanese', category: 'Foreign' },
  { id: 11, name: 'Mandarin', category: 'Foreign' },
  { id: 12, name: 'Persian', category: 'Foreign' },
  { id: 13, name: 'Portuguese', category: 'Foreign' },
  { id: 14, name: 'Pushto', category: 'Foreign' },
  { id: 15, name: 'Spanish', category: 'Foreign' },
  { id: 16, name: 'English', category: 'Foreign' },
  { id: 17, name: 'Hindi-Bihar/Jharkhand', category: 'North' },
  { id: 18, name: 'Hindi-Delhi', category: 'North' },
  { id: 19, name: 'Haryanvi', category: 'North' },
  { id: 20, name: 'Himachali', category: 'North' },
  { id: 21, name: 'Kashmiri', category: 'North' },
  { id: 22, name: 'Hindi-MP/CG', category: 'North' },
  { id: 23, name: 'Punjabi', category: 'North' },
  { id: 24, name: 'Hindi-Rajasthan', category: 'North' },
  { id: 25, name: 'Marwadi', category: 'North' },
  { id: 26, name: 'Sindhi', category: 'North' },
  { id: 27, name: 'Sindhi', category: 'North' },
  { id: 28, name: 'Hindi-UP/UK', category: 'North' },
  { id: 29, name: 'Urdu', category: 'North' },
  { id: 30, name: 'Telugu', category: 'South' },
  { id: 31, name: 'Kannada', category: 'South' },
  { id: 32, name: 'Malayalam', category: 'South' },
  { id: 33, name: 'Tamil', category: 'South' },
  { id: 34, name: 'Tulu', category: 'South' },
  { id: 35, name: 'Urdu', category: 'South' },
  { id: 36, name: 'Gujarati', category: 'West' },
  { id: 37, name: 'Hindi-MP/CG', category: 'West' },
  { id: 38, name: 'Marathi', category: 'West' },
  { id: 39, name: 'Konkani', category: 'West' },
  { id: 40, name: 'Kutchi', category: 'West' },
  { id: 41, name: 'Hindi', category: 'North' },
  { id: 42, name: 'Daman & Diu', category: 'West' },
  { id: 43, name: 'Nicobarese', category: 'East' },
  { id: 44, name: 'Aurnachali', category: 'East' },
  { id: 45, name: 'Khasi/Meghalaya', category: 'East' },
  { id: 46, name: 'Mizo', category: 'East' },
  { id: 47, name: 'Konyak/Nagaland', category: 'East' },
  { id: 48, name: 'Tripuri', category: 'East' },
  { id: 49, name: 'Foreign origin', category: 'Foreign' },
  { id: 50, name: 'Manipuri', category: 'East' },
  { id: 51, name: 'Lakshadweep/Mahl', category: 'West' },
];

export const LanguageDataMap: { [key in number]: LanguageDetail } = LanguageData.reduce((map, data) => {
  map[data.id] = data;
  return map;
}, {});
