import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { GenUtil } from 'src/util/GenUtil';
import { Icon } from 'react-native-elements';
import { Ionicons } from '@expo/vector-icons';

import { View, Text, KeyboardAvoidingView } from 'src/components/Themed';
import InputAdornment from 'src/components/common/input/InputAdornment';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';
import EFonts from 'src/constant/EFonts';
import { Color } from 'src/constant/Color';

const { height, width } = GenUtil.getDimension();

const OnboardingPasswordInputComp = ({
  next = () => {},
  prev = () => {},
  password = null,
  setPassword = (_) => {},
  confirmPassword = null,
  setconfirmPassword = (_) => {},
}) => {
  const [passwordVisible, setPasswordVisbile] = useState(false);
  const [confirmPasswordVisible, setconfirmPasswordVisible] = useState(false);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Set Your Password</Text>
      <InputAdornment
        icon={<Icon name="lock" type="font-awesome" size={20} color={Color.gray} />}
        endIcon={
          password.trim().length !== 0 && (
            <Ionicons
              name={passwordVisible ? 'eye-off' : 'eye'}
              type="font-awesome"
              size={20}
              color={Color.gray}
            />
          )
        }
        onEndIconClick={() => setPasswordVisbile(!passwordVisible)}
        value={password}
        setValue={setPassword}
        secureTextEntry={!passwordVisible}
        placeholder={'New Password'}
        styleOverride
      />
      <InputAdornment
        icon={<Icon name="lock" type="font-awesome" size={20} color={Color.gray} />}
        endIcon={
          confirmPassword.trim().length !== 0 && (
            <Ionicons
              name={confirmPasswordVisible ? 'eye-off' : 'eye'}
              type="font-awesome"
              size={20}
              color={Color.gray}
            />
          )
        }
        onEndIconClick={() => setconfirmPasswordVisible(!confirmPasswordVisible)}
        value={confirmPassword}
        setValue={setconfirmPassword}
        placeholder={'Confirm New Password'}
        styleOverride
        secureTextEntry={!confirmPasswordVisible}
      />
      {password.trim().length < 6 && (
        <View
          style={
            password.trim().length >= 6
              ? styles.errorView
              : StyleSheet.compose(styles.errorView, styles.errorViewAppearOverride)
          }
        >
          <Icon name="warning" color={Color.red} />
          <Text style={styles.errorInfo}>Password must be atleast 6 characters</Text>
        </View>
      )}
      <View
        style={
          password === confirmPassword && password.trim().length >= 3
            ? styles.errorView
            : StyleSheet.compose(styles.errorView, styles.errorViewAppearOverride)
        }
      >
        <Icon name="warning" color={Color.red} />
        <Text style={styles.errorInfo}>Passwords do not match</Text>
      </View>
      <KeyboardAvoidingView behavior={'position'} style={styles.buttonWrapper}>
        <PrimaryButton
          buttonDisabled={
            password !== confirmPassword ||
            password.trim().length < 6 ||
            confirmPassword.trim().length < 6
          }
          buttonText="Continue"
          handleKeyPress={next}
        />
      </KeyboardAvoidingView>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontFamily: EFonts.SORA_LIGHT,
    fontSize: 28,
    lineHeight: 38,
    color: Color.lightBlack,
    marginTop: 10,
  },
  container: {
    paddingTop: 20,
    flex: GenUtil.isWEB() ? null : 1,
    paddingHorizontal: width * 0.05,
    backgroundColor: 'transparent',
  },
  back: {
    justifyContent: 'flex-start',
  },
  buttonWrapper: {
    position: 'absolute',
    top: GenUtil.isWEB() ? height * 0.83 : height * 0.82,
    left: width * 0.1,
  },
  errorView: {
    flexDirection: 'row',
    marginTop: 5,
    left: 5,
    opacity: 0,
    alignItems: 'center',
  },
  errorViewAppearOverride: {
    opacity: 1,
  },
  errorInfo: {
    color: Color.red,
    marginLeft: 5,
  },
});

export default OnboardingPasswordInputComp;
