import { ActivityIndicator, FlatList, View, Text } from 'react-native';
import { useEffect, useState } from 'react';
import { ProfileResp } from 'src/common/response/profile.resp';
import { InterestService } from 'src/service/InterestService';
import { InterestsStatus, SentType } from 'src/common/models/interest.model';
import HomeSection from 'src/components/common/HomeSection';
import MinimalProfileCard from 'src/components/common/profileCard/MinimalProfileCard';
import { ProfileTabsEnum } from 'src/screens/DynamicProfileList';

const RecievedInterestsSection = () => {
  const [profilesData, setProfilesData] = useState<ProfileResp[]>([]);
  const fetchReceivedInterestProfiles = async () => {
    try {
      const response = await InterestService.fetchInterests({
        sentType: SentType.Received,
        status: InterestsStatus.Pending,
      });
      if (response.success) {
        setProfilesData(response.data?.length > 0 ? response.data : null);
      } else {
        setProfilesData(null);
        console.log(response.message, response);
      }
    } catch (error) {
      setProfilesData(null);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchReceivedInterestProfiles();
  }, []);

  return profilesData && profilesData.length > 0 ? (
    <HomeSection
      title={'Interest Recieved'}
      navigateOnViewAll={{
        to: 'dynamicprofileslist',
        state: {
          title: 'Interest Recieved',
          tabs: [
            ProfileTabsEnum.InterestReceived,
            ProfileTabsEnum.InterestSent,
            ProfileTabsEnum.InterestFiltered,
            ProfileTabsEnum.InterestArchived,
          ],
        },
      }}
    >
      {profilesData ? (
        <FlatList
          data={profilesData}
          renderItem={({ item }) => <MinimalProfileCard item={item} showAcceptAction={true} />}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item) => item.uuid}
        />
      ) : (
        <>
          {!profilesData ? (
            <View style={{ minHeight: 50, justifyContent: 'center' }}>
              <Text style={{ textAlign: 'center' }}>No Interests Recieved</Text>
            </View>
          ) : (
            <View style={{ minHeight: 50, justifyContent: 'center' }}>
              <ActivityIndicator />
            </View>
          )}
        </>
      )}
    </HomeSection>
  ) : (
    <></>
  );
};

export default RecievedInterestsSection;
